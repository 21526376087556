import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom'
import MasterService from '../../Services/MasterService';
import InvoiceService from '../../Services/Invoices/InvoiceService';
import { connect } from "react-redux";
import { Button, Dropdown } from 'react-bootstrap';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import { validateCreateInvoice } from './Validation'
import { changeDateFormate } from '../../Helpers/DateFormat';
import CreatedInvoiceList from './CreateInvoiceSideList';
import ChangeLog from '../../Components/BoilerPlate/ChangeLog';
import { setViewInvoice } from '../../Redux/Actions/Invoices';
import CurrencyInput from 'react-currency-input-field';
import InputNumber from '../../Components/BoilerPlate/InputNumber';
import { Multiselect } from 'multiselect-react-dropdown';
import  TextareaAutosize  from "@mui/material/TextareaAutosize";

class CreateInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_side_fltr: false,
      show_save_modal: false,
      show_change_log: false,

      items: [{
        "item": "", "hsn": "", "rate": 0, "quantity": 0, "gross_total": 0,
        "discount_percentage": 0, "discounted_amount": 0, "discounted_total": 0, "cgst_amount": 0,
        "sgst_amount": 0, "igst_amount": 0, "cgst_percentage": 0, "sgst_percentage": 0,
        "igst_percentage": 0, "gst_total_amount": 0, "net_total": 0
      }],
      gross_total: 0,
      discounted_amount: 0,
      discounted_total: 0,
      cgst_amount: 0,
      sgst_amount: 0,
      igst_amount: 0,
      gst_total_amount: 0,
      net_total: 0,
      tds_percentage: 0,
      tds_amount: 0,
      total_after_tds: 0,

      financial_institute_id: 0,
      account_number: "",
      ifsc_code: "",
      city_name: "",
      branch: "",

      category_id: 0,
      tags: [],
      selected_tags: [],
      listed_tags: [],
      status: 2,

      digital_signature: "",
      is_gst: 0,
      last_invoice_number: "",

      categories: [],
      payment_details: [],
      countries: [],
      states: [],
      cities: [],
      errors: {},
      loading: false,

      invoice_id: this.props.match.params.invoice_id,

      invoice_type: "",
      invoice_number: "",
      payment_status: "",
      name: "",
      email: "",
      phone_number: "",
      gst_no: "",
      address: "",
      city_id: "",
      state_id: "",
      country_id: "",
      pincode: "",
      date: '',
      due_date: "",
      payment_id: ""

    }
  }

  toogleSaveModal = () => {
    this.setState({
      show_save_modal: !this.state.show_save_modal
    });
  }

  toggleSideFilter = () => {
    this.setState({
      show_side_fltr: !this.state.show_side_fltr
    });
  }

  toogleChangeLogModal = () => {
    this.setState({
      show_change_log: !this.state.show_change_log
    });
  }

  handleOnChange = (e) => {
    const { value, name } = e.target;
    if (name == 'status') {
      if (value == 2) {
        this.setState({ [name]: value });
      } else {
        this.checkForReadyState(true)
      }
    }
    else {
      this.setState({ [name]: value }, () => {
        if (name == 'invoice_number' || name == 'payment_status' || name == 'name')
          this.checkForReadyState(false);
      })
    }
  }

  invoiceTypeOnChange = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
    if (value == "2")
      this.setState({ invoice_number: "" });
    this.checkForReadyState(false)
  }

  handleItemOnChange = (e, index) => {
    const { value, name } = e.target;
    let items = [...this.state.items];
    items[index] = { ...items[index], [name]: value };
    this.setState({ items });
  }

  calculateAmount = (e, index) => {
    let items = [...this.state.items];
    if (index != -1) {
      let item = items[index];
      item.gross_total = item.rate * item.quantity;
      item.discounted_amount = item.gross_total * (item.discount_percentage / 100);
      item.discounted_total = item.gross_total - item.discounted_amount;
      item.cgst_amount = item.discounted_total * (item.cgst_percentage / 100);
      item.sgst_amount = item.discounted_total * (item.sgst_percentage / 100);
      item.igst_amount = item.discounted_total * (item.igst_percentage / 100);
      item.gst_total_amount = item.cgst_amount + item.sgst_amount + item.igst_amount;
      item.net_total = item.discounted_total + item.gst_total_amount;
      items[index] = item;
    }

    let gross_total = 0, discounted_amount = 0, discounted_total = 0, cgst_amount = 0,
      sgst_amount = 0, igst_amount = 0, gst_total_amount = 0, net_total = 0,
      tds_amount = 0, total_after_tds = 0;

    for (let i = 0; i < items.length; i++) {
      gross_total += items[i].gross_total;
      discounted_amount += items[i].discounted_amount;
      discounted_total += items[i].discounted_total;
      cgst_amount += items[i].cgst_amount;
      sgst_amount += items[i].sgst_amount;
      igst_amount += items[i].igst_amount;
      gst_total_amount += items[i].gst_total_amount;
      net_total += items[i].net_total;
    }

    tds_amount = (gross_total - discounted_amount) * this.state.tds_percentage / 100;
    total_after_tds = net_total - tds_amount;

    this.setState({ items });
    this.setState({
      gross_total: gross_total, discounted_amount: discounted_amount,
      discounted_total: discounted_total, cgst_amount: cgst_amount,
      sgst_amount: sgst_amount, igst_amount: igst_amount, gst_total_amount: gst_total_amount,
      net_total: net_total, tds_amount: tds_amount, total_after_tds: total_after_tds
    });
  }

  addItemRow = () => {
    let item = {
      "item": "", "hsn": "", "rate": 0, "quantity": 0, "gross_total": 0,
      "discount_percentage": 0, "discounted_amount": 0, "discounted_total": 0, "cgst_amount": 0,
      "sgst_amount": 0, "igst_amount": 0, "cgst_percentage": 0, "sgst_percentage": 0,
      "igst_percentage": 0, "gst_total_amount": 0, "net_total": 0
    }

    this.setState({ items: [...this.state.items, item] })
  }

  removeItem = (index) => {
    let items = [...this.state.items];
    const isEmpty = Object.values(items[index]).every(x => (x === 0 || x === ''));
    if (isEmpty) {
      items.splice(index, 1);
    } else if (confirm("Do you want to remove Item")) {
      items.splice(index, 1);
    }

    if (items.length == 0) {
      this.addItemRow();
    }
    this.setState({ items }, () => { this.state.items.length == 0 ? this.addItemRow() : "" });
  }

  onCountryChange = (e) => {
    let country_id = e.target.value;
    if (country_id) {
      this.setState({
        country_id: country_id,
        city_id: ""
      });
      this.getStates(country_id);
    }
  }

  onStateChange = (e) => {
    let state_id = e.target.value;
    if (state_id) {
      this.setState({
        state_id: state_id,
        city_id: ""
      });
      this.getCities(state_id);
    }
  }

  onCityChange = (e) => {
    let city_id = e.target.value;
    if (city_id) {
      this.setState({
        city_id: city_id
      });
    }
  }

  onPaymentChange = (e) => {
    let index
    if (typeof e !== "undefined" && typeof e.target !== "undefined" && typeof e.target.value !== "undefined")
      index = e.target.value;
    else
      index = e;
    this.setState({
      payment_id: index,
      financial_institute_id: this.state.payment_details[index].id,
      account_number: this.state.payment_details[index].account_number,
      ifsc_code: this.state.payment_details[index].ifsc_code,
      city_name: this.state.payment_details[index].city_name,
      branch: this.state.payment_details[index].branch,
    });
  }

  getCountries = () => {
    MasterService.getCountries().then(
      (data) => {
        if (data.response_code == 200) {
          this.setState({
            countries: data.result.countries
          });
        }
      });
  }
  getStates = (country_id) => {
    MasterService.getStates(country_id).then(
      (data) => {
        if (data.response_code == 200) {
          this.setState({
            states: data.result.states
          });
        }
      });
  }
  getCities = (state_id) => {
    MasterService.getCities(state_id).then(
      (data) => {
        if (data.response_code == 200) {
          this.setState({
            cities: data.result.cities
          });
        }
      });
  }

  setDate = (date, key) => {
    this.setState({ [key]: date }, () => {
      this.checkForReadyState(false)
    })
  }

  getPaymentDetail = () => {
    MasterService.getPaymentDetail().then(
      (data) => {
        if (data.response_code == 200) {
          this.setState({
            payment_details: data.result.payment_details
          }, () => {
            if (this.state.financial_institute_id) {
              this.state.payment_details.map((item, index) => {
                if (item.id === this.state.financial_institute_id)
                  this.onPaymentChange(index)
              })
            }
          });
        }
      });
  }

  getCategryList = () => {
    MasterService.getCategryList().then(
      (data) => {
        if (data.response_code == 200) {
          this.setState({
            categories: data.result.categories
          });
        }
      });
  }

  getInitialdata = () => {
    InvoiceService.getInitialdata().then(
      (data) => {
        if (data.response_code == 200) {
          this.setState({
            digital_signature: data.result.digital_signature,
            is_gst: data.result.gst,
            last_invoice_number: data.result.last_invoice_number
          });
        }
      });
  }

  getTags = () => {
    MasterService.getTags().then(
      (data) => {
        if (data.response_code == 200) {
          this.setState({
            listed_tags: data.result.tags
          });
        }
      });
  }

  tagSelected = (index) => {
    let tag = this.state.listed_tags[index];
    this.setState({ selected_tags: [...this.state.selected_tags, tag.id] });
  }

  removeTag = (index) => {
    let selected_tags = [...this.state.selected_tags];
    selected_tags.splice(index, 1);
    this.setState({ selected_tags });

  }

  checkForReadyState = (valid) => {
    let data = {
      "invoice_type": this.state.invoice_type,
      "invoice_number": this.state.invoice_number,
      "payment_status": this.state.payment_status,
      "date": changeDateFormate(this.state.date),
      "name": this.state.name,
      "phone_number": this.state.phone_number,
    }
    let errors = validateCreateInvoice(data);
    if (Object.keys(errors).length === 0) {
      this.setState({
        status: 1
      });
    } else {
      if (valid)
        toast.warning('Please fill the mandatory details')
      this.setState({
        status: 2
      });
    }
  }
  /*
    set all invoice data here
  */
  getInvoiceDetail = (invoice_id) => {
    InvoiceService.getInvoiceDetail(invoice_id).then(
      (data) => {
        if (data.response_code == 200) {
          this.setState({
            is_gst: data.result.gst,
            invoice_type: data.result.invoice_type,
            invoice_number: data.result.invoice_number,
            payment_status: data.result.payment_status,
            date: new Date(data.result.date),
            due_date: new Date(data.result.due_date),
            name: data.result.name,
            email: data.result.email,
            phone_number: data.result.phone_number,
            gst_no: data.result.gst_no,
            address: data.result.address,
            city_id: data.result.city_id,
            state_id: data.result.state_id,
            country_id: data.result.country_id,
            pincode: data.result.pincode,
            items: data.result.items,
            category_id: data.result.category_id,
            financial_institute_id: data.result.financial_institute_id,

            gross_total: data.result.gross_total,
            discounted_amount: data.result.dicounted_amount,
            discounted_total: data.result.discounted_total,
            cgst_amount: data.result.cgst_amount,
            sgst_amount: data.result.sgst_amount,
            igst_amount: data.result.igst_amount,
            gst_total_amount: data.result.gst_total_amount,
            net_total: data.result.net_total,
            tds_percentage: data.result.tds_percentage,
            tds_amount: data.result.tds_amount,
            total_after_tds: data.result.total_after_tds,
          }, () => {
            this.getPaymentDetail()
          });
          this.getStates(data.result.country_id)
          this.getCities(data.result.state_id)
          this.setTags(data.result.tags)
        }
      });
  }


  setTags = (tagList) => {
    let tags = tagList.map(item => {
      return item.id
    })
    this.setState({ selected_tags: tags })
  }

  emptyStateValue = () => {
    this.setState({
      items: [{
        "item": "", "hsn": "", "rate": 0, "quantity": 0, "gross_total": 0,
        "discount_percentage": 0, "discounted_amount": 0, "discounted_total": 0, "cgst_amount": 0,
        "sgst_amount": 0, "igst_amount": 0, "cgst_percentage": 0, "sgst_percentage": 0,
        "igst_percentage": 0, "gst_total_amount": 0, "net_total": 0
      }],
      gross_total: 0,
      discounted_amount: 0,
      discounted_total: 0,
      cgst_amount: 0,
      sgst_amount: 0,
      igst_amount: 0,
      gst_total_amount: 0,
      net_total: 0,
      tds_percentage: 0,
      tds_amount: 0,
      total_after_tds: 0,

      financial_institute_id: 0,
      account_number: "",
      ifsc_code: "",
      city_name: "",
      branch: "",

      category_id: 0,
      selected_tags: [],
      status: 2,

      invoice_id: '',

      invoice_type: "",
      invoice_number: "",
      payment_status: "",
      name: "",
      email: "",
      phone_number: "",
      gst_no: "",
      address: "",
      city_id: "",
      state_id: "",
      country_id: "",
      pincode: "",
      date: '',
      due_date: "",
      payment_id: ""

    })
  }

  componentWillReceiveProps(nextProps) {
    if ((nextProps.match.params.invoice_id !== this.props.match.params.invoice_id)) {
      if (nextProps.match.params.invoice_id) {
        this.setState({
          invoice_id: nextProps.match.params.invoice_id
        }, () => this.getInvoiceDetail(nextProps.match.params.invoice_id))
      } else {
        this.emptyStateValue()
      }
    }
  }

  componentDidMount() {
    if (this.state.invoice_id) {
      this.getInvoiceDetail(this.state.invoice_id);
    } else {
      this.getPaymentDetail();
    }
    this.getTags();
    this.getInitialdata();
    this.getCountries();
    this.getCategryList();
  }


  saveInvoice = (e) => {
    const { dispatch, history } = this.props;
    e.preventDefault();

    this.setState({
      loading: true
    });
    let data = {
      "invoice_type": this.state.invoice_type,
      "invoice_number": this.state.invoice_number,
      "payment_status": this.state.payment_status,
      "date": changeDateFormate(this.state.date),
      "due_date": changeDateFormate(this.state.due_date),
      "name": this.state.name,
      "email": this.state.email,
      "phone_number": this.state.phone_number,
      "gst_no": this.state.gst_no,
      "address": this.state.address,
      "city_id": this.state.city_id,
      "state_id": this.state.state_id,
      "country_id": this.state.country_id,
      "pincode": this.state.pincode,
      "items": this.state.items,
      "gross_total": this.state.gross_total,
      "discounted_amount": this.state.discounted_amount || 0,
      "discounted_total": this.state.discounted_total,
      "cgst_amount": this.state.cgst_amount,
      "sgst_amount": this.state.sgst_amount,
      "igst_amount": this.state.igst_amount,
      "gst_total_amount": this.state.gst_total_amount,
      "net_total": this.state.net_total,
      "tds_percentage": this.state.tds_percentage,
      "tds_amount": this.state.tds_amount,
      "total_after_tds": this.state.total_after_tds,
      "financial_institute_id": this.state.financial_institute_id,
      "category_id": this.state.category_id,
      "tags": this.state.selected_tags,
      "status": this.state.status,
    }
    if (this.state.invoice_id)
      data.id = this.state.invoice_id
    let errors = validateCreateInvoice(data);
    if (Object.keys(errors).length === 0) {
      if (this.state.invoice_id) {
        InvoiceService.updateInvoice(data).then(
          (data) => {
            this.setState({
              loading: false
            });
            if (data.response_code == 200) {
              toast.success(data.message);
              let invoice = {
                "date": changeDateFormate(this.state.date),
                "id": this.state.invoice_id,
                "invoice_type": this.state.invoice_type,
                "is_star": 0,
                "name": this.state.name,
                "payment_status": this.state.payment_status,
                "status": this.state.status,
                "total_after_tds": this.state.total_after_tds
              }
              this.props.newInvoice(invoice, true)
            } else {
              toast.error(data.message);
              // this.setState({
              //   errors: data.reason
              // });
            }
          });
      } else
        InvoiceService.createInvoice(data).then(
          (data) => {
            this.setState({
              loading: false
            });
            if (data.response_code == 200) {
              toast.success(data.message);
              let invoice = {
                "date": changeDateFormate(this.state.date),
                "id": data.result.id,
                "invoice_type": this.state.invoice_type,
                "is_star": 0,
                "name": this.state.name,
                "payment_status": this.state.payment_status,
                "status": this.state.status,
                "total_after_tds": this.state.total_after_tds
              }
              this.props.newInvoice(invoice, false)
              this.props.history.push("/invoices/create/" + data.result.id)

            } else {
              toast.error(data.message);
              // this.setState({
              //   errors: data.reason
              // });
            }
          });
    } else {
      this.setState({
        loading: false,
        errors: errors
      });
    }
  }

  render() {
    return (
      <div>
        {/* <div className="inner-content-sec dev-inner-content-sec">
          <div className="clearfix main-title-btn-sec">
            <div className="head-title pull-left">
              <h2 className="heads-sbold blu-color m-0">Invoices</h2>
            </div>            
          </div>
        </div> */}

        <div className="inner-content-sec dev-inner-content-sec">
        
          {/* <div className="clearfix main-title-btn-sec">
            <div className="head-title pull-left">
              <h2 className="heads-sbold s-sbold blu-color m-0">Invoices</h2>
            </div>  
            <div className="pull-right">
              <a className="cross" onClick={this.toogleSaveModal}>
                <span className="icon-Cross-with-grey-circle"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
              </a>
            </div>          
          </div> */}

          <div className="row sub-header sub-header-bg">
						<div className="col-md-6 sleft">
							<h2 className="s-sbold blu-color spheading m-0">Invoices<span className="icon-Information green-icon help"></span></h2>
						</div>
						<div className="col-md-6 sright">
							<h2 className="s-sbold blu-color m-0">
								<span className="icon-log icon-round-info-button shicon" onClick={this.getLogs}></span>
								<Link to={'/invoices'} className="cross line-height-inherit shclose" onClick={()=>this.toogleChangeLogModal()}>
									<span class="icon-Cross-with-grey-circle shclosei"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
								</Link>
							</h2>
						</div>
					</div>

          <div className="main-file-item-sec">
            
            <div className="row">
              <div className="col-xl-12 col-sm-12">
                <div className="right-inner-content-sec pb-2">
                  <div className="right-inner-content-sec-box">
                    <div className="top-head clearfix">
                      <p className="pull-left s-sbold ft-sz-22 blu-color m-0">Invoice Details</p>
                      {/* <p className="pull-right s-sbold ft-sz-22 blu-color d-flex m-0"><span className="icon-log icon-round-info-button" onClick={this.toogleChangeLogModal}></span></p> */}
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-sm-12"></div>
                      <div className="col-lg-9 col-sm-12 right-form-sec">
                        <div className="row">
                          <div className="col-xl-4 col-lg-6 col-sm-12">
                            <div className="form-group">
                              <p className="lable-input font-colr">Type<span className="red-star">*</span></p>
                              <select className="form-control"
                                onChange={this.invoiceTypeOnChange}
                                value={this.state.invoice_type}
                                name="invoice_type" >
                                <option value="">Select Invoice type</option>
                                <option value="1">Invoice</option>
                                <option value="2">Proforma</option>
                              </select>
                              {this.state.errors.invoice_type && <small className="form-text text-muted">{this.state.errors.invoice_type}</small>}
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-6 col-sm-12">
                            <div className="form-group clearfix">
                              <p className="lable-input font-colr pull-left">Invoice No.<span className="red-star">*</span></p>
                              {this.state.last_invoice_number && <p className="lable-input font-colr pull-right">(Last inv# : {this.state.last_invoice_number} )</p>}
                              <input type="text" className="form-control input-bg-green"
                                className={"form-control " + (this.state.errors.invoice_number ? " wrong-red " : "")}
                                value={this.state.invoice_number}
                                onChange={this.handleOnChange}
                                name="invoice_number"
                                disabled={this.state.invoice_type == 2 ? "disabled" : ""} />
                              {this.state.errors.invoice_number && <small className="form-text text-muted">{this.state.errors.invoice_number}</small>}
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-6 col-sm-12">
                            <div className="form-group">
                              <p className="lable-input font-colr">Payment Status<span className="red-star">*</span></p>
                              <select className="form-control"
                                onChange={this.handleOnChange}
                                value={this.state.payment_status}
                                name="payment_status" >
                                <option value="">Select Payment Status</option>
                                <option value="1">Paid</option>
                                <option value="2">Unpaid</option>
                                <option value="3">Part Payment</option>
                              </select>
                              {this.state.errors.payment_status && <small className="form-text text-muted">{this.state.errors.payment_status}</small>}
                            </div>
                          </div>
                       
                          <div className="col-xl-4 col-lg-6 col-sm-12">
                            <div className="form-group date-sec">
                              <p className="lable-input font-colr">Date<span className="red-star">*</span></p>
                              <div className="inner-date-sec">
                                <DatePicker
                                  dateFormat="yyyy-MM-dd"
                                  onChange={date => this.setDate(date, "date")}
                                  className="form-control"
                                  selected={this.state.date}
                                  autoComplete="off"
                                  name="date" />
                                <span className="icon-calendar"></span>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-6 col-sm-12">
                            <div className="form-group date-sec">
                              <p className="lable-input font-colr">Due Date</p>
                              <div className="inner-date-sec">
                                <DatePicker
                                  minDate={this.state.invoice_id ? new Date(this.state.date) : new Date()}
                                  dateFormat="yyyy-MM-dd"
                                  onChange={date => this.setDate(date, "due_date")}
                                  className="form-control"
                                  selected={this.state.due_date}
                                  autoComplete="off"
                                  name="due_date" />
                                <span className="icon-calendar"></span>
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-4 col-lg-6 col-sm-12">
                            <div className="form-group clearfix">
                              <p className="lable-input font-colr pull-left">PO/Reference No.</p>
                              <input type="text" className="form-control input-bg-green"
                                className="form-control"
                                // value={this.state.invoice_number}
                                // onChange={this.handleOnChange}
                                name="po_ref_number"
                                // disabled={this.state.invoice_type == 2 ? "disabled" : ""} 
                              />
                            </div>
                          </div>

                         
                          <div className="col-xl-4 col-lg-6 col-sm-12">
                            <div className="form-group">
                              <p className="lable-input font-colr">Category</p>
                              <select className="form-control"
                                value={this.state.category_id}
                                onChange={this.handleOnChange}
                                name="category_id">
                                <option value="0">Select</option>
                                {
                                  this.state.categories.map((categroy, i) => {
                                    return <option key={i} value={categroy.id}>{categroy.name}</option>
                                  })
                                }
                              </select>
                              {this.state.errors.category_id && <small className="form-text text-muted">{this.state.errors.category_id}</small>}
                            </div>
                          </div>
                         
                        <div className="col-xl-8 col-lg-12 col-sm-12">
                          <div className="form-group">
                            {/* <p className="lable-input font-colr">Tags</p><span className="s-reg ft-sz-15 sub-font-colr">Add New Tag</span> */}
                            <p className="lable-input font-colr">Tags (Add New Tag)</p>
                            {/* <textarea className="form-control" rows="3"></textarea> */}
                            <div className="input-tag-sec hash-tags tag-minh-dashbrd pt-1 pl-2 pr-2 pb-0">
                              <ul>
                                {
                                  this.state.listed_tags.map((tag, index) => {
                                    let tag_index = this.state.selected_tags.indexOf(tag.id);
                                    return tag_index != -1 ? <li key={index} className="tag ft-sz-13 s-reg">
                                      <span>{tag.name}</span>
                                      <button onClick={() => this.removeTag(tag_index)} className="btn">X</button>
                                    </li> : ""
                                  })
                                }
                              </ul>
                            </div>
                          </div>
                          {
                            this.state.listed_tags.map((tag, index) => {
                              let tag_index = this.state.selected_tags.indexOf(tag.id);
                              return tag_index == -1 ?
                                <div onClick={() => this.tagSelected(index)} className="tag ft-sz-13 s-reg">
                                  {/* <span className="icon-tag-black-shape"></span> */}
                                  {tag.name}
                                </div> : ""
                            })
                          }
                        </div>
                        {/* <div className="col"></div> */}
                        {/* <div className="col-xl-5 col-lg-12 col-sm-12">
                          <div className="form-group">
                            <p className="lable-input font-colr">Digital Signature</p>
                            <div className="inner-digital-sign-sec">
                              <img className="w-100" src={this.state.digital_signature} />
                            </div>
                          </div>
                        </div> */}

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="right-inner-content-sec-box">
                    <div className="top-head clearfix">
                      <p className="pull-left s-sbold ft-sz-22 blu-color m-0">Bill To</p>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-sm-12"></div>
                      <div className="col-lg-9 col-sm-12 right-form-sec">
                        <div className="row">
                          <div className="col-xl-4 col-lg-6 col-sm-12">
                            <div className="form-group">
                              <p className="lable-input font-colr">Name<span className="red-star">*</span></p>
                              <input type="text"
                                className={"form-control " + (this.state.errors.name ? " wrong-red " : "")}
                                value={this.state.name}
                                onChange={this.handleOnChange}
                                name="name" />
                              {this.state.errors.name && <small className="form-text text-muted">{this.state.errors.name}</small>}
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-6 col-sm-12">
                            <div className="form-group">
                              <p className="lable-input font-colr">E-mail</p>
                              <input type="email"
                                className={"form-control " + (this.state.errors.email ? " wrong-red " : "")}
                                value={this.state.email}
                                onChange={this.handleOnChange}
                                name="email" />
                              {this.state.errors.email && <small className="form-text text-muted">{this.state.errors.email}</small>}
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-6 col-sm-12">
                            <div className="form-group">
                              <p className="lable-input font-colr">Phone</p>
                              <input type="text"
                                className={"form-control " + (this.state.errors.phone_number ? " wrong-red " : "")}
                                value={this.state.phone_number}
                                onChange={this.handleOnChange}
                                name="phone_number" />
                              {this.state.errors.phone_number && <small className="form-text text-muted">{this.state.errors.phone_number}</small>}
                            </div>
                          </div>
                        
                          <div className="col-xl-4 col-lg-6 col-sm-12">
                            <div className="form-group">
                              <p className="lable-input font-colr">GST Number</p>
                              <input type="text" className="form-control"
                                className={"form-control " + (this.state.errors.gst_no ? " wrong-red " : "")}
                                value={this.state.gst_no}
                                onChange={this.handleOnChange}
                                name="gst_no" />
                              {this.state.errors.gst_no && <small className="form-text text-muted">{this.state.errors.gst_no}</small>}
                            </div>
                          </div>
                          <div className="col-xl-8 col-lg-12 col-sm-12">
                            <div className="form-group">
                              <p className="lable-input font-colr">Address</p>
                              <input type="text" className="form-control"
                                className={"form-control " + (this.state.errors.address ? " wrong-red " : "")}
                                value={this.state.address}
                                onChange={this.handleOnChange}
                                name="address" />
                              {this.state.errors.address && <small className="form-text text-muted">{this.state.errors.address}</small>}
                            </div>
                          </div>
                        
                        
                          <div className="col-xl-3 col-lg-6 col-sm-12">
                            <div className="form-group">
                              <p className="lable-input font-colr">Country</p>
                              <select className="form-control"
                                value={this.state.country_id}
                                onChange={this.onCountryChange} >
                                <option value="">Select Country</option>
                                {
                                  this.state.countries.map((country, i) => {
                                    return <option key={i} value={country.id}>{country.name}</option>
                                  })
                                }
                              </select>
                              {this.state.errors.country_id && <small className="form-text text-muted">{this.state.errors.country_id}</small>}
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-sm-12">
                            <div className="form-group">
                              <p className="lable-input font-colr">State</p>
                              <select className="form-control"
                                value={this.state.state_id}
                                onChange={this.onStateChange} >
                                <option value="">Select State</option>
                                {
                                  this.state.states.map((state, i) => {
                                    return <option key={i} value={state.id}>{state.name}</option>
                                  })
                                }
                              </select>
                              {this.state.errors.state_id && <small className="form-text text-muted">{this.state.errors.state_id}</small>}
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-sm-12">
                            <div className="form-group">
                              <p className="lable-input font-colr">City</p>
                              <select className="form-control"
                                value={this.state.city_id}
                                onChange={this.onCityChange} >
                                <option value="">Select City</option>
                                {
                                  this.state.cities.map((city, i) => {
                                    return <option key={i} value={city.id}>{city.name}</option>
                                  })
                                }
                              </select>
                              {this.state.errors.city_id && <small className="form-text text-muted">{this.state.errors.city_id}</small>}
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-sm-12">
                            <div className="form-group">
                              <p className="lable-input font-colr">Pincode</p>
                              <input type="text" className="form-control"
                                className={"form-control " + (this.state.errors.pincode ? " wrong-red " : "")}
                                value={this.state.pincode}
                                onChange={this.handleOnChange}
                                name="pincode" />
                              {this.state.errors.pincode && <small className="form-text text-muted">{this.state.errors.pincode}</small>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="right-inner-content-sec-box">
                    <div className="top-head clearfix">
                      <p className="pull-left s-sbold ft-sz-22 blu-color m-0">Item Details</p>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-sm-12">
                        {/* <div className="main-invoice-item-sec"> */}
                        {/* <Table responsive > */}

                        <div className="table-responsive main-invoice-item-sec invoice-tbl-sec mt-2">
                          {!this.state.is_gst ?
                            
                            <Table className="invoice-tblepad">
                              <thead>
                                <tr className="gray-bg ladger-table">
                                  <th className="blue-color item">Item</th>
                                  <th className="blue-color" width="13%">HSN/SAC</th>
                                  <th className="blue-color" width="13%">Rate</th>
                                  <th className="blue-color">Quantity</th>
                                  <th className="blue-color">Discount(%)</th>
                                  <th className="blue-color" width="5%">CGST(%)</th>
                                  <th className="blue-color" width="5%">SGST(%)</th>
                                  <th className="blue-color" width="5%">IGST(%)</th>
                                  <th className="blue-color" width="10%">Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  this.state.items.map((item, index) => {
                                    return <tr>
                                      <td>
                                        <input type="text" className="form-control"
                                          placeholder="Description of service or product"
                                          value={item.item}
                                          onChange={(e) => this.handleItemOnChange(e, index)}
                                          name="item" />
                                      </td>
                                      <td>
                                        <input type="text" className="form-control text-right"
                                          // placeholder="HSN/SAC"
                                          value={item.hsn}
                                          onChange={(e) => this.handleItemOnChange(e, index)}
                                          name="hsn" />
                                      </td>
                                      <td>
                                        {/* <input type="number" className="form-control text-right"
                                          placeholder="Rate"
                                          value={item.rate}
                                          onChange={(e) => this.handleItemOnChange(e, index)}
                                          onBlur={(e) => { this.calculateAmount(e, index) }}
                                          name="rate" /> */}
                                        <CurrencyInput 
                                          type="text"
                                          className="form-control text-right"
                                          name="rate"
                                          autoComplete="off"
                                          // placeholder={this.state.type == 2 ? '₹ 0' : ""}
                                          // placeholder='₹ 0'
                                          prefix="&#8377; "
                                          // value={this.state.type == 2 ? this.state.amount : ""}
                                          allowNegativeValue={false}
                                          decimalsLimit={2}
                                          // disabled={(this.state.type == 2 && this.state.is_permission_granted) && !read_only ? false : true}
                                          // onValueChange={(value, name) => this.amountChange(value, name)}
                                        />
                                      </td>
                                      <td>
                                        {/* <div className="main-quantity-sec form-control">
                                          <span>2</span>
                                          <div className="inner-quantity-sec">
                                            <p className="m-0"><a><span className="icon-right-arrow"></span></a></p>
                                            <p className="m-0"><a><span className="icon-right-arrow"></span></a></p>
                                          </div>
                                        </div> */}
                                        <input type="number" className="form-control text-right"
                                          placeholder="Qty"
                                          value={item.quantity}
                                          onChange={(e) => this.handleItemOnChange(e, index)}
                                          onBlur={(e) => { this.calculateAmount(e, index) }}
                                          name="quantity" />                                     
                                      </td>
                                      <td>
                                        {/* <input type="number" className="form-control text-right"
                                          placeholder="Discount"
                                          value={item.discount_percentage}
                                          onChange={(e) => this.handleItemOnChange(e, index)}
                                          onBlur={(e) => { this.calculateAmount(e, index) }}
                                          name="discount_percentage" /> */}
                                          <InputNumber type="text" className="form-control"
                                            className="form-control text-right"
                                            name="discount_percentage" 
                                            value={item.discount_percentage}                                           
                                          />  
                                      </td>
                                      <td>
                                        {/* <input type="number" className="form-control text-right"
                                          placeholder="CGST"
                                          value={item.cgst_percentage}
                                          onChange={(e) => this.handleItemOnChange(e, index)}
                                          onBlur={(e) => { this.calculateAmount(e, index) }}
                                          name="cgst_percentage" /> */}
                                        <InputNumber type="text" className="form-control"
                                          className="form-control text-right"
                                          name="cgst_percentage" 
                                          value={item.cgst_percentage}                                           
                                        />
                                      </td>
                                      <td>
                                        {/* <input type="number" className="form-control text-right"
                                          // placeholder="SGST"
                                          value={item.sgst_percentage}
                                          onChange={(e) => this.handleItemOnChange(e, index)}
                                          onBlur={(e) => { this.calculateAmount(e, index) }}
                                          name="sgst_percentage" /> */}
                                        <InputNumber type="text" className="form-control"
                                          className="form-control text-right"
                                          name="sgst_percentage" 
                                          value={item.sgst_percentage}                                           
                                        />
                                      </td>
                                      <td>
                                        {/* <input type="number" className="form-control text-right"
                                          placeholder="IGST"
                                          value={item.igst_percentage}
                                          onChange={(e) => this.handleItemOnChange(e, index)}
                                          onBlur={(e) => { this.calculateAmount(e, index) }}
                                          name="igst_percentage" /> */}
                                        <InputNumber type="text" className="form-control"
                                          className="form-control text-right"
                                          name="igst_percentage" 
                                          value={item.igst_percentage}                                           
                                        />
                                      </td>
                                      <td>
                                        {/* <input type="text" className="form-control text-right"
                                          placeholder="Amount" readOnly
                                          value={item.gross_total}
                                          name="gross_total" /> */}
                                        <CurrencyInput 
                                          type="text"
                                          className="form-control text-right"
                                          name="gross_total"
                                          autoComplete="off"
                                          // placeholder={this.state.type == 2 ? '₹ 0' : ""}
                                          // placeholder='₹ 0'
                                          prefix="&#8377; "
                                          // value={this.state.type == 2 ? this.state.amount : ""}
                                          allowNegativeValue={false}
                                          decimalsLimit={2}
                                          // disabled={(this.state.type == 2 && this.state.is_permission_granted) && !read_only ? false : true}
                                          // onValueChange={(value, name) => this.amountChange(value, name)}
                                        />
                                      </td>
                                      <td>
                                        {/* <div className="cross"><span onClick={() => this.removeItem(index)}>X</span></div> */}
                                        <span class="icon-Cross-with-grey-circle" onClick={() => this.removeItem(index)}>
                                          <span class="path1"></span><span class="path2"></span><span class="path3"></span>
                                        </span>
                                      </td>

                                      {/* {(this.props.index > 1) &&
                                        <td>
                                          <span class="icon-Cross-with-grey-circle" onClick={() => this.removeItem(index)}>
                                            <span class="path1"></span><span class="path2"></span><span class="path3"></span>
                                          </span>
                                        </td>
                                      }  */}

                                    </tr>
                                  })
                                }
                                {/* <tr>
                                  <div className="item-increment" onClick={this.addItemRow}>
                                    <span className="icon-plus"></span>
                                  </div>
                                  <td colspan="9" class="text-right" align='center'>
                                  <td colspan="9">
                                    <button className="item-increment border-0 bg-transparent removebrd mt-0 p-0" onClick={this.addItemRow} >
                                      <span className="icon-plus"></span>
                                    </button> 
                                  </td>                                 
                                </tr> */}
                                <tr>
                                  {/* <td colspan="4" className="text-right">Total</td> */}
                                  <td>
                                    <button className="item-increment border-0 bg-transparent removebrd mt-0 p-0" onClick={this.addItemRow} >
                                      <span className="icon-plus"></span>
                                    </button>
                                  </td>
                                  <td colspan="3" class="text-right s-sbold blue-color ft-sz-20 mlfz">Total (₹)</td>
                                  <td>
                                    <input type="text" className="form-control text-right input-bg-gray"
                                      disabled="disabled" readOnly
                                      // value={"₹ " + this.state.discounted_amount} 
                                      value={this.state.discounted_amount} 
                                    />
                                  </td>
                                  <td>
                                    <input type="text" className="form-control text-right input-bg-gray"
                                      disabled="disabled" readOnly
                                      value={this.state.cgst_amount} />
                                  </td>
                                  <td>
                                    <input type="text" className="form-control text-right input-bg-gray"
                                      disabled="disabled" readOnly
                                      value={this.state.sgst_amount} />
                                  </td>
                                  <td>
                                    <input type="text" className="form-control text-right input-bg-gray"
                                      disabled="disabled" readOnly
                                      value={this.state.igst_amount} />
                                  </td>
                                  <td>
                                    <input type="text" className="form-control text-right input-bg-gray"
                                      disabled="disabled" readOnly
                                      value={this.state.gross_total} />
                                  </td>
                                </tr>
                                <tr>                                  
                                  {/* <td colspan="8">Net Amount (₹)</td> */}
                                  <td colspan="8" class="text-right s-sbold blue-color ft-sz-20 mlfz">Net Amount (₹)</td>
                                  <td>
                                    <input type="text" className="form-control text-right input-bg-gray"
                                      disabled="disabled" readOnly
                                      value={this.state.net_total} />
                                  </td>
                                </tr>
                                <tr></tr>
                                <tr>
                                  {/* <td colspan="8">TDS (%)</td> */}
                                  <td colspan="8" class="text-right s-sbold blue-color ft-sz-20 mlfz">TDS (%)</td>
                                  <td>
                                    {/* <input type="number" className="form-control text-right"
                                      placeholder="TDS"
                                      value={this.state.tds_percentage}
                                      onChange={this.handleOnChange}
                                      onBlur={(e) => { this.calculateAmount(e, -1) }}
                                      name="tds_percentage" /> */}                                    
                                    <InputNumber type="text" className="form-control"
                                      className="form-control text-right"
                                      name="tds_percentage" 
                                      value={this.state.tds_percentage}                                          
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  {/* <td colspan="8">TDS (Amount)</td> */}
                                  <td colspan="8" class="text-right s-sbold blue-color ft-sz-20 mlfz">TDS Amount (₹)</td>
                                  <td>
                                    <input type="text" className="form-control text-right input-bg-gray"
                                      disabled="disabled" readOnly
                                      value={this.state.tds_amount} />
                                  </td>
                                </tr>
                              </tbody>
                            </Table>

                           :
                                                  
                           // <Table responsive>
                            //   <thead>
                            //     <tr className="blu-bg white-color">
                            //       <th className="ft-sz-15 s-reg w-50">Item</th>
                            //       <th className="ft-sz-15 s-reg w-8">Rate</th>
                            //       <th className="ft-sz-15 s-reg w-8">Quantity</th>
                            //       <th className="ft-sz-15 s-reg w-8">Discount(%)</th>
                            //       <th className="ft-sz-15 s-reg">Amount</th>
                            //     </tr>
                            //   </thead> 

                            <Table className="ledger-tblepad ">
                              <thead>
                                <tr className="gray-bg ladger-table">
                                  <th className="blue-color w-50">Item</th>
                                  <th className="blue-color" width="10%">Rate</th>
                                  <th className="blue-color" width="10%">Quantity</th>
                                  <th className="blue-color" width="15%">Discount(%)</th>
                                  <th className="blue-color" width="15%">Amount</th>
                                </tr>
                              </thead>

                              <tbody>
                                {
                                  this.state.items.map((item, index) => {
                                    return <tr>
                                      <td>
                                        <input type="text" className="form-control"
                                          placeholder="Description of service or product"
                                          value={item.item}
                                          onChange={(e) => this.handleItemOnChange(e, index)}
                                          name="item" />
                                      </td>
                                      <td>
                                        {/* <input type="number" className="form-control text-right"
                                          placeholder="Rate"
                                          value={item.rate}
                                          onChange={(e) => this.handleItemOnChange(e, index)}
                                          onBlur={(e) => { this.calculateAmount(e, index) }}
                                          name="rate" /> */}

                                        <CurrencyInput 
                                          type="text"
                                          className="form-control text-right"
                                          name="rate"
                                          autoComplete="off"
                                          // placeholder={this.state.type == 2 ? '₹ 0' : ""}
                                          // placeholder='₹ 0'
                                          prefix="&#8377; "
                                          // value={this.state.type == 2 ? this.state.amount : ""}
                                          allowNegativeValue={false}
                                          decimalsLimit={2}
                                          // disabled={(this.state.type == 2 && this.state.is_permission_granted) && !read_only ? false : true}
                                          // onValueChange={(value, name) => this.amountChange(value, name)}
                                        />

                                      </td>
                                      <td>
                                        <input type="number" className="form-control text-right"
                                          placeholder="Qty"
                                          value={item.quantity}
                                          onChange={(e) => this.handleItemOnChange(e, index)}
                                          onBlur={(e) => { this.calculateAmount(e, index) }}
                                          name="quantity" />
                                      </td>
                                      <td>
                                        {/* <input type="number" className="form-control text-right"
                                          placeholder="Discount"
                                          value={item.discount_percentage}
                                          onChange={(e) => this.handleItemOnChange(e, index)}
                                          onBlur={(e) => { this.calculateAmount(e, index) }}
                                          name="discount_percentage" /> */}
                                        <InputNumber type="text" className="form-control"
                                          className="form-control text-right"
                                          name="discount_percentage" 
                                          value={this.state.discount_percentage}                                          
                                        />
                                      </td>
                                      <td>
                                        <input type="text" className="form-control text-right"
                                          placeholder="Amount" readOnly
                                          value={item.gross_total}
                                          name="gross_total" />
                                      </td>
                                      <td>
                                        {/* <div className="cross"><span onClick={() => this.removeItem(index)}>X</span></div> */}
                                        <span class="icon-Cross-with-grey-circle" onClick={() => this.removeItem(index)}><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                                      </td>
                                    </tr>
                                  })
                                }
                        
                                {/* <tr>
                                  <div className="item-increment" onClick={this.addItemRow}>
                                    <span className="icon-plus"></span>
                                  </div>
                                </tr> */}
                                <tr>
                                  <td>
                                    <button className="item-increment border-0 bg-transparent removebrd mt-0 p-0" onClick={this.addItemRow} >
                                      <span className="icon-plus"></span>
                                    </button>
                                  </td>
                                  <td colspan="2" class="text-right s-sbold blue-color ft-sz-20 mlfz">Total (₹)</td>
                                  <td>
                                    <input type="text" className="form-control text-right input-bg-gray"
                                      disabled="disabled" readOnly
                                      value={this.state.discounted_amount} />
                                  </td>
                                  <td>
                                    <input type="text" className="form-control text-right input-bg-gray"
                                      disabled="disabled" readOnly
                                      value={this.state.gross_total} />
                                  </td>
                                </tr>
                                <tr>
                                  {/* <td colspan="4" >Net Amount</td> */}
                                  <td colspan="4" class="text-right s-sbold blue-color ft-sz-20 mlfz">Net Amount (₹)</td>
                                  <td>
                                    <input type="text" className="form-control text-right input-bg-gray"
                                      disabled="disabled" readOnly
                                      value={this.state.net_total} />
                                  </td>
                                </tr>
                                <tr></tr>
                                <tr>
                                  {/* <td colspan="4">TDS(%)</td> */}
                                  <td colspan="4" class="text-right s-sbold blue-color ft-sz-20 mlfz">TDS (%)</td>
                                  <td>
                                    {/* <input type="number" className="form-control text-right"
                                      placeholder="TDS"
                                      value={this.state.tds_percentage}
                                      onChange={this.handleOnChange}
                                      onBlur={(e) => { this.calculateAmount(e, -1) }}
                                      name="tds_percentage" /> */}
                                    <InputNumber type="text" className="form-control"
                                      className="form-control text-right"
                                      name="tds_percentage" 
                                      value={this.state.tds_percentage}                                          
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  {/* <td colspan="4">TDS(Amount)</td> */}
                                  <td colspan="4" class="text-right s-sbold blue-color ft-sz-20 mlfz">TDS Amount (₹)</td>
                                  <td>
                                    <input type="text" className="form-control text-right input-bg-gray"
                                      disabled="disabled" readOnly
                                      value={this.state.tds_amount} />
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          } 
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="right-inner-content-sec-box">
                    <div className="top-head clearfix">
                      <p className="pull-left s-sbold ft-sz-22 blu-color m-0">Payment Details</p>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-sm-12"></div>
                      <div className="col-lg-9 col-sm-12 right-form-sec">
                        <div className="row">
                          <div className="col-xl-4 col-lg-6 col-sm-12">
                            <div className="form-group">
                              <p className="lable-input font-colr">Mode Of Payment</p>
                              <select className="form-control"
                                value={this.state.payment_id}
                                onChange={this.onPaymentChange} >
                                <option value="">Select</option>
                                {
                                  this.state.payment_details.map((payment, i) => {
                                    return <option key={payment.id} value={i}>{payment.bank_name + ' ' +payment.account_number}</option>
                                  })
                                }
                              </select>
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-6 col-sm-12">
                            <div className="form-group">
                              <p className="lable-input font-colr">Account Number</p>
                              <input type="text" className="form-control input-bg-gray"
                                value={this.state.account_number}
                                disabled="disabled"
                                name="account_number" />
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-6 col-sm-12">
                            <div className="form-group">
                              <p className="lable-input font-colr">IFSC Code</p>
                              <input type="text" className="form-control input-bg-gray"
                                value={this.state.ifsc_code}
                                disabled="disabled"
                                name="ifsc_code" />
                            </div>
                          </div>                        
                          <div className="col-xl-4 col-lg-6 col-sm-12">
                            <div className="form-group">
                              <p className="lable-input font-colr">City</p>
                              <input type="text" className="form-control input-bg-gray"
                                value={this.state.city_name}
                                disabled="disabled"
                                name="city_name" />
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-6 col-sm-12">
                            <div className="form-group">
                              <p className="lable-input font-colr">Branch</p>
                              <input type="text" className="form-control input-bg-gray"
                                value={this.state.branch}
                                disabled="disabled"
                                name="branch" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="right-inner-content-sec-box">
                    <div className="top-head clearfix">
                      <p className="pull-left s-sbold ft-sz-22 blu-color m-0">Notes</p>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-sm-12"></div>
                      <div className="col-lg-9 col-sm-12 right-form-sec">
                        <div className="row">
                          <div className="col-xl-12 col-lg-12 col-sm-12">
                            <div className="form-group">
                              <p className="lable-input font-colr">Additional Information</p>
                              <TextareaAutosize type="text" maxLength="1000" className="form-control"
                                // className={"form-control " + (this.state.errors.additional_information ? " wrong-red " : "")}
                                className="form-control "
                                // value={this.state.additional_information}
                                minRows={2}
                                // onChange={this.handleChange}
                                // disabled={this.VIEW_ONLY_SNAPSHOT || !editable}
                                name="additional_information"></TextareaAutosize>
                                {/* {this.state.errors.additional_information && <small className="form-text text-muted">{this.state.errors.additional_information}</small>} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="top-head clearfix mt-2"></div> */}
                    {/* <p className="lable-input font-colr mt-2">Declaration</p> */}
                  </div>

                  <div className="right-inner-content-sec-box">
                    <div className="top-head clearfix mb-2">
                      <p className="pull-left s-sbold ft-sz-22 blu-color m-0">Declaration</p>
                    </div>
                    <p className="font-colr">Multi like free text that will get printed as part of the invoice. Can be terms and conditions or anything.</p>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="bottom-save-btn clearfix">
            {/* {
              !!this.state.invoice_id && */}
              <div className="pull-left">
                <button type="button" className="btn save-btn graybtn">Preview and Print </button>
              </div>
            {/* } */}
            <div className="pull-right">
              {/* <div className="ready-btn">
                <select
                  onChange={this.handleOnChange}
                  value={this.state.status}
                  name="status" >
                  <option value="2" selected>Not Ready</option>
                  <option value="1">Ready</option>
                </select>
              </div> */}
              <div className="float-left">
								<div className="global_rn">
									<Multiselect
										onRemove={function noRefCheck() { }}
										onSearch={function noRefCheck() { }}
										onSelect={this.handleStatusChange}
										singleSelect
										// disable={(this.VIEW_ONLY_SNAPSHOT && !(!!this.state.snapshot_id && this.state.created_by == SessionManagement.getUserDetail().id))}
										options={this.state.option}
										displayValue="name"
										selectedValues={[this.state.status]}
										className="btn graybtn"
									/>
								</div>
							</div>

              <button type="button"
                className={"btn save-btn s-bold greenbtn pull-right " + (this.state.loading ? "position-reltv" : "")}
                disabled={this.state.loading}
                onClick={this.saveInvoice} >
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm ml-4"></span>
                )}
                <span>  {this.state.invoice_id ? 'Update' : 'Save'}</span></button>
            </div>
          </div>

          <Modal
            show={this.state.show_save_modal}
            onHide={this.toogleSaveModal}
            backdrop="static"
            keyboard={false}
            centered
            className="save-model-sec"
          >

            <Modal.Body className="clearfix">
              <div className="pull-left mr-3">
                {/* <i className="fa fa-exclamation-circle red-color" aria-hidden="true"></i> */}
                <span className="icon-exclamation-red-sign popicon"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
              </div>
              <div className="pull-left">
                <p className="ft-sz-30 s-bold blu-color mb-1">Save Changes?</p>
                <p className="ft-sz-18 s-reg font-colr">If you close without saving,the changes you have made will be lost</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.toogleSaveModal} variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Cancel</Button>
              <Button variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold">Save</Button>
            </Modal.Footer>
          </Modal>
          {/* <ChangeLog show_change_log={this.state.show_change_log} toogleChangeLogModal={this.toogleChangeLogModal}/> */}

        </div>

      </div>
    );
  }

}

function mapStateToProps(state) {
  const { message } = state.message;
  const view_invoice_id = state.Invoices.view_invoice_id;

  return {
    message, view_invoice_id
  };
}

export default withRouter(connect(mapStateToProps)(CreateInvoice))