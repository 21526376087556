import React from 'react';
import hdfc_bnk_fevi from '../../Assets/img/hdfc-fevicon.png';
import BankLogo from './BankLogos';
import { displayDateFormate } from '../../Helpers/DateFormat';
import AddBankModal from './AddBankModal';
import AddNewCardModal from './AddCardModal';
import { showAmount } from '../../Helpers/Currency';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class AccountDetailsHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_add_card: false,
      card_details: null,
      show_add_bank_form: false
    }
  }
  toggleAddCard = () => {
    if (this.state.show_add_card) {
      this.setState({ card_details: null })
    }
    this.setState({ show_add_card: !this.state.show_add_card })
  }

  toggleAddBankModal = () => {
    if (this.state.show_add_bank_form) {
      this.setState({ card_details: null })
    }
    this.setState({ show_add_bank_form: !this.state.show_add_bank_form })
  }

  editInstitute = () => {
    console.log(this.props.card_details)
    //this.setState({ card_details: this.props.fi_details }, () => {
    this.setState({ card_details: this.props.card_details }, () => {
      if (this.props.card_details.is_credit_card === 0)
        this.toggleAddBankModal()
      if (this.props.card_details.is_credit_card === 1)
        this.toggleAddCard()
    })
  }

  getBankInitials = (bank_name) => {
    if (bank_name) {
      const fullName = bank_name.split(' ');
      let initials = ""
      fullName.forEach((name, index) => {
        if (index <= 1)
          initials += name.charAt(0)
      });

      //const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
      return initials.toUpperCase();
    } else {
      return ''
    }
  }

  render() {
    let institute = this.props.fi_details
    let financial_institute_info = this.props.financial_institute_info

    console.log(institute)
    console.log(financial_institute_info)

    return (
      <>
        <AddNewCardModal
        ledgerNameList = {this.props.ledgerNameList}
          bank_name_list={this.props.bank_name_list || []}
          showModal={this.state.show_add_card}
          closeModal={this.toggleAddCard}
          card_details={this.state.card_details}
          updateList={this.props.updateList}
          FIList={this.props.FIList}
          getFIDetails={this.props.getFinancialInstituteDetails}
          inside_page="true"
          default_entity_detail={this.props.default_entity_detail}
        />

        <AddBankModal
        ledgerNameList = {this.props.ledgerNameList}
          showModal={this.state.show_add_bank_form}
          closeModal={this.toggleAddBankModal}
          card_details={this.state.card_details}
          updateList={this.props.updateList}
          FIList={this.props.FIList}
          getFIDetails={this.props.getFinancialInstituteDetails}
          inside_page="true"
          default_entity_detail={this.props.default_entity_detail}
        />

        <div className="account-details-header sec-mb widgets_bankin">
          <div className="row">
            <div className="col-xl-1 col-lg-1 col-md-1">
              {BankLogo(institute.bank_code) == null ? <div className="bank-new-initial">{this.getBankInitials(institute.bank_name)}</div> : <img className="img-fluid bank-new-logo" src={BankLogo(institute.bank_code)} />}
            </div>

            <div className="col-xl-2 col-lg-2 col-md-3 pl-lg-0">
              <lable className="lable-input-account">Financial Institute</lable>
              <p className="form-control-account">{institute.bank_name}</p>
            </div>

            <div className="col-xl col-lg col-md-3 pl-lg-0">
              <lable className="lable-input-account">{institute.account_number && institute.account_number.length == 4 ? "Last 4 Digit" : "Account Number"}</lable>
              <p className="form-control-account">{institute.account_number}</p>
            </div>

            <div className="col-xl col-lg col-md-2 pl-lg-0">
              <lable className="lable-input-account">Opening Bal.</lable>
              <p className="form-control-account">
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showAmount(financial_institute_info.opening_balance) ? (showAmount(financial_institute_info.opening_balance)) : '-'}</Tooltip>}>
                  <span>{showAmount(financial_institute_info.opening_balance) ? (showAmount(financial_institute_info.opening_balance)) : '-'}</span>
                </OverlayTrigger>
              </p>
            </div>

            <div className="col-xl col-lg col-md-3 pl-lg-0">
              <lable className="lable-input-account">Opening Date</lable>
              <p className="form-control-account">{financial_institute_info.opening_balance_date ? displayDateFormate(financial_institute_info.opening_balance_date) : '-'}</p>
            </div>

            <div className="col-xl col-lg col-md-1 d-sm-none d-lg-none d-xl-none d-md-block"></div>

            <div className="col-xl col-lg col-md-3 pl-lg-0">
              <lable className="lable-input-account">Closing Bal.</lable>
              <p className="form-control-account">
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showAmount(financial_institute_info.closing_balance) ? (showAmount(financial_institute_info.closing_balance)) : '-'}</Tooltip>}>
                  <span>{showAmount(financial_institute_info.closing_balance) ? (showAmount(financial_institute_info.closing_balance)) : '-'}</span>
                </OverlayTrigger>
              </p>
            </div>

            <div className="col-xl col-lg col-md-3 mb-2 pl-lg-0">
              <lable className="lable-input-account">Closing Date</lable>
              <p className="form-control-account">{financial_institute_info.closing_balance_date ? displayDateFormate(financial_institute_info.closing_balance_date) : '-'}</p>
            </div>

            <div className="col-xl-1 col-lg-1 col-md-2 mb-2 pl-lg-0 account-statu-col">
              <lable className="lable-input-account">Status</lable>
              <p className="form-control-account">{institute.is_active ? 'Active' : 'Inactive'}</p>
            </div>

            {(this.props.bank_modal  && this.props.card_details.is_credit_card == 0 && !this.props.checkBulkFlag) ? <div className="col-xl-1 col-lg-1 col-md-1 text-center pl-lg-0 account-statu-col">
              {this.props.show_edit && <div className="edit-icon-account cpointer" onClick={() => this.editInstitute()} >
                <span className="icon-edit"></span></div>}
            </div> : null}

            {(this.props.bank_modal == 0 )  ? <div className="col-xl-1 col-lg-1 col-md-1 text-center pl-lg-0 account-statu-col">
              {this.props.show_edit && <div className="edit-icon-account cpointer" onClick={() => this.editInstitute()} >
                <span className="icon-edit"></span></div>}
            </div> : null}

            {/* <div className="col-xl-1 col-lg-1 col-md-12 ">
              <img className="img-fluid bank-stm-logo d-block ml-2" src={BankLogo(institute.bank_code)} /> <div className="bank-initial d-flex align-items-center justify-content-center ml-2">B</div>
              {BankLogo(institute.bank_code) == null ? <div className="bank-initial d-flex align-items-center justify-content-center ml-2">{this.getBankInitials(institute.bank_name)}</div> :
                <img className="img-fluid bank-stm-logo d-block m-lg-auto" src={BankLogo(institute.bank_code)} />}
            </div> */}
            {/* <div className="col-xl col-lg col-md-4 mb-2 pl-lg-0">
              <p className="lable-input font-colr mb-0">Last Stmt.</p>      
              <p className="form-ldata mb-0">{displayDateFormate(institute.last_updated_at)}</p>
            </div> */}
          </div>
        </div>
      </>
    )
  }
}

export default AccountDetailsHeader;