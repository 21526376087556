import axios from 'axios';
import Header from '../Header';
import { API_URL } from '../../Utils/Config';
import { timeoutInMillis } from '../../Helpers/Constants';

class FinancialInstituteService {

    deleteTransaction(data){
        return axios.delete(API_URL + 'financial-institute-transactions' , { 
            data:{
                dates:data.dates,
                financial_institute_id: data.id,
            }, 
                headers: Header.entityHeader() })
        .then((response)=> {
          return response.data;
        }).catch((error)=> {
          return error;
        })
    }

    getFinancialInstituteDetails(data) {
        return axios.get(API_URL + 'financial-institute-info/' + data.financial_institute_id, { headers: Header.entityHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    getURL(data) {
        return axios.post(API_URL + 'fetch-url', data, { headers: Header.entityHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    getDetailsByIFSC(ifsc_code) {
        return axios.get(API_URL + 'ifsc/' + ifsc_code, { headers: Header.authHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    addNewBank(data) {
        return axios.post(API_URL + 'financial-institute', data, { headers: Header.entityHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    getFinancialInstituteList() {
        return axios.post(API_URL + 'financial-institutes', {}, { headers: Header.entityHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    getBankNameList = () => {
        return axios.get(API_URL + 'credit-card-bank-list', { headers: Header.authHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    updateBankAndCard = (data) => {
        return axios.put(API_URL + 'financial-institute', data, { headers: Header.entityHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    getPollTransactionData = (data) => {
        return axios.get(API_URL + 'poll-transactions-data/' + data.financial_institute_request_id , { headers: Header.entityHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    storeTransactionData = (data) => {
        return axios.post(API_URL + 'store-transaction-data', data, { headers: Header.entityHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    getAccountStatementList = (data) => {
        return axios.post(API_URL + 'financial-institute-statements', data, { headers: Header.entityHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    getAccountStatementDetails = (data) => {
        return axios.post(API_URL + 'get-transaction-for-statement', data, { headers: Header.entityHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    deleteAccountStatement = (data) => {
        return axios.delete(API_URL + 'financial-institute-statement', { data: data, headers: Header.entityHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    deleteFinancialInstitute = (data) => {
        return axios.delete(API_URL + 'financial-institute/'+data.financial_institute_id, {headers: Header.entityHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    // Ram

    getFinancialInstituteStatements = (data) => {
        let headerObj =  { headers: Header.entityHeader() }
        if(data.is_export == 1){
            headerObj.responseType = 'arraybuffer'
        }
        return axios.post(API_URL + 'financial-institute-transactions', data, headerObj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    // End

    // getFinancialInstituteInfo = (data) => {
    //     console.log(data)
    //     return axios.get(API_URL + 'financial-institute-info-fin/' + data.financial_institute_id , { headers: Header.entityHeader() })
    //     .then((response) => {
    //         return response.data;
    //     }).catch((error) => {
    //         return error;
    //     });
    // }

    getFinancialInstituteInfo = (data) => {
        //console.log(data)
        return axios.post(API_URL + 'financial-institute-info',  data , { headers: Header.entityHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    getSupportedBanks = () => {
        return axios.get(API_URL + 'yodlee-supported-banks', { headers: Header.authHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    getBuktecSupportedBanks =  () => {
        return axios.get(API_URL + 'buktec-supported-banks' , {headers : Header.authHeader()})
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error
        })
    }

    getFIData = (id) => {
        return axios.get(API_URL + 'financial-institute/'+id, { headers: Header.entityHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    getExtractTransactionNew = (formData) => {
        return axios.post(API_URL + 'extract-transactions' , formData , {headers: Header.formDataHeader() , timeout: timeoutInMillis})
        .then((response)=> {
            return response.data;
        }).catch((error) =>{
            if(error.request){
                return 'timeout'
              }
              return error;
           
        })
    }
}

export default new FinancialInstituteService();