import React, { Component } from 'react';
import logo from './logo.svg';
// import './App.css';
import './StyleActions.css';
import './StyleWidgetIcon.css';
import './StyleOnboarding.css';

import { connect } from "react-redux";
import { Router, Switch, Route, Link, Redirect } from "react-router-dom";

import './Assets/css/icon-style.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
// import 'node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

// import "./App.css";
// import "./App-copy.css";
import './Assets/ModulScss/Styles.scss';

// let branch_new;
// if ((window.location.pathname == '/task-manager') || (window.location.pathname == 'task-manager/create/')) {
//     import('./Assets/ModulScss/Styles.scss');
// } else {
//     import('./App.css');
// }


// 456
import Login from "./Views/Auth/Login";
import Register from "./Views/Auth/Register";
import ForgetUsername from "./Views/Auth/ForgetUsername";
import ForgetPassword from "./Views/Auth/ForgetPassword";
import Loginold from './Views/Auth/Login-old'
// import BoardModerator from "./components/board-moderator.component";
import RegisterTrial from "./Views/ui/registerTrial";
import snapshot from "./Views/ui/snapshot";
import profileentity from "./Views/ui/profileentity";
import evidencesms from "./Views/ui/evidencesms";
import profileentityCa from "./Views/ui/profileentityCa";
import invoice from "./Views/ui/invoice";
import taskManager from "./Views/ui/taskManager";
import messenger from "./Views/ui/messenger";
import cheques from "./Views/ui/cheques";
import docbox from "./Views/ui/docbox";
import financial from "./Views/ui/financial";
import accoutstatement from "./Views/ui/accoutstatement";
import myprofile from "./Views/ui/myprofile";
import moneylink from "./Views/ui/moneylink";
import licenses from "./Views/ui/licenses";
import myhome from "./Views/ui/myhome";
import myDashboard from "./Views/ui/myDashboard";

import { logout } from "./Redux/Actions/Auth";
import { clearMessage } from "./Redux/Actions/Message";

import { history } from './Helpers/History';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TheLayout } from './Views/Containers';

import "react-datepicker/dist/react-datepicker.css";
import ScrollToTop from './Components/BoilerPlate/ScrollToTop';
import MoneyLinkView from './Views/MoneyLink/MoneyLinkView';
import Loader from './Views/Icon/Loader';
import PublicRoute from './Components/Authentication/PublicRoute';
import PrivateRoute from './Components/Authentication/PrivateRoute';
import ErrorPage from './Views/Auth/ErrorPage';
import YodleeCallback from './Views/Callback/YodleeCallback';

import firebase, { onMessageListener } from './Utils/firebase';
import 'firebase/messaging';
import StorageData from './Helpers/StorageData';
import { setNotificationCount } from './Redux/Actions/Notification';
import MasterService from './Services/MasterService';
import { setEntityList } from "./Redux/Actions/Session";
import SessionManagement from './Utils/SessionManagement';
import Logout from './Views/Auth/Logout';
import SetDefaultEntity from './Views/Containers/SetDefaultEntity';
import PageNotFound from './Views/ui/PageNotFound';
import { CLARITY_KEY } from './Utils/Config';

import TestPage from './Views/Containers/TestPage';

//import DashboardView1 from "./Views/Dashboard/DashboardView1";
//import DashboardView2 from "./Views/Dashboard/DashboardView2";

//interceptor to manage user session
import './Services/Interceptors';
import Maintenance from './Components/Maintenance/Maintenance';

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
      loader_path: {}
    };

    history.listen((location) => {
      this.setState({loader_path:location})
      props.dispatch(clearMessage()); // clear message when changing location
    });
  }

  componentDidMount() {

    // const clarityCode = `
    // (function(c,l,a,r,i,t,y){
    //   c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    //   t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    //   y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    // })(window, document, "clarity", "script", "`+CLARITY_KEY+`");`;
    //  new Function(clarityCode)();

    const clarityCode = `
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "h1s0bip3cf");`
      
    new Function(clarityCode)();
   
    const user = this.props.user;

    if (user) {
      this.setState({
        currentUser: user,
        // showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        // showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }

    /*
    Firebase Push notification task
    */
    StorageData.setNotificationPermission("No");
    if ((Notification.permission === 'granted' || Notification.permission === 'default') && firebase.messaging.isSupported()) {
      const msg = firebase.messaging();
      msg.getToken().then((data) => {
        if (!!data && !!SessionManagement.getAccessToken()) {
          /* CHECK PERMISSION GRANTTED */
          if (Notification.permission === 'granted'){
            StorageData.setNotificationPermission("yes");
          }
          let token_data = {
            "old_registration_id": !!StorageData.getFCMTOken() ? StorageData.getFCMTOken() : null,
            "new_registration_id": data
          }
          MasterService.sendFCMToken(token_data).then(data => {
            if (data.response_code === 200) {
              StorageData.setFCMTOken(token_data.new_registration_id);
            }
          });
        } else {
          console.log("token not generated.")
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });

      msg.onMessage((data) => {
        if (!!data.data) {
          const default_entity_detail = StorageData.getDefaultEntity() || {};
          if (data.data.entity_id == default_entity_detail.id || data.data.entity_id == "0") {
            setTimeout(() => { 
              MasterService.getNotificationCount().then(data => {
                if (data.response_code === 200) {
                  this.props.dispatch(setNotificationCount(data.result.count));
                }
              });
            }, 5000);
            
          } else {
            let entity_list = this.props.entity_list;
            let index = entity_list.findIndex(entity => entity.id == data.data.entity_id);
            if (index != -1) {
              entity_list[index].count = entity_list[index].count + 1;
              this.props.dispatch(setEntityList(entity_list));
            }
            this.props.dispatch(setEntityList([]));
            this.props.dispatch(setEntityList(entity_list));
          }
        }
      });

      // msg.onBackgroundMessage((data)=>{
      //   console.log("data",data);
      //   toast.error("msg","onBackgroundMessage recieved")
      // });
      /*
      for further help refer this link :- https://rharshad.com/web-push-notifications-react-firebase/#service-worker
      */

    }
    /*
    Firebase Push notification task end
    */

  }

  logOut() {
    this.props.dispatch(logout());
  }
  
  render() {
    const { currentUser, showModeratorBoard, showAdminBoard } = this.state;
    const isUnderMaintenance = false;
    //console.log(CLARITY_KEY)
    return (
      <Router history={history}>
        <ScrollToTop />
        
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          theme="colored"
        />
         {((this.state.loader_path.pathname != '/snapshot/quick-entry-create') &&  ( this.state.loader_path.pathname != '/snapshot/gstr-create') && (this.state.loader_path.pathname != '/money-link/quick-entry-create')) && <Loader />}
          {/* <div className="container mt-3"> */}
            
            {isUnderMaintenance 
            ? 
            <Switch>
            <Route path="/" component={Maintenance} />
            </Switch>
            : 
            <Switch>
            <PublicRoute exact path={["/", "/login/:token?"]} component={Login} />
              <PublicRoute exact path="/register/:token?" component={Register} />
              <PublicRoute path="/forgot-username" component={ForgetUsername} />
              <PublicRoute path="/forgot-password" component={ForgetPassword} />
              <PublicRoute path="/error-page" component={ErrorPage} />
              <Route path="/yodlee-redirection" component={YodleeCallback} />

              <Route path="/register-trial" component={RegisterTrial} />
              <Route path="/temp-snapshot" component={snapshot} />
              <Route path="/loginold" component={Loginold} />
              <Route path="/profileentity" component={profileentity} />
              <Route path="/profileentityCa" component={profileentityCa} />
              <Route path="/evidencesms" component={evidencesms} />
              <Route path="/invoice" component={invoice} />
              <Route path="/taskManager" component={taskManager} />
              <Route path="/temp-messenger" component={messenger} />
              <Route path="/cheques" component={cheques} />
              <Route path="/docboxUI" component={docbox} />
              <Route path="/financial" component={financial} />
              <Route path="/accoutstatement" component={accoutstatement} />
              <Route path="/myprofile" component={myprofile} />
              <Route path="/moneylink" component={moneylink} />
              <Route path="/set-default-entity" component={SetDefaultEntity} />
              {/* <Route path="/license" component={licenses} /> */}
              {/* <Route path="/myhome" component={myhome} /> */}
              {/* <Route path="/my-dashboard" component={myDashboard} /> */}
              <Route path="/logout" component={Logout} />
              <Route path="/page-not-found" component={PageNotFound} />
              <Route path="/test" component={TestPage} />

              <PrivateRoute path="/*" component={TheLayout} />
              </Switch>
            }
          
          {/* </div> 
     
        </div>*/}
      </Router>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const session_value = state.Session || {};
  const entity_list = session_value.entity_list || [];
  return {
    user, entity_list
  };
}

export default connect(mapStateToProps)(App);
