import React from 'react';
import { Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { displayDateFormate } from '../../Helpers/DateFormat';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import SortingIcon from '../IcoonNew/SortingIcon';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { showAmount } from '../../Helpers/Currency';
import ReactDOM from 'react-dom';


const td_style = { whiteSpace: 'normal', wordWrap: 'break-all' };  // added this line from AccountStatementsLists.jsx

class AccountStatementTransactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };

  }

  onSortClick = (sort_order, sort_by) => {
    this.props.onSortClick(sort_order, sort_by)
  }

  onSortChange(sortName, sortOrder) {
    this.props.onSortClick(sortName, sortOrder)
  }

  getCaret = (direction) => {
    console.log(direction)
    switch (direction) {
      case 'asc':
        return (<span className="icon-right-arrow-1 sort-topbottom-icon"></span>);
      case 'desc':
        return (<span className="icon-right-arrow sort-topbottom-icon"></span>);
      default:
        return (<span className="icon-Group-4449 sort-both-icon"></span>);
    }
  }

  revertSortFunc(a, b, order) {   // order is desc or asc
    if (order === 'desc') {
      return a.amount - b.amount;
    } else {
      return b.amount - a.amount;
    }
  }

  changeCredit(cell, row) {
    if (row.type == 1) {
      return cell
    }
    else {
      return "-"
    }
  }

  changeDebit(cell, row) {
    if (row.type == 1) {
      return "-"
    }
    else {
      return cell
    }
  }

  trStyleChange(row, sort_order) {
    if (!!row.is_missing_data == 1) {
      let line_position = sort_order == 1 ? "-8px" : "8px";
      return {
        //backgroundColor: '#fbceb1',
        //borderWidth:"1px", borderColor:"red", borderStyle:'solid'
        //boxShadow: "0 7px 5px -6px red"
        //boxShadow: "1px 3px 1px #9E9E9E"
        boxShadow: "0 " + line_position + " 4px -5px red"
      }
    }
    else {
      return null
    }
  }

  handlePageChange = (page_no) => {
    this.props.handlePageChange(page_no)
  }

  renderShowsTotal(start, to, total) {
    return (
      <p className="main-pagi-show">
        <span className="pagi-show">{start}</span> <span style={{ padding: 1 }}>to</span> <span className="pagi-show">{to}</span> <span style={{ padding: 1 }}>of</span> <span className="pagi-show">{total}</span>
      </p>
    );
  }

  dataFormatDebitAmount = (cell, row) => {
    if (!!row.is_missing_data == 1) {
      if (row.type == 2) {
        return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="" style={{ color: 'red' }}>{"There are some missing entries " + (this.props.sort_order == 1 ? "before" : "after") + " this transaction."}</Tooltip>}><div><span title={showAmount(row.amount)}>{showAmount(row.amount)}</span></div></OverlayTrigger>
      } else {
        return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{"There are some missing entries " + (this.props.sort_order == 1 ? "before" : "after") + " this transaction."}</Tooltip>}><div><span title={showAmount(row.amount)}>-</span></div></OverlayTrigger>
      }
    } else {
      if (row.type == 2) {
        return <span>{showAmount(row.amount)}</span>
      } else {
        return '-'
      }
    }
  }

  dataFormatCreditAmount = (cell, row) => {
    if (!!row.is_missing_data == 1) {
      if (row.type == 1) {
        return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{"There are some missing entries " + (this.props.sort_order == 1 ? "before" : "after") + " this transaction."}</Tooltip>}><div><span title={showAmount(row.amount)}>{showAmount(row.amount)}</span></div></OverlayTrigger>
      } else {
        return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{"There are some missing entries " + (this.props.sort_order == 1 ? "before" : "after") + " this transaction."}</Tooltip>}><div><span title={showAmount(row.amount)}>-</span></div></OverlayTrigger>
      }
    } else {
      if (row.type == 1) {
        return <span>{showAmount(row.amount)}</span>
      } else {
        return '-'
      }
    }
  }

  dataFormatDate = (cell, row) => {
    if (!!row.is_missing_data == 1) {
      return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{"There are some missing entries " + (this.props.sort_order == 1 ? "before" : "after") + " this transaction."}</Tooltip>}><div><span title={displayDateFormate(row.date)}>{displayDateFormate(row.date)}</span></div></OverlayTrigger>
    } else {
      return displayDateFormate(row.date)
    }
  }

  dataFormatNarration = (cell, row) => {
    if (row.is_missing_data == 1) {
      return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className=""><>{"There are some missing entries " + (this.props.sort_order == 1 ? "before" : "after") + " this transaction."}</><br /><>{row.narration}</></Tooltip>}><div className="ellips-auto">{row.narration}</div></OverlayTrigger>
    } else {
      return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{row.narration}</Tooltip>}>
        <div className="ellips-auto">{row.narration}</div>
      </OverlayTrigger>
    }
  }

  dataFormatBalance = (cell, row) => {
    if (!!row.is_missing_data == 1) {
      return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{"There are some missing entries " + (this.props.sort_order == 1 ? "before" : "after") + " this transaction."}</Tooltip>}><div><span title={row.balance_after_transaction}>{row.balance_after_transaction}</span></div></OverlayTrigger>
    } else {
      return <span>{showAmount(row.balance_after_transaction)}</span> ? <span>{showAmount(row.balance_after_transaction)}</span> : "-"
      // return row.balance_after_transaction?row.balance_after_transaction: "-"
    }
  }

  render() {
    let transaction_list = this.props.transaction_list || []
    let page_no = this.props.page_no || 1
    let per_page_items = this.props.per_page_items || 10
    let total_count = this.props.total_count || ''
    let total_pages = this.props.total_pages || ''

    const options = {
      onPageChange: this.props.handlePageChange,
      page: this.props.page_no,
      sizePerPage: this.props.per_page_items,
      pageStartIndex: 1,
      paginationSize: 5,
      prePage: 'Prev',
      nextPage: 'Next',
      firstPage: 'First',
      lastPage: 'Last',
      hideSizePerPage: true,
      //paginationShowsTotal: this.renderShowsTotal,
      //   onRowMouseOver: (row) => {
      //     if(!!row.is_missing_data == 1){
      //       return	<OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">aaa</Tooltip>}>
      // 		<span>aa</span>
      // </OverlayTrigger>
      //     }
      //     else{
      //       return	<OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">aaa</Tooltip>}>
      // 		<span>aa</span>
      // </OverlayTrigger>
      //     }
      //   }
    };

    return (
      <BootstrapTable ref='table' data={transaction_list}
        keyField='transaction_list_id'
        options={options}
        fetchInfo={{ dataTotalSize: this.props.total_count }}
        remote
        // hover
        pagination={true}
        // className="btable"
        trStyle={(row) => this.trStyleChange(row, this.props.sort_order)}
        //style={{}}
      >
        <TableHeaderColumn width="115px" tdStyle={td_style} headerAlign='center' dataAlign='center' dataFormat={(cell, row) => this.dataFormatDate(cell, row)}>
          <SortingIcon onSortClick={this.onSortClick} field_name="Date" field_number="1" sort_by={this.props.sort_by} sort_order={this.props.sort_order} />
        </TableHeaderColumn>
        <TableHeaderColumn thStyle={{ ...td_style, width: "32%" }} tdStyle={{ ...td_style, width: "32%", overflow: "hidden" }} headerAlign='left' dataAlign='left' dataFormat={(cell, row) => this.dataFormatNarration(cell, row)}>
          <SortingIcon onSortClick={this.onSortClick} field_name="Narration" field_number="5" sort_by={this.props.sort_by} sort_order={this.props.sort_order} />
        </TableHeaderColumn>
        {/* <TableHeaderColumn tdStyle={td_style} headerAlign='left' dataAlign='left' dataField='remark'>
            <SortingIcon onSortClick={this.onSortClick} field_name="Remark" field_number="3" sort_by={this.props.sort_by} sort_order={this.props.sort_order} />     
        </TableHeaderColumn> */}
        <TableHeaderColumn tdStyle={td_style} headerAlign='right' dataAlign='right' dataFormat={(cell, row) => this.dataFormatDebitAmount(cell, row)}>
          <SortingIcon onSortClick={this.onSortClick} field_name="Debit" field_number="3" sort_by={this.props.sort_by} sort_order={this.props.sort_order} />
        </TableHeaderColumn>
        <TableHeaderColumn tdStyle={td_style} headerAlign='right' dataAlign='right' dataFormat={(cell, row) => this.dataFormatCreditAmount(cell, row)}>
          <SortingIcon onSortClick={this.onSortClick} field_name="Credit" field_number="2" sort_by={this.props.sort_by} sort_order={this.props.sort_order} />
        </TableHeaderColumn>
        <TableHeaderColumn tdStyle={td_style} headerAlign='right' dataAlign='right' dataFormat={(cell, row) => this.dataFormatBalance(cell, row)} >
          <SortingIcon onSortClick={this.onSortClick} field_name="Closing Bal." field_number="4" sort_by={this.props.sort_by} sort_order={this.props.sort_order} />
        </TableHeaderColumn>
      </BootstrapTable>
    )
  }
}

export default AccountStatementTransactions;