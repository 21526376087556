import React from "react";
import { withRouter } from "react-router-dom";

class ScrollPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          bottomReached: false,
        };
     }

   
    componentDidMount() {
        document.addEventListener('scroll', this.trackScrolling);
    }
      
    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
    }
      
    trackScrolling = () => {
        const { scrollTop, offsetHeight } = document.documentElement;
        const { innerHeight } = window;
        const bottomOfWindow = Math.round(scrollTop) + innerHeight === offsetHeight;

        if(bottomOfWindow){
            this.setState({bottomReached: true})
        } else {
            this.setState({bottomReached: false})
        }
    };
    
    render() {

        // Get the current pathname from props (provided by withRouter)
        const { location } = this.props;
        const isExcelInventoryRoute = location.pathname.includes("excel-inventory");

        if (isExcelInventoryRoute) {
            return null; // Don't render anything if route includes 'excel-inventory'
        }

        return(
            // scroll to top bottom on page on click icon
            this.state.bottomReached ?
            <div className="top-bottom-icon  cpointer" onClick={() => { window.scrollTo({top:0 ,left:0, behavior: 'smooth'}); }} >
                <span className="icon-Red-arrow icon-tbwhite"></span>
            </div>
            :
            <div className="top-bottom-icon btbottom-icon cpointer" onClick={() => { window.scrollTo({top:document.body.scrollHeight, left: 0, behavior: 'smooth'}); }} >
                <span className="icon-Red-arrow icon-tbwhite"></span>
            </div>
        )
    }
}

export default withRouter(ScrollPage);