import { SET_FINANCIAL_INSTITUTE_REQUEST_ID,  SET_FI_FILTER_DATE_RANGE, SET_FI_FINANCIAL_YEAR_NAME, SET_BANKING_TRANSACTION_LIST } from "../Actions/Types";

const initialState = {
    fi_request_id: null,
    financial_year_name: "",
    filter_start_date: null,
    filter_end_date: null,
    bulk_transaction_list : [],
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_FINANCIAL_INSTITUTE_REQUEST_ID:
            return {
                ...state,
                fi_request_id: payload
            }
        case SET_FI_FINANCIAL_YEAR_NAME:
            return {
                ...state,
                financial_year_name: payload
            }
        case SET_FI_FILTER_DATE_RANGE:
            return {
                ...state,
                filter_start_date: payload.start_date,
                filter_end_date: payload.end_date
            }
        case SET_BANKING_TRANSACTION_LIST:
            return{
              ...state,
              bulk_transaction_list : payload
            }
        default:
            return state;
    }
}