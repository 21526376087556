import React from 'react'
import MoneyLinkService from '../../Services/MoneyLink/MoneyLinkService'

class ViewEvidenceReport extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            blob_report_url: ""
        }
    }

    loadXMLDoc = () => {
        var xmlhttp;
        if (window.XMLHttpRequest) {
            // Code for Internet Explorer 7+, Firefox, Chrome, Opera, and Safari
            xmlhttp = new XMLHttpRequest();
        }
        else {
            // Code for Internet Explorer 6 and Internet Explorer 5
            // xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
        }
        xmlhttp.onreadystatechange = function () {
            if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
                document.getElementById("myDiv").innerHTML = xmlhttp.responseText;
            }
        }
        xmlhttp.open("GET", this.props.report_url, true);
        xmlhttp.send();
    }

    loadpdf = () => {
        console.log("callllleddddd", this.props.report_url);
        MoneyLinkService.showReportPdf(this.props.report_url).then((data) => {
            var file = new Blob([data], { type: 'application/pdf' });
            var fileURL = URL.createObjectURL(file);
            this.setState({ blob_report_url: fileURL })
        })
    }

    componentDidMount() {
        this.loadpdf();
    }

    render() {
        console.log("inside render", this.state.blob_report_url)
        if (!this.props.report_url) {
            return "";
        }
        // const file = new Blob(this.props.report_url)        
        // const fileURL = URL.createObjectURL(file);

        return (
            <>
                <div className={"list-history-new-popup " + (this.props.show_modal ? "" : "d-none")}>
                    <div className="list-history-content">
                        <span class="popup-cross" onClick={() => this.props.closeModal()}>
                            <span class="icon-Cross-with-grey-circle popup-cross-icon"><span class="path1"></span><span class="path2 cpointer"></span><span class="path3"></span></span>
                        </span>
                        <embed src={this.state.blob_report_url} width="100%" height="100%" type="application/pdf" />
                    </div>
                </div>
            </>
        )
    }
}

export default ViewEvidenceReport;