import React, { Component,createRef } from "react";
import Table from "react-bootstrap/Table";
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';
import MerchantDropdown from "../../Components/Snapshot/MerchantDropdown";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";
import CurrencyInput from "react-currency-input-field";
// import { TextareaAutosize } from "@material-ui/core";
import { TextareaAutosize } from "@mui/material";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { checkValidGSTNo } from "../../Views/Snapshot/Validation";

import Tooltip from "react-bootstrap/Tooltip";
import ProgressBar from "../BoilerPlate/ProgressBar";
import { TableVirtuoso } from "react-virtuoso";


class ReactVirtualized extends Component{
    constructor(props) {
        super(props);
        // const{excelData}  = this.props
        this.scrollerRef = React.createRef();
        this.state = {
         
            // shortcut key state
            scrollStep: 100, // Adjust this value as needed
            scrollTop: 0,
            scrollLeft: 0,
          };
    }
    
     renderRow = (index) =>{
        const {excelData,handleStarClick,onMerchantChanged,changeAddNew,all_merchant_list,handleChange,onGSTChange,handleNewCategorySubmit,removeCategory,
            categories,checkForReadyState,isDownload,isUploading,handleTypeChange,snapshot_types,handleDateChange,amountChange,calculateTotalGst,calculateTDS,handleCategoryChange,
          tags,handleMultiSelectChange,handleKeyDown,handleChangeAddNew,customStyles,isIgstChange,handleIgnore,handleDisable,handleRestore,amountRoundOff,handleGstOverrideChange} = this.props;
          const row = { ...excelData[index] };
          return(
            // <tr key={index} style={{border: "1px solid black",}}>
            <>

                {/* Date */}
                    <td
                        key={index + 6}
                        className={row.disabled ? "ie_tr_disabled" : ""}
                    >
                        {/* <> */}
                            <DatePicker
                            disabled={
                                row.disabled || isDownload
                            }
                            className="ie_form ie_date datepicker-icon"
                            // showIcon
                            selected={
                                row.date
                                ? moment(
                                    row.date,
                                    "DD-MM-YYYY"
                                ).toDate()
                                : null
                            }
                            onChange={(date) =>
                                handleDateChange(date, index)
                            }
                            dateFormat="dd-MM-yyyy" // Set your desired date format
                            />
                        {/* </> */}
                    </td>
                {/* Date */}

                {/* Type */}
                    <td
                        key={index + 5}
                        className={row.disabled ? "ie_tr_disabled" : ""}
                    >
                        <>
                            <select
                            disabled={
                                row.disabled || isDownload
                            }
                            className="ie_form ie_select_box"
                            value={row.type}
                            onChange={(e) => {
                                handleTypeChange(e, index);
                            }}
                            // disabled={this.props.VIEW_ONLY_SNAPSHOT || !editable || this.props.is_reimbursement}
                            name="type"
                            >
                            <option value="">Select Type</option>
                            {snapshot_types.map(
                                (type, index) => {
                                return (
                                    <option key={index} value={type.id}>
                                    {type.expense} {type.name}
                                    </option>
                                );
                                }
                            )}
                            </select>
                            {/* {this.props.errors.type && <small className="filter-error-msg">{this.props.errors.type}</small>} */}
                            {row.errors.type && (
                            <OverlayTrigger
                                overlay={
                                <Tooltip id="tooltip-disabled">
                                    {row.errors.type}
                                </Tooltip>
                                }
                            >
                                <span className="ie_error_dot ie_red_dot"></span>
                            </OverlayTrigger>
                            )}
                        </>
                    </td>
                {/* Type */}

                {/* Refrence No */}
                    <td
                        key={index + 7}
                        className={row.disabled ? "ie_tr_disabled" : ""}
                    >
                        <>
                            <input
                            disabled={
                                row.disabled || isDownload
                            }
                            type="text"
                            className="ie_form ie_pgst_pan_reg"
                            // className={"form-control " + (this.state.errors.invoice_number ? " wrong-red " : "") + (this.state.global_preidction_status && !!this.state.snapshot_number_prediction_status ? " input-bg-green " : "")}
                            value={row.invoice_number}
                            onBlur={(e) => {
                                checkForReadyState(index);
                            }}
                            onChange={(e) => {
                                handleChange(e, index);
                            }}
                            // disabled={this.VIEW_ONLY_SNAPSHOT || !editable}
                            name="invoice_number"
                            />
                            {row.errors.invoice_number && (
                            <OverlayTrigger
                                overlay={
                                <Tooltip id="tooltip-disabled">
                                    {row.errors.invoice_number}
                                </Tooltip>
                                }
                            >
                                <span className="ie_error_dot ie_red_dot"></span>
                            </OverlayTrigger>
                            )}
                        </>
                    </td>
                {/* Reference No */}

                {/* Party Name */}
                    <td
                        key={index + 1}
                        className={row.disabled ? "ie_tr_disabled" : ""}
                    >
                        <>
                            <MerchantDropdown
                            isExcel={true}
                            global_preidction_status={1}
                            merchant_name_prediction_status={1}
                            isDisabled={
                                row.disabled || isDownload
                            }
                            selected_option={row.merchant_option}
                            selected_value={
                                row.merchant_option.value || ""
                            }
                            onMerchantChanged={(
                                option,
                                isEdit = false
                            ) => {
                                console.log(
                                "new object options--------------------",
                                option
                                );
                                if (option.label == null)
                                option.label = "";
                                onMerchantChanged(
                                option,
                                isEdit,
                                index
                                );
                            }}
                            merchant_list={all_merchant_list}
                            is_edit={row.is_edit_supplier}
                            is_new={row.is_new}
                            new_value={row.new_value}
                            changeAddNew={(val) => {
                                changeAddNew(val,index);
                            }}
                            onBlur={() => {
                                checkForReadyState(index);
                            }}
                            isHiddenOpt={true}
                            />

                            {row.errors.merchant_name && (
                            <OverlayTrigger
                                overlay={
                                <Tooltip id="tooltip-disabled">
                                    {row.errors.merchant_name}
                                </Tooltip>
                                }
                            >
                                <span className="ie_error_dot ie_red_dot"></span>
                            </OverlayTrigger>
                            )}
                            {row.ignoreErrors?.ignore_merchant_name && (
                            <OverlayTrigger
                                overlay={
                                <Tooltip id="tooltip-disabled">
                                    {
                                    row.ignoreErrors
                                        .ignore_merchant_name
                                    }
                                </Tooltip>
                                }
                            >
                                <span className="ie_error_dot ie_yellow_dot"></span>
                            </OverlayTrigger>
                            )}
                        </>
                    {/* {!this.state.errors.merchant_name && <small className="error_right">{this.state.errors.merchant_name}</small>} */}
                    </td>
                {/* Party Name */}

                {/* GST NO  */}
                    <td
                        key={index + 2}
                        className={
                            row.disabled || isDownload
                            ? "ie_tr_disabled"
                            : ""
                        }
                    >
                        <>
                            <input
                            disabled={
                                row.disabled || isDownload
                            }
                            className="ie_form ie_pgst_pan_reg"
                            type="text"
                            value={row.gst_no}
                            name="gst_no"
                            onChange={(e) => {
                                handleChange(e, index);
                            }}
                            onBlur={(e) => {
                                checkForReadyState(index);
                                onGSTChange(e, index);
                            }}
                            />

                            {row.errors.gst_no && (
                            <OverlayTrigger
                                overlay={
                                <Tooltip id="tooltip-disabled">
                                    {row.errors.gst_no}
                                </Tooltip>
                                }
                            >
                                <span className="ie_error_dot ie_red_dot"></span>
                            </OverlayTrigger>
                            )}
                        </>
                    </td>
                {/* GST NO */}

                {/* pan num */}
                    <td
                        key={index + 3}
                        className={
                            row.disabled || isDownload
                            ? "ie_tr_disabled"
                            : ""
                        }
                    >
                        <>
                            <input
                            className="ie_form ie_pgst_pan_reg"
                            type="text"
                            name="pan_number"
                            value={row.pan_number}
                            disabled={
                                checkValidGSTNo(row.gst_no) ||
                                row.disabled ||
                                isDownload
                            }
                            onChange={(e) => {
                                handleChange(e, index);
                            }}
                            onBlur={(e) => {
                                checkForReadyState(index);
                            }}
                            />
                            {row.errors.pan_number && (
                            <OverlayTrigger
                                overlay={
                                <Tooltip id="tooltip-disabled">
                                    {row.errors.pan_number}
                                </Tooltip>
                                }
                            >
                                <span className="ie_error_dot ie_red_dot"></span>
                            </OverlayTrigger>
                            )}
                        </>
                    </td>
                {/* Pan num */}

                {/* cgst Amount */}
                    <td
                        key={index + 13}
                        className={row.disabled ? "ie_tr_disabled" : ""}
                    >
                        <CurrencyInput
                            type="text"
                            className="ie_form ie_csi_gst form-text-right"
                            name="cgst_amount"
                            value={row.cgst_amount}
                            allowNegativeValue={false}
                            autoComplete="off"
                            onBlur={async() => {
                            await amountRoundOff('cgst_amount',index)
                            calculateTotalGst(index);
                            checkForReadyState(index);
                            }}
                            disabled={
                            row.disabled || isDownload
                                ? row.disabled
                                : row.is_igst
                            }
                            decimalsLimit={2}
                            prefix="&#8377; "
                            placeholder="&#8377; 0"
                            onValueChange={(value, name) =>
                            amountChange(name, value, index)
                            }
                        />
                        {row.errors.cgst_amount && !row.is_igst && (
                            <OverlayTrigger
                            overlay={
                                <Tooltip id="tooltip-disabled">
                                {row.errors.cgst_amount}
                                </Tooltip>
                            }
                            >
                            <span className="ie_error_dot ie_red_dot"></span>
                            </OverlayTrigger>
                        )}
                        {row.errors.same_amount && (
                            <OverlayTrigger
                            overlay={
                                <Tooltip id="tooltip-disabled">
                                {row.errors.same_amount}
                                </Tooltip>
                            }
                            >
                            <span className="ie_error_dot ie_red_dot"></span>
                            </OverlayTrigger>
                        )}
                    </td>
                {/* cgst amount */}

                {/* Sgst Amount */}
                    <td
                        key={index + 15}
                        className={row.disabled ? "ie_tr_disabled" : ""}
                    >
                        <CurrencyInput
                            type="text"
                            className="ie_form ie_csi_gst form-text-right"
                            // disabled={(!!this.state.cgst_amount || !!this.state.sgst_amount) ? "disabled" : ""}
                            //	className={"form-control form-text-right mt-1 " + (this.state.errors.igst_amount ? " wrong-red " : "") + (this.state.global_preidction_status && !!this.state.igst_amount_prediction_status ? " input-bg-green " : "")}
                            value={row.sgst_amount}
                            allowNegativeValue={false}
                            decimalsLimit={2}
                            prefix="&#8377; "
                            placeholder="&#8377; 0"
                            onValueChange={(value, name) =>
                            amountChange(name, value, index)
                            }
                            onBlur={async() => {
                            await amountRoundOff('sgst_amount',index)
                            calculateTotalGst(index);
                            checkForReadyState(index);
                            }}
                            disabled={
                            row.disabled || isDownload
                                ? row.disabled
                                : row.is_igst
                            }
                            name="sgst_amount"
                        />
                        {row.errors.sgst_amount && !row.is_igst && (
                            <OverlayTrigger
                            overlay={
                                <Tooltip id="tooltip-disabled">
                                {row.errors.sgst_amount}
                                </Tooltip>
                            }
                            >
                            <span className="ie_error_dot ie_red_dot"></span>
                            </OverlayTrigger>
                        )}
                        {row.errors.same_amount && (
                            <OverlayTrigger
                            overlay={
                                <Tooltip id="tooltip-disabled">
                                {row.errors.same_amount}
                                </Tooltip>
                            }
                            >
                            <span className="ie_error_dot ie_red_dot"></span>
                            </OverlayTrigger>
                        )}
                    </td>
                {/* Sgst Amount */}

                {/* Igst */}
                    <td
                        key={index + 16}
                        className={row.disabled ? "ie_tr_disabled" : ""}
                    >
                        <div className="d-flex align-items-center">
                            <div className="sup-cust-check lable-input mt-0 ml-2">
                            <input
                                disabled={
                                row.disabled || isDownload || row.disable_igst
                                }
                                className="styled-checkbox"
                                id="styled-checkbox-igst"
                                type="checkbox"
                                name="is_igst"
                                checked={row.is_igst}
                                //	disabled={this.VIEW_ONLY_SNAPSHOT || !editable || this.state.disable_igst}
                                onChange={(e) => {
                                isIgstChange(e,index)
                                }}
                            />
                            </div>
                            <CurrencyInput
                            //disabled={}
                            className="ie_form ie_csi_gst form-text-right"
                            // disabled={!!this.state.igst_amount ? "disabled" : ""}
                            //	className={"form-control form-text-right" + (this.state.errors.sgst_amount ? " wrong-red " : "") + (this.state.global_preidction_status && !!this.state.sgst_amount_prediction_status ? " input-bg-green " : "")}
                            value={row.igst_amount}
                            allowNegativeValue={false}
                            decimalsLimit={2}
                            prefix="&#8377; "
                            placeholder="&#8377; 0"
                            onValueChange={(value, name) =>
                                amountChange(name, value, index)
                            }
                            is_amount={1}
                            onBlur={async() => {
                                await amountRoundOff('igst_amount',index)
                                calculateTotalGst(index);
                                checkForReadyState(index);
                            }}
                            disabled={
                                row.disabled || isDownload
                                ? true
                                : !row.is_igst
                            }
                            name="igst_amount"
                            />
                        </div>
                        {row.errors.igst_amount && (
                            <OverlayTrigger
                            overlay={
                                <Tooltip id="tooltip-disabled">
                                {row.errors.igst_amount}
                                </Tooltip>
                            }
                            >
                            <span className="ie_error_dot ie_red_dot"></span>
                            </OverlayTrigger>
                        )}
                        {row.errors.igst_amount && row.is_igst && (
                            <OverlayTrigger
                            overlay={
                                <Tooltip id="tooltip-disabled">
                                {row.errors.igst_amount}
                                </Tooltip>
                            }
                            >
                            <span className="ie_error_dot ie_red_dot"></span>
                            </OverlayTrigger>
                        )}
                        {row.errors.igst_amount &&
                            row.errors.same_amount && (
                            <OverlayTrigger
                                overlay={
                                <Tooltip id="tooltip-disabled">
                                    {row.errors.same_amount}
                                </Tooltip>
                                }
                            >
                                <span className="ie_error_dot ie_red_dot"></span>
                            </OverlayTrigger>
                            )}
                    </td>
                {/* Igst */}

                {/* gst override */}
                    <td
                        key={index + 18}
                        className={row.disabled ? "ie_tr_disabled" : ""}
                    >
                        <div className="sup-cust-check lable-input">
                            <input
                                disabled={
                                    row.disabled || isDownload || row.hide_gst_override
                                    }
                                className="styled-checkbox"
                                id="styled-checkbox-igst"
                                type="checkbox"
                                name="gst_override"
                                checked={row.gst_override}
                                //	disabled={row.disable_igst}
                                onChange={(e) => {
                                handleGstOverrideChange(e, index);
                                    }}
                            />
                        </div>
                    </td>
                {/* gst override */}

                {/* Total Amount */}
                    <td
                        key={index + 8}
                        className={row.disabled ? "ie_tr_disabled" : ""}
                    >
                        <CurrencyInput
                            disabled={
                            row.disabled || isDownload
                            }
                            type="text"
                            className="ie_form ie_amount form-text-right"
                            //		className={"form-control form-text-right" + (this.state.errors.total_amount ? " wrong-red " : "") + (this.state.global_preidction_status && !!this.state.total_amount_prediction_status ? " input-bg-green " : "")}
                            name="total_amount"
                            value={row.total_amount}
                            allowNegativeValue={false}
                            autoComplete="off"
                            onBlur={async(e) => {
                            //	this.fetchSMS()
                            await amountRoundOff('total_amount',index)
                            calculateTDS(index);
                            checkForReadyState(index);
                            //this.setApprovalAmt(e)
                            }}
                            //		disabled={this.VIEW_ONLY_SNAPSHOT || !editable}
                            decimalsLimit={2}
                            prefix="&#8377; "
                            placeholder="&#8377; 0"
                            onValueChange={(value, name) =>
                            amountChange(name, value, index)
                            }
                        />
                        {row.errors.total_amount && (
                            <OverlayTrigger
                            overlay={
                                <Tooltip id="tooltip-disabled">
                                {row.errors.total_amount}
                                </Tooltip>
                            }
                            >
                            <span className="ie_error_dot ie_red_dot"></span>
                            </OverlayTrigger>
                        )}
                    </td>
                {/* Total Amount */}

                {/* tds percentage */}
                    <td
                        key={index + 19}
                        className={row.disabled ? "ie_tr_disabled" : ""}
                    >
                        <CurrencyInput
                        disabled={
                            row.disabled || isDownload || (row.type == 9 || row.type == 10 || row.type == 4 || row.type == 5)
                            }
                        type="text"
                        className="ie_form ie_tds form-text-right"
                        //	className={"form-control form-text-right" + (this.state.errors.tds_percentage ? " wrong-red " : "") + (this.state.global_preidction_status && !!this.state.tds_percentage_prediction_status ? " input-bg-green " : "")}
                        value={row.tds_percentage}
                        allowNegativeValue={false}
                        decimalsLimit={2}
                        onValueChange={(value, name) => {
                                        value > 100
                                        ? ""
                                        : amountChange(
                                            name,
                                            value,
                                            index
                                            );
                                            }}
                        onBlur={async() => {
                            // this.amountRoundOff('tds_percentage',index)
                            await amountRoundOff('tds_percentage',index)
                            calculateTDS(index);
                            checkForReadyState(index);
                            }}
                        is_amount={1}
                        //	disabled={this.VIEW_ONLY_SNAPSHOT || !editable}
                        name="tds_percentage"
                        />
                                                {/* {this.state.errors.tds_percentage && <small className="error_right">{this.state.errors.tds_percentage}</small>} */}
                    </td>
                {/* tds percentage */}

                {/* Category */}
                    <td
                        key={index + 4}
                        className={
                            row.disabled || isDownload
                            ? "ie_tr_disabled"
                            : ""
                        }
                    >
                        {/* {console.log("is new ctaegory -------", row.isNewCategory, row.category_id)} */}
                        {row.isNewCategory ? (
                            <div className="add-new-selectie">
                            <input
                                disabled={
                                row.disabled || isDownload
                                }
                                className="ie_form"
                                type="text"
                                value={row.newCategoryName}
                                name="newCategoryName"
                                // onChange={(e) =>
                                //   this.setState({
                                //     newCategoryName: e.target.value,
                                //   })
                                // }
                                onBlur={(e) => {
                                checkForReadyState(index);
                                }}
                                onChange={(e) => {
                                handleChange(e, index);
                                }}
                                onKeyDown={(e) =>
                                handleNewCategorySubmit(
                                    e,
                                    index
                                )
                                }
                            />
                            <span
                                disabled={
                                row.disabled || isDownload
                                }
                                class="icon-Cross-with-grey-circle ie-add-icon"
                                onClick={()=> {
                                    console.log("removed cate",index)
                                    removeCategory(index)
                                }
                                
                                }
                            >
                                <span class="path1"></span>
                                <span class="path2"></span>
                                <span class="path3"></span>
                            </span>
                            </div>
                        ) : (
                            <>
                            <select
                                disabled={
                                row.disabled || isDownload
                                }
                                className="ie_form ie_select_box"
                                value={row.category_id}
                                onChange={(e) =>
                                    handleCategoryChange(e,index)
                                }
                                name="category_id"
                            >
                                {/* {!row.category_id && ( */}
                                <option value="" disabled hidden>
                                Select Categories
                                </option>
                                {/* )} */}
                                <option value="-2" className="ie_add_new">
                                Add New Category
                                </option>
                                {categories.map(
                                (category, index) => {
                                    return (
                                    <option
                                        key={index}
                                        value={category.id}
                                    >
                                        {category.name}
                                    </option>
                                    );
                                }
                                )}
                            </select>
                            </>
                        )}
                        {row.ignoreErrors?.ignore_category_name && (
                            <OverlayTrigger
                            overlay={
                                <Tooltip id="tooltip-disabled">
                                {row.ignoreErrors.ignore_category_name}
                                </Tooltip>
                            }
                            >
                            <span className="ie_error_dot ie_yellow_dot"></span>
                            </OverlayTrigger>
                        )}
                    </td>
                {/* Category */}
                
                {/* Tags */}
                    <td
                        key={index + 9}
                        className={row.disabled ? "ie_tr_disabled" : ""}
                    >
                        <Select
                            isDisabled={
                            row.disabled || isDownload
                            }
                            className="ie_form_multi"
                            isMulti
                            options={tags}
                            value={(row.tags || []).map((tag) =>
                            tags.find(
                                (option) => option.value === tag
                            )
                            )}
                            onChange={(selectedOptions) =>
                            handleMultiSelectChange(
                                selectedOptions,
                                index
                            )
                            }
                            onInputChange={(selectedOptions) =>
                            handleChangeAddNew(
                                selectedOptions,
                                index
                            )
                            }
                            onKeyDown={(e) =>
                            handleKeyDown(e, index)
                            }
                            styles={customStyles}
                        />
                        {row.ignoreErrors?.ignore_tags_name && (
                            <OverlayTrigger
                            overlay={
                                <Tooltip id="tooltip-disabled">
                                {row.ignoreErrors.ignore_tags_name}
                                </Tooltip>
                            }
                            >
                            <span className="ie_error_dot ie_yellow_dot"></span>
                            </OverlayTrigger>
                        )}
                    </td>
                {/* Tags */}

                {/* is perqusite*/}
                    <td
                        key={index + 10}
                        className={row.disabled ? "ie_tr_disabled" : ""}
                    >
                        <div className="sup-cust-check">
                            <input
                            disabled={
                                row.disabled || isDownload || (row.type == 11 || row.type == 7 || row.type == 10 || row.type == 1 || row.type == 9)
                            }
                            className="styled-checkbox"
                            type="checkbox"
                            // disabled={!!!row.expense}
                            onChange={(e) => {
                                handleChange(e, index);
                            }}
                            name="is_perquisite"
                            checked={row.is_perquisite} // assuming cell contains the boolean value
                            // onChange={(e) =>
                            //   this.handlePerquisitCheckboxChange(
                            //     e,
                            //     index
                            //   )
                            // }
                            />
                        </div>
                    </td>
                {/* is perqusite*/}

                {/*Additional Info*/}
                    <td
                        key={index + 11}
                        className={row.disabled ? "ie_tr_disabled" : ""}
                    >
                        <TextareaAutosize
                            type="text"
                            maxLength="1000"
                            className=" ietextarea"
                            disabled={row.disabled || row.isDownload}
                            //	className={"form-control " + (this.state.errors.additional_information ? " wrong-red " : "")}
                            value={row.additional_information}
                            minRows={1}
                            onChange={(e) => {
                            handleChange(e, index);
                            }}
                            //	disabled={this.VIEW_ONLY_SNAPSHOT || !editable}
                            name="additional_information"
                        ></TextareaAutosize>
                    </td>
                {/*Addditional Info*/}

                {/* is msme  */}
                    <td
                        key={index + 12}
                        className={row.disabled ? "ie_tr_disabled" : ""}
                    >
                        <div className="sup-cust-check lable-input justify-content-end">
                            <input
                            disabled={
                                row.disabled || isDownload
                            }
                            className="styled-checkbox"
                            id="styled-checkbox-msme"
                            type="checkbox"
                            name="is_msme"
                            checked={row.is_msme}
                            onChange={(e) => {
                                handleChange(e, index);
                            }}
                            //	disabled={this.VIEW_ONLY_SNAPSHOT || !editable || (!this.state.is_edit_supplier && !!this.state.merchant_option.value)}
                            />
                        </div>
                    </td>
                {/* is msme */}

                {/* STAR */}
                    <td
                        key={index + 0}
                        className={
                            row.disabled || isDownload
                            ? "ie_tr_disabled"
                            : ""
                        }
                    >
                        <div className="text-center">
                            <span
                            disabled={
                                row.disabled || isDownload
                            }
                            class={`icon-Star star-td ${row.star.toUpperCase() === "YES"
                                ? "active"
                                : ""
                                }`}
                            onClick={() =>
                                handleStarClick(index)
                            }
                            ></span>
                        </div>
                    </td>
                {/* Star */}

                {/* gst claim elligible */}
                    <td
                        key={index + 17}
                        className={row.disabled ? "ie_tr_disabled" : ""}
                    >
                        <div className="sup-cust-check lable-input">
                            <input
                            disabled={
                                row.disabled || isDownload
                            }
                            className="styled-checkbox"
                            id="styled-checkbox-igst"
                            type="checkbox"
                            name="is_gst_claim_eligible"
                            checked={row.is_gst_claim_eligible}
                            //	disabled={row.disable_igst}
                            onChange={(e) => {
                                handleChange(e, index);
                            }}
                            />
                        </div>
                    </td>
                {/* gst claim elligible */}
                
                <td>
                {isUploading ? (
                    <div className="ie_ProgressBar">
                    <ProgressBar
                        key={index + 19}
                        bgcolor={"#77d7b1"}
                        completed={
                    100
                        }
                    />
                    </div>
                ) : 100 > 0 ? (
                    row.disabled ? (
                    <span className="ie_status_msg">
                        {" "}
                        Deleted{" "}
                    </span>
                    ) : (
                    <span className="ie_status_msg">
                        {" "}
                        {row["STATUS"] === "Uploaded" ? (
                        <span style={{ color: "green" }}>
                            {row["STATUS"]}
                        </span>
                        ) : (
                        <span style={{ color: "red" }}>
                            {row["STATUS"]}
                        </span>
                        )}
                    </span>
                    )
                ) : row.disabled || isDownload ? (
                    <span className="ie_status_msg">
                    {" "}
                    Deleted{" "}
                    </span>
                ) : (
                    <span className="ie_status_msg">
                    {" "}
                    Waiting...{" "}
                    </span>
                )}
                </td>
                <td>
                <div className="ie_icon_up_btn">
                    {row.ignoreErrors &&
                    Object.keys(row.ignoreErrors).length > 0 ? (
                    <OverlayTrigger
                        overlay={
                        <Tooltip
                            id={`tooltip-disabled-${index}`}
                        >
                            Ignore
                        </Tooltip>
                        }
                    >
                        <span
                        class="icon-warning ie_icon_btn yellow-icon"
                        onClick={() =>
                            handleIgnore(index)
                        }
                        ></span>
                    </OverlayTrigger>
                    ) : (
                    <span class="icon-warning ie_icon_btn gray-icon cmenu"></span>
                    )}
                    {row.disabled ? (
                    <OverlayTrigger
                        overlay={
                        <Tooltip id="tooltip-disabled">
                            Restore
                        </Tooltip>
                        }
                    >
                        <span
                        class="icon-Exports-green undu-icon blue-icon ie_icon_btn"
                        onClick={() =>
                            handleRestore(index)
                        }
                        ></span>
                    </OverlayTrigger>
                    ) : (
                    <OverlayTrigger
                        overlay={
                        <Tooltip id="tooltip-disabled">
                            Delete
                        </Tooltip>
                        }
                    >
                        <span
                        class="icon-delete red-icon ie_icon_btn"
                        onClick={() =>
                            handleDisable(index)
                        }
                        ></span>
                    </OverlayTrigger>
                    )}
                </div>
                </td>
            </>
          )
     }

     // shortcut key scroll function
  scroll(direction) {
    const { scrollStep, scrollTop, scrollLeft } = this.state;
    const element = this.scrollerRef.current;

    let newScrollTop = scrollTop;
    let newScrollLeft = scrollLeft;
    if (direction === "up") {
      newScrollTop = Math.max(scrollTop - scrollStep, 0);
    } else if (direction === "down") {
      newScrollTop = scrollTop + scrollStep;
    } else if (direction === "left") {
      newScrollLeft = Math.max(scrollLeft - scrollStep, 0);
    } else if (direction === "right") {
      newScrollLeft = scrollLeft + scrollStep;
    }

    // Update state and scroll position
    this.setState(
      { scrollTop: newScrollTop, scrollLeft: newScrollLeft },
      () => {
        if (element) {
          element.scrollTop = newScrollTop;
          element.scrollLeft = newScrollLeft;
        }
      }
    );
  }
   
    render(){
        
        const {excelData} = this.props
        return(
            <>
            <div className="row">
            <div className="col-12">
            <div className="import-excel-border">
               <div className="import-inventory-table" style = {{height: "600px",  }} ref={this.scrollRef}> 
            <div className="table-container">
                {/* <table className="table"  style={{ width: "90%", borderCollapse: "collapse"}}> */}
                    {/* <thead>
                    <tr style={{ display: "flex", backgroundColor: "#f0f0f0", fontWeight: "bold" }}>
                    {excelData.length > 0 && (
                      <>
                        <th key={0} className="text-center">
                          Star
                        </th>
                        <th key={1}>Party Name</th>
                        <th key={2}>Party GST</th>
                        <th key={3}>Party PAN</th>
                        <th key={4}>Category</th>
                        <th key={5}>Type</th>
                        <th key={6} className="ie_date">
                          Date
                        </th>
                        <th key={7}>Reference No</th>
                        <th key={8} className="ie_amount">
                          Total Amount
                        </th>
                        <th key={9}>Tags</th>
                        <th key={10}>Perquisite</th>
                        <th key={11}>Additional Info</th>
                        <th key={12}>MSME</th>
                        <th key={13} className="ie_csi_gst">
                          CGST
                        </th>
                        <th key={14} className="ie_csi_gst">
                          SGST
                        </th>
                        <th key={15} className="ie_csi_gst">
                          IGST
                        </th>
                        <th key={16}>GST Eligible</th>
                       
                        <th key={17}>GST Override</th>
                        <th key={18} className="ie_tds">
                          TDS %
                        </th>
                        <th key={19}>Status</th>
                        <th className="ie_action">Action</th>
                      </>
                    )}
                  </tr>
                    </thead> */}
                    
                    {/* <tbody> */}
                        {excelData.length > 0 && 
                            // <tr>
                            // <td>
                                <div style = {{width:'100%', height: "600px",  }} >
                                    <TableVirtuoso
                                    // style={{border: "1px solid red"}}
                                    fixedHeaderContent={() => (
                                        // <tr style={{border: "3px solid blue",display:'inline-flex', backgroundColor: "#f0f0f0", fontWeight: "bold" }}>
                                        <>
                                        {excelData.length > 0 && (
                                          <>
                                          <th key={6} className="ie_date">
                                              Date
                                            </th>
                                            <th key={5}>Type</th>
                                            <th key={7}>Reference No</th>
                                            <th key={1} className="ie_party_name">Party Name</th>
                                            <th key={2}>Party GST</th>
                                            <th key={3}>Party PAN</th>
                                            <th key={13} className="ie_csi_gst">
                                              CGST
                                            </th>
                                            <th key={14} className="ie_csi_gst">
                                              SGST
                                            </th>
                                            <th key={15} className="ie_csi_gst">
                                              IGST
                                            </th>
                                            <th key={17}>GST Override</th>
                                            <th key={8} className="ie_amount">
                                              Total Amount
                                            </th>
                                            <th key={18} className="ie_tds">
                                              TDS %
                                            </th>
                                            <th key={4}>Category</th>
                                            <th key={9}>Tags</th>
                                            <th key={10}>Perquisite</th>
                                            <th key={11}>Additional Info</th>
                                            <th key={12}>MSME</th>
                                            <th key={0} className="ie_star">
                                              Star
                                            </th>
                                           
                                            <th key={16}>GST Eligible</th> 
                                          
                                            {/* <th key={17} className="ie_tds"> */}
                                            
                                            <th key={19}>Status</th>
                                            <th className="ie_action">Action</th>
                                          </>
                                        )}
                                      </>
                                    )}
                                    // style={{ height: "100%", width: "100%" }}
                                   //  scrollerRef={this.scrollerRef}
                                   scrollerRef={(ref) => { this.scrollerRef.current = ref; }}
                                   //  useWindowScroll = {true}
                                       // totalCount={excelData.length}
                                       data={excelData}
                                        itemContent={(index) => this.renderRow(index)}
                                    />
                                </div>
                        //     </td>
                        // </tr>
                        }
                        
{/*                         
                    </tbody>
                </table> */}
            </div>
            </div>
            </div>
            </div>
            </div>

                {excelData.length > 0 ? (
                <div className="ie_arrows">
                  {/* <div className="ie_arrows justify-content-end"> */}
                  <span
                    class="icon-right-arrow ie_arrows_icon ie_left"
                    onClick={() => this.scroll("left")}
                  ></span>
                  <span
                    class="icon-right-arrow-1 ie_arrows_icon ie_right"
                    onClick={() => this.scroll("right")}
                  ></span>
                  <span
                    class="icon-right-arrow-1 ie_arrows_icon pt-1"
                    onClick={() => this.scroll("down")}
                  ></span>
                  <span
                    class="icon-right-arrow ie_arrows_icon"
                    onClick={() => this.scroll("up")}
                  ></span>
                </div>
              ) : null}
              
            </>
          
      
        )
    }
}

function mapStateToProps(state) {
    const session_values = state.Session || {};
    const entity_gst_no = session_values.entity_config_param.gst_no || null;
    const amount_round_off = session_values.entity_config_param.amount_round_off;
    const features_permissions_list =
      session_values.features_permissions_list || [];
    const accounting_mode =
      session_values.entity_config_param.accounting_mode || 1;
    return {
      entity_gst_no,
      features_permissions_list,
      accounting_mode,
      amount_round_off
    };
  }


export default connect(mapStateToProps)(withRouter(ReactVirtualized));