import { createStore, applyMiddleware,compose } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
// import thunk from "redux-thunk";
import { thunk } from "redux-thunk";
import rootReducer from "../Redux/Reducers";

const middleware = [thunk];

const enhancer = compose(composeWithDevTools(applyMiddleware(...middleware)));

// const Store = createStore(
//   rootReducer,
//   composeWithDevTools(applyMiddleware(...middleware))
// );
const Store = createStore(rootReducer,enhancer)

export default Store;