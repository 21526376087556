import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import * as XLSX from "xlsx";
import  TextareaAutosize  from "@mui/material/TextareaAutosize";
import Table from "react-bootstrap/Table";
import DatePicker from "react-datepicker";
import CurrencyInput from "react-currency-input-field";
import moment from "moment";
import Button from "react-bootstrap/Button";
import { ExcelRenderer } from "react-excel-renderer";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import loaderw from "../../Assets/img/buktec-preloader.gif";
import SnapshotService from "../../Services/Snapshot/SnapshotService";
import MerchantDropdown from "../../Components/Snapshot/MerchantDropdown";
import Tooltip from "react-bootstrap/Tooltip";
import ProgressBar from "../BoilerPlate/ProgressBar";
import {
  CREATE_BUSSINESS_SNAPSHOT_CODE,
  CREATE_REIMBUSRMENT_SNAPSHOT_CODE,
} from "../../Helpers/Constants";
import { connect } from "react-redux";
import { withRouter, Prompt } from "react-router-dom";
import { changeDateFormate } from "../../Helpers/DateFormat";
import {
  checkValidGSTNo,
  validateCreateEvidenceWithReadyState,
} from "../../Views/Snapshot/Validation";
import { confirm } from "../../Components/BoilerPlate/Confirm";
import { toast } from "react-toastify";
import excelFile from "../../Assets/img/Buktec-Snapshot-Excel-Import-Sample.xlsx";
import { roundOffValue } from "../../Views/Snapshot/Roundoff";
import TableComponent from "./TableComponent";
import ReactVirtualized from "./ReactVirtualized";

const tableH = [
  "STAR",
  "PARTY NAME",
  "PARTY GST",
  "PARTY PAN",
  "CATEGORY",
  "TYPE",
  "DATE",
  "REFERENCE NO",
  "TOTAL AMOUNT",
  "TAGS",
  "PERQUISITE",
  "ADDITIONAL INFO",
  "MSME",
  "CGST",
  "SGST",
  "IGST",
  "GST ELIGIBLE",
  "TDS %",
];

const masterPayload = {
  tags: [],
  categories: [],
  merchants: [],
};

const BATCHSIZE = 400;
const RECORD_LIMIT = 3000;
class ImportExcelPopups extends Component {
  constructor(props) {
    super(props);

    this.PERMISSION_LIST = this.props.features_permissions_list;
    this.CREATE_BUSSINESS_SNAPSHOT = this.PERMISSION_LIST.includes(
      CREATE_BUSSINESS_SNAPSHOT_CODE
    );
    this.CREATE_REIMBUSRMENT_SNAPSHOT = this.PERMISSION_LIST.includes(
      CREATE_REIMBUSRMENT_SNAPSHOT_CODE
    );

    this.state = {
      excelData: [],
      isUploading: false,
      uploading_file_percent: 0,
      isDownload: false,
      isReady: false,
      isSelectFile: false,
      selectFileError: "",
      merchant_option: {},
      categories: [],
      merchants: [],
      all_merchant_list: [],
      all_old_merchant_list: [],
      snapshot_types: [],
      tags: [],

      editableRowIndex: null,
      //  editableColumnIndex: null,
      newCategoryName: "",
      // shortcut key state
      scrollStep: 100, // Adjust this value as needed
      scrollTop: 0,
      scrollLeft: 0,
    };

    this.scrollRef = React.createRef();
  }
  customStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: 200,
    }),
  };
  componentDidMount() {
    this.getSnapshotMasters();

    // shortcut key

    Mousetrap.bind("ctrl+shift+up", () => this.scroll("up"));
    Mousetrap.bind("ctrl+shift+down", () => this.scroll("down"));
    Mousetrap.bind("ctrl+shift+left", () => this.scroll("left"));
    Mousetrap.bind("ctrl+shift+right", () => this.scroll("right"));
  }

  componentDidUpdate(prevProps, prevState) {
    // shortcut key
    Mousetrap.bind("ctrl+shift+up", () => this.scroll("up"));
    Mousetrap.bind("ctrl+shift+down", () => this.scroll("down"));
    Mousetrap.bind("ctrl+shift+left", () => this.scroll("left"));
    Mousetrap.bind("ctrl+shift+right", () => this.scroll("right"));
  }

  componentWillUnmount() {
    Mousetrap.unbind([
      "ctrl+shift+up",
      "ctrl+shift+down",
      "ctrl+shift+left",
      "ctrl+shift+right",
    ]);
  }


  // validation function for matching pan gst
  validationMatching(data) {
    console.log("validation matching -------------------------", data);
    let errors = {};

    data.forEach((row, index) => {
      if (row.disabled) {
        return;
      }

      // Check if both PARTY GST and PARTY PAN are not empty
      if (row.gst_no.trim() !== "" && row.pan_number.trim() !== "") {
        // Validate against merchant list for GST/PAN uniqueness but with a different party name
        const duplicate = this.state.all_merchant_list.find(
          (merchant) =>
            merchant.gst_no === row.gst_no &&
            merchant.pan_number === row.pan_number &&
            merchant.label !== row.merchant_name
        );

        if (duplicate) {
          errors[index] = {
            ...errors[index],
            gst_no:
              "Merchant with same GST already exists with a different name.",
            pan_number:
              "Merchant with same PAN already exists with a different name.",
          };
        }
      }

      // If PARTY GST is not empty but PARTY PAN is, validate only for GST
      else if (row.gst_no.trim() !== "") {
        const duplicateGST = this.state.merchant_list.find(
          (merchant) =>
            merchant.gst_no === row.gst_no &&
            merchant.label !== row.merchant_name
        );

        if (duplicateGST) {
          errors[index] = {
            ...errors[index],
            gst_no:
              "Merchant with same GST already exists with a different name.",
          };
        }
      }

      // If PARTY PAN is not empty but PARTY GST is, validate only for PAN
      else if (row.pan_number.trim() !== "") {
        const duplicatePAN = this.state.merchant_list.find(
          (merchant) =>
            merchant.pan_number === row.pan_number &&
            merchant.label !== row.merchant_name
        );

        if (duplicatePAN) {
          errors[index] = {
            ...errors[index],
            pan_number:
              "Merchant with same PAN already exists with a different name.",
          };
        }
      }

      // Other validation rules can be added here
    });

    return errors;
  }

  // function for ignor
  ignoreFunction(data) {
    console.log('igonore -------------------', data);
    let errors = {};
    const matchingMerchant = this.state.all_merchant_list.find(
      (merchant) => merchant.label === data.merchant_name
    );

    const matchingCategoriesList = this.state.categories.find(
      (category) => category?.id.toString() === data?.category_id.toString()
    );

    // Check for matching tags
    const matchingTagsList =
      Array.isArray(data.tags) &&
      data.tags.some((value) => {
        return this.state.tags.some((tag) => tag.label === value);
      });

    if (data.disabledIgnore || data.disabled) {
      return;
    }

    if (!matchingMerchant && data.merchant_name) {
      errors.ignore_merchant_name =
        "This merchant name is new you can ignore it";
    }
    if ((!matchingCategoriesList && data.category_id != "") || (data.isNewCategory && data.newCategoryName)) {
      console.log("sdasdsadsdsd---sdsdsdsdsd");
      errors.ignore_category_name =
        "This category name is new you can ignore it";
    }
    if (matchingTagsList) {
      errors.ignore_tags_name = "This tags name is new you can ignore it";
    }

    return errors;
  }

  ignorePartyName = (index) => {
    console.log("newp arty name data ------------------", index, this.state.excelData[index], this.state.all_merchant_list)
    let merchantOpt = this.state.excelData[index].merchant_option
    const newParty = [
      {
        category_id: this.state.excelData[index].category_id, // You need to set this value appropriately
        value: merchantOpt.label,
        label: merchantOpt.label,
        gst_no: this.state.excelData[index].gst_no,
        pan_number: this.state.excelData[index].pan_number,
        tds_percentage: this.state.excelData[index].tds_percentage,
      },
    ];


    console.log("newp arty name data ------------------", newParty)


    // update value into duplicate party name
    const updatedNewPartyName = newParty.map((partyValue) => {
      const updatedNewMerchants = this.state.all_merchant_list.find(
        (merchant) => merchant.label === partyValue.label
      );
      const merchantID = updatedNewMerchants
        ? updatedNewMerchants.value
        : partyValue.value;
      return {
        ...partyValue,
        value: merchantID,
      };
    });

    console.log("newp arty data -------------", updatedNewPartyName);

    // /// if party name new then add in master payload

    // const existingMerchatName = this.state.all_merchant_list.find(
    //   (party) => party.value === newParty.value
    // );
    // console.log(
    //   "222 check1:::existingMerchatName:::",
    //   existingMerchatName

    // );

    // if (
    //   !existingMerchatName &&
    //   !masterPayload.merchants.find(
    //     (merchant) =>
    //       merchant.name === newParty.value
    //   )
    // ) {
    //   console.log(
    //     "3333 check1:::existingMerchatName:::",
    //     existingMerchatName

    //   );
    //   const merchant = {
    //     name: newParty.label,
    //     gst_no: this.state.excelData[index].gst_no,
    //     pan_number: this.state.excelData[index].pan_number,
    //     type: 3, //merchant type
    //     is_msme: this.state.excelData[index].is_msme ? 1 : 0,
    //   };

    //   masterPayload.merchants.push(merchant);
    // }


    if (merchantOpt.label !== undefined) {
      this.setState(
        (prevState) => ({
          all_merchant_list: [
            ...prevState.all_merchant_list,
            ...updatedNewPartyName,
          ],
        }),
        () => {
          var opt = this.state.excelData[index].merchant_option;
          opt.value = opt.label;
          const updatedData = this.state.excelData.map((row, ind) =>
            ind === index
              ? {
                ...row,
                is_new: false,
                disabledIgnore: true,
                merchant_option: opt,
              }
              : row
          );
          this.setState({ excelData: updatedData }, () => {
            // master payload
            // Check if the PARTY NAME is already present in merchant_list state
            const existingMerchatName = this.state.all_merchant_list.find(
              (party) => party.value === this.state.excelData[index].merchant_name
            );
            console.log(
              "222 check1:::existingMerchatName:::",
              existingMerchatName,
              this.state.excelData[index].merchant_name
            );

            if (
              existingMerchatName &&
              !masterPayload.merchants.find(
                (merchant) =>
                  merchant.name === this.state.excelData[index].merchant_name
              )
            ) {
              console.log(
                "3333 check1:::existingMerchatName:::",
                existingMerchatName,
                this.state.excelData[index]
              );
              console.log("type value -------------", this.state.excelData[index].type);

              let tp = this.state.excelData[index].type ? this.state.snapshot_types.find((item) => item.id == this.state.excelData[index].type).merchant_type : 3;
              const merchant = {
                name: this.state.excelData[index].merchant_name,
                gst_no: this.state.excelData[index].gst_no,
                pan_number: this.state.excelData[index].pan_number,
                type: tp ? tp : 3, //merchant type
                is_msme: this.state.excelData[index].is_msme ? 1 : 0,
              };
              console.log("merchant --- ", merchant, tp);
              masterPayload.merchants.push(merchant);
            }
            //end master payload

            if (
              this.state.excelData[index].ignoreErrors?.ignore_category_name
            ) {
              this.ignoreCategory(index);
            } else this.checkForReadyIgnoreMsg(index);
          });
        }
      );
    }
  };

  ignoreCategory = (rowIndex) => {
    // this.setState(
    //   (prevState) => ({
    //     categories: [
    //       ...prevState.categories,
    //       {
    //         id: this.state.excelData[rowIndex].newCategoryName,
    //         name: this.state.excelData[rowIndex].newCategoryName,
    //       },
    //     ],
    //   }),
    //   () => {

    const newCategory = {
      id: this.state.excelData[rowIndex].newCategoryName,
      name: this.state.excelData[rowIndex].newCategoryName,
    };

    const existingCategory = this.state.categories.find(
      (cat) => cat.name === this.state.excelData[rowIndex].newCategoryName
    );
    console.log("exs=isting category ===========", existingCategory,);
    if (
      !existingCategory &&
      !masterPayload.categories.includes(
        this.state.excelData[rowIndex].newCategoryName
      )
    ) {
      // console.log("data.CATEGORY::::data.CATEGORY::", data.CATEGORY);
      masterPayload.categories.push(
        this.state.excelData[rowIndex].newCategoryName
      );

      console.log(
        "merchant value category ----------------",
        masterPayload
      );
    }

    const updatedData = this.state.excelData.map((row, index) =>
      index === rowIndex
        ? {
          ...row,
          isNewCategory: false,
          newCategoryName: "",
          category_id: newCategory.id
        }
        : row
    );
    this.setState(
      {
        excelData: updatedData,
        categories: [...this.state.categories, newCategory],
      },
      () => {
        if (this.state.excelData[rowIndex].ignoreErrors?.ignore_tags_name) {
          this.ignoreTags(rowIndex);
        } else this.checkForReadyIgnoreMsg(rowIndex);
      }
    );
    // }
    // );
  };

  ignoreTags = (index) => {
    const updatedData = this.state.excelData.map((row, ind) =>
      ind === index
        ? {
          ...row,
          disabledIgnore: true,
          ignoreErrors: {},
        }
        : row
    );
    this.setState({ excelData: updatedData }, () => {
      this.checkForReadyIgnoreMsg(index);
    });
  };
  handleIgnore = (index) => {
    if (this.state.excelData[index].ignoreErrors?.ignore_merchant_name) {
      this.ignorePartyName(index);
    } else {
      if (this.state.excelData[index].ignoreErrors?.ignore_category_name) {
        this.ignoreCategory(index);
      } else {
        if (this.state.excelData[index].ignoreErrors?.ignore_tags_name) {
          this.ignoreTags(index);
        }
      }
    }
  };

  handleIgnoreAll = async () =>{
    const confirmation_result = await confirm({confirmation: `Do you wish to ignore warnings of all snapshots?`});
    if(confirmation_result){
      this.setState({ isSelectFile: true }, () => {
      // Delay execution to allow the loader to render
      // setTimeout(async () => {
      //   await Promise.all(
      //     this.state.excelData.map((data, index) => {
      //       if (data.ignoreErrors?.ignore_merchant_name) {
      //         return this.ignorePartyName(index);
      //       } else if (data.ignoreErrors?.ignore_category_name) {
      //         return this.ignoreCategory(index);
      //       } else if (data.ignoreErrors?.ignore_tags_name) {
      //         return this.ignoreTags(index);
      //       }
      //       return Promise.resolve();
      //     })
      //   );
    
      //   // Hide the loader after the operation is complete
      //   this.setState({ isSelectFile: false });
      // }, 0);
      setTimeout(()=>{
        // let excelDataCP = [...this.state.excelData];
      let all_merchant_listCP = [...this.state.all_merchant_list];
      let categoriesCP = [...this.state.categories];

      const updatedData = this.state.excelData.map((row) => {
        let item = { ...row };
        //merchnat
        if (item.ignoreErrors?.ignore_merchant_name) {
          console.log("newp arty name data ------------------", item, all_merchant_listCP)
          let merchantOpt = item.merchant_option
          const newParty = [
            {
              category_id: item.category_id, // You need to set this value appropriately
              value: merchantOpt.label,
              label: merchantOpt.label,
              gst_no: item.gst_no,
              pan_number: item.pan_number,
              tds_percentage: item.tds_percentage,
            },
          ];
          console.log("newp arty name data ------------------", newParty);

            // update value into duplicate party name
            const updatedNewPartyName = newParty.map((partyValue) => {
              const updatedNewMerchants = all_merchant_listCP.find(
                (merchant) => merchant.label === partyValue.label
              );
              const merchantID = updatedNewMerchants  ? updatedNewMerchants.value : partyValue.value;
              return {
                ...partyValue,
                value: merchantID,
              };
            });
            console.log("newp arty data -------------", updatedNewPartyName);

            if (merchantOpt.label !== undefined) {
              // all_merchant_listCP.push(updatedNewPartyName)
              all_merchant_listCP = [...all_merchant_listCP, ...updatedNewPartyName];
              // this.setState(
              //   (prevState) => ({
              //     all_merchant_list: [
              //       ...prevState.all_merchant_list,
              //       ...updatedNewPartyName,
              //     ],
              //   }),)

              var opt = item.merchant_option;
              opt.value = opt.label;

              item.is_new= false;
              item.disabledIgnore= true;
              item.merchant_option= opt;

              // master payload
              // Check if the PARTY NAME is already present in merchant_list state
              const existingMerchatName = all_merchant_listCP.find((party) => party.value === item.merchant_name);
              if ( existingMerchatName && !masterPayload.merchants.find( (merchant) => merchant.name === item.merchant_name) ) {
                  let tp = item.type ? this.state.snapshot_types.find((_item) => _item.id == item.type).merchant_type : 3;
                  const merchant = {
                    name: item.merchant_name,
                    gst_no: item.gst_no,
                    pan_number: item.pan_number,
                    type: tp ? tp : 3, //merchant type
                    is_msme: item.is_msme ? 1 : 0,
                  };
                  console.log("merchant --- ", merchant, tp);
                  masterPayload.merchants.push(merchant);
              }
              //end master payload
            }
        }
        //category
        if (item.ignoreErrors?.ignore_category_name){
            const newCategory = {
              id: item.newCategoryName,
              name: item.newCategoryName,
            };

            const existingCategory = categoriesCP.find((cat) => cat.name === item.newCategoryName );
            console.log("exs=isting category ===========", existingCategory,);
            if (!existingCategory && !masterPayload.categories.includes(item.newCategoryName)) {
                console.log("data.CATEGORY::::data.CATEGORY::", item);
                masterPayload.categories.push(item.newCategoryName);
            }
            item.isNewCategory= false;
            item.newCategoryName= "";
            item.category_id= newCategory.id;
            categoriesCP = [...categoriesCP, newCategory];
            // this.setState({categories:[...this.state.categories,newCategory]})
            // categoriesCP.push(newCategory)
        } 
        
        // tags
        if(item.ignoreErrors?.ignore_tags_name){
          item.disabledIgnore= true;
          item.ignoreErrors= {};
        }
        let ignoreErrors = this.checkForReadyStateIgnoreMsgOndrop(item);
        item.ignoreErrors = ignoreErrors;

        return item;
      })
      console.log("updated data",all_merchant_listCP)
      // const updatedMerchantList = [
      //   ...this.state.all_merchant_list,
      //   ...all_merchant_listCP,
      // ];
      // const updatedCategories = [
      //   ...this.state.categories,
      //   ...categoriesCP,
      // ];
      this.setState({excelData:updatedData,all_merchant_list:all_merchant_listCP,categories:categoriesCP,isSelectFile:false,})
      // this.setState({all_merchant_list:all_merchant_listCP},()=>{
      //   this.setState({categories:categoriesCP},()=>{
      //     this.setState({excelData:updatedData,isSelectFile:false})
      //   })
      // })
      },0)
      
    });

  }

}
  shouldEnableIgnoreButton(records) {
    return records.some(record => {
      return record.ignoreErrors && Object.keys(record.ignoreErrors).length > 0;
    });
  }


  // shortcut key scroll function
  scroll(direction) {
    const { scrollStep, scrollTop, scrollLeft } = this.state;
    const element = this.scrollRef.current;

    let newScrollTop = scrollTop;
    let newScrollLeft = scrollLeft;
    if (direction === "up") {
      newScrollTop = Math.max(scrollTop - scrollStep, 0);
    } else if (direction === "down") {
      newScrollTop = scrollTop + scrollStep;
    } else if (direction === "left") {
      newScrollLeft = Math.max(scrollLeft - scrollStep, 0);
    } else if (direction === "right") {
      newScrollLeft = scrollLeft + scrollStep;
    }

    // Update state and scroll position
    this.setState(
      { scrollTop: newScrollTop, scrollLeft: newScrollLeft },
      () => {
        if (element) {
          element.scrollTop = newScrollTop;
          element.scrollLeft = newScrollLeft;
        }
      }
    );
  }
  // add new category function
  handleNewCategorySubmit = (e, rowIndex) => {
    if (e.key === "Enter") {
      const newCategory = {
        id: this.state.excelData[rowIndex].newCategoryName,
        name: this.state.excelData[rowIndex].newCategoryName,
      };

      console.log("categories ------------", this.state.categories);
      const existingCategory = this.state.categories.find(
        (cat) => cat.name === newCategory.name
      );
      console.log("exs=isting category ===========", existingCategory);
      if (
        !existingCategory &&
        !masterPayload.categories.includes(
          newCategory.name
        )
      ) {
        // console.log("data.CATEGORY::::data.CATEGORY::", data.CATEGORY);
        masterPayload.categories.push(
          newCategory.name
        );

        console.log(
          "merchant value category ----------------",
          masterPayload
        );
      }



      // Update the excelData with the new party name
      const newData = this.state.excelData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            newCategoryName: "",
            isNewCategory: false,
            category_id: newCategory.id, // Assuming 'PARTY NAME' is the key for party name in excelData
          };
        } else {
          return row;
        }
      });

      this.setState({
        excelData: newData,
        categories: [...this.state.categories, newCategory],
      });
    }
  };

  handleCategoryChange = (e, rowIndex) => {
    const { value } = e.target;
    const { checked } = e.target;
    console.log("value ===================== before ", value);
    // add new category , check if user is adding new category
    if (value === "-2") {
      const updatedData = this.state.excelData.map((row, index) =>
        index === rowIndex
          ? {
            ...row,
            isNewCategory: true,
            newCategoryName: "",
            editableRowIndex: rowIndex,
          }
          : row
      );
      this.setState({ excelData: updatedData }, () => {
        this.checkForReadyState(rowIndex);
      });

      return;
    }
    console.log("value ===================== after ");

    const updatedData = this.state.excelData.map((row, index) =>
      index === rowIndex ? { ...row, category_id: value } : row
    );
    this.setState({ excelData: updatedData }, () => {
      this.checkForReadyState(rowIndex);
    });
  };
  getSnapshotMasters = () => {
    SnapshotService.getSnapshotMasters().then((data) => {
      if (data.response_code == 200) {
        let merchants = data.result.merchants.map((supp) => {
          return {
            value: supp.id,
            label: supp.name,
            gst_no: supp.gst_no || "",
            pan_number: supp.pan_number || "",
            tds_percentage: supp.tds_percentage,
            snapshot_type: supp.snapshot_type,
            is_gst_claim_eligible: supp.is_gst_claim_eligible,
            is_msme: supp.is_msme,
            is_perquisite: supp.is_perquisite,
            type: supp.type,
            category_id: supp.category_id,
            tags: supp.tags || [],
            party_ledger_guid: supp.party_ledger_guid || "",
            purchase_ledger_guid: supp.purchase_ledger_guid || "",
            sales_ledger_guid: supp.sales_ledger_guid || "",
          };
        });

        let tagData = data.result.tags.map((tag) => ({
          value: tag.id,
          label: tag.name,
        }));
        console.log("PArty Details List ----", data.result);
        this.setState({
          // payment_details: data.result.financial_institutes,
          categories: data.result.categories,
          tags: tagData,
          all_merchant_list: merchants,
          all_old_merchant_list: _.cloneDeep(merchants),
          snapshot_types: data.result.snapshot_types,
          // line_items_list: data.result.line_items
        });
      }
    });
  };

  toggleImportExcelPopup = () => {
    this.props.toggleImportExcelPopup(this.state.excelData);
    this.setState({ excelData: [], isDownload: false, isReady: false });
    //this.getSnapshotMasters();
  };
  handleSupplierCustomerToggle = (name, value, rowIndex) => {
    const updatedData = this.state.excelData.map((row, index) =>
      index === rowIndex
        ? {
          ...row,
          [name]: value,
        }
        : row
    );
    this.setState({ excelData: updatedData }, () => {
      let merchant_list = this.state.all_merchant_list.filter(
        (merchant, index) => {
          if (
            merchant.type == 3 ||
            (this.state.is_customer && merchant.type == 1) ||
            (this.state.is_supplier && merchant.type == 2) ||
            (!this.state.is_customer && !this.state.is_supplier)
          ) {
            return merchant;
          }
        }
      );

      this.setState({ merchants: merchant_list });
    });
  };
  handleStarClick = (rowIndex) => {
    // Toggle star clicked status
    const updatedData = this.state.excelData.map((row, index) =>
      index === rowIndex
        ? {
          ...row,
          star: row.star.toUpperCase() === "YES" ? "No" : "Yes",
          // merchant_name: "testing data",
        }
        : row
    );
    this.setState({ excelData: updatedData }, () => {
      console.log("updated data ---------", this.state.excelData);
    });
  };

  findPartyDetails = (row) => {
    console.log("row ------------", row);
    var partyName = row[1];
    var gstNo = row[2] ? row[2].toString() : "";
    var panNo = row[3] ? row[3].toString() : "";
    var defaultData = {
      category_id: "",
      gst_no: row[2] ? row[2].toString() : "",
      value: 0,
      label: row[1] ? row[1].toString() : "",
      pan_number: row[3] ? row[3].toString() : (checkValidGSTNo(gstNo)?gstNo.substring(2, 12):""),
      tds_percentage: "",
      snapshot_type: false,
      is_msme: false,
      type: "",
      tags: [],
      party_ledger_guid: "",
      purchase_ledger_guid: "",
      sales_ledger_guid: "",
    };
    var record = defaultData;
    if (gstNo) {
      console.log("Compare with GST First");
      record = this.state.all_merchant_list.find(
        (record) => record.gst_no.toUpperCase() === gstNo.toUpperCase()
      );
    }
    if (record == undefined && panNo) {
      console.log("Compare with Pan number secondly");
      record = this.state.all_merchant_list.find(
        (record) => record.pan_number.toUpperCase() === panNo.toUpperCase()
      );
    }
    if (record  == undefined  && partyName) {
      console.log("Compare with party name at last");
      record = this.state.all_merchant_list.find(
        (record) => record.label.toUpperCase() === partyName.toUpperCase()
      );
    }
    if (!record) record = defaultData;
    console.log("record data ---------- ", record);
    return record;
  };

  formatDate(dateString) {
    // Helper function to get the current date formatted as "dd-mm-yyyy"
    // Helper function to get the current date formatted as "dd-mm-yyyy"
    const getCurrentDateFormatted = () => {
      const currentDate = new Date();
      const day = currentDate.getDate().toString().padStart(2, "0");
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const year = currentDate.getFullYear();
      return `${day}-${month}-${year}`;
    };

    // Return current date if input is empty
    if (!dateString) {
      return getCurrentDateFormatted();
    }

    // Convert dateString to a string if it's not already
    // let dateStringStr = dateString.toString();
    let dateStringStr = dateString.toString().trim();

    if (
      !isNaN(dateStringStr) &&
      dateStringStr.indexOf("-") === -1 &&
      dateStringStr.indexOf("/") === -1
    ) {
      const MS_PER_DAY = 86400000; // Number of milliseconds in a day
      const EPOCH_OFFSET_DAYS = 25569; // Excel epoch starts on December 30, 1899
      const excelDateInMS =
        (parseInt(dateStringStr) - EPOCH_OFFSET_DAYS) * MS_PER_DAY;
      const date = new Date(excelDateInMS);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    }

    // Splitting the input string by dashes or slashes
    let parts = dateStringStr.split(/-|\//);

    let day, month, year;
    if (parts.length === 3) {
      if (parts[0].length === 4) {
        // Likely format is yyyy-mm-dd or yyyy/mm/dd
        year = parts[0];
        month = parts[1];
        day = parts[2];
      } else if (parts[2].length === 4) {
        // Likely format is dd-mm-yyyy or mm/dd/yyyy
        year = parts[2];
        month = parts[1];
        day = parts[0];
      } else {
        // If none of the expected formats match, return the current date
        return getCurrentDateFormatted();
      }

      // Pad single digits with leading zeros
      day = day.padStart(2, "0");
      month = month.padStart(2, "0");

      // Validate date to prevent returning an invalid date format
      const validationDate = new Date(year, month - 1, day);
      if (
        validationDate.getFullYear() !== parseInt(year) ||
        validationDate.getMonth() + 1 !== parseInt(month) ||
        validationDate.getDate() !== parseInt(day)
      ) {
        return getCurrentDateFormatted();
      }

      return `${day}-${month}-${year}`;
    } else {
      // If parts length is not 3, indicating an incorrect format, return current date
      return getCurrentDateFormatted();
    }
  }

  handleRestore = (rowIndex) => {
    const updatedData = this.state.excelData.map((row, index) => {
      if (index === rowIndex) {
        const updatedRow = {
          ...row,
        };

        updatedRow.disabled = false;
        let data = {
          isNewCategory: updatedRow.isNewCategory,
          newCategoryName: updatedRow.newCategoryName,
          category_id: updatedRow.category_id ? updatedRow.category_id : "",
          tags: updatedRow.tags,
          merchant_name: updatedRow.merchant_option.label || null,
          disabledIgnore: updatedRow.disabledIgnore,
        };
        //console.log("data --------" ,Number.isInteger(dataObj.category_id), data);
        updatedRow.ignoreErrors = this.ignoreFunction(data);

        return updatedRow;
      } else {
        return row;
      }
    });
    this.setState({ excelData: updatedData }, () => {
      this.checkForReadyState(rowIndex);
    });
  };

  handleDisable = (rowIndex) => {
    const updatedData = this.state.excelData.map((row, index) =>
      index === rowIndex ? { ...row, disabled: true, ignoreErrors: {}, errors: {} } : row
    );
    this.setState({ excelData: updatedData }, () => {
      //this.checkForReadyState(rowIndex);
    });
  };

  handleDrop = (acceptedFiles) => {
    if (acceptedFiles.target.files.length == 0) return;
    this.setState(
      {
        excelData: [],
        isUploading: false,
        isDownload: false,
        isReady: false,
        uploading_file_percent: 0,
      },
      () => {
        masterPayload.tags = [];
        masterPayload.categories = [];
        masterPayload.merchants = [];

        const file = acceptedFiles.target.files[0];
        console.log("file:::", file.name);

        console.log("snapshot types ----------------", this.state.snapshot_types);
        this.setState({ fileName: file.name, selectFileError: "" });
        if (file) {
          this.setState({ isSelectFile: true });
          ExcelRenderer(file, (err, resp) => {
            if (err) {
              this.setState({
                isSelectFile: false,
              });
              console.log("error::", err);
            } else {
              console.log("resonse ----------", resp);
              let keys = resp.rows[0];
              const headersMatch = tableH.every(
                (item, index) => {
                  console.log("item ---------", item, keys[index], item === keys[index]);
                  if (index == 6)
                    return item === "DATE" || item === "DATE(DD-MM-YYYY)"
                  else
                    return item === keys[index]
                }
              );
              if (!headersMatch) {
                // Headers do not match, handle error state
                console.error("Selected Excel File Header mismatch error");
                this.setState({
                  isSelectFile: false,
                  isDownload: false,
                  selectFileError: "Selected file is not in required format",
                  excelData: [],
                });
                return;
              }
              let excelRows = resp.rows.slice(1);

              excelRows = excelRows.filter(row => 
                row.some(cell => cell !== null && cell !== undefined && cell !== "")
              );
              console.log("records ------", excelRows);
              if (excelRows.length > 0 && excelRows.length <= RECORD_LIMIT) {
                var arr = [];

                let is_reimbursement = false; //(this.CREATE_REIMBUSRMENT_SNAPSHOT && !this.CREATE_BUSSINESS_SNAPSHOT) ? true : false;

                const filteredRows = excelRows.filter(row => 
                  row.some(cell => cell !== null && cell !== undefined && cell !== "")
                );
                
                console.log("records after filtering ------", filteredRows);

                excelRows.map((row) => {
                  var record = this.findPartyDetails(row);
                  console.log(
                    "Sub Details row Value --- ",
                    row,
                    "merchant --- ",
                    record
                  );

                  const originalDate = row[6];
                  const formattedDate = this.formatDate(originalDate);
                  let momentDate = moment(formattedDate, "DD-MM-YYYY").toDate();
                  row[6] = formattedDate; // Replace the original date with formatted date

                  console.log("1-- ", record.tags);
                  /* Tag Started */
                  const prefillTags = record.tags
                    ? record.tags.map((tag) => {
                      const foundTag = this.state.tags.find(
                        (t) => t.value === tag.id
                      );
                      return foundTag ? foundTag.value : tag.id;
                    })
                    : [];

                  row[9] = row[9]
                    ? row[9]
                    : prefillTags
                      ? prefillTags.map((tag) => tag.label).join(", ")
                      : row[9];
                  //TAGS for covert into array
                  const tagsString = row[9];
                  // Convert tagsString to string if it's a number
                  const normalizedTagsString =
                    typeof tagsString === "number"
                      ? tagsString.toString()
                      : tagsString;
                  console.log("5-- ", normalizedTagsString);
                  const addTags =
                    normalizedTagsString &&
                      typeof normalizedTagsString === "string"
                      ? normalizedTagsString.split(",").map((tag) => ({
                        value: tag.trim(),
                        label: tag.trim(),
                      }))
                      : [];
                  console.log("6-- ", addTags);

                  // master tags
                  const newTags = []
                  addTags.map((tagId) => {
                    const tag = this.state.tags.find(
                      (tag) => tag.label === tagId.label
                    );

                    if (!tag && !masterPayload.tags.includes(tagId.label)) {
                      masterPayload.tags.push(tagId.label);
                      newTags.push(tagId)
                    }
                  });

                  //end master tags

                  this.setState((prevState) => ({
                    tags: [...prevState.tags, ...newTags],
                  }));

                  let tags =
                    normalizedTagsString &&
                      typeof normalizedTagsString === "string"
                      ? normalizedTagsString
                        ?.split(",")
                        .map((tag) => tag.trim())
                      : [];
                  tags = [...new Set(tags)]; //remove duplicats
                  // replace id of duplicate tags

                  // master push data for tags
                  const updatedTags = tags.map((tagId) => {
                    const tag = this.state.tags.find(
                      (tag) => tag.label === tagId
                    );

                    // console.log("Tag ---- " , tag , masterPayload.tags.includes(tagId));
                    // if (!tag && !masterPayload.tags.includes(tagId)) {
                    //   masterPayload.tags.push(tagId);
                    // }

                    // console.log("merchant value tags name ----------------", masterPayload);

                    // console.log('tag:::updated', tag);
                    return tag ? tag.value : tagId;
                  });
                  // console.log("updatedTags:::updatedTags", updatedTags);

                  row[9] = updatedTags;
                  /* Tag Ended */

                  row[10] = row[10] ? row[10] : "";
                  const isPerquiset = record.value
                    ? record.is_perquisite
                      ? record.is_perquisite
                      : false
                    : row[10].toUpperCase() === "YES"
                      ? true
                      : false;

                  row[12] = row[12] ? row[12] : "";
                  const isMsme = record.value
                    ? record.is_msme
                      ? record.is_msme
                      : false
                    : row[12].toUpperCase() === "YES"
                      ? true
                      : false;

                  //cgst, igst,sgst convert into string
                  // if (Number.isInteger(row[13])) {
                  // Convert the integer value to a string
                  //  row[13] = row[13].toString();
                  //  }
                  //  if (Number.isInteger(row[14])) {
                  // Convert the integer value to a string
                  //  row[14] = row[14].toString();
                  // }

                  row[16] = row[16] ? row[16] : "";
                  // check for gst elegible
                  const isGstEligible = record.is_gst_claim_eligible
                    ? record.is_gst_claim_eligible
                    : row[16].toUpperCase() === "YES"
                      ? true
                      : false;
                  //row[16] = isGstEligible;

                  // add new category name into categories drop-down
                  const excelNewCategoryName = row[4] ? row[4].toString() : "";
                  // const newCategory = [
                  //   {
                  //     id: excelNewCategoryName,
                  //     name: excelNewCategoryName,
                  //   },
                  // ];

                  console.log(
                    "excelNewCategoryName--------------- ",
                    excelNewCategoryName
                  );
                  const updatedNewcat = this.state.categories.find(
                    (cats) =>
                      cats.name.toUpperCase() ===
                      excelNewCategoryName.toUpperCase()
                  );

                  if (updatedNewcat) {
                    row[4] = updatedNewcat.id;
                  }

                  row[4] = record.category_id ? record.category_id : row[4];
                  // row[10] = isPerquiset;

                  //CGST
                  var cgst = parseInt(row[13]);//?row[13].toString():"";
                  //

                  var sgst = parseInt(row[14]);//?row[14].toString():"";
                  //  gstStatus = !!sgst;

                  var igst = parseInt(row[15]);//?row[15].toString():"";
                  //  gstStatus = !!igst;

                  var gstStatus = cgst || sgst || igst ? true : false;

                  let tp = row[5] ? row[5] : "";
                  let snapId = this.state.snapshot_types.find(
                    (type) => type.name.toUpperCase() == tp.toUpperCase()
                  );

                  let user_snapId = this.state.snapshot_types.find(
                    (type) => type.id == record.snapshot_type
                  );

                  console.log(
                    "snapshot tyoes ==================",
                    tp,
                    snapId,
                    record.value,
                    record.snapshot_type,
                    user_snapId,
                    this.state.snapshot_types
                  );
                  var snaphotType =
                    record.value != 0
                      ? user_snapId
                        ? user_snapId.id
                        : 8
                      : snapId
                        ? snapId.id
                        : 8;

                  var exp = record.value != 0
                    ? user_snapId
                      ? user_snapId.expense
                      : 1
                    : snapId
                      ? snapId.expense
                      : 1;
                  console.log("snaphotType  ----- ", snaphotType);

                  var rowData = {
                    category_id: row[4] ? row[4] : "",
                    // isNewCategory:row[4]?false:true,// updatedNewcat ?false : true,
                    isNewCategory: excelNewCategoryName
                      ? updatedNewcat
                        ? false
                        : true
                      : false,
                    newCategoryName: updatedNewcat ? "" : excelNewCategoryName,
                    additional_information: row[11] ? row[11] : "",
                    star: row[0] ? row[0] : "NO",
                    is_edit_supplier: false,
                    is_new:
                      record.value == 0 && record.label != "" ? true : false,
                    merchant_id: record.value,
                    new_value: record.label,
                    merchant_name: record.label,
                    merchant_option: record,
                    merchant_type: record.type ? record.type : 2,
                    gst_no: record.gst_no.toUpperCase(),
                    pan_number: record.pan_number.toUpperCase(),
                    is_msme: isMsme,
                    is_dirty: false,
                    is_customer: true,
                    is_supplier: true,
                    is_perquisite: isPerquiset,
                    expense: exp,//snapId ? snapId.expense : 0,
                    type: snaphotType,
                    selected_tags: [],
                    // cgst_amount: cgst ? cgst.toString() : "",
                    // sgst_amount: sgst ? sgst.toString() : "",
                    // igst_amount: igst ? igst.toString() : "",
                    cgst_amount: cgst ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,row[13]) : row[13] : "",
                    sgst_amount: sgst ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,row[14]) : row[14] : "",
                    igst_amount: igst ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,row[15]) : row[15] : "",
                    gst_total_amount: "",
                    is_igst: igst ? true : false, // igst ? true : false,
                    disable_igst: igst ? false : true,
                    // total_amount: row[8],
                    total_amount: row[8] ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,row[8]) : row[8] : row[8],
                    // tds_percentage: record.tds_percentage
                    //   ? record.tds_percentage
                    //   : row[17],
                    tds_percentage: record.tds_percentage ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,record.tds_percentage) : record.tds_percentage : 
                    row[17] ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,row[17]) : row[17] : row[17],
                    tds_amount: "",

                    is_reimbursement: is_reimbursement,
                    tds_status: false,
                    is_gst_claim_eligible: isGstEligible,
                    gst_status: true, // gstStatus,
                    payment_status: false,
                    date: row[6],
                    invoice_number: row[7] ? row[7].toString() : "",
                    party_ledger_guid: "",
                    entry_status: this.state.isReady ? 2 : 1,
                    errors: {},
                    ignoreErrors: {},
                    tags: row[9],
                    inventory_mode: false,
                    gst_override:false,
                    hide_gst_override: true,
                  };
                  console.log("type val -----------------------------", rowData.type);
                  rowData.tds_percentage = parseInt(rowData.tds_percentage) ? rowData.tds_percentage : "";
                  rowData.tds_percentage = (rowData.type == 9 || rowData.type == 10 || rowData.type == 4 || rowData.type == 5) ? "" : rowData.tds_percentage;
                  rowData.is_perquisite = (rowData.type == 11 || rowData.type == 7 || rowData.type == 10 || rowData.type == 1 || rowData.type == 9) ? false : true;
                  // for Warning messages----------------

                  let dataObj = rowData;

                  let data = {
                    isNewCategory: dataObj.isNewCategory,
                    newCategoryName: dataObj.newCategoryName,
                    category_id: dataObj.category_id ? dataObj.category_id : "",
                    tags: dataObj.tags,
                    merchant_name: dataObj.merchant_option.label || null,
                    disabledIgnore: dataObj.disabledIgnore,
                  };
                  //console.log("data --------" ,Number.isInteger(dataObj.category_id), data);
                  rowData.ignoreErrors = this.ignoreFunction(data);

                  // End warning

                  let customer_gst = rowData.gst_no
                    ? rowData.gst_no.slice(0, 2)
                    : null || null;
                  let entity_gst = !!this.props.entity_gst_no
                    ? this.props.entity_gst_no.slice(0, 2)
                    : null;
                  console.log(
                    "custome gst --------------------------- ",
                    customer_gst,
                    entity_gst
                  );
                  // if (!customer_gst || !entity_gst) {
                  //   rowData.is_igst = false;
                  //   // rowData.igst_amount = "";
                  //   rowData.disable_igst = false;
                  //   rowData.hide_gst_override = true;
                  // } else {
                  //     if (
                  //       customer_gst == entity_gst &&
                  //       customer_gst &&
                  //       entity_gst
                  //     ) {
                  //       rowData.is_igst = false;
                  //       //  rowData.igst_amount = "";
                  //       rowData.disable_igst = true;
                  //       rowData.hide_gst_override = false;
                  //       //    this.calculateTotalGst();
                  //     } else {
                  //       // rowData.is_igst = true;
                  //       rowData.disable_igst = false;
                  //       //  rowData.sgst_amount = "";
                  //       //    rowData.cgst_amount = "";
                  //       rowData.hide_gst_override = true;
                  //     }
                  // }
                  if (!customer_gst || !entity_gst) {
                    // rowData.is_igst = false;
                    // // rowData.igst_amount = "";
                    // rowData.disable_igst = false;
                    // rowData.hide_gst_override = true;
                    if(rowData.cgst_amount && rowData.sgst_amount){
                       rowData.is_igst = false;
                      // rowData.igst_amount = "";
                      rowData.disable_igst = false;
                      rowData.hide_gst_override = true;
                    }else if(rowData.igst_amount){
                      rowData.is_igst = true;
                      // rowData.igst_amount = "";
                      rowData.disable_igst = false;
                      rowData.hide_gst_override = true;
                    }else{
                      console.log("here in false")
                      rowData.is_igst = true;
                      // rowData.igst_amount = "";
                      rowData.disable_igst = false;
                      rowData.hide_gst_override = true;
                    }
                  } else {
                    if (
                      customer_gst == entity_gst &&
                      customer_gst &&
                      entity_gst
                    ) {
                      // rowData.is_igst = false;
                      // //  rowData.igst_amount = "";
                      // rowData.disable_igst = true;
                      // rowData.hide_gst_override = false;
                      //    this.calculateTotalGst();
                      if(rowData.cgst_amount && rowData.sgst_amount){
                        console.log("gst same cgst")
                        rowData.is_igst = false;
                         rowData.igst_amount = "";
                        rowData.disable_igst = true;
                        rowData.hide_gst_override = false;
                      }else if(rowData.igst_amount){
                        rowData.is_igst = true;
                        rowData.disable_igst = false;
                        rowData.hide_gst_override = false;
                        rowData.gst_override = true;
                        rowData.sgst_amount = ""
                        rowData.cgst_amount = ""
                      }else{
                        rowData.is_igst = false;
                        //  rowData.igst_amount = "";
                        rowData.disable_igst = true;
                        rowData.hide_gst_override = false;
                      }
                    }else if(customer_gst != entity_gst && customer_gst && entity_gst){
                      // rowData.is_igst = true;
                      // //  rowData.igst_amount = "";
                      // rowData.disable_igst = true;
                      // rowData.hide_gst_override = false;
                      if(rowData.cgst_amount && rowData.sgst_amount){
                        console.log("gst different cgs")
                        rowData.is_igst = false;
                        rowData.disable_igst = false;
                        rowData.hide_gst_override = false;
                        rowData.gst_override = true;
                        rowData.igst_amount = ""
                      }else if(rowData.igst_amount){
                        rowData.is_igst = true;
                        rowData.disable_igst = true;
                        rowData.hide_gst_override = false;
                        rowData.cgst_amount = ""
                        rowData.sgst_amount = ""
                      }else{
                        rowData.is_igst = true;
                        rowData.disable_igst = true;
                        rowData.hide_gst_override = false;
                      }
                    } else {
                      // rowData.is_igst = true;
                      rowData.disable_igst = false;
                      //  rowData.sgst_amount = "";
                      //    rowData.cgst_amount = "";
                      rowData.is_igst = fasle;
                      rowData.hide_gst_override = true;
                    }
                  }

                  // if (rowData.igst_amount)
                  //   rowData.is_igst = true;


                  let gst_total_amount =
                    parseFloat(rowData.sgst_amount || 0) +
                    parseFloat(rowData.cgst_amount || 0) +
                    parseFloat(rowData.igst_amount || 0);

                  rowData.gst_total_amount = gst_total_amount;

                  let total_amt =
                    parseFloat(rowData.total_amount || 0) -
                    parseFloat(rowData.gst_total_amount || 0);
                  let tds_amount =
                    (total_amt * parseFloat(rowData.tds_percentage || 0)) / 100;

                  console.log("tds amount value -------------", tds_amount);
                  if (tds_amount < 0) tds_amount = 0;
                  // rowData.tds_amount = tds_amount.toFixed(2);
                  rowData.tds_amount = this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,tds_amount) :  tds_amount.toFixed(2);

                  //handled new checkforready function
                  let errors =  this.checkForReadyStateOnDrop(rowData)
                  if(!rowData.is_reimbursement){
                    if(Object.keys(errors).length === 0){
                      rowData.errors = {};
                      // rowData.entry_status = 2;
                      rowData.is_dirty = true
                    }else{
                      rowData.errors = errors
                    }
                  }
                 

                 let ignoreErrors = this.checkForReadyStateIgnoreMsgOndrop(rowData)
                 rowData.ignoreErrors = ignoreErrors;

                  arr.push(rowData);
                });

                this.setState({ excelData: arr }, () => {
                  this.state.excelData.map((row, rowIndex) => {
                    // this.checkForReadyIgnoreMsg(rowIndex);
                    // this.checkForReadyState(0);
                  });
                });
              }
              else{
                this.setState({
                  isSelectFile: false,
                  isDownload: false,
                  selectFileError: "The File must have at least " + RECORD_LIMIT + "  or fewer records.",
                  excelData: [],
                });
                return;
              }
              setTimeout(() => {
                this.setState({
                  isSelectFile: false,
                });
              }, 2000);
            }
          });
        } else {
          this.setState({
            isSelectFile: false,
            isDownload: false,
          });
        }
      }
    );
  };

  checkForReadyStateOnDrop =  (initialData) => {
    let errors = {};
    if(initialData.disabled){
       errors = {}
    }else{
      
      let valid = false;
      let dataObj = initialData;
      let error_result = {}
  
  
      let data = {
        gst_no: dataObj.gst_no,
        category_id: dataObj.category_id,
        pan_number: dataObj.pan_number,
        tags: dataObj.tags,
        type: dataObj.type,
        date: changeDateFormate(new Date(dataObj.date)),
        merchant_name: dataObj.merchant_option.label || null,
        invoice_number: dataObj.invoice_number,
        total_amount: parseFloat(dataObj.total_amount),
        gst_status: dataObj.gst_status,
        sgst_amount: dataObj.gst_status
          ? parseFloat(dataObj.sgst_amount)
          : null,
        cgst_amount: dataObj.gst_status
          ? parseFloat(dataObj.cgst_amount)
          : null,
        igst_amount: dataObj.gst_status
          ? parseFloat(dataObj.igst_amount)
          : null,
        gst_total_amount: dataObj.gst_status
          ? parseFloat(dataObj.gst_total_amount)
          : null,
        tds_status: dataObj.tds_status ? 1 : 0,
        tds_percentage: dataObj.tds_status
          ? parseFloat(dataObj.tds_percentage)
          : null,
        tds_amount: dataObj.tds_status
          ? parseFloat(dataObj.tds_amount)
          : null,
        // "payment_status": dataObj.payment_status,
        // "payment_information": dataObj.payment_information,
        accounting_mode: this.props.accounting_mode,
        is_igst: dataObj.is_igst,
        party_ledger_guid: dataObj.party_ledger_guid,
        inventory_mode: dataObj.inventory_mode,
        is_gst_claim_eligible: dataObj.is_gst_claim_eligible,
        // entry_status:this.state.isReady?2:1
      };
      console.log("ondrop in func")
        error_result =  validateCreateEvidenceWithReadyState(data,this.state.isReady);
        
          if (Object.keys(error_result).length === 0) {
            errors = {};
          } else {
            errors = {
              ...error_result,
              entry_status: "Please fill the mandatory details.",
            };
          }
        
  
  
  
  
    }
    console.log("ondrop",errors)
     return errors;
    };

  checkForReadyStateIgnoreMsgOndrop = (dataObj) =>{
      let ignoreErrors = {};
      let data = {
        category_id: dataObj.category_id ? dataObj.category_id : "",
        isNewCategory: dataObj.isNewCategory,
        newCategoryName: dataObj.newCategoryName,
        tags: dataObj.tags,
        merchant_name: dataObj.merchant_option.label || null,
        disabledIgnore: dataObj.disabledIgnore,
      };
  
      ignoreErrors = this.ignoreFunction(data);
  
      return ignoreErrors;
  
  
    }
    

  calculateTotalGst = (rowIndex) => {
    let gst_total_amount =
      parseFloat(this.state.excelData[rowIndex].sgst_amount || 0) +
      parseFloat(this.state.excelData[rowIndex].cgst_amount || 0) +
      parseFloat(this.state.excelData[rowIndex].igst_amount || 0);

    const updatedData = this.state.excelData.map((row, index) =>
      index === rowIndex ? { ...row, gst_total_amount: gst_total_amount } : row
    );
    this.setState({ excelData: updatedData }, () => {
      console.log("After Updated Data 555555 --->", this.state.excelData);
      this.calculateTDS(rowIndex);
    });
  };

  calculateTDS = (rowIndex) => {
    let total_amt =
      parseFloat(this.state.excelData[rowIndex].total_amount || 0) -
      parseFloat(this.state.excelData[rowIndex].gst_total_amount || 0);
    let tds_amount =
      (total_amt *
        parseFloat(this.state.excelData[rowIndex].tds_percentage || 0)) /
      100;

    console.log("tds amount value -------------", tds_amount);
    if (tds_amount < 0) tds_amount = 0;
    const updatedData = this.state.excelData.map((row, index) =>
      // index === rowIndex ? { ...row, tds_amount: tds_amount.toFixed(2) } : row
    index === rowIndex ? { ...row, tds_amount: this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,tds_amount) : tds_amount.toFixed(2) } : row
    );
    this.setState({ excelData: updatedData }, () => {
      this.checkForReadyState(rowIndex);
    });
  };

  amountRoundOff = (name,rowIndex) =>{
    let excelData = [...this.state.excelData];
    let updatedRow = {...excelData[rowIndex]}
    if(name == 'cgst_amount'){
      console.log("here in cgst", this.props.amount_round_off)
        updatedRow.cgst_amount = updatedRow.cgst_amount ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,updatedRow.cgst_amount) : updatedRow.cgst_amount : updatedRow.cgst_amount;
    }
    if(name == 'sgst_amount'){
      updatedRow.sgst_amount = updatedRow.sgst_amount ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,updatedRow.sgst_amount) : updatedRow.sgst_amount : updatedRow.sgst_amount;
    }
    if(name == 'igst_amount'){
      updatedRow.igst_amount = updatedRow.igst_amount ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,updatedRow.igst_amount) : updatedRow.igst_amount : updatedRow.igst_amount;
    }
    if(name == 'total_amount'){
      updatedRow.total_amount = updatedRow.total_amount ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,updatedRow.total_amount) : updatedRow.total_amount : updatedRow.total_amount;
    }
    if(name == 'tds_percentage'){
      updatedRow.tds_percentage = updatedRow.tds_percentage ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,updatedRow.tds_percentage) : excelData[rowIndex].tds_percentage : excelData[rowIndex].tds_percentage;
    }
    console.log("here in cgst",updatedRow)
    excelData[rowIndex] = updatedRow;
    console.log("here in rowindex",excelData[rowIndex])
    this.setState({excelData:excelData})
}


  amountChange = (name, value, rowIndex) => {
    if (value > 999999999.99) {
      return;
    }
    const updatedData = this.state.excelData.map((row, index) =>
      index === rowIndex ? { ...row, [name]: value, is_dirty: true } : row
    );
    this.setState({ excelData: updatedData }, () => {
      this.checkForReadyState(rowIndex);
    });
  };
  finishImportExcelPopup = () => {
    this.props.finishImportExcelPopup(this.state.excelData);
    this.setState({ excelData: [], isDownload: false, isReady: false });
    this.getSnapshotMasters();
  };
  onMerchantChanged = async (merchant_option, is_edit = false, rowIndex) => {
    console.log("merchant option -----------------", merchant_option.__isNew__);
    const newState = await Promise.all(
      this.state.excelData.map(async (row, index) => {
        if (index === rowIndex) {
          const updatedRow = {
            ...row,
          };

          let merchant = merchant_option || {};
          let selected_tags = [];

          if (
            Object.keys(merchant_option).length > 0 &&
            merchant_option.snapshot_type != null &&
            !is_edit
          ) {
            let expense = 1;
            let snapshot_type = this.state.snapshot_types.find(
              (type) => type.id == merchant_option.snapshot_type
            );
            expense = snapshot_type.expense == 1 ? 1 : 0;

            if (expense == 0 && row.is_perquisite == true) {
              if (
                await confirm({
                  confirmation:
                    "This action will uncheck the perquisite checkbox. Do you still want to continue?",
                })
              ) {
                updatedRow.expense = 0;
                updatedRow.is_perquisite = false;
                updatedRow.type = merchant_option.snapshot_type;
              } else {
                return updatedRow;
              }
            }
            selected_tags = merchant_option.tags.map((tag, index) => {
              return tag.id;
            });
          }
          (updatedRow.merchant_id = merchant.value),
            (updatedRow.merchant_name = merchant.label),
            (updatedRow.merchant_option = merchant);
            (updatedRow.gst_override = false);

          if (!merchant_option.__isNew__) {
            updatedRow.gst_no =
              merchant_option.gst_no ||
              (merchant_option.__isNew__ || merchant_option.is_edit == 1
                ? row.gst_no
                : "");

            updatedRow.pan_number =
              merchant_option.pan_number ||
              (merchant_option.__isNew__ || merchant_option.is_edit == 1
                ? row.pan_number
                : "");
          }
          if (merchant_option.__isNew__ === false) {
            updatedRow.gst_no =
              merchant_option.gst_no ||
              (merchant_option.__isNew__ || merchant_option.is_edit == 1
                ? row.gst_no
                : "");

            updatedRow.pan_number =
              merchant_option.pan_number ||
              (merchant_option.__isNew__ || merchant_option.is_edit == 1
                ? row.pan_number
                : "");
            updatedRow.is_dirty = true;
            updatedRow.is_edit_supplier = is_edit;
            updatedRow.is_msme =
              merchant_option.is_msme ||
              (merchant_option.__isNew__ || merchant_option.is_edit == 1
                ? row.is_msme
                : false);
            updatedRow.tags = selected_tags ? selected_tags : [];
            let customer_gst = updatedRow.gst_no
              ? updatedRow.gst_no.slice(0, 2)
              : null || null;
            let entity_gst = !!this.props.entity_gst_no
              ? this.props.entity_gst_no.slice(0, 2)
              : null;
            console.log(customer_gst, entity_gst);

            if (customer_gst == entity_gst && customer_gst && entity_gst) {
              updatedRow.is_igst = false;
              updatedRow.igst_amount = "";
              updatedRow.disable_igst = true;
              updatedRow.hide_gst_override= false;
            } else if (!customer_gst || !entity_gst) {
              updatedRow.is_igst = true;
              updatedRow.sgst_amount = "";
              updatedRow.cgst_amount = "";
              updatedRow.disable_igst = false;
              updatedRow.hide_gst_override = true;
            } else if (customer_gst != entity_gst && customer_gst && entity_gst) {
              updatedRow.is_igst = true;
              updatedRow.disable_igst = true;
              updatedRow.sgst_amount = "";
              updatedRow.cgst_amount = "";
              updatedRow.hide_gst_override = false;
            } else {
              updatedRow.is_igst = false;
              updatedRow.igst_amount = "";
              updatedRow.disable_igst = false;
              updatedRow.hide_gst_override = true;
            }

            if (
              !merchant_option.__isNew__ &&
              merchant_option.value != 0 &&
              !is_edit
            ) {
              updatedRow.category_id = merchant_option.category_id || "";
              updatedRow.selected_tags = selected_tags;

              if (Object.keys(merchant_option).length > 0) {
                if (!row.is_reimbursement)
                  updatedRow.type = merchant_option.snapshot_type || ""; // });

                let expense = 1;

                let snapshot_type = this.state.snapshot_types.find(
                  (type) => type.id == merchant_option.snapshot_type
                );
                let merchant_type = 2;

                if (snapshot_type != null) {
                  expense = snapshot_type.expense == 1 ? 1 : 0;
                  merchant_type = snapshot_type.merchant_type;
                } else {
                  expense = 1;
                }

                updatedRow.expense = expense;
                updatedRow.merchant_type = merchant_type;
                updatedRow.is_perquisite =
                  expense && row.is_perquisite ? true : false;
              } else {
                if (!row.is_reimbursement) updatedRow.type = ""; // });

                updatedRow.expense = 1;
                updatedRow.merchant_type = 2;
                updatedRow.is_perquisite = row.is_perquisite ? true : false;
              }

              if (!!merchant_option.tds_percentage && !is_edit) {
                updatedRow.tds_status = true;
                updatedRow.tds_percentage = merchant_option.tds_percentage;
              } else {
                updatedRow.tds_status = false;
                updatedRow.tds_percentage = "";
                updatedRow.tds_amount = "";
              }
            }

          }

          if (is_edit) {
            return updatedRow;
          }

          if (!!merchant_option.gst_no) {
            updatedRow.gst_status = true;
            updatedRow.is_gst_claim_eligible = true;
          }
          //chnage the prediction status
          updatedRow.merchant_id_prediction_status = 0;

          return updatedRow;
        } else {
          return row;
        }
      })
    );

    this.setState({ excelData: newState }, () => {
      console.log(
        "update merchant data after================================ ",
        this.state.excelData
      );
      this.hideShowOnTypeChange(this.state.excelData[rowIndex].type, rowIndex);
    });
  };

  hideShowOnTypeChange = (value, rowIndex) => {
    let tds_status = this.state.excelData[rowIndex].tds_status;
    let payment_status = this.state.excelData[rowIndex].payment_status;
    let tds_percentage = this.state.excelData[rowIndex].tds_percentage;
    let tds_amount = this.state.excelData[rowIndex].tds_amount;

    if (value == 5 || value == 4 || value == 9 || value == 10) {
      tds_status = false;
      payment_status = false;
      tds_percentage = "";
      tds_amount = "";
    }
    const updatedData = this.state.excelData.map((row, index) =>
      index === rowIndex
        ? { ...row, tds_status, payment_status, tds_percentage, tds_amount }
        : row
    );
    this.setState({ excelData: updatedData }, () => {
      this.calculateTotalGst(rowIndex);
    });
  };
  handleGstOverrideChange = (e,rowIndex) =>{
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if(name == 'gst_override' && value == true){
      const updatedData = this.state.excelData.map((row, index) =>
        index === rowIndex ? { ...row, [name]: value, is_dirty: true,disable_igst:false } : row
      );
      this.setState({excelData:updatedData})
    }else{
      const updatedData = this.state.excelData.map((row, index) =>
        index === rowIndex ? { ...row, [name]: value, is_dirty: true, } : row
      );
      this.setState({excelData:updatedData},()=>{
        let customer_gst = this.state.excelData[rowIndex].gst_no ? this.state.excelData[rowIndex].gst_no.slice(0, 2) : null || null;
        let entity_gst = !!this.props.entity_gst_no
        ? this.props.entity_gst_no.slice(0, 2)
        : null;

        if (customer_gst == entity_gst && customer_gst && entity_gst) {
          const updatedData = this.state.excelData.map((row, index) =>
            index === rowIndex
              ? {
                ...row,
                is_igst: false,
                igst_amount: "",
                disable_igst: true,
                hide_gst_override:false
              }
              : row
          );
          this.setState({ excelData: updatedData }, () => {
            this.calculateTotalGst(rowIndex);
          });
        } else if (!customer_gst || !entity_gst) {
          const updatedData = this.state.excelData.map((row, index) =>
            index === rowIndex
              ? {
                ...row,
                is_igst: true,
                 sgst_amount: "",
                 cgst_amount: "",
                disable_igst: false,
                hide_gst_override:true
              }
              : row
          );
          this.setState({ excelData: updatedData }, () => {
            this.calculateTotalGst(rowIndex);
          });
        } else if (customer_gst != entity_gst && customer_gst && entity_gst) {
          const updatedData = this.state.excelData.map((row, index) =>
            index === rowIndex
              ? {
                ...row,
                is_igst: true,
                disable_igst: true,
                  sgst_amount: "",
                  cgst_amount: "",
                hide_gst_override:false
              }
              : row
          );
          this.setState({ excelData: updatedData }, () => {
            this.calculateTotalGst(rowIndex);
          });
        } else {
          const updatedData = this.state.excelData.map((row, index) =>
            index === rowIndex
              ? {
                ...row,
                is_igst: false,
                  igst_amount: "",
                disable_igst: false,
                hide_gst_override:true
              }
              : row
          );
          this.setState({ excelData: updatedData }, () => {
            this.calculateTotalGst(rowIndex);
          });
        }
        
      })
    }

  }
  handleChange = (e, rowIndex) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const updatedData = this.state.excelData.map((row, index) =>
      index === rowIndex ? { ...row, [name]: value, is_dirty: true } : row
    );
    this.setState({ excelData: updatedData }, () => {
      if (target.type === "checkbox")
        this.checkForReadyState(rowIndex);
    });
    // }
  };

  checkForReadyIgnoreMsg =  (rowIndex) => {
   
    let excelData = [...this.state.excelData]
    let updatedRow = {...excelData[rowIndex]}
      let ignoreErrors = {};
      let dataObj = updatedRow;
      console.log(
        "dataobject ------------- ",
        dataObj,
        rowIndex,
        Date(dataObj.date)
      );

      let data = {
        category_id: dataObj.category_id ? dataObj.category_id : "",
        isNewCategory: dataObj.isNewCategory,
        newCategoryName: dataObj.newCategoryName,
        tags: dataObj.tags,
        merchant_name: dataObj.merchant_option.label || null,
        disabledIgnore: dataObj.disabledIgnore,
      };
      ignoreErrors = this.ignoreFunction(data);

      updatedRow.ignoreErrors = ignoreErrors;
      
   
  // const updatedData = this.state.excelData.map((row, index) =>
  //   index === rowIndex ? { ...row, ignoreErrors: ignoreErrors } : row
  // );
  excelData[rowIndex] = updatedRow;
  this.setState({ excelData });
};


  checkForDraftReadyIgnoreMsg = async () => {
    const newState = await Promise.all(
      this.state.excelData.map(async (row, index) => {
        const updatedRow = {
          ...row,
        };

        let ignoreErrors = {};
        let dataObj = updatedRow;
        console.log(
          "dataobject ------------- ",
          dataObj,
          // rowIndex,
          Date(dataObj.date)
        );

        let data = {
          category_id: dataObj.category_id ? dataObj.category_id : "",
          isNewCategory: dataObj.isNewCategory,
          newCategoryName: dataObj.newCategoryName,
          tags: dataObj.tags,
          merchant_name: dataObj.merchant_option.label || null,
          disabledIgnore: dataObj.disabledIgnore,
        };
        ignoreErrors = this.ignoreFunction(data);

        updatedRow.ignoreErrors = ignoreErrors;
        return updatedRow;
      }));
    // const updatedData = this.state.excelData.map((row, index) =>
    //   index === rowIndex ? { ...row, ignoreErrors: ignoreErrors } : row
    // );
    this.setState({ excelData: newState });
  };

  checkForReadyState =  (rowIndex) => {
    
      
    let excelData = [...this.state.excelData];
    let updatedRow = {...excelData[rowIndex]}

    if (updatedRow.disabled) {
      updatedRow.errors = {};
    } else {
      let errors = {};
      let valid = false;
      let dataObj = updatedRow;

      let data = {
        gst_no: dataObj.gst_no,
        category_id: dataObj.category_id,
        pan_number: dataObj.pan_number,
        tags: dataObj.tags,
        type: dataObj.type,
        date: changeDateFormate(new Date(dataObj.date)),
        merchant_name: dataObj.merchant_option.label || null,
        invoice_number: dataObj.invoice_number,
        total_amount: parseFloat(dataObj.total_amount),
        gst_status: dataObj.gst_status,
        sgst_amount: dataObj.gst_status
          ? parseFloat(dataObj.sgst_amount)
          : null,
        cgst_amount: dataObj.gst_status
          ? parseFloat(dataObj.cgst_amount)
          : null,
        igst_amount: dataObj.gst_status
          ? parseFloat(dataObj.igst_amount)
          : null,
        gst_total_amount: dataObj.gst_status
          ? parseFloat(dataObj.gst_total_amount)
          : null,
        tds_status: dataObj.tds_status ? 1 : 0,
        tds_percentage: dataObj.tds_status
          ? parseFloat(dataObj.tds_percentage)
          : null,
        tds_amount: dataObj.tds_status
          ? parseFloat(dataObj.tds_amount)
          : null,
        // "payment_status": dataObj.payment_status,
        // "payment_information": dataObj.payment_information,
        accounting_mode: this.props.accounting_mode,
        is_igst: dataObj.is_igst,
        party_ledger_guid: dataObj.party_ledger_guid,
        inventory_mode: dataObj.inventory_mode,
        is_gst_claim_eligible: dataObj.is_gst_claim_eligible,
        // entry_status:this.state.isReady?2:1
      };

      console.log("Error validation ---- ", rowIndex, data);

      errors = validateCreateEvidenceWithReadyState(
        data,
        this.state.isReady
      );
      console.log("gst---------------------", dataObj.gst_no);
      if (!errors.gst_no && dataObj.gst_no)
        updatedRow.pan_number = dataObj.gst_no.substring(2, 12);
      if (!dataObj.is_reimbursement) {
        if (Object.keys(errors).length === 0) {
          updatedRow.errors = {};
          // updatedRow.entry_status = 2;
          updatedRow.is_dirty = true;
        } else {
          let errors_result = {
            ...errors,
            entry_status: "Please fill the mandatory details.",
          };

          updatedRow.errors = errors_result;
        }
      }
    }


    excelData[rowIndex] = updatedRow;
this.setState({ excelData }, () => {
  this.checkForReadyIgnoreMsg(rowIndex);
});
};


checkForDraftToReadyState = async () => {
    // const newState = await Promise.all(
    //   this.state.excelData.map(async (row, index) => {
    //     const updatedRow = {
    //       ...row,
    //     };
    //     if (row.disabled) {
    //       updatedRow.errors = {};
    //     } else {
    //       let errors = {};
    //       let valid = false;
    //       let dataObj = updatedRow;

    //       let data = {
    //         gst_no: dataObj.gst_no,
    //         category_id: dataObj.category_id,
    //         pan_number: dataObj.pan_number,
    //         tags: dataObj.tags,
    //         type: dataObj.type,
    //         date: changeDateFormate(new Date(dataObj.date)),
    //         merchant_name: dataObj.merchant_option.label || null,
    //         invoice_number: dataObj.invoice_number,
    //         total_amount: parseFloat(dataObj.total_amount),
    //         gst_status: dataObj.gst_status,
    //         sgst_amount: dataObj.gst_status
    //           ? parseFloat(dataObj.sgst_amount)
    //           : null,
    //         cgst_amount: dataObj.gst_status
    //           ? parseFloat(dataObj.cgst_amount)
    //           : null,
    //         igst_amount: dataObj.gst_status
    //           ? parseFloat(dataObj.igst_amount)
    //           : null,
    //         gst_total_amount: dataObj.gst_status
    //           ? parseFloat(dataObj.gst_total_amount)
    //           : null,
    //         tds_status: dataObj.tds_status ? 1 : 0,
    //         tds_percentage: dataObj.tds_status
    //           ? parseFloat(dataObj.tds_percentage)
    //           : null,
    //         tds_amount: dataObj.tds_status
    //           ? parseFloat(dataObj.tds_amount)
    //           : null,
    //         // "payment_status": dataObj.payment_status,
    //         // "payment_information": dataObj.payment_information,
    //         accounting_mode: this.props.accounting_mode,
    //         is_igst: dataObj.is_igst,
    //         party_ledger_guid: dataObj.party_ledger_guid,
    //         inventory_mode: dataObj.inventory_mode,
    //         is_gst_claim_eligible: dataObj.is_gst_claim_eligible,
    //         // entry_status:this.state.isReady?2:1
    //       };

    //       console.log("Error validation ---- ", index, data);

    //       errors = validateCreateEvidenceWithReadyState(
    //         data,
    //         this.state.isReady
    //       );
    //       console.log("gst---------------------", dataObj.gst_no);
    //       if (!errors.gst_no && dataObj.gst_no)
    //         updatedRow.pan_number = dataObj.gst_no.substring(2, 12);
    //       if (!dataObj.is_reimbursement) {
    //         if (Object.keys(errors).length === 0) {
    //           updatedRow.errors = {};
    //           updatedRow.entry_status = 2;
    //           updatedRow.is_dirty = true;
    //         } else {
    //           let errors_result = {
    //             ...errors,
    //             entry_status: "Please fill the mandatory details.",
    //           };

    //           updatedRow.errors = errors_result;
    //         }
    //       }
    //     }
    //     return updatedRow;
    //   })
    // );

    // this.setState({ excelData: newState }, () => {
    //   // this.checkForReadyIgnoreMsg(rowIndex);
    //   // this.checkForDraftReadyIgnoreMsg();
    // });

    let updatedExcelData = this.state.excelData.map((row) => {
      let updatedRow = { ...row };
      if(this.state.isReady){
        updatedRow.entry_status = 2
      }else{
        updatedRow.entry_status = 1
      }

      if (updatedRow.disabled) {
        updatedRow.errors = {};
      } else {
        let errors = {};
        let dataObj = updatedRow;

        let data = {
          gst_no: dataObj.gst_no,
          category_id: dataObj.category_id,
          pan_number: dataObj.pan_number,
          tags: dataObj.tags,
          type: dataObj.type,
          date: changeDateFormate(new Date(dataObj.date)),
          merchant_name: dataObj.merchant_option.label || null,
          invoice_number: dataObj.invoice_number,
          total_amount: parseFloat(dataObj.total_amount),
          gst_status: dataObj.gst_status,
          sgst_amount: dataObj.gst_status
            ? parseFloat(dataObj.sgst_amount)
            : null,
          cgst_amount: dataObj.gst_status
            ? parseFloat(dataObj.cgst_amount)
            : null,
          igst_amount: dataObj.gst_status
            ? parseFloat(dataObj.igst_amount)
            : null,
          gst_total_amount: dataObj.gst_status
            ? parseFloat(dataObj.gst_total_amount)
            : null,
          tds_status: dataObj.tds_status ? 1 : 0,
          tds_percentage: dataObj.tds_status
            ? parseFloat(dataObj.tds_percentage)
            : null,
          tds_amount: dataObj.tds_status
            ? parseFloat(dataObj.tds_amount)
            : null,
          accounting_mode: this.props.accounting_mode,
          is_igst: dataObj.is_igst,
          party_ledger_guid: dataObj.party_ledger_guid,
          inventory_mode: dataObj.inventory_mode,
          is_gst_claim_eligible: dataObj.is_gst_claim_eligible,
        };
        errors = validateCreateEvidenceWithReadyState(data, this.state.isReady);
        if (!errors.gst_no && dataObj.gst_no) {
          updatedRow.pan_number = dataObj.gst_no.substring(2, 12);
        }
        if (!dataObj.is_reimbursement) {
          if (Object.keys(errors).length === 0) {
            updatedRow.errors = {};
            // updatedRow.entry_status = 2;
            updatedRow.is_dirty = true;
          } else {
            updatedRow.errors = {
              ...errors,
              entry_status: "Please fill the mandatory details.",
            };
          }
        }
      }
      let ignoreErrors = this.checkForReadyStateIgnoreMsgOndrop(updatedRow);
      updatedRow.ignoreErrors = ignoreErrors;

      return updatedRow;
    })
    this.setState({excelData:updatedExcelData});
  };

  onGSTChange = (e, rowIndex) => {
    let gst_no = e.target.value;

    if (!!gst_no) {
      if (
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(gst_no)
      ) {
        const updatedData = this.state.excelData.map((row, index) =>
          index === rowIndex
            ? { ...row, pan_number: gst_no.substring(2, 12), gst_status: true,gst_override:false }
            : row
        );
        this.setState({ excelData: updatedData }, () => {
          let customer_gst = gst_no ? gst_no.slice(0, 2) : null || null;
          let entity_gst = !!this.props.entity_gst_no
            ? this.props.entity_gst_no.slice(0, 2)
            : null;

          if (customer_gst == entity_gst && customer_gst && entity_gst) {
            const updatedData = this.state.excelData.map((row, index) =>
              index === rowIndex
                ? {
                  ...row,
                  is_igst: false,
                  //igst_amount: "",
                  disable_igst: true,
                  hide_gst_override:false
                }
                : row
            );
            this.setState({ excelData: updatedData }, () => {
              this.calculateTotalGst(rowIndex);
            });
          } else if (!customer_gst || !entity_gst) {
            const updatedData = this.state.excelData.map((row, index) =>
              index === rowIndex
                ? {
                  ...row,
                  is_igst: true,
                  //  sgst_amount: "",
                  //  cgst_amount: "",
                  disable_igst: false,
                  hide_gst_override:true
                }
                : row
            );
            this.setState({ excelData: updatedData }, () => {
              this.calculateTotalGst(rowIndex);
            });
          } else if (customer_gst != entity_gst && customer_gst && entity_gst) {
            const updatedData = this.state.excelData.map((row, index) =>
              index === rowIndex
                ? {
                  ...row,
                  is_igst: true,
                  disable_igst: true,
                  //   sgst_amount: "",
                  //   cgst_amount: "",
                  hide_gst_override:false
                }
                : row
            );
            this.setState({ excelData: updatedData }, () => {
              this.calculateTotalGst(rowIndex);
            });
          } else {
            const updatedData = this.state.excelData.map((row, index) =>
              index === rowIndex
                ? {
                  ...row,
                  is_igst: false,
                  //   igst_amount: "",
                  disable_igst: false,
                  hide_gst_override:true
                }
                : row
            );
            this.setState({ excelData: updatedData }, () => {
              this.calculateTotalGst(rowIndex);
            });
          }
        });
      } else {
        console.log("pan will be empty==== ");
        const updatedData = this.state.excelData.map((row, index) =>
          index === rowIndex ? { ...row, pan_number: "" } : row
        );
        this.setState({ excelData: updatedData }, () => {
          this.calculateTotalGst(rowIndex);
        });
      }
    } else {
      const updatedData = this.state.excelData.map((row, index) =>
        index === rowIndex
          ? { ...row,gst_override:false }
          : row
      );
      this.setState({excelData: updatedData},()=>{
        let customer_gst = gst_no ? gst_no.slice(0, 2) : null || null;
        let entity_gst = !!this.props.entity_gst_no
          ? this.props.entity_gst_no.slice(0, 2)
          : null;
  
        if (customer_gst == entity_gst && customer_gst && entity_gst) {
          const updatedData = this.state.excelData.map((row, index) =>
            index === rowIndex
              ? {
                ...row, is_igst: false,
                //  igst_amount: "",
                disable_igst: true,
                hide_gst_override:false
              }
              : row
          );
          this.setState({ excelData: updatedData }, () => {
            this.calculateTotalGst(rowIndex);
          });
        } else if (!customer_gst || !entity_gst) {
          const updatedData = this.state.excelData.map((row, index) =>
            index === rowIndex
              ? {
                ...row,
                is_igst: true,
                // sgst_amount: "",
                // cgst_amount: "",
                disable_igst: false,
                hide_gst_override:true
              }
              : row
          );
          this.setState({ excelData: updatedData }, () => {
            this.calculateTotalGst(rowIndex);
          });
        } else if (customer_gst != entity_gst && customer_gst && entity_gst) {
          const updatedData = this.state.excelData.map((row, index) =>
            index === rowIndex
              ? {
                ...row,
                is_igst: true,
                disable_igst: true,
                //  sgst_amount: "",
                //  cgst_amount: "",
                hide_gst_override:false
              }
              : row
          );
          this.setState({ excelData: updatedData }, () => {
            this.calculateTotalGst(rowIndex);
          });
        } else {
          const updatedData = this.state.excelData.map((row, index) =>
            index === rowIndex
              ? {
                ...row, is_igst: false,
                //  igst_amount: "", 
                disable_igst: false,
                hide_gst_override:true
              }
              : row
          );
          this.setState({ excelData: updatedData }, () => {
            this.calculateTotalGst(rowIndex);
          });
        }
      })
     
    }
  };

  handleTypeChange = async (e, rowIndex) => {
    const newState = await Promise.all(
      this.state.excelData.map(async (row, index) => {
        if (index === rowIndex) {
          const { name, value } = e.target;
          let oldVal = row[name];
          const updatedRow = {
            ...row,
            [name]: value,
          };

          let expense = 1;
          let isPerquisiteDissable = false;

          let snapshot_type = this.state.snapshot_types.find(
            (type) => type.id == value
          );
          expense = snapshot_type ? snapshot_type.expense : 0;
          updatedRow.expense = expense;
          let merchant_type = snapshot_type.merchant_type;
          if (expense == 0 && row.is_perquisite == true) {
            if (
              await confirm({
                confirmation:
                  "This action will uncheck the perquisite checkbox. Do you still want to continue?",
              })
            ) {
              // updatedRow.expense = 0;
              updatedRow.is_perquisite = false;
              updatedRow.merchant_type = merchant_type;
              isPerquisiteDissable = true;
            } else {
              updatedRow[name] = oldVal;
              return updatedRow;
            }
          }
          //   updatedRow.expense = expense;
          // })
          //  updatedRow.type = merchant_type;
          let merchant_option = row.merchant_option;

          if (Object.keys(merchant_option).length != 0) {
            let merchant_list = this.state.all_merchant_list;
            let merchant_data = merchant_list.find(
              (merchant) =>
                merchant.gst_no == row.gst_no &&
                merchant.label == merchant_option.label
            );
            merchant_option.value = !merchant_data ? 0 : merchant_data.value;
            updatedRow.merchant_option = merchant_option;
          }

          if (merchant_type == 2) {
            updatedRow.is_customer = false;
          } else {
            updatedRow.is_supplier = false;
          }

          return updatedRow;
        } else {
          return row;
        }
      })
    );

    this.setState({ excelData: newState }, () => {
      if (!this.state.excelData[rowIndex].is_supplier)
        this.handleSupplierCustomerToggle("is_supplier", true, rowIndex);
      if (!this.state.excelData[rowIndex].is_customer)
        this.handleSupplierCustomerToggle("is_customer", true, rowIndex);
      this.hideShowOnTypeChange(e.target.value, rowIndex);
    });
  };

  // formatDateNew = (dateString) => {
  //   if (!dateString) return null;

  //   // Parse the dateString if it's not already a Date object
  //   const date = dateString instanceof Date ? dateString : new Date(dateString);

  //   // Get day, month, and year
  //   const day = String(date.getDate()).padStart(2, "0");
  //   const month = String(date.getMonth() + 1).padStart(2, "0");
  //   const year = date.getFullYear();

  //   // Return formatted date
  //   return `${day}-${month}-${year}`;
  // }

  // handleDateChange(date, rowIndex) {
  //   const formatDate = this.formatDateNew(date);
  //   const newData = this.state.excelData.map((row, index) =>
  //     index === rowIndex ? { ...row, date: formatDate } : row
  //   );
  //   this.setState({ excelData: newData });
  // }

  formatDateNew = (dateString) => {
    if (!dateString) return null;

    const date = dateString instanceof Date ? dateString : new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  handleDateChange = (date, rowIndex) => {
    const formatDate = this.formatDateNew(date);
    const newData = this.state.excelData.map((row, index) =>
      index === rowIndex ? { ...row, date: formatDate } : row
    );
    this.setState({ excelData: newData });
  };


  // tag functionality for multiselect functionality with dropdown and and new tag
  // function for show multiselect value from dropdown
  handleMultiSelectChange = (selectedOptions, rowIndex) => {
    const newData = this.state.excelData.map((row, index) =>
      index === rowIndex
        ? { ...row, tags: selectedOptions.map((option) => option.value) }
        : row
    );
    // const ignorError = this.ignoreFunction(newData);
    this.setState({ excelData: newData }, () => {
      this.checkForReadyState(rowIndex);
    }); //, isIgnoreErrors: ignorError
  };

  handleChangeAddNew = (newValue, rowIndex) => {
    const rowData = this.state.excelData[rowIndex];
    const inputValue = newValue;
    if (inputValue && !rowData.tags.includes(inputValue)) {
      this.setState({ newOption: newValue });
    }
  };

  changeAddNew = (val,rowIndex) => {
    console.log("chnage val",val,rowIndex)
    var arr = this.state.excelData;
    arr[rowIndex].is_new = val;
    this.setState({ excelData: arr });
  }

  removeCategory = (rowIndex) =>{
    console.log("remove category row",rowIndex)
    const updatedData =
                this.state.excelData.map(
                  (row, index) =>
                    index === rowIndex
                      ? {
                        ...row,
                        isNewCategory: false,
                      }
                      : row
                );
              this.setState({
                excelData: updatedData,
              }, () => {
                this.checkForReadyState(rowIndex);
              });
  }

isIgstChange = (e,rowIndex) =>{
    const target = e.target;
    const value = target.checked;

    const updatedData =
      this.state.excelData.map(
        (row, index) =>
          index === rowIndex
            ? {
              ...row,
              is_igst: value,
              is_dirty: true,
            }
            : row
      );
    this.setState(
      { excelData: updatedData },
      () => {
        if (value) {
          const updatedData =
            this.state.excelData.map(
              (row, index) =>
                index === rowIndex
                  ? {
                    ...row,
                    cgst_amount: "",
                    sgst_amount: "",
                  }
                  : row
            );
          this.setState(
            { excelData: updatedData },
            () => {
              this.calculateTotalGst(
                rowIndex
              );
            }
          );
        } else {
          const updatedData =
            this.state.excelData.map(
              (row, index) =>
                index === rowIndex
                  ? {
                    ...row,
                    igst_amount: "",
                  }
                  : row
            );
          this.setState(
            { excelData: updatedData },
            () => {
              this.calculateTotalGst(
                rowIndex
              );
            }
          );
        }
      }
    );
  }

  handleKeyDown = (e, rowIndex) => {
    if (e.key === "Enter") {
      const rowData = this.state.excelData[rowIndex];
      // const inputValue = e.target.value.trim();
      const inputValue = {
        value: e.target.value.trim(),
        label: e.target.value.trim(),
      };
      // Check if the option already exists in the tags  state
      const optionExists = this.state.tags.some(
        (option) => option.label === inputValue.label
      );

      if (!masterPayload.tags.includes(inputValue.label) && !optionExists) {
        masterPayload.tags.push(inputValue.label);
      }

      if (!optionExists) {
        this.setState((prevState) => ({
          tags: [...prevState.tags, inputValue],
        }));
      }
      if (inputValue && !rowData.tags.includes(inputValue)) {
        const updatedTags = [...rowData.tags, inputValue];
        const newData = this.state.excelData.map((row, index) =>
          index === rowIndex ? { ...row, tags: updatedTags } : row
        );
        this.setState({ excelData: newData, newOption: "" }, () => {
          this.checkForReadyState(rowIndex);
        });
        e.target.value = "";
      }
      e.target.value = ""; // Clear the input value after adding
    }
  };

  // perquisit checkbox function

  handlePerquisitCheckboxChange = (e, rowIndex) => {
    const { checked } = e.target;
    const newData = this.state.excelData.map((row, index) =>
      index === rowIndex ? { ...row, is_perquisite: checked } : row
    );
    this.setState({ excelData: newData });
  };

  payloadformatDateNew = (dateString) => {
    const parts = dateString.split("-");
    // Reformat the date to "yyyy-mm-dd"
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  };

  emailExcelData = () => {
    // // api call for send email

    const currentDate = new Date();
    const timestamp = currentDate
      .toISOString()
      .replace(/:/g, "-")
      .replace(/\..+/, "");

    const payload = {
      snapshots: [],
      file_name: `Buktec-Snapshot-Excel-Import-Sample_${timestamp}_uploaded.xlsx`,
    };
    this.state.excelData.forEach((data) => {
      console.log("item............data", data);
      const parseIntCategory = parseInt(data.category_id);
      // console.log("check:::item.CATEGORY", item.CATEGORY)
      // console.log("check:::parseIntCategory", parseIntCategory)

      const categoryMatch = this.state.categories.find(
        (cat) => cat.id === parseIntCategory
      );
      // console.log("check:::categoryMatch",categoryMatch)
      const tagMap = {};
      this.state.tags.forEach((tag) => {
        tagMap[tag.value] = tag.label;
      });

      const tags = data.tags.map((tagValue) => tagMap[tagValue] || tagValue); // Convert tag values to labels
      const tagsString = tags.join(", "); // Join tags into a single string separated by commas
      // console.log("tags",tags)

      // replace type id into name
      const parseIntType = parseInt(data.type);
      console.log("check:::item.TYPE", data.type);
      // console.log("check:::parseIntType", parseIntType)
      const matchType = this.state.snapshot_types.find(
        (type) => type.id === parseIntType
      );
      console.log("check:::matchType ", matchType);

      const snapshot = {
        star: data.star.toUpperCase() == "YES" ? "YES" : "NO",
        party_name: data.merchant_name,
        party_gst: data.gst_no,
        party_pan: data.pan_number,
        category: categoryMatch ? categoryMatch.name : "",
        type: matchType ? matchType.name : "",
        date: this.payloadformatDateNew(data.date),
        reference_no: data.invoice_number ? data.invoice_number : "",
        total_amount: parseInt(data.total_amount) ? parseInt(data.total_amount) : 0,
        tags: tagsString,
        perquisite: data.is_perquisite === true ? "yes" : "no",
        additional_info: data.additional_info
          ? data.additional_information
          : "",
        tds_percentage: data.tds_percentage ? parseFloat(data.tds_percentage) : 0,
        msme: data.is_msme === true ? "yes" : "no",
        cgst: data.cgst_amount ? parseInt(data.cgst_amount) : 0,
        sgst: data.sgst_amount ? parseInt(data.sgst_amount) : 0,
        igst: data.igst_amount ? parseInt(data.igst_amount) : 0,
        gst_eligible: data.is_gst_claim_eligible === true ? "yes" : "no",
        status: data.STATUS,
      };
      payload.snapshots.push(snapshot);
    });

    console.log("check:::payload:::::download------------------", payload);

    SnapshotService.importExcelEmail(payload).then(async (resp_data) => {
      console.log("Email of excel data  ----------", resp_data);
      if (resp_data.response_code == 200) {
        toast.success(resp_data.message);
      } else {
        toast.error(resp_data.message);
      }
    });
  };

  // Helper function to split data into batches
   chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  // Process and prepare snapshot payload
   prepareSnapshots = (dataList) => {
    return dataList
      //.filter((data) => !data.disabled)
      .map((data) => {
        if(data.disabled){
          return {...data,'STATUS':'Deleted'}
        }
        let gst_total_amount =
          data.sgst_amount || data.cgst_amount
            ? parseFloat(data.sgst_amount) + parseFloat(data.cgst_amount)
            : data.igst_amount
            ? parseFloat(data.igst_amount)
            : null;

        let total_amt = parseFloat(data.total_amount || 0) - (gst_total_amount || 0);
        let tds_amount = (total_amt * parseFloat(data.tds_percentage || 0)) / 100;

        return {
          is_star: data.star.toUpperCase() == "YES" ? 1 : 0,
          expense: data.expense, // Assuming CATEGORY maps to expense
          merchant_type: data.merchant_type ? data.merchant_type : 2, // Assuming a fixed value for merchant_type
          type: data.type ? data.type : 8, // Assuming a fixed value for type
          date: this.payloadformatDateNew(data.date), // Assuming a fixed value for date
          merchant_update: 1 , // Assuming a default value for merchant_update
          merchant_name: data.merchant_name ? data.merchant_name : null, // Assuming PARTY NAME maps to merchant_name
          merchant_id: data.merchant_id ? data.merchant_id : 0, // Assuming a default value for merchant_id
          gst_no: data.gst_no, // Assuming PARTY GST maps to gst_no
          invoice_number: data.invoice_number ? data.invoice_number : "", // Assuming an empty string for invoice_number
          // total_amount: parseInt(data.total_amount) ? parseInt(data.total_amount) :
          //   0, // Assuming a default value for total_amount
          total_amount: parseFloat(data.total_amount) ? parseFloat(data.total_amount) :
          0, // Assuming a default value for total_amount
          category_id: data.category_id
            ? parseInt(data.category_id)
            : null, // Assuming a default value for category_id
          tags: data.tags, // Using TAGS directly
          additional_information: data.additional_information
            ? data.additional_information
            : "", // Assuming an empty string for additional_information
          // Assuming default values for the rest of the properties
          payment_status: 2,
          payment_total: 0,
          payment_information: [],
          mode: 1,
          file_url: "",
          pan_number: data.pan_number,
          gst_no_prediction_status: 0,
          total_amount_prediction_status: 0,
          igst_amount_prediction_status: 0,
          sgst_amount_prediction_status: 0,
          cgst_amount_prediction_status: 0,
          date_prediction_status: 0,
          merchant_name_prediction_status: 0,
          snapshot_number_prediction_status: 0,
          merchant_id_prediction_status: 0,
          pan_number_prediction_status: 0,
          snapshot_type_prediction_status: 0,
          expense_prediction_status: 0,
          category_id_prediction_status: 0,
          tds_percentage_prediction_status: 0,
          tags_prediction_status: 0,
          is_msme_prediction_status: 0,
          is_entity_mismatch_prediction_status: 0,
          approval_comment: null,
          approval_status: null,
          approved_amount: null,
          new_tags: [],
          voucher_status: 0,
          is_duplicate: 0,
          prediction_id: null,
          source: 4,
          synced_by_user: null,
          entry_status: this.state.isReady ? 2 : 1,
          accounting_status: 3,
          is_msme: data.is_msme === true ? 1 : 0,
          is_perquisite: data.is_perquisite === true ? 1 : 0,
          is_gst_claim_eligible:
            data.is_gst_claim_eligible === true ? 1 : 0,
          is_same_invoice: 0,
          is_entity_mismatch: 0,
          is_reimbursement: 0,
          reimbursement_narration: null,
          inventory_mode: 0,
          inventory_details: null,
          gst_summary: null,
          hsn_sac_summary: null,
          gst_status:
            (data.sgst_amount && data.cgst_amount) || data.igst_amount
              ? 1
              : 0,
          sgst_amount: data.sgst_amount
            ? parseFloat(data.sgst_amount)
            : null,
          cgst_amount: data.cgst_amount
            ? parseFloat(data.cgst_amount)
            : null,
          igst_amount: data.igst_amount
            ? parseFloat(data.igst_amount)
            : null,
          gst_total_amount:
            data.sgst_amount || data.cgst_amount
              ? parseFloat(data.sgst_amount) + parseFloat(data.cgst_amount)
              : data.igst_amount
                ? parseFloat(data.igst_amount)
                : null,
          tds_status: data.tds_percentage ? 1 : 0,
          tds_details: [{
            base_amount: total_amt,
            tds_amount: tds_amount,
            tds_percentage: (data.tds_percentage
              ? (data.tds_percentage)
              : 0),
          }],
          // tds_percentage: data.tds_percentage
          //   ? parseInt(data.tds_percentage)
          //   : null,
          tds_total_amount: tds_amount || null,
          has_igst: data.igst_amount ? 1 : 0,
          vouchers: [],
          snapshot_type_update: data.merchant_id ? 1 : 0,
          is_gst_override:data.gst_override ? 1 : 0,
          amount_round_off:this.props.amount_round_off,
          request_id : null,
          excel_filename : this.state.fileName
        };
      });
  };


  // Main function to upload data in batches
 uploadInBatches = async () => {
  const allSnapshots = this.prepareSnapshots(this.state.excelData);
  const batches = this.chunkArray(allSnapshots, BATCHSIZE); // Divide data into chunks of 300

  let updatedExcelData = [...this.state.excelData];
  let globalIndex = 0; // Keep track of position across batches

  for (const batch of batches) {
    const filter_batch = batch.filter((data) => !data.disabled)
    const payload = { snapshots: filter_batch };

    try {
      const resp_data = await SnapshotService.importExcelUpload(payload);
      console.log("Batch Upload Response : ",batch.length, resp_data);

      if (resp_data.response_code === 200) {
        const batchResponses = resp_data.result.snapshots;
        //this.updateExcelData(resp_data.result.snapshots);
        // Update the excelData with the batch responses
        let cnt = -1;
        updatedExcelData = updatedExcelData.map((item, index) => {
          if (item.disabled) {
            return { ...item, STATUS: "Deleted" };
          }
          console.log(" --------------- " , index , globalIndex , batch.length);
          // Ensure correct mapping with global index
          if (
            index >= globalIndex &&
            ((index < globalIndex + batch.length))
          ) {
            cnt++;
            // const response = batchResponses[index - globalIndex];
            const response = batchResponses[cnt];
            let updatedStatus = "Error 104: Could not create snapshot";

            if (response?.response_code === 200) {
              updatedStatus = "Uploaded";
            } else if (response?.response_code === 400) {
              if (response?.reason.is_duplicate) {
                updatedStatus = "Duplicate Snapshot";
              } else if (response?.reason.is_same_invoice) {
                updatedStatus = "Is Same Invoice";
              }
            }

            return { ...item, STATUS: updatedStatus };
          }

          return item; // Keep other items unchanged
        });

        globalIndex += batch.length; // Move to the next batch index


      } else {
        toast.error(resp_data.message);
      }
    } catch (error) {
      console.error("Batch Upload Error: ", error);
      toast.error("An error occurred during batch upload.");
    }
  }

    // Call emailExcelData after the last batch is processed
    console.log("All batches processed. Sending email...",updatedExcelData);
    //this.emailExcelData();
     // Ensure updated state is set after all batches
     this.setState(
      {
        excelData: updatedExcelData,
        isUploading: false,
        isDownload: true,
        uploading_file_percent: 100,
        isSelectFile:false
      }
      ,
      () => this.emailExcelData()
    );
};

// Update excel data with upload status
 updateExcelData = (responses) => {
  let cnt = -1;
  const updatedExcelData = this.state.excelData.map((data) => {
    if (data.disabled) {
      return { ...data, STATUS: "Deleted" };
    } else {
      cnt++;
      const response = responses[cnt];
console.log("updateExcelData ------------------ ", response)
      if (response?.response_code === 200) {
        return { ...data, STATUS: "Uploaded" };
      } else if (response?.response_code === 400) {
        console.log("11111111111111111");
        if (response?.reason?.is_duplicate) {
          console.log("22222222222222222");
          return { ...data, STATUS: "Duplicate Snapshot" };
        } else if (response?.reason?.is_same_invoice) {
          console.log("3333333333333333333333");
          return { ...data, STATUS: "Is Same Invoice" };
        } else {
          return { ...data, STATUS: "Error: Could not create snapshot" };
        }
      }
    }
    return data; // No changes for other cases
  });
console.log("updated excle data --------------" , updatedExcelData);
  this.setState(
    {
      excelData: updatedExcelData,
      isUploading: false,
      isDownload: true,
      uploading_file_percent: 100,
    }
    // ,
    // () => this.emailExcelData()
  );
};

  handleUpload = () => {
    const payload = {
      snapshots: [],
    };

    // Your upload logic goes here
    this.setState({ isUploading: true ,isSelectFile:true});

    console.log("Master PAyload ---------", masterPayload , this.state.excelData.length);

    // api call
    SnapshotService.importExcelMasterSnapshot(masterPayload).then(
      async (resp_data) => {
        console.log("master payload response ------------------", resp_data);
        if (resp_data.response_code == 200) {
          // toast.success(`${resp_data.message} created`);
          // console.log("response:::200::", resp_data.result);
          this.setState({
            masterSnapshotResponse: resp_data.result,
            uploading_file_percent: 50,
          });
          const masterData = resp_data.result;

          console.log(
            "---- merchant list --------- ",
            this.state.all_merchant_list
          );
          // update added new party name id in merchant_list state and exceldata state
          const updateMerchantList = this.state.all_merchant_list.map(
            (newMerchant) => {
              const matchingMerchantState =
                masterData.merchants &&
                masterData?.merchants?.find(
                  (stateMerchant) => stateMerchant.name === newMerchant.label
                );

              const merchantID = matchingMerchantState
                ? matchingMerchantState.id
                : newMerchant.value;
              const merchantType = matchingMerchantState
                ? matchingMerchantState.type
                : newMerchant.type;
              const merchantPan = matchingMerchantState
                ? matchingMerchantState.pan_number
                : newMerchant.pan_number;
              const merchantGst = matchingMerchantState
                ? matchingMerchantState.gst_no
                : newMerchant.gst_no;
              return {
                ...newMerchant,
                value: merchantID,
                id: merchantID,
                type: merchantType,
                pan_number: merchantPan,
                gst_no: merchantGst,
              };
            }
          );

          this.setState({ all_merchant_list: updateMerchantList });

          const updatedMerchantListInExcelData = this.state.excelData.map(
            (data) => {
              // console.log("this.state.masterSnapshotResponse.categories :::", masterData.categories )
              // console.log("updatedMerchantListInExcelData:::dtaa", data);
              const matchingMerchantState =
                masterData.merchants &&
                masterData?.merchants?.find(
                  (stateMerchant) => stateMerchant.name === data.merchant_name
                );
              console.log(
                "matchingMerchantState:::exceldata::",
                matchingMerchantState
              );
              const merchantID = matchingMerchantState
                ? matchingMerchantState.id
                : data.merchant_id;
              // const merchantID = matchingMerchantState ? matchingMerchantState.id:"";
              const merchantType = matchingMerchantState
                ? matchingMerchantState.type
                : data.merchant_type;
              const merchantPan = matchingMerchantState
                ? matchingMerchantState.pan_number
                : data.pan_number;
              const merchantGst = matchingMerchantState
                ? matchingMerchantState.gst_no
                : data.gst_no;
              // console.log("merchantID::", merchantID);
              // console.log("matchingCategoryState::", matchingCategoryState)
              // console.log("matchingCategoryState::", categoryId)
              return {
                ...data,
                gst_no: merchantGst, // Prefill gst_no
                pan_number: merchantPan, // Prefill pan_number
                merchant_id: merchantID,
                merchant_type: merchantType,
              };
            }
          );
          this.setState({ excelData: updatedMerchantListInExcelData });

          // update added new category id in category state and exceldata state
          const updatedCategories = this.state.categories.map((category) => {
            // console.log("this.state.masterSnapshotResponse.categories :::", masterData.categories )
            const matchingCategoryState =
              masterData.categories &&
              masterData?.categories?.find(
                (stateCategory) => stateCategory.name === category.name
              );
            const categoryId = matchingCategoryState
              ? matchingCategoryState.id
              : category.id;
            // console.log("matchingCategoryState::", matchingCategoryState)
            // console.log("matchingCategoryState::", categoryId)
            return {
              ...category,
              id: categoryId,
            };
          });
          this.setState({ categories: updatedCategories });

          const updatedCategoryInExcelData = this.state.excelData.map(
            (data) => {
              // console.log(
              //   "this.state.masterSnapshotResponse.categories :::",
              //   masterData.categories
              // );
              const matchingCategoryState =
                masterData.categories &&
                masterData?.categories?.find(
                  (stateCategory) => stateCategory.name === data.category_id
                );
              const categoryId = matchingCategoryState
                ? matchingCategoryState.id
                : data.category_id;
              // console.log("matchingCategoryState::", matchingCategoryState);
              // console.log("matchingCategoryState::", categoryId);
              return {
                ...data,
                category_id: categoryId,
              };
            }
          );
          this.setState({ excelData: updatedCategoryInExcelData });

          console.log("updated tags -------------------1 ", masterData.tags);
          // update added new tags id in tags state and exceldata state
          const updatedTags = this.state.tags.map((tag) => {
            // console.log("this.state.masterSnapshotResponse.categories :::", masterData.categories )
            const matchingTagsState =
              masterData.tags &&
              masterData?.tags?.find((stateTag) => stateTag.name === tag.label);
            const tagsId = matchingTagsState ? matchingTagsState.id : tag.value;
            console.log("updated tags -------------------2 ", tagsId);
            // console.log("matchingCategoryState::", matchingCategoryState)
            // console.log("matchingCategoryState::", categoryId)
            return {
              ...tag,
              value: tagsId,
            };
          });
          console.log("updated tags -------------------3 ", updatedTags);
          this.setState({ tags: updatedTags });

          const updatedTagsInExcelData = this.state.excelData.map((data) => {
            const updatedTags = data.tags.map((tagId) => {
              const tag =
                masterData.tags &&
                masterData?.tags?.find((tag) => tag.name === tagId);
              return tag ? tag.id : tagId;
            });
            return { ...data, tags: updatedTags };
          });
          console.log(
            "updated tags -------------------4 ",
            updatedTagsInExcelData
          );
          this.setState({ excelData: updatedTagsInExcelData });

          // Start batch upload
          this.uploadInBatches();
          /*
          const payload = {
            snapshots: [],
          };
          // console.log("check1:::exceldata:::masterapi", this.state.excelData);
          // console.log("check1:::,filter:::masterapi", filteredData);
          // console.log("final data --------------" , data);
          //  sdsdsdsd
          this.state.excelData.forEach((data) => {
            console.log("data=================== ", data.disabled, (data.tds_percentage));
            if (!data.disabled) {
              let gst_total_amount =
                data.sgst_amount || data.cgst_amount
                  ? parseInt(data.sgst_amount) + parseInt(data.cgst_amount)
                  : data.igst_amount
                    ? parseInt(data.igst_amount)
                    : null;

              let total_amt =
                parseInt(data.total_amount || 0) -
                parseInt(gst_total_amount || 0);
              let tds_amount =
                (total_amt * parseInt(data.tds_percentage || 0)) / 100;

              const snapshot = {
                is_star: data.star.toUpperCase() == "YES" ? 1 : 0,
                expense: data.expense, // Assuming CATEGORY maps to expense
                merchant_type: data.merchant_type ? data.merchant_type : 2, // Assuming a fixed value for merchant_type
                type: data.type ? data.type : 8, // Assuming a fixed value for type
                date: this.payloadformatDateNew(data.date), // Assuming a fixed value for date
                merchant_update: data.merchant_id ? 1 : 0, // Assuming a default value for merchant_update
                merchant_name: data.merchant_name ? data.merchant_name : null, // Assuming PARTY NAME maps to merchant_name
                merchant_id: data.merchant_id ? data.merchant_id : 0, // Assuming a default value for merchant_id
                gst_no: data.gst_no, // Assuming PARTY GST maps to gst_no
                invoice_number: data.invoice_number ? data.invoice_number : "", // Assuming an empty string for invoice_number
                // total_amount: parseInt(data.total_amount) ? parseInt(data.total_amount) :
                //   0, // Assuming a default value for total_amount
                total_amount: parseFloat(data.total_amount) ? parseFloat(data.total_amount) :
                0, // Assuming a default value for total_amount
                category_id: data.category_id
                  ? parseInt(data.category_id)
                  : null, // Assuming a default value for category_id
                tags: data.tags, // Using TAGS directly
                additional_information: data.additional_information
                  ? data.additional_information
                  : "", // Assuming an empty string for additional_information
                // Assuming default values for the rest of the properties
                payment_status: 2,
                payment_total: 0,
                payment_information: [],
                mode: 1,
                file_url: "",
                pan_number: data.pan_number,
                gst_no_prediction_status: 0,
                total_amount_prediction_status: 0,
                igst_amount_prediction_status: 0,
                sgst_amount_prediction_status: 0,
                cgst_amount_prediction_status: 0,
                date_prediction_status: 0,
                merchant_name_prediction_status: 0,
                snapshot_number_prediction_status: 0,
                merchant_id_prediction_status: 0,
                pan_number_prediction_status: 0,
                snapshot_type_prediction_status: 0,
                expense_prediction_status: 0,
                category_id_prediction_status: 0,
                tds_percentage_prediction_status: 0,
                tags_prediction_status: 0,
                is_msme_prediction_status: 0,
                is_entity_mismatch_prediction_status: 0,
                approval_comment: null,
                approval_status: null,
                approved_amount: null,
                new_tags: [],
                voucher_status: 0,
                is_duplicate: 0,
                prediction_id: null,
                source: 4,
                synced_by_user: null,
                entry_status: this.state.isReady ? 2 : 1,
                accounting_status: 3,
                is_msme: data.is_msme === true ? 1 : 0,
                is_perquisite: data.is_perquisite === true ? 1 : 0,
                is_gst_claim_eligible:
                  data.is_gst_claim_eligible === true ? 1 : 0,
                is_same_invoice: 0,
                is_entity_mismatch: 0,
                is_reimbursement: 0,
                reimbursement_narration: null,
                inventory_mode: 0,
                inventory_details: null,
                gst_summary: null,
                hsn_sac_summary: null,
                gst_status:
                  (data.sgst_amount && data.cgst_amount) || data.igst_amount
                    ? 1
                    : 0,
                sgst_amount: data.sgst_amount
                  ? parseFloat(data.sgst_amount)
                  : null,
                cgst_amount: data.cgst_amount
                  ? parseFloat(data.cgst_amount)
                  : null,
                igst_amount: data.igst_amount
                  ? parseFloat(data.igst_amount)
                  : null,
                gst_total_amount:
                  data.sgst_amount || data.cgst_amount
                    ? parseFloat(data.sgst_amount) + parseFloat(data.cgst_amount)
                    : data.igst_amount
                      ? parseFloat(data.igst_amount)
                      : null,
                tds_status: data.tds_percentage ? 1 : 0,
                tds_details: [{
                  base_amount: total_amt,
                  tds_amount: tds_amount,
                  tds_percentage: (data.tds_percentage
                    ? (data.tds_percentage)
                    : 0),
                }],
                // tds_percentage: data.tds_percentage
                //   ? parseInt(data.tds_percentage)
                //   : null,
                tds_total_amount: tds_amount || null,
                has_igst: data.igst_amount ? 1 : 0,
                vouchers: [],
                snapshot_type_update: data.merchant_id ? 1 : 0,
                is_gst_override:data.gst_override ? 1 : 0,
                amount_round_off:this.props.amount_round_off,
                request_id : null,
                excel_filename : this.state.fileName
              };

              payload.snapshots.push(snapshot);
            }
          });

          console.log("check1:::payload:::::snapshotapi", payload);

          // snapshot-upload api call
          SnapshotService.importExcelUpload(payload).then(async (resp_data) => {
            console.log("Excel Import ==================== ", resp_data);
            const responses = resp_data?.result?.snapshots;
            // console.log("check333:::responses:::1", responses)
            if (resp_data.response_code == 200) {
              let cnt = -1;
              let updatedExcelData = this.state.excelData.map((data, index) => {
                // Check if the response at the same index has a 200 or 400 status
                if (data.disabled) {
                  return { ...data, STATUS: "Deleted" };
                } else {
                  cnt++;

                  console.log("response code --------------", responses[cnt]?.response_code);


                  if (responses[cnt]?.response_code === 200) {
                    return { ...data, STATUS: "Uploaded" }; // If response_code is 200, set STATUS to "Uploaded"
                  } else if (responses[cnt]?.response_code === 400) {
                    if (responses[cnt]?.reason.is_duplicate) {
                      return { ...data, STATUS: "Duplicate Snapshot" };
                    } else if (responses[cnt]?.reason.is_same_invoice) {
                      return { ...data, STATUS: "Is Same Invoice" };
                    } else {
                      return {
                        ...data,
                        STATUS: "Error 104 : Could not create snapshot",
                      };
                    }
                    // return { ...data, STATUS: "Not Uploaded" }; // If response_code is 400, set STATUS to "Not Uploaded"
                  }
                }

                return data; // Return the data unchanged if no matching condition
              });

              console.log("excel data ---------------------", updatedExcelData);
              // Update the state once with all the changes
              this.setState(
                {
                  isUploading: false,
                  isDownload: true,
                  uploading_file_percent: responses.every(
                    (r) => r.response_code === 200
                  )
                    ? 100
                    : 75, // Set percent based on responses
                  excelData: updatedExcelData,
                },
                () => {
                  this.emailExcelData();
                }
              );
            } else {
              toast.error(resp_data.message);
            }
          });*/
        } else if (resp_data.response_code == 400) {
          toast.error(resp_data.reason.pan_number);
          const duplicat = this.validationMatching(this.state.excelData);
          const duplicatignore = this.ignoreFunction(this.state.excelData);
          this.setState({
            isUploading: false,
            isSelectFile:false,
            uploading_file_percent: 0,
            errors: duplicat,
            isIgnoreErrors: duplicatignore,
          });
        } else {
          toast.error(resp_data.message);
        }
      }
    );
  };

  downloadExcel() {
    // Map each tag value to its corresponding label for easy lookup
    const tagMap = {};
    this.state.tags.forEach((tag) => {
      tagMap[tag.value] = tag.label;
    });

    const updatedData = this.state.excelData.map((item) => {
      // console.log("item",item)
      const parseIntCategory = parseInt(item.category_id);

      const categoryMatch = this.state.categories.find(
        (cat) => cat.id === parseIntCategory
      );
      // console.log("check:::categoryMatch",categoryMatch)

      const tags = item.tags.map((tagValue) => tagMap[tagValue] || tagValue); // Convert tag values to labels
      const tagsString = tags.join(", "); // Join tags into a single string separated by commas
      // console.log("tags",tags)

      // replace type id into name
      const parseIntType = parseInt(item.type);
      console.log("check:::item.TYPE", item.type);
      // console.log("check:::parseIntType", parseIntType)
      const matchType = this.state.snapshot_types.find(
        (type) => type.id === parseIntType
      );
      console.log("check:::matchType", matchType);

      // console.log("check:::matchType", matchType)

      // Compute values for MSME, PERQUISITE, and GST ELIGIBLE
      const msmeStatus = item.is_msme ? "Yes" : "No";
      const perquisiteStatus = item.is_perquisite ? "Yes" : "No";
      const gstEligibleStatus = item.is_gst_claim_eligible ? "Yes" : "No";
      const allStatusesAreNo =
        !item.is_msme && !item.is_perquisite && !item.is_gst_claim_eligible;

      // Final object assembly

      return {
        //  ...item,
        STAR: item.star.toUpperCase() == "YES" ? "Yes" : "No",
        "PARTY NAME": item.merchant_name,
        "PARTY GST": item.gst_no,
        "PARTY PAN": item.pan_number,
        CATEGORY: this.state.categories.find(
          (cat) => parseInt(cat.id) == parseInt(item.category_id)
        )?.name,
        TYPE: matchType ? matchType.name : "",
        DATE: this.payloadformatDateNew(item.date),
        "REFERENCE NO": item.invoice_number,
        "TOTAL AMOUNT": item.total_amount ? parseInt(item.total_amount) : "",
        TAGS: tagsString,
        PERQUISITE: allStatusesAreNo ? "No" : perquisiteStatus,
        "ADDITIONAL INFO": item.additional_information,
        MSME: allStatusesAreNo ? "No" : msmeStatus,
        CGST: item.cgst_amount ? parseInt(item.cgst_amount) : "",
        SGST: item.sgst_amount ? parseInt(item.sgst_amount) : "",

        IGST: item.igst_amount ? parseInt(item.igst_amount) : "",
        "GST ELIGIBLE": allStatusesAreNo ? "No" : gstEligibleStatus,
        "TDS %": item.tds_percentage ? (item.tds_percentage) : "",
        // DISABLE: item.disabled,
        STATUS: item.STATUS,
      };
    });

    console.log("check:::updatedData:::", updatedData);

    // console.log("check:::download:::updatedData", updatedData);
    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert JSON data to worksheet
    const ws = XLSX.utils.json_to_sheet(updatedData);

    // Customize header style to make it bold
    const headerStyle = { bold: true };
    // const headerStyle = {
    //   font: {
    //     bold: true,
    //   },
    // };

    const range = XLSX.utils.decode_range(ws["!ref"]);
    // console.log('check:::range', range);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const headerCell = XLSX.utils.encode_cell({ r: range.s.r, c: C });
      ws[headerCell].s = headerStyle;
    }

    // for (let C = range.s.c; C <= range.e.c; ++C) {
    //   const cellAddress = { c: C, r: range.s.r };
    //   const cellRef = XLSX.utils.encode_cell(cellAddress);
    //   if (!ws[cellRef]) continue; // Skip empty cells
    //   ws[cellRef].s = headerStyle;
    // }

    // console.log('check:::range', range);
    // console.log('check:::ws', ws);

    // Set column widths
    ws["!cols"] = [
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
    ];

    const currentDate = new Date();
    const timestamp = currentDate
      .toISOString()
      .replace(/:/g, "-")
      .replace(/\..+/, ""); // Format: YYYY-MM-DDTHH-MM-SS

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Write the workbook to a file
    XLSX.writeFile(
      wb,
      `Buktec-Snapshot-Excel-Import-Sample_${timestamp}_uploaded.xlsx`
    );
  }

  downloadSampleExcelFile() {
    // Create a temporary link element
    const link = document.createElement("a");
    link.href = excelFile;
    link.download = "Buktec-Snapshot-Excel-Import-Sample.xlsx"; // Set the file name for download
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up
    document.body.removeChild(link);
  }

  
  render() {
    const { excelData, isUploading } = this.state;

    return (
      <Modal
        // show={true}
        show={this.props.show_import_excel_popup}
        onHide={() => this.toggleImportExcelPopup()}
        backdrop="static"
        keyboard={false}
        centered
        // size="xl"
        className="popup-model import-excel-popup"
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center  gap-10 w-100">
            <span className="mr-3">Excel Import</span>

            {/* Select File.. */}
            <div className="ie_upload_file">
              <input
                id="file-upload"
                type="file"
                accept=".xlsx, .xls"
                onChange={this.handleDrop}
              />
              <label
                for="file-upload"
                class="btn btn-success apply_btn_new mb-0"
              >
                {" "}
                Select File{" "}
              </label>
              <p className="ml-3 mb-0">
                File Name:{" "}
                <span>
                  {" "}
                  {this.state.fileName
                    ? this.state.fileName
                    : "No File Selected"}
                </span>
                {this.state.selectFileError && (
                  <>
                    <br />
                    <span style={{ color: "red" }}>
                      {this.state.selectFileError}
                    </span>
                  </>
                )}
              </p>
            </div>
            {/* End Select File.. */}

            <Button variant="outline-success reset_btn_new ml-auto" onClick={this.downloadSampleExcelFile}><span class="icon-Download reset-download-icon mr-2"></span>Sample Excel File</Button>

          </Modal.Title>
        </Modal.Header>

        {/* Loader */}
        {this.state.isSelectFile && (
          <>
            <div className="ie_loader_overlay"></div>
            <img className="ie_loader_img" src={loaderw} />
          </>
        )}
        {/* End Loader */}

        <Modal.Body>
          {/* Table */}
          {/* <div className="row">
            <div className="col-12"> */}
              {/* <div className="import-excel-border">
               <div className="import-excel-table" style = {{height: "600px",  }} ref={this.scrollRef}>  */}
               
                  <ReactVirtualized
                   excelData={excelData}
                   isDownload = {this.state.isDownload}
                   isUploading = {this.state.isUploading}
                   handleStarClick = {this.handleStarClick}
                   onMerchantChanged = {this.onMerchantChanged}
                   all_merchant_list = {this.state.all_merchant_list}
                   changeAddNew = {this.changeAddNew}
                   checkForReadyState= {this.checkForReadyState}
                   handleChange = {this.handleChange}
                   onGSTChange = {this.onGSTChange}
                   amountChange = {this.amountChange}
                   calculateTotalGst = {this.calculateTotalGst}
                   handleNewCategorySubmit = {this.handleNewCategorySubmit}
                   handleCategoryChange = {this.handleCategoryChange}
                   removeCategory = {this.removeCategory}
                   categories = {this.state.categories}
                   snapshot_types = {this.state.snapshot_types}
                   handleTypeChange = {this.handleTypeChange}
                   handleDateChange = {this.handleDateChange}
                   calculateTDS = {this.calculateTDS}
                   tags = {this.state.tags}
                   handleMultiSelectChange = {this.handleMultiSelectChange}
                   handleChangeAddNew = {this.handleChangeAddNew}
                   handleKeyDown = {this.handleKeyDown}
                   customStyles = {this.customStyles}
                   isIgstChange = {this.isIgstChange}
                   handleIgnore = {this.handleIgnore}
                   handleDisable = {this.handleDisable}
                   handleRestore = {this.handleRestore}
                   amountRoundOff = {this.amountRoundOff}
                   handleGstOverrideChange = {this.handleGstOverrideChange}
                  />
                {/* </div>
              </div> */}
            {/* </div>
          </div> */}
          {/* End Table */}

          {/* Download and Status */}
          <div className="row status_arrow_btn">

            <div className="col-xl-12 col-lg-12 col-md-12 mt-0 mt-lg-0 mt-md-3 text-right d-flex align-items-center justify-content-end bottom-save-btn">
            {/* <div className="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center"> */}
              {/* key shortcuts */}
              {/* {this.state.excelData.length > 0 ? (
                <div className="ie_arrows">
                  <span
                    class="icon-right-arrow ie_arrows_icon ie_left"
                    onClick={() => this.scroll("left")}
                  ></span>
                  <span
                    class="icon-right-arrow-1 ie_arrows_icon ie_right"
                    onClick={() => this.scroll("right")}
                  ></span>
                  <span
                    class="icon-right-arrow-1 ie_arrows_icon pt-1"
                    onClick={() => this.scroll("down")}
                  ></span>
                  <span
                    class="icon-right-arrow ie_arrows_icon"
                    onClick={() => this.scroll("up")}
                  ></span>
                </div>
              ) : null} */}
              {/* End key shortcuts */}
              <div className="ie_icon_up_btn">
                    
                      {this.shouldEnableIgnoreButton(this.state.excelData) ? 
                        <OverlayTrigger
                          overlay={
                            <Tooltip
                              id={`tooltip-disabled-${1}`}
                            >
                              Ignore All
                            </Tooltip>
                          }
                        >
                            <span
                              class="icon-warning ie_icon_btn yellow-icon"
                              onClick={()=> {
                                this.handleIgnoreAll()
                                
                              }}
                            ></span>
                        </OverlayTrigger> : (
                          <span class="icon-warning ie_icon_btn gray-icon cmenu"></span>
                        )
                   }    
                        
                      
                </div>

            {/* </div> */}
              {/* {(this.CREATE_BUSSINESS_SNAPSHOT) &&
                <div >
                  <button onClick={this.downloadSampleExcelFile}>
                    <span class="action-new-icon-sec">
                      <span class="icon-Download action-new-icon"></span>
                    </span>
                    <span> Sample Excel File</span>
                  </button>
                </div>
              } */}

              {this.state.isDownload && (
                <Button variant="success apply_btn_new mr-3" onClick={() => this.downloadExcel()} >Download</Button>
              )}

              {/* <Button variant="outline-success reset_btn_new mr-3" onClick={this.downloadSampleExcelFile}><span class="icon-Download reset-download-icon mr-2"></span>Sample Excel File</Button> */}

              <div className="toggle-switch-btn toggle-btn-bordered toggle-switch-center mr-3">
                <span className="toggle-btn-sec-text mr-4">Status</span>
                <span className="toggle-switch-center">
                  <span className="toggle-btn-sec-text">Draft</span>
                  <label className="switch">
                    <input
                      disabled={this.state.isDownload}
                      type="checkbox"
                      checked={this.state.isReady}
                      value={this.state.isReady}
                      onChange={(e) => {
                        const target = e.target;
                        this.setState({ isReady: target.checked,isSelectFile:true }, () => {
                          // this.checkForReadyState(0);
                          setTimeout(async()=>{
                            await this.checkForDraftToReadyState();
                            this.setState({isSelectFile:false})
                          },0)
                          
                        });
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                  <span className="toggle-btn-sec-text">Ready</span>
                </span>
              </div>

              <div className="bottom_save_btn">
                {this.state.isDownload ? (
                  <Button
                    variant="success apply_btn_new"
                    onClick={() => this.finishImportExcelPopup()}
                  >
                    Finish
                  </Button>
                ) : (
                  <Button
                    variant="success apply_btn_new"
                    onClick={this.handleUpload}
                    disabled={
                      isUploading ||
                      excelData.length === 0 ||
                      this.state.uploading_file_percent > 0 ||
                      !excelData.every((item) =>
                        item.errors
                          ? Object.keys(item.errors).length === 0
                          : true
                      ) ||
                      !excelData.every((item) =>
                        item.ignoreErrors
                          ? Object.keys(item.ignoreErrors).length === 0
                          : true
                      ) ||
                      // Object.keys(this.state.errors).length > 0 ||
                      // Object.keys(this.state.isIgnoreErrors).length > 0 ||
                      excelData.filter((item) => item.disabled).length ===
                      excelData.length // Disable button if all items are disabled
                    } // Disable upload button while uploading
                  >
                    {isUploading ? "Uploading..." : "Upload"}
                  </Button>
                )}
              </div>
            </div>
          </div>
          {/* End Download and Status */}
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const session_values = state.Session || {};
  const entity_gst_no = session_values.entity_config_param.gst_no || null;
  const amount_round_off = session_values.entity_config_param.amount_round_off;
  const features_permissions_list =
    session_values.features_permissions_list || [];
  const accounting_mode =
    session_values.entity_config_param.accounting_mode || 1;
  return {
    entity_gst_no,
    features_permissions_list,
    accounting_mode,
    amount_round_off
  };
}

export default connect(mapStateToProps)(withRouter(ImportExcelPopups));
