import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import { ActiveUser, InactiveUser } from '../../Views/IcoonNew'
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';
import { setAppilcationLoder } from "../../Redux/Actions/App";
import MasterService from '../../Services/MasterService';
import EntityService from '../../Services/ProfileManagement/EntityService';
import { toast } from 'react-toastify';
import { validateCACreateEntity, validateCreateEntity } from './Validation'
import ImageUpload from '../BoilerPlate/ImageUpload';
import { PROFILE_MANAEGEMT_FILE_CODE } from '../../Helpers/Constants';
import StorageData from '../../Helpers/StorageData';
import SessionManagement from '../../Utils/SessionManagement';
import DeletePopUp from '../BoilerPlate/DeletePopUp';
import RouteLeavingGuard from '../../Utils/RouteLeavingGuard';
import { confirm } from '../BoilerPlate/Confirm';
import NumberTypeInputBox from '../BoilerPlate/InputNumber';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
// import confirmDelete from '../../Helpers/ConfirmPopup'; 
import { setEntityConfigParam } from "../../Redux/Actions/Session";
import { Button, ButtonGroup } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

// Intro js user guid
import { render } from "react-dom";
import { Steps } from "intro.js-react";
// End Intro js user guid 

class CreateEntity extends Component {

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.ACCOUNT_TYPE = (SessionManagement.getUserDetail()).account_type;
    this.IS_PROMOTIONAL = (SessionManagement.getUserDetail()).is_promotional;
    this.MANAGE_ENTITY = this.props.account_permissions.includes(19);

    this.state = {
      entityBeforeEdit: {},
      account_code: "",
      address: "",
      entity_name: "",
      email: "",
      company_type_id: "",
      phone_number: "",
      gst_no: "",
      pan_no: "",
      tan_no: "",
      country_id: "1",
      state_id: "",
      city_id: "",
      pincode: "",
      account_license_id: 0,
      sponser_license_id: 0,
      hierarchy: 1,
      license_name: "",
      plan_name: "",
      end_date: "",
      gst_username: "",
      countries: [],
      states: [],
      cities: [],
      company_types: [],
      available_licenses: [],
      old_licences: [],
      files: [],
      company_logo_files: [],
      users: [],

      paid_by: this.ACCOUNT_TYPE == 2 ? "sponsered" : "self",
      sponser_otp: "",
      invite_email: "",

      errors: {},
      loading: false,
      showSponser: false,
      entity_id: !!this.props.match.params.entity_id ? this.props.match.params.entity_id : null,
      entity_type: this.props.location.pathname.includes("pending") ? "invite" : "edit",

      listed_tags: [],
      selected_tags: [],
      hashtag: "",
      new_hashtags: [],
      is_deleted: 0,
      is_dirty: false,
      show_delete_popup: false,
      save_and_redirect: false,
      next_route: false,
      closed: false,
      default_entity_detail: StorageData.getDefaultEntity() || {},

      // Intro js user guid
      stepsEnabled: false,
      initialStep: 0,
      steps: [
        {
          element: ".gdtl_pfin",
          intro: "Enter General Details",
          position: 'top',
        },
        {
          element: ".tdtl_pfin",
          intro: "Enter Taxation Details",
        },
        {
          element: ".pdtl_pfin",
          intro: "Paid By",
        },
        {
          element: ".adtl_pfin",
          intro: "Additional ",
        },
        {
          element: ".save_pfin",
          intro: "Save Entity ",
        },
      ],
      // End Intro js user guid

      show_email_snapshot_config: true,
      receiver_entity_email: "",
      email_snapshot_acknowledgement: false,
      email_snapshot_notification: false,
      forward_snapshot_email: false,
      ledger_recommendation: 0,
      is_buktec_narration: 1,
      accounting_mode: 1,
      is_common_item_ledger: 1,
      amount_round_off : 0,
      is_service_description_voucher : 0,
    };
  }

  toggleDeletePopup = () => {
    this.setState({ show_delete_popup: !this.state.show_delete_popup })
  }

  deleteCAEntity = async () => {
    let is_delete = await confirm({
      confirmation: 'Do you want to Release this entity and from your account?' + (!!this.state.sponser_license_id ? ' This will remove your license attached in bussiness account' : '')
    });
    if (!is_delete) {
      return true;
    }
    this.props.dispatch(setAppilcationLoder(true));
    EntityService.deleteEntity(this.state.entity_id, this.state.entity_type).then(
      (resp_data) => {
        this.props.dispatch(setAppilcationLoder(false));
        this.setState({
          errors: {},
          is_dirty: false
        });
        if (resp_data.response_code == 200) {
          toast.success(resp_data.message);
        } else if (resp_data.response_code == 404) {
          toast.error(resp_data.message);
        } else {
          toast.error(resp_data.message);
        }
        if (this.state.entity_type == "invite") {
          this.props.removePendingEntity(this.state.entity_id);
          if (this.props.pending_entity_count <= 1) {
            this.props.history.push('/profile-management/entities/')
          } else {
            this.props.history.push('/profile-management/pending/')
          }
        } else {
          this.props.removeCAEntityFromList(this.state.entity_id);
          this.props.history.push('/profile-management/entities/')
        }
      });
  }

  deleteEntity = async () => {
    let is_delete = await confirm({
      // confirmation: 'This entity is deleted. To restore the same kindly contact admin.'
      confirmation: 'Are you sure, you want to delete it? If you delete it,it will be deleted permenantly.'
    });
    if (!is_delete) {
      return true;
    }
    this.props.dispatch(setAppilcationLoder(true));
    EntityService.deleteEntity(this.state.entity_id, this.state.entity_type).then(
      (resp_data) => {
        this.setState({ is_dirty: false })
        this.props.dispatch(setAppilcationLoder(false));
        if (resp_data.response_code == 200) {
          this.props.removeEntityFromList(this.state.entity_id);
          toast.success(resp_data.message);
        } else if (resp_data.response_code == 404) {
          toast.error(resp_data.message);
        } else {
          toast.error(resp_data.message);
        }
        if (this.state.entity_id == this.state.default_entity_detail.id) {
          window.location.href = "/profile-management/entities/"
        } else {
          this.props.history.push('/profile-management/entities/');
        }
      });
  }

  rejectEntity = async () => {
    // this.toggleDeletePopup()
    if (!await confirm({ confirmation: 'Are you sure, Do you want to Reject invite ?' })) {
      return true;
    }
    this.props.dispatch(setAppilcationLoder(true));
    EntityService.RejectEntityInvite(this.state.entity_id).then(
      (resp_data) => {
        this.props.dispatch(setAppilcationLoder(false));
        this.setState({
          errors: {},
          is_dirty: false
        });
        let pending_entity_count = this.props.pending_entity_count;
        if (resp_data.response_code == 200) {
          this.props.removePendingEntity(this.state.entity_id);
          toast.success(resp_data.message);
        } else if (resp_data.response_code == 404) {
          toast.error(resp_data.message);
        } else {
          toast.error(resp_data.message);
        }

        if (pending_entity_count == 1) {
          this.props.history.push('/profile-management/entities/')
        } else {
          this.props.history.push('/profile-management/pending/')
        }
      });
  }

  addNewHashtag = (e) => {
    e.preventDefault();
    if (!!this.state.hashtag) {
      this.setState({
        new_hashtags: [...this.state.new_hashtags, "#" + this.state.hashtag],
        hashtag: "", is_dirty: true
      })
    }
  }

  removeNewHashtag = (index) => {
    let new_hashtags = this.state.new_hashtags;
    new_hashtags.splice(index, 1);
    this.setState({ new_hashtags: new_hashtags, is_dirty: true });
  }

  getHashTags = () => {
    EntityService.getHashTags().then(
      (data) => {
        if (data.response_code == 200) {
          this.setState({
            listed_tags: data.result.hashtags
          });
        }
      });
  }

  tagSelected = (index) => {
    let tag = this.state.listed_tags[index];
    this.setState({ selected_tags: [...this.state.selected_tags, tag.id] });
  }

  removeTag = (index) => {
    let selected_tags = [...this.state.selected_tags];
    selected_tags.splice(index, 1);
    this.setState({ selected_tags, is_dirty: true });
  }

  togglePaidBy = (paid_by) => {
    this.setState({
      paid_by: (paid_by == "self") ? "self" : "sponsered"
    });
  }

  verifySponserOTP = () => {

    if (this.state.sponser_otp == "") {
      this.setState({
        sponser_error: "Please enter valid OTP"
      });
      return true;
    }

    this.setState({
      sponser_error: false
    });

    EntityService.verfifySponser(this.state.sponser_otp).then(
      (data) => {
        if (data.response_code == 200) {
          if (this.state.hierarchy > data.result.hierarchy) {
            this.setState({
              loading: false,
              sponser_error: "You cannot downgrade license."
            });
          } else {
            this.setState({
              showSponser: true,
              sponser_detail: data.result,
              sponser_license_id: data.result.id
            });
          }
          toast.success(data.message);
        } else {
          this.setState({
            sponser_error: data.reason.otp
          });
          toast.error(data.message);
        }
      });

  }

  handleOnChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value, is_dirty: true });
    if(name == "accounting_mode"){
      this.setState({is_service_description_voucher:0});
    }
  }

  getCompanyType = () => {
    EntityService.getCompanyType().then(
      (data) => {
        if (data.response_code == 200) {
          this.setState({
            company_types: data.result.company_types
          });
        }
      });
  }

  getAvailableLicense = () => {
    EntityService.getAvailableLicense().then(
      (data) => {
        if (data.response_code == 200) {
          this.setState({
            available_licenses: data.result.licenses
          });
          if (this.ACCOUNT_TYPE == 1 && this.IS_PROMOTIONAL == 1 && (!!!this.state.entity_id || this.state.entity_type == "invite")) {
            let lic = data.result.licenses.find(lic => lic.is_trial == 1);
            if (!!lic) {
              this.setState({
                account_license_id: lic.id
              })
            }
          }
        }
      });
  }

  onCountryChange = (e) => {
    let country_id = e.target.value;
    if (country_id) {
      this.setState({
        country_id: country_id,
        city_id: "", is_dirty: true
      });
      this.getStates(country_id);
    }
  }

  onStateChange = (e) => {
    let state_id = e.target.value;
    if (state_id) {
      this.setState({
        state_id: state_id,
        city_id: "", is_dirty: true
      });
      this.getCities(state_id);
    }
  }

  onCityChange = (e) => {
    let city_id = e.target.value;
    if (city_id) {
      this.setState({
        city_id: city_id, is_dirty: true
      });
    }
  }

  getCountries = () => {
    MasterService.getCountries().then(
      (data) => {
        if (data.response_code == 200) {
          this.setState({
            countries: data.result.countries
          });
        }
      });
  }
  getStates = (country_id) => {
    MasterService.getStates(country_id).then(
      (data) => {
        if (data.response_code == 200) {
          this.setState({
            states: data.result.states
          });
        }
      });
  }
  getCities = (state_id) => {
    MasterService.getCities(state_id).then(
      (data) => {
        if (data.response_code == 200) {
          this.setState({
            cities: data.result.cities
          });
        }
      });
  }

  componentDidMount() {
    this.getCountries();
    this.getCompanyType();
    if (this.props.account_permissions.includes(19)) {
      this.getAvailableLicense();
    }

    if (!!this.state.country_id) {
      this.getStates(this.state.country_id);
    }

    if (!!this.state.entity_id) {
      this.getEntityDetails();
    }

    if (this.ACCOUNT_TYPE == 2) {
      this.getHashTags();
    }
    window.scroll(0, 400)
    // this.myRef.current.scrollIntoView();
  }

  addFile = (file, uploadtype) => {
    if (uploadtype == "digital_signature")
      this.setState({
        is_dirty: true,
        files: file.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      });
    else
      this.setState({
        is_dirty: true,
        company_logo_files: file.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      });
  };

  removeFile = (uploadtype) => {
    if (uploadtype == "digital_signature")
      this.setState({
        is_dirty: true,
        files: [],
        digital_signature: "",
      });
    else
      this.setState({
        is_dirty: true,
        company_logo_files: [],
        logo: "",
      });

  }

  restoreEntity = async (e) => {
    if (await confirm({
      confirmation: 'Do you want to Restore entity?'
    })) {
      this.SaveEntity(1);
    }
  }

  SaveEntity = async (save_type) => {
    //e.preventDefault();
    this.props.dispatch(setAppilcationLoder(true));

    let otp = null;
    let paid_type = 1;
    let account_license_id = !!parseInt(this.state.account_license_id) ? parseInt(this.state.account_license_id) : null;
    let entity_list_length = this.props.entity_list.length;

    if (this.state.paid_by == "sponsered" && !!this.state.sponser_license_id) {
      otp = this.state.sponser_otp;
      paid_type = 2;
      account_license_id = this.state.sponser_license_id;
    }

    let data = {
      "name": this.state.entity_name,
      "address": this.state.address,
      "city_id": !!this.state.city_id ? this.state.city_id : null,
      "state_id": !!this.state.state_id ? this.state.state_id : null,
      "country_id": !!this.state.country_id ? this.state.country_id : null,
      "company_type_id": !!this.state.company_type_id ? parseInt(this.state.company_type_id) : null,
      "pincode": this.state.pincode,
      "gst_no": this.state.gst_no,
      "pan_no": this.state.pan_no,
      "account_license_id": parseInt(account_license_id),
      "otp": !!otp ? otp : null,
      "paid_type": paid_type,
      "logo": "",
      "digital_signature": "",
      "tan_no": this.state.tan_no == null ? "" : this.state.tan_no,
      "ledger_recommendation": parseInt(this.state.ledger_recommendation) || 0,
      "email_snapshot": this.state.show_email_snapshot_config ? 1 : 0,
      "receiver_entity_email": this.state.receiver_entity_email,
      "email_snapshot_acknowledgement": this.state.email_snapshot_acknowledgement ? 1 : 0,
      "email_snapshot_notification": this.state.email_snapshot_notification ? 1 : 0,
      "forward_snapshot_email": this.state.forward_snapshot_email ? 1 : 0,
      "gst_username": this.state.gst_username,
      "is_buktec_narration": this.state.is_buktec_narration || 1,
      "accounting_mode": parseInt(this.state.accounting_mode) || 1,
      "is_common_item_ledger": this.state.is_common_item_ledger == null ? 1 : parseInt(this.state.is_common_item_ledger),
      "amount_round_off": parseInt(this.state.amount_round_off) || 0,
      "is_service_description_voucher" : parseInt(this.state.is_service_description_voucher) || 0,
    }


    let errors = validateCreateEntity(data);
    if (Object.keys(errors).length === 0) {

      if (this.state.files.length && !!this.state.files[0].name) {
        const file = this.state.files[0];
        const file_name = file.name;
        const file_type = file.type;
        const signed_url_data = await MasterService.getSignedUploadFile(file, file_name, file_type, PROFILE_MANAEGEMT_FILE_CODE)
        if (signed_url_data) {
          data.digital_signature = signed_url_data.gcp_subpath;
        }
      }

      if (this.state.company_logo_files.length && !!this.state.company_logo_files[0].name) {
        const company_logo_files = this.state.company_logo_files[0];
        const file_name = company_logo_files.name;
        const file_type = company_logo_files.type;
        const signed_url_data = await MasterService.getSignedUploadFile(company_logo_files, file_name, file_type, PROFILE_MANAEGEMT_FILE_CODE)
        if (signed_url_data) {
          data.logo = signed_url_data.gcp_subpath;
        }
        // Ram -> if user doesn't upload image with valid extension
        else {
          toast.error("Please provide valid extensions for image")
        }
      }

      this.props.dispatch(setAppilcationLoder(false));
      if (!!!data.account_license_id) {
        let allow = await confirm({
          confirmation: 'License not attached. Do you want to save entity detail without license ?'
        });
        if (!allow) {
          if (this.state.save_and_redirect) {
            this.setState({
              loading: false, errors: {}, is_dirty: false
            }, () => { this.props.history.push(this.state.next_route) });

          } else {
            this.setState({ save_and_redirect: false, nextRoute: false, closed: false });
          }
          return true;
        } else {
          this.props.dispatch(setAppilcationLoder(true));
        }
      }
      if (this.state.entity_id) {
        data.id = parseInt(this.state.entity_id);
        /*
          remove license alert 
        */
        // if(entityBeforeEdit.paid_type != data.paid_type){
        //   if(entityBeforeEdit.account_license_id != data.account_license_id){            
        //       let allow = await confirm({
        //         confirmation: 'Do you want to delete this entity ? it will delete after 15 days grace period. If you want to restore it you can do within 15 days.'
        //       });
        //       if (!is_delete) {
        //         return true;
        //       }
        //   }
        // }
        //console.log(data);
        //return;
        if (save_type == 1) {
          this.props.dispatch(setAppilcationLoder(true));
          EntityService.updateEntity(data).then(
            (data) => {
              this.props.dispatch(setAppilcationLoder(false));
              this.setState({
                loading: false, errors: {}, is_dirty: false
              });
              if (data.response_code == 200) {
                // update entity in listing
                let entity = {
                  end_date: data.result.end_date,
                  id: data.result.id,
                  name: this.state.entity_name,
                  paid_by: data.result.paid_by,
                  paid_by_other: data.result.paid_by_other,
                  plan_name: data.result.plan_name,
                  status: data.result.status,
                  type: !!this.state.company_type_id ? this.state.company_types.find(type => type.id == this.state.company_type_id).name : ""
                };
                //console.log(this.state.company_type_id)
                //console.log(this.state.company_types.find(type => type.id == this.state.company_type_id).name)
                console.log(entity)
                this.props.updateEntityInList(entity);

                // Restore entity
                this.props.restoreEntityInList(entity);

                if (this.state.paid_by == "self") {
                  this.setState({
                    sponser_detail: {},
                    showSponser: false,
                    sponser_otp: ""
                  });
                } else if (this.state.paid_by == "sponsered" && !!this.state.sponser_license_id) {
                  this.setState({
                    account_license_id: 0
                  });
                }

                this.setState({
                  hierarchy: data.result.hierarchy,
                  is_deleted: 0,
                  sponser_otp: ""
                })
                toast.success(data.message);

                const entity_data = JSON.parse(localStorage.getItem('entity'));
                let entity_id = entity_data ? entity_data.id : null

                if (data.result.id == entity_id) {
                  let entity_config_param = this.props.entity_config_param;
                  entity_config_param.ledger_recommendation = parseInt(this.state.ledger_recommendation);
                  entity_config_param.accounting_mode = parseInt(this.state.accounting_mode);
                  entity_config_param.is_common_item_ledger = parseInt(this.state.is_common_item_ledger);
                  entity_config_param.amount_round_off = parseInt(this.state.amount_round_off);
                  entity_config_param.is_service_description_voucher = parseInt(this.state.is_service_description_voucher)
                  this.props.dispatch(setEntityConfigParam(entity_config_param));
                }

                if (this.state.save_and_redirect) {
                  this.props.history.push(this.state.next_route)
                }

                //set default entity and permission license wise
                if (!!account_license_id && entity_list_length == 0) {
                  window.location.reload();
                }

              } else if (data.response_code == 400) {
                toast.error(data.message);
                this.setState({ errors: data.reason, save_and_redirect: false, next_route: false });
              } else {
                toast.error(data.message);
                this.setState({ save_and_redirect: false, next_route: false });
              }
            });
        } else {
          delete data["otp"];
          this.props.dispatch(setAppilcationLoder(true));
          EntityService.AcceptEntityInvite(data).then(
            (data) => {
              this.props.dispatch(setAppilcationLoder(false));
              this.setState({
                loading: false, errors: {}, is_dirty: false
              });
              if (data.response_code == 200) {
                this.props.acceptPendingEntity(this.state.entity_id);
                // update entity in listing
                let entity = {
                  end_date: data.result.end_date,
                  id: data.result.id,
                  name: this.state.entity_name,
                  paid_by: data.result.paid_by,
                  paid_by_other: "",
                  plan_name: data.result.plan_name,
                  status: 3,//data.result.status,
                  users: [SessionManagement.getUserDetail().full_name]
                };

                this.props.addEntityInList(entity);
                if (this.state.paid_by == "self") {
                  this.setState({
                    sponser_detail: {},
                    showSponser: false,
                    sponser_otp: ""
                  });
                }
                this.setState({
                  hierarchy: data.result.hierarchy,
                  sponser_otp: ""
                })

                toast.success(data.message);

                if (this.state.save_and_redirect) {
                  this.props.history.push(this.state.next_route)
                }

                //set default entity and permission license wise
                if (!!account_license_id && entity_list_length == 0) {
                  window.location.reload();
                }

                this.props.history.push({
                  pathname: `/profile-management/entities/create/` + data.result.id
                });

              } else if (data.response_code == 400) {
                toast.error(data.message);
                this.setState({ errors: data.reason, save_and_redirect: false, next_route: false });
              } else {
                toast.error(data.message);
                this.setState({ save_and_redirect: false, next_route: false });
              }
            });
        }
      }
      else {
        EntityService.createEntity(data).then(
          (data) => {
            this.setState({
              loading: false, errors: {}, is_dirty: false
            });
            if (data.response_code == 200) {
              // update entity in listing
              let entity = {
                end_date: data.result.end_date,
                id: data.result.id,
                name: this.state.entity_name,
                paid_by: data.result.paid_by,
                paid_by_other: data.result.paid_by_other,
                plan_name: data.result.plan_name,
                status: data.result.status,
                users: [SessionManagement.getUserDetail().full_name],
                to_email: data.result.generated_entity_email || "",
              };

              this.props.addEntityInList(entity);
              toast.success(data.message);
              if (this.state.paid_by == "self") {
                this.setState({
                  sponser_detail: {},
                  showSponser: false,
                  sponser_otp: ""
                });
              }

              this.setState({
                entity_id: data.result.id,
                hierarchy: data.result.hierarchy,
                sponser_otp: "",
                to_email: data.result.generated_entity_email || "",
              })

              if (this.state.save_and_redirect) {
                this.props.history.push(this.state.next_route)
              }

              this.props.history.push({
                pathname: `/profile-management/entities/create/` + this.state.entity_id
              });

              //set default entity and permission license wise
              if (!!account_license_id && entity_list_length == 0) {
                window.location.reload();
              }

              window.scroll(0, 400);
            } else if (data.response_code == 400) {
              toast.error(data.message);
              this.setState({ errors: data.reason, save_and_redirect: false, next_route: false });
            } else {
              toast.error(data.message);
              this.setState({ save_and_redirect: false, next_route: false });
            }
          });
      }
      this.props.dispatch(setAppilcationLoder(false));
    } else {
      this.props.dispatch(setAppilcationLoder(false));
      this.setState({
        loading: false,
        errors: errors,
        next_route: false,
        save_and_redirect: false
      });
      toast.error("Please fill mandatory fields")
    }
  }

  SaveCAEntity = async (e) => {
    //e.preventDefault();
    this.props.dispatch(setAppilcationLoder(true));
    let paid_type = 1;
    let account_license_id = !!parseInt(this.state.account_license_id) ? parseInt(this.state.account_license_id) : null;
    if (this.state.paid_by == "sponsered" && !!this.state.sponser_license_id && this.state.sponser_license_id != 0) {
      paid_type = 2;
      account_license_id = this.state.sponser_license_id;
    }

    let data = {
      "name": this.state.entity_name,
      "address": this.state.address,
      "city_id": !!this.state.city_id ? this.state.city_id : null,
      "state_id": !!this.state.state_id ? this.state.state_id : null,
      "country_id": !!this.state.country_id ? this.state.country_id : null,
      "company_type_id": !!this.state.company_type_id ? parseInt(this.state.company_type_id) : null,
      "pincode": this.state.pincode,
      "gst_no": this.state.gst_no,
      "pan_no": this.state.pan_no,
      "account_license_id": parseInt(account_license_id),
      "paid_type": paid_type,
      "logo": "",
      "digital_signature": "",
      "tan_no": this.state.tan_no == null ? "" : this.state.tan_no,
      "hashtags": this.state.selected_tags,
      "new_hashtags": this.state.new_hashtags,
      "gst_username": this.state.gst_username
    }


    if (this.state.entity_id && this.state.entity_type != "invite") {
      data.email_snapshot = this.state.show_email_snapshot_config ? 1 : 0;
      data.receiver_entity_email = this.state.receiver_entity_email;
      data.email_snapshot_acknowledgement = this.state.email_snapshot_acknowledgement ? 1 : 0;
      data.email_snapshot_notification = this.state.email_snapshot_notification ? 1 : 0;
      data.forward_snapshot_email = this.state.forward_snapshot_email ? 1 : 0;
      data.ledger_recommendation = parseInt(this.state.ledger_recommendation);
      data.is_buktec_narration = parseInt(this.state.is_buktec_narration) || 1
      data.accounting_mode = parseInt(this.state.accounting_mode) || 1;
      data.is_common_item_ledger = parseInt(this.state.is_common_item_ledger)
      data.amount_round_off = parseInt(this.state.amount_round_off);
      data.is_service_description_voucher = parseInt(this.state.is_service_description_voucher) || 0;
    }

    if (this.state.entity_type != "edit" || !!!this.state.entity_id) {
      data.email = this.state.invite_email;
    }
    let errors = validateCACreateEntity(data, this.state.entity_type, this.state.entity_id);
    if (Object.keys(errors).length === 0) {


      if (this.state.files.length && !!this.state.files[0].name) {
        const file = this.state.files[0];
        const file_name = file.name;
        const file_type = file.type;
        const signed_url_data = await MasterService.getSignedUploadFile(file, file_name, file_type, PROFILE_MANAEGEMT_FILE_CODE)
        if (signed_url_data) {
          data.digital_signature = signed_url_data.gcp_subpath;
        }
      }

      if (this.state.company_logo_files.length && !!this.state.company_logo_files[0].name) {
        const company_logo_files = this.state.company_logo_files[0];
        const file_name = company_logo_files.name;
        const file_type = company_logo_files.type;
        const signed_url_data = await MasterService.getSignedUploadFile(company_logo_files, file_name, file_type, PROFILE_MANAEGEMT_FILE_CODE)
        if (signed_url_data) {
          data.logo = signed_url_data.gcp_subpath;
        }
      }

      if (this.state.entity_id) {
        // delete data['email'];
        data.id = parseInt(this.state.entity_id);
        EntityService.updateEntity(data, this.state.entity_type).then(
          (data) => {
            this.props.dispatch(setAppilcationLoder(false));
            this.setState({
              loading: false, errors: {}, is_dirty: false
            });
            if (data.response_code == 200) {
              toast.success(data.message);
              // Ram ------> changed below line from entity_type to this.state.entity_type
              if (this.state.entity_type == "edit") {
                let entity = {
                  end_date: data.result.end_date,
                  id: parseInt(this.state.entity_id),
                  name: this.state.entity_name,
                  paid_by: data.result.paid_by,
                  paid_by_other: data.result.paid_by_other,
                  plan_name: data.result.plan_name,
                  status: data.result.status,
                  type: !!this.state.company_type_id ? this.state.company_types.find(type => type.id == this.state.company_type_id).name : "",
                };
                this.props.updateEntityInList(entity);
              }

              let new_hashtags = data.result.new_hashtags;
              let selected_tags = [...this.state.selected_tags, ...new_hashtags.map(tag => tag.id)];
              let listed_tags = [...this.state.listed_tags, ...new_hashtags];
              this.setState({
                // entity_id: data.result.id, 
                is_dirty: false,
                // entity_type: "invite",
                selected_tags: selected_tags,
                listed_tags: listed_tags,
                new_hashtags: [],
                hierarchy: data.result.hierarchy
              });
              let entity = {
                end_date: !!data.result.license_expiry ? data.result.license_expiry : "N/A",
                id: this.state.entity_id,
                name: this.state.entity_name,
                paid_by: !!data.result.paid_by ? data.result.paid_by : "N/A",
                plan_name: !!data.result.plan_name ? data.result.plan_name : "N/A",
                status: 1,
                type: !!this.state.company_type_id ? this.state.company_types.find(type => type.id == this.state.company_type_id).name : "",
              }
              this.props.updateInvitedEntity(entity);

            } else if (data.response_code == 400) {
              toast.error(data.message);
              this.setState({ errors: data.reason });
            } else {
              toast.error(data.message);
            }
          });
      }
      else {
        EntityService.createEntity(data).then(
          (data) => {
            this.props.dispatch(setAppilcationLoder(false));
            this.setState({
              loading: false, errors: {}
            });
            if (data.response_code == 200) {
              toast.success(data.message);
              let new_hashtags = data.result.new_hashtags;
              let selected_tags = [...this.state.selected_tags, ...new_hashtags.map(tag => tag.id)];
              let listed_tags = [...this.state.listed_tags, ...new_hashtags];
              this.setState({
                entity_id: data.result.id, is_dirty: false,
                entity_type: "invite",
                selected_tags: selected_tags,
                listed_tags: listed_tags,
                new_hashtags: [],
                hierarchy: data.result.hierarchy
              });

              let entity = {
                end_date: !!data.result.license_expiry ? data.result.license_expiry : "N/A",
                id: this.state.entity_id,
                name: this.state.entity_name,
                paid_by: !!data.result.paid_by ? data.result.paid_by : "N/A",
                plan_name: !!data.result.plan_name ? data.result.plan_name : "N/A",
                status: 1,
                type: !!this.state.company_type_id ? this.state.company_types.find(type => type.id == this.state.company_type_id).name : "",
              }
              this.props.addPendingEntity(entity);


              this.props.history.push({
                pathname: `/profile-management/pending/` + data.result.id
              });
              setTimeout(() => {
                window.scroll(0, document.body.scrollHeight);
              }, 500)
            } else if (data.response_code == 400) {
              toast.error(data.message);
              this.setState({ errors: data.reason });
            } else {
              toast.error(data.message);
            }
          });
      }
    } else {
      this.props.dispatch(setAppilcationLoder(false));
      this.setState({
        loading: false,
        errors: errors
      });
      toast.error("Wrong input provided");
    }
  }

  resendInvite = () => {
    let data = {
      "id": this.state.entity_id,
      "event": "entity_invite"
    }
    EntityService.resendEntityInvite(data).then(
      (data) => {
        this.setState({
          loading: false, errors: {}
        });
        if (data.response_code == 200) {
          this.props.resendInvite(this.state.entity_id);
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
      });
  }

  // #Get Entity details @Nehal K. (02/05/2022)  
  getEntityDetails = () => {
    this.props.dispatch(setAppilcationLoder(true));
    EntityService.getEntityDetail(this.state.entity_id, this.state.entity_type).then(
      async (data) => {
        if (data.response_code == 200) {
          this.setState({
            entityBeforeEdit: data.result,
            account_code: data.result.account_code,
            address: data.result.address,
            entity_name: data.result.name,
            company_type_id: !!data.result.company_type_id ? data.result.company_type_id : "",
            gst_no: data.result.gst_no,
            pan_no: data.result.pan_no,
            gst_username: data.result.gst_username,
            tan_no: data.result.tan_no,
            country_id: data.result.country_id ? data.result.country_id : "101",
            state_id: data.result.state_id ? data.result.state_id : "",
            city_id: data.result.city_id ? data.result.city_id : "",
            pincode: data.result.pincode,
            account_license_id: (data.result.paid_type == 1 && !!data.result.account_license_id) ? data.result.account_license_id : this.state.account_license_id,
            sponser_license_id: (data.result.paid_type == 2 && !!data.result.account_license_id) ? data.result.account_license_id : 0,
            license_name: data.result.license_name,
            hierarchy: data.result.hierarchy,
            company_logo_files: data.result.qualified_url_logo ? [{ preview: StorageData.getQualifiedFileUrl(data.result.qualified_url_logo) }] : [],
            files: data.result.qualified_url_sign ? [{ preview: StorageData.getQualifiedFileUrl(data.result.qualified_url_sign) }] : [],
            digital_signature: data.result.digital_signature,
            logo: data.result.logo,
            paid_by: data.result.paid_type == 2 ? 'sponsered' : 'self',
            users: data.result.users ? data.result.users : [],
            invite_email: data.result.email ? data.result.email : "",
            plan_name: data.result.plan_name,
            end_date: data.result.end_date,
            is_deleted: data.result.is_deleted,
            selected_tags: !!data.result.hashtags ? data.result.hashtags.map(tag => tag.id) : [],
            to_email: data.result.generated_entity_email || "",
            show_email_snapshot_config: data.result.email_snapshot || true,
            receiver_entity_email: data.result.receiver_entity_email || "",
            email_snapshot_acknowledgement: !!data.result.email_snapshot_acknowledgement,
            email_snapshot_notification: !!data.result.email_snapshot_notification,
            forward_snapshot_email: !!data.result.forward_snapshot_email,
            ledger_recommendation: data.result.ledger_recommendation,
            is_buktec_narration: data.result.is_buktec_narration,
            accounting_mode: data.result.accounting_mode,
            is_common_item_ledger: data.result.is_common_item_ledger,
            amount_round_off : data.result.amount_round_off,
            is_service_description_voucher : data.result.is_service_description_voucher
          });
          if (!!data.result.state_id) {
            this.getStates(data.result.country_id)
          }
          if (!!data.result.city_id) {
            this.getCities(data.result.state_id)
          }
          if (data.result.paid_type == 2) {
            let sponser_detail = {
              "account_code": data.result.account_code,
              "end_date": data.result.end_date,
              "full_name": data.result.full_name,
              "id": data.result.account_license_id,
              "plan_name": data.result.plan_name
            };
            this.setState({
              sponser_detail,
              showSponser: true
            });
          } else {
            if (!!data.result.account_license_id) {
              let old_licences = [{
                "end_date": data.result.end_date,
                "id": data.result.account_license_id,
                "name": data.result.plan_name,
                "start_date": data.result.end_date
              }];
              this.setState({
                old_licences
              });
            }

          }

          this.props.dispatch(setAppilcationLoder(false));
          if (!!data.result.is_deleted) {
            await confirm({
              confirmation: "This entity will delete in 15 days. You can restore it by clicking restore button.",
              options: { type: "alert" }
            })
          }

          if (this.ACCOUNT_TYPE == 1 && this.state.entity_type != "edit") {
            await confirm({
              confirmation: "Kindly fill in required information and click on 'accept & save' to make the entity active.",
              options: { type: "alert" }
            })
          }
        } else if (data.response_code == 404) {
          toast.error("Entity not found");
          this.props.history.push({
            pathname: `/profile-management/entities/`
          });
        } else {
          toast.error(data.message);
        }
        this.props.dispatch(setAppilcationLoder(false));
      });
  }

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    this.state.files.forEach(file => URL.revokeObjectURL(file.preview));
    this.state.company_logo_files.forEach(file => URL.revokeObjectURL(file.preview));
  }

  saveBeforeLeave = (nextRoute) => {
    this.setState({ save_and_redirect: true, next_route: nextRoute }, function () {
      if (this.ACCOUNT_TYPE == 1) {
        let save_type = this.state.entity_type == "invite" ? 2 : 1
        this.SaveEntity(save_type)
      } else {
        this.SaveCAEntity()
      }
    });
  }

  closeAction = () => {
    this.setState({ closed: false })
  }

  removeFromEntity = () => {

  }

  resetForm = () => {
    /* empty from */
  }

  checkNewLicenseAttached = () => {

  }

  // Intro js user guid   
  toggleSteps = () => {
    this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }));
  };
  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };
  // End Intro js user guid

  checkValidGSTNo = (gst_no) => {
    return (!!gst_no && /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(gst_no));
  }

  render() {
    // Intro js user guid 
    const {
      stepsEnabled,
      steps,
      initialStep,
      hintsEnabled,
      hints
    } = this.state;
    // End Intro js user guid 

    const MANAGE_ENTITY_PERMISSION = this.props.account_permissions.includes(19) && !!!this.state.is_deleted;
    this.MANAGE_ENTITY = this.props.account_permissions.includes(19);
    console.log(this.state.is_common_item_ledger, this.state.show_email_snapshot_config)
    console.log("amount round off",this.state.amount_round_off)
    console.log("service dedcription",this.state.is_service_description_voucher)

    return (
      <>
        {/* Intro js user guid */}
        <Steps
          enabled={stepsEnabled}
          steps={steps}
          initialStep={initialStep}
          onExit={this.onExit}
        />
        {/* End Intro js user guid */}

        <div className="form-inner-blue-new mb-0" ref={this.myRef}>
          {(this.state.entity_type == "invite") ?
            <Link to="/profile-management/pending" onClick={() => this.setState({ closed: true })}>
              <span class="ss-cross"><span class="icon-Cross-with-grey-circle"><span class="path1"></span><span class="path2 cpointer"></span><span class="path3"></span></span></span>
            </Link>
            :
            <Link to="/profile-management/entities" onClick={() => this.setState({ closed: true })}>
              <span class="ss-cross"><span class="icon-Cross-with-grey-circle"><span class="path1"></span><span class="path2 cpointer"></span><span class="path3"></span></span></span>
            </Link>
          }

          <RouteLeavingGuard
            when={this.state.is_dirty}
            saveChanges={this.saveBeforeLeave}
            closed={this.state.closed}
            closeAction={this.closeAction}
            resetForm={this.resetForm}
          />

          <DeletePopUp showModal={this.state.show_delete_popup} deleteLoading={this.state.deleteLoading} toggleDeletePopUp={this.toggleDeletePopup} deleteItem={this.deleteCAEntity} />

          {/* General */}
          <div className="row">
            <div className="col-xl-3">
              <h3 className="pm-form-title">General</h3>
            </div>
            <div className="col-xl-9">
              <div className="row">
                <div className="col-xl-4 col-lg-3 col-md-3">
                  <div className="form-group">
                    <lable className="lable-input">Entity Name<span className="red-star">*</span></lable>
                    <input type="text"
                      className={"form-control " + (this.state.errors.entity_name ? " wrong-red " : "")}
                      value={this.state.entity_name}
                      onChange={this.handleOnChange}
                      disabled={!MANAGE_ENTITY_PERMISSION}
                      autoComplete="off"
                      autoFocus={true}
                      name="entity_name" />
                    {this.state.errors.entity_name && <small className="error_right">{this.state.errors.entity_name}</small>}
                    {this.state.errors.name && <small className="error_right">{this.state.errors.name}</small>}
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3">
                  <div className="form-group">
                    <lable className="lable-input">Type</lable>
                    <select className="form-control"
                      onChange={this.handleOnChange}
                      disabled={!MANAGE_ENTITY_PERMISSION}
                      value={this.state.company_type_id}
                      autoComplete="off"
                      name="company_type_id" >
                      <option value="">Select Type</option>
                      {
                        this.state.company_types.map((type, i) => {
                          return <option key={i} value={type.id}>{type.name}</option>
                        })
                      }
                    </select>
                    {this.state.errors.company_type_id && <small className="error_right">{this.state.errors.company_type_id}</small>}
                  </div>
                </div>
                <div className="col-xl-5 col-lg-6 col-md-6">
                  <div className="form-group">
                    <lable className="lable-input">Address</lable>
                    <input type="text"
                      className={"form-control " + (this.state.errors.address ? " wrong-red " : "")}
                      value={this.state.address}
                      onChange={this.handleOnChange}
                      disabled={!MANAGE_ENTITY_PERMISSION}
                      autoComplete="off"
                      name="address" />
                    {this.state.errors.address && <small className="error_right">{this.state.errors.address}</small>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-3">
                  <div className="form-group">
                    <lable className="lable-input">Country<span className="red-star">*</span></lable>
                    <select className="form-control"
                      value={this.state.country_id}
                      onChange={this.onCountryChange}
                      disabled={!MANAGE_ENTITY_PERMISSION || true} >
                      <option value="">Select Country</option>
                      {
                        this.state.countries.map((country, i) => {
                          return <option key={i} value={country.id}>{country.name}</option>
                        })
                      }
                    </select>
                    {this.state.errors.country_id && <small className="error_right">{this.state.errors.country_id}</small>}
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3">
                  <div className="form-group">
                    <lable className="lable-input">State<span className="red-star">*</span></lable>
                    <select
                      className={"form-control " + (this.state.errors.state_id ? " wrong-red " : "")}
                      value={this.state.state_id}
                      onChange={this.onStateChange}
                      disabled={!MANAGE_ENTITY_PERMISSION} >
                      <option value="">Select State</option>
                      {
                        this.state.states.map((state, i) => {
                          return <option key={i} value={state.id}>{state.name}</option>
                        })
                      }
                    </select>
                    {this.state.errors.state_id && <small className="error_right">{this.state.errors.state_id}</small>}
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3">
                  <div className="form-group">
                    <lable className="lable-input">City</lable>
                    <select className="form-control"
                      value={this.state.city_id}
                      onChange={this.onCityChange}
                      disabled={!MANAGE_ENTITY_PERMISSION} >
                      <option value="">Select City</option>
                      {
                        this.state.cities.map((city, i) => {
                          return <option key={i} value={city.id}>{city.name}</option>
                        })
                      }
                    </select>
                    {this.state.errors.city_id && <small className="error_right">{this.state.errors.city_id}</small>}
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3">
                  <div className="form-group">
                    <lable className="lable-input">Pincode</lable>
                    <NumberTypeInputBox type="text"
                      className={"form-control " + (this.state.errors.pincode ? " wrong-red " : "")}
                      value={this.state.pincode}
                      onChange={this.handleOnChange}
                      disabled={!MANAGE_ENTITY_PERMISSION}
                      autoComplete="off"
                      maxLength={6}
                      name="pincode" />
                    {this.state.errors.pincode && <small className="error_right">{this.state.errors.pincode}</small>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-12">
              <hr className="pm-hr"></hr>
            </div>
          </div>
          {/* End General */}


          {/* Taxation Details */}
          <div className="row">
            <div className="col-xl-3">
              <h3 className="pm-form-title">Taxation Details</h3>
            </div>
            <div className="col-xl-9">
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-3">
                  <div className="form-group">
                    <lable className="lable-input">PAN Number</lable>
                    <input type="text"
                      className={"form-control  " + (this.state.errors.pan_no ? " wrong-red " : "")}
                      value={this.state.pan_no}
                      onChange={this.handleOnChange}
                      //disabled={!MANAGE_ENTITY_PERMISSION}
                      disabled={this.checkValidGSTNo(this.state.gst_no)}
                      name="pan_no" />
                    {this.state.errors.pan_no && <small className="error_right">{this.state.errors.pan_no}</small>}
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3">
                  <div className="form-group">
                    <lable className="lable-input">GST Number</lable>
                    <input type="text"
                      className={"form-control  " + (this.state.errors.gst_no ? " wrong-red " : "")}
                      value={this.state.gst_no}
                      onChange={(e) => {
                        let gst_no = e.target.value;
                        if (!!gst_no) {
                          if (/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(gst_no)) {
                            this.setState({ "pan_no": gst_no.substring(2, 12) })
                          } else {
                            this.setState({ "pan_no": "" })
                          }
                        } else {
                          this.setState({ "pan_no": "" })
                        }
                        this.handleOnChange(e)
                      }}
                      disabled={!MANAGE_ENTITY_PERMISSION}
                      name="gst_no" />
                    {this.state.errors.gst_no && <small className="error_right">{this.state.errors.gst_no}</small>}
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3">
                  <div className="form-group">
                    <lable className="lable-input">TAN Number</lable>
                    <input type="text"
                      className={"form-control  " + (this.state.errors.tan_no ? " wrong-red " : "")}
                      value={this.state.tan_no}
                      onChange={this.handleOnChange}
                      disabled={!MANAGE_ENTITY_PERMISSION}
                      name="tan_no" />
                    {this.state.errors.tan_no && <small className="error_right">{this.state.errors.tan_no}</small>}
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3">
                  <div className="form-group">
                    <lable className="lable-input">GST Username</lable>
                    <input type="text"
                      //className={"form-control  " + (this.state.errors.tan_no ? " wrong-red " : "")}
                      className="form-control"
                      value={this.state.gst_username}
                      onChange={this.handleOnChange}
                      disabled={!MANAGE_ENTITY_PERMISSION}
                      name="gst_username" />
                    {/* {this.state.errors.gst_username && <small className="error_right">{this.state.errors.gst_username}</small>} */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-12">
              <hr className="pm-hr"></hr>
            </div>
          </div>
          {/* End Taxation Details */}


          {/* Paid By  */}
          <div className="row">
            <div className="col-xl-3">
              <h3 className="pm-form-title">Paid By</h3>
            </div>

            {this.ACCOUNT_TYPE == 1 ?
              <>
                {/* Bussiness view */}
                <div className="col-xl-9">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <ButtonGroup className="activein_btn_group" aria-label="Basic example">
                        <Button variant={" apply_btn_new activein_btn_new " + (this.state.paid_by == "self" ? " success " : " deactivein_btn_new ")} onClick={(e) => this.togglePaidBy("self")}>Business (Self)</Button>
                        <Button variant={" apply_btn_new activein_btn_new " + (this.state.paid_by == "sponsered" ? " success " : " deactivein_btn_new ")} onClick={(e) => this.togglePaidBy("sponsered")}>Sponsored</Button>
                      </ButtonGroup>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6 text-right">
                      <Link to="/licenses/buy-licenses" onClick={() => { SessionManagement.setLastUrl(window.location.pathname) }}>
                        {this.props.account_permissions.includes(20) && <Button variant="success apply_btn_new">Buy License</Button>}
                      </Link>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className={"col-xl-4 col-lg-4 col-md-6 " + (this.state.paid_by == "self" ? "" : " d-none ")}>
                      <div className="form-group">
                        <lable className="lable-input">Subscription</lable>
                        <select className="form-control"
                          value={this.state.account_license_id}
                          name="account_license_id"
                          onChange={this.handleOnChange}
                          disabled={!MANAGE_ENTITY_PERMISSION} >
                          {
                            (!!this.state.old_licences.length) ?
                              this.state.old_licences.map((license, i) => {
                                return <option key={i} disabled={this.state.hierarchy > license.hierarchy} value={license.id}>{this.state.license_name + "" + license.name} (expires on : {license.end_date})(Attached)</option>
                              })
                              :
                              <option value="0">Select License</option>
                          }
                          {
                            this.state.available_licenses.map((license, i) => {
                              return <option key={i} disabled={this.state.hierarchy > license.hierarchy} value={license.id}>{license.license_name + "-" + license.name} (expires on : {license.end_date})</option>
                            })
                          }
                        </select>
                      </div>
                    </div>

                    <div className={"col-xl-4 col-lg-4 col-md-6 pr-0 " + (this.state.paid_by == "sponsered" ? "" : " d-none ")}>
                      <div className={"form-group " + (this.state.entity_type == "invite" ? " d-none " : " ")}>
                        <lable className="lable-input">Enter Code</lable>
                        <input type="text"
                          className={"form-control  " + (this.state.sponser_error ? " wrong-red " : "")}
                          value={this.state.sponser_otp}
                          onChange={this.handleOnChange}
                          disabled={!MANAGE_ENTITY_PERMISSION}
                          autoComplete="off"
                          name="sponser_otp" />
                        {this.state.sponser_error && <small className="error_right">{this.state.sponser_error}</small>}
                      </div>

                      {/* <div className={"form-group " + (this.state.showSponser ? "" : " d-none ")}>
                          <p className="lable-input font-colr m-0 text-capitalize">{this.state.sponser_detail && <span>{this.state.sponser_detail.full_name + " (A/C ID " + this.state.sponser_detail.account_code + ")"}</span>}</p>
                          <input type="text"
                            className={"form-control blu-color"}
                            placeholder="Basic ( Monthly Subscription )"
                            value={this.state.sponser_detail ? (this.state.sponser_detail.plan_name + "(" + this.state.sponser_detail.end_date + ")") : ""}
                            readOnly />
                        </div> */}

                      {/* Attached License */}
                      {!!this.state.showSponser &&
                        <div className="mb-3">
                          <lable className="lable-input d-block mb-2">Attached License</lable>
                          <div className="paid-by-cards">
                            <div className="row">
                              <div class="col-12">
                                <p className="pm-pb-lable">Sponsered License Detail</p>
                                <p className="pm-pb-name">{this.state.sponser_detail.plan_name}</p>
                              </div>
                              <div class="col-8 pr-0">
                                <p className="pm-pb-lable">{this.state.sponser_detail.full_name}</p>
                                <p className="pm-pb-lable">CA (A/C ID {this.state.sponser_detail.account_code})</p>
                              </div>
                              <div class="col-4">
                                <div class="pm-pb-edate">
                                  <p className="pm-pb-lable">Expires On</p>
                                  <p className="pm-pb-date">{this.state.sponser_detail.end_date}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                      {/* End Attached License */}
                    </div>


                    <div className={"col-2 " + (this.state.paid_by == "sponsered" && this.state.entity_type == "edit" ? "" : " d-none ")}>
                      <lable className="lable-input d-block">&nbsp;</lable>
                      <Button variant="success apply_btn_new" onClick={this.verifySponserOTP}>Verify</Button>
                    </div>
                  </div>
                </div>
                {/* End Bussiness view */}
              </>
              :
              <>
                {/* CA view */}
                <div className="col-xl-9">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <ButtonGroup className="activein_btn_group" aria-label="Basic example">
                        <Button variant={" apply_btn_new activein_btn_new " + (this.state.paid_by == "self" ? " success " : " deactivein_btn_new ")} onClick={(e) => this.togglePaidBy("self")}>Business</Button>
                        <Button variant={" apply_btn_new activein_btn_new " + (this.state.paid_by == "sponsered" ? " success " : " deactivein_btn_new ")} onClick={(e) => this.togglePaidBy("sponsered")}> Self </Button>
                      </ButtonGroup>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6 text-right">
                      <Link to="/licenses/buy-licenses" onClick={() => { SessionManagement.setLastUrl(window.location.pathname) }}>
                        {this.props.account_permissions.includes(20) && <Button variant="success apply_btn_new">Buy License</Button>}
                      </Link>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className={"col-xl-4 col-lg-4 col-md-6 " + (this.state.paid_by == "self" ? "" : " d-none ")}>
                      <div className="form-group">
                        <lable className="lable-input">Subscription</lable>
                        <select className="form-control"
                          value={this.state.account_license_id}
                          name="account_license_id"
                          disabled={true}
                          onChange={this.handleOnChange}>
                          {
                            (!!this.state.old_licences.length) ?
                              this.state.old_licences.map((license, i) => {
                                return <option key={i} disabled={this.state.hierarchy > license.hierarchy} value={license.id}>{this.state.license_name + "-" + license.name} (expires on : {license.end_date})(Attached)</option>
                              })
                              :
                              <option value="0">Select License</option>
                          }
                        </select>
                      </div>
                    </div>
                    <div className={"col-xl-4 col-lg-4 col-md-6 " + (this.state.paid_by == "sponsered" ? "" : " d-none ")}>
                      <div className="form-group">
                        <lable className="lable-input">Subscription</lable>
                        <select className="form-control"
                          value={this.state.sponser_license_id}
                          name="sponser_license_id"
                          onChange={this.handleOnChange}
                          disabled={!MANAGE_ENTITY_PERMISSION} >
                          {
                            (!!this.state.sponser_detail) ?
                              <option value={this.state.sponser_detail.id}>{this.state.license_name + "-" + this.state.sponser_detail.plan_name} (expires on : {this.state.sponser_detail.end_date})(Attached)</option>
                              :
                              <option value="0">Select License</option>
                          }
                          {
                            this.state.available_licenses.map((license, i) => {
                              return <option key={i} disabled={this.state.hierarchy > license.hierarchy} value={license.id}>{license.license_name + "-" + license.name} (expires on : {license.end_date})</option>
                            })
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End CA view */}
              </>
            }

            <div className="col-xl-12">
              <hr className="pm-hr"></hr>
            </div>
          </div>
          {/* End Paid By */}


          {/* Users Table List */}
          {(!!this.state.entity_id && this.state.entity_type == "edit") &&
            <React.Fragment>
              <div className="row">
                <div className="col-12">
                  <h3 className="pm-form-title">Users</h3>
                </div>
                <div className="col-12 main-table-listing listing-icons mb-2">
                  <div className="table-sec">
                    {this.state.users.length == 0 ?
                      <h2 className="no-item-list mt-3">Users Not Available</h2>
                      :
                      <div className="bs_table_container table-scroll">
                        {/* <Table bordered responsive > */}
                        <Table responsive className="bs_table mt-0" >
                          <thead>
                            <tr>
                              <th>User</th>
                              <th>User Name</th>
                              <th>Role</th>
                              <th className="text-center">Last Login</th>
                              <th className="text-center">Date Created</th>
                              <th width="150px" className="text-center">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              this.state.users.map((user, index) => {
                                return <tr key={index}>
                                  <td>{user.full_name}</td>
                                  <td>{user.username}</td>
                                  <td>{user.role}</td>
                                  <td className="text-center">{user.last_login}</td>
                                  <td className="text-center">{user.created_at}</td>
                                  <td width="100px" className="text-center">
                                    <span className="pl-2">
                                      {!!user.is_active ? <ActiveUser /> : <InactiveUser />}
                                    </span>
                                  </td>
                                </tr>
                              })
                            }
                          </tbody>
                        </Table>
                      </div>
                    }
                  </div>
                </div>
                <div className="col-xl-12">
                  <hr className="pm-hr"></hr>
                </div>
              </div>
            </React.Fragment>
          }
          {/* End Users Table List */}


          {/* Additional Details */}
          <div className="row mb-2">
            <div className="col-xl-3">
              <h3 className="pm-form-title">Additional Details</h3>
            </div>
            <div className="col-xl-9">
              <div className="row">
                <div className="col-lg-3 col-md-5">
                  <div className="profile-upload-width d-flex flex-column align-items-center">
                    <lable className="lable-input d-block mb-2">Digital Signature</lable>
                    <ImageUpload addFile={this.addFile} removeFile={this.removeFile} uploadtype="digital_signature" files={this.state.files} editMode={!MANAGE_ENTITY_PERMISSION} />
                  </div>
                </div>

                <div className="col-lg-3 col-md-5">
                  <div className="profile-upload-width d-flex flex-column align-items-center ">
                    <lable className="lable-input d-block mb-2">Entity Log</lable>
                    <ImageUpload addFile={this.addFile} removeFile={this.removeFile} uploadtype="entity_logo" files={this.state.company_logo_files} editMode={!MANAGE_ENTITY_PERMISSION} />
                  </div>
                </div>
              </div>

              {/* Hashtag */}
              {
                this.ACCOUNT_TYPE == 2 && <React.Fragment>
                  <div className="main-hash-tag mt-2">
                    <div className="form-group mb-0">
                      <lable className="lable-input">Hashtag</lable>
                      <div className="add-tag-new">
                        <ul className="tag-list">
                          {
                            // do not change color
                            this.state.listed_tags.map((tag, index) => {
                              let tag_index = this.state.selected_tags.indexOf(tag.id);
                              return tag_index != -1 ? <li key={index} className="tags">
                                <span>{tag.name}</span>
                                <span class="icon-Cross-with-grey-circle cpointer ml-1" onClick={() => this.removeTag(tag_index)} disabled={!MANAGE_ENTITY_PERMISSION}><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                              </li> : ""
                            })
                          }
                          {
                            // change it to green colour
                            this.state.new_hashtags.map((hashtag, hashtag_index) => {
                              return <li key={hashtag_index} className="tags green-bg">
                                <span>{hashtag}</span>
                                <span class="icon-Cross-with-grey-circle cpointer ml-1" onClick={() => this.removeNewHashtag(hashtag_index)} disabled={!MANAGE_ENTITY_PERMISSION}><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                              </li>
                            })
                          }
                          <li>
                            <form onSubmit={this.addNewHashtag} noValidate>
                              <input type="text" className="form-control mb-2" name="hashtag" value={this.state.hashtag} onChange={this.handleOnChange} disabled={!MANAGE_ENTITY_PERMISSION}
                                placeholder="Add Tag +"
                                style={{ width: this.state.hashtag.length + 'ch', minWidth: "100px" }} 
                                />
                            </form>
                          </li>
                        </ul>
                        {
                          this.state.listed_tags.map((tag, index) => {
                            let tag_index = this.state.selected_tags.indexOf(tag.id);
                            return tag_index == -1 ?
                              <div key={index} onClick={() => this.tagSelected(index)} className="tags ">
                                {tag.name}
                              </div> : ""
                          })
                        }
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              }
              {/* End Hashtag */}
            </div>
          </div>
          {/* End Additional Details */}


          {/* Entity Configuration */}
          <div className={((!this.state.entity_id || this.state.entity_type == "invite") && this.ACCOUNT_TYPE == 2 ? " d-none " : "")}>
            <div className="row">
              <div className="col-xl-12">
                <hr className="pm-hr"></hr>
              </div>
              <div className="col-xl-3">
                <h3 className="pm-form-title">Entity Configuration</h3>
              </div>

              <div className="col-xl-9">
                <div className="row">
                  {/* Snapshot Email Upload Config */}
                  <div className="col-lg-6">
                    <div className="card-forms">
                      <div className="card-form-titlebar">
                        <h4 className="card-form-title">Snapshot Email Upload Config</h4>
                        <div className="toggle-switch-btn toggle-switch-center">
                          <span className="toggle-switch-center">
                            <span className="toggle-btn-sec-text">No</span>
                            <label className="switch">
                              <input type="checkbox"
                                className={"form-control " + (this.state.errors.show_email_snapshot_config ? " wrong-red " : "")}
                                checked={this.state.show_email_snapshot_config}
                                onChange={this.handleOnChange}
                                disabled={!MANAGE_ENTITY_PERMISSION}
                                autoComplete="off"
                                autoFocus={true}
                                name="show_email_snapshot_config" />
                              <span className="slider round"></span>
                            </label>
                            <span className="toggle-btn-sec-text">Yes</span>
                          </span>
                        </div>
                      </div>

                      <div className="form-group">
                        <lable className="lable-input">Entity Email<span className="red-star">*</span></lable>
                        <div className="d-flex">
                          <input type="email"
                            className="form-control"
                            value={this.state.to_email}
                            onChange={this.handleOnChange}
                            disabled={true}
                            autoComplete="off"
                            autoFocus={true}
                            id="to_email"
                            name="to_email" />
                          {!!this.state.to_email && <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Copy</Tooltip>}>
                            <button className="copyfile" onClick={() => { navigator.clipboard.writeText(this.state.to_email) }}>
                              <span class="icon-copy-file white-icon"></span>
                            </button></OverlayTrigger>}
                        </div>
                        {this.state.errors.to_email && <small className="error_right">{this.state.errors.to_email}</small>}
                      </div>

                      <div className="form-group">
                        <lable className="lable-input">Processing Status Email<span className="red-star">*</span></lable>
                        <div className="d-flex align-items-center justify-content-between gap-20">
                          <div className="toggle-switch-btn toggle-switch-center">
                            <span className="toggle-switch-center">
                              <span className="toggle-btn-sec-text">No</span>
                              <label className="switch">
                                <input type="checkbox"
                                  className={"form-control " + (this.state.errors.forward_snapshot_email ? " wrong-red " : "")}
                                  checked={this.state.forward_snapshot_email}
                                  onChange={this.handleOnChange}
                                  disabled={!MANAGE_ENTITY_PERMISSION || !this.state.show_email_snapshot_config}
                                  autoComplete="off"
                                  autoFocus={true}
                                  name="forward_snapshot_email" />
                                <span className={"slider round " + ((!MANAGE_ENTITY_PERMISSION || !this.state.show_email_snapshot_config) ? "disable-any disable-opacity" : "")}></span>
                              </label>
                              <span className="toggle-btn-sec-text">Yes</span>
                              {this.state.errors.forward_snapshot_email && <small className="error_right">{this.state.errors.forward_snapshot_email}</small>}
                            </span>
                          </div>
                          <div className="w-100 ml-auto">
                            <input type="text"
                              className={"form-control " + (this.state.errors.receiver_entity_email ? " wrong-red " : "")}
                              value={this.state.receiver_entity_email}
                              onChange={this.handleOnChange}
                              disabled={!MANAGE_ENTITY_PERMISSION || !this.state.forward_snapshot_email || !this.state.show_email_snapshot_config}
                              autoComplete="off"
                              autoFocus={true}
                              placeholder="Processing Status Email"
                              name="receiver_entity_email" />
                            {this.state.errors.receiver_entity_email && <small className="error_right">{this.state.errors.receiver_entity_email}</small>}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-5">
                          <div className="form-group mb-0">
                            <lable className="lable-input">Enable Notifications<span className="red-star">*</span></lable>
                            <div className="toggle-switch-btn toggle-switch-center">
                              <span className="toggle-switch-center">
                                <span className="toggle-btn-sec-text">No</span>
                                <label className="switch">
                                  <input type="checkbox"
                                    className={"form-control " + (this.state.errors.email_snapshot_notification ? " wrong-red " : "")}
                                    checked={this.state.email_snapshot_notification}
                                    onChange={this.handleOnChange}
                                    disabled={!MANAGE_ENTITY_PERMISSION || !this.state.show_email_snapshot_config}
                                    autoComplete="off"
                                    autoFocus={true}
                                    name="email_snapshot_notification" />
                                  <span className={"slider round " + ((!MANAGE_ENTITY_PERMISSION || !this.state.show_email_snapshot_config) ? "disable-any disable-opacity" : "")}></span>
                                </label>
                                <span className="toggle-btn-sec-text">Yes</span>
                                {this.state.errors.email_snapshot_notification && <small className="error_right">{this.state.errors.email_snapshot_notification}</small>}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7 pl-0">
                          <div className="form-group mb-0">
                            <lable className="lable-input">Send Acknowledgement to Sender<span className="red-star">*</span></lable>
                            <div className="toggle-switch-btn toggle-switch-center">
                              <span className="toggle-switch-center">
                                <span className="toggle-btn-sec-text">No</span>
                                <label className="switch">
                                  <input type="checkbox"
                                    className={"form-control " + (this.state.errors.email_snapshot_acknowledgement ? " wrong-red " : "")}
                                    checked={this.state.email_snapshot_acknowledgement}
                                    onChange={this.handleOnChange}
                                    disabled={!MANAGE_ENTITY_PERMISSION || !this.state.show_email_snapshot_config}
                                    autoComplete="off"
                                    autoFocus={true}
                                    name="email_snapshot_acknowledgement" />
                                  <span className={"slider round " + ((!MANAGE_ENTITY_PERMISSION || !this.state.show_email_snapshot_config) ? "disable-any disable-opacity" : "")}></span>
                                </label>
                                <span className="toggle-btn-sec-text">Yes</span>
                                {this.state.errors.email_snapshot_acknowledgement && <small className="error_right">{this.state.errors.email_snapshot_acknowledgement}</small>}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>  {/* card-forms div */}
                  </div>
                  {/* End Snapshot Email Upload Config */}

                  {/* Miscellaneous */}
                  <div className="col-lg-6">
                    <div className="card-forms">
                      <div className="card-form-titlebar">
                        <h4 className="card-form-title">Miscellaneous</h4>
                      </div>

                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <lable className="lable-input">Ledger Recommendation<span className="red-star">*</span></lable>
                            <select className="form-control"
                              value={this.state.ledger_recommendation}
                              onChange={this.handleOnChange}
                              disabled={!MANAGE_ENTITY_PERMISSION}
                              name="ledger_recommendation" >
                              <option value="0">No</option>
                              <option value="1">Yes</option>
                              <option value="2">Prompt</option>
                            </select>
                            {this.state.errors.ledger_recommendation && <small className="error_right">{this.state.errors.ledger_recommendation}</small>}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <lable className="lable-input">Accounting Mode<span className="red-star">*</span></lable>
                            <select className="form-control"
                              value={this.state.accounting_mode}
                              onChange={(e) => {
                                this.handleOnChange(e);
                                const target = e.target;
                                if (target.value == "1") {
                                  this.setState({ is_common_item_ledger: 1 })
                                }
                              }}
                              disabled={!MANAGE_ENTITY_PERMISSION}
                              name="accounting_mode" >
                              <option value="1">Simple Accounting</option>
                              <option value="2">Inventory Accounting</option>
                            </select>
                            {this.state.errors.accounting_mode && <small className="error_right">{this.state.errors.accounting_mode}</small>}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <lable className="lable-input">Buktec Narration<span className="red-star">*</span></lable>
                            <select className="form-control"
                              value={this.state.is_buktec_narration}
                              onChange={this.handleOnChange}
                              disabled={!MANAGE_ENTITY_PERMISSION}
                              name="is_buktec_narration" >
                              <option value="0">No</option>
                              <option value="1">Yes</option>
                            </select>
                            {this.state.errors.is_buktec_narration && <small className="error_right">{this.state.errors.is_buktec_narration}</small>}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <lable className="lable-input">Service Description in Voucher<span className="red-star">*</span></lable>
                            <select className="form-control"
                              value={this.state.is_service_description_voucher}
                              onChange={this.handleOnChange}
                              disabled={!MANAGE_ENTITY_PERMISSION || this.state.accounting_mode == 2}
                              name="is_service_description_voucher" >
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </select>
                            {this.state.errors.is_service_description_voucher && <small className="error_right">{this.state.errors.is_service_description_voucher}</small>}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group mb-0">
                            <lable className="lable-input">Round Off<span className="red-star">*</span></lable>
                            <select className="form-control"
                              value={this.state.amount_round_off}
                              onChange={this.handleOnChange}
                              disabled={!MANAGE_ENTITY_PERMISSION}
                              name="amount_round_off" >
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                              <option value="2">Round up</option>
                              <option value="3">Round Down</option>
                            </select>
                            {this.state.errors.amount_round_off && <small className="error_right">{this.state.errors.amount_round_off}</small>}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <lable className="lable-input">Common Item Ledger for Allocation<span className="red-star">*</span></lable>
                            <select className="form-control"
                              value={this.state.is_common_item_ledger}
                              onChange={this.handleOnChange}
                              disabled={!MANAGE_ENTITY_PERMISSION || this.state.accounting_mode == 1}
                              name="is_common_item_ledger" >
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </select>
                            {this.state.errors.is_common_item_ledger && <small className="error_right">{this.state.errors.is_common_item_ledger}</small>}
                          </div>
                        </div>
                       
                      </div>
                    </div>  {/* card-forms div */}
                  </div>
                  {/* End Miscellaneous */}
                </div>
              </div>
            </div>
          </div>
          {/* End Entity Configuration */}


          {/* Send Invite */}
          {(this.ACCOUNT_TYPE == 2 && (this.state.entity_type != "edit" || !!!this.state.entity_id)) &&
            <React.Fragment>
              <div className="row">
                <div className="col-xl-12">
                  <hr className="pm-hr"></hr>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3">
                  <h3 className="pm-form-title">Send Invite</h3>
                </div>
                <div className="col-xl-9 col-lg-9 col-md-9">
                  <div className="row">
                    <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="form-group">
                        {/* <lable className="lable-input">Send Invite</lable> */}
                        <input type="text" className={"form-control " + ((this.state.errors.invite_email || this.state.errors.email) ? " wrong-red " : "")} value={this.state.invite_email} name="invite_email" onChange={this.handleOnChange} placeholder="Business account email" />

                        {(this.state.errors.invite_email || this.state.errors.email) && <small className="error_right">{this.state.errors.invite_email}</small>}
                        {(this.state.errors.email || this.state.errors.email) && <small className="error_right">{this.state.errors.email}</small>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          }
          {/* End Send Invite */}


          {/* Buttons Bar */}
          {this.ACCOUNT_TYPE == 1 ?
            !!this.state.is_deleted ?
              <div className="row mt-4">
                <div className="col-6">
                  <div class="alert alert-secondary d-inline-block blue-text gray-bg py-2 px-3 border-0 mb-0">Note: This entity is deleted.</div>
                </div>
                <div className="col-6 text-right">
                  <Button type="button" variant="success apply_btn_new" onClick={(e) => this.restoreEntity(e)}>Restore</Button>
                </div>
              </div>
              :
              MANAGE_ENTITY_PERMISSION && <React.Fragment>
                {this.state.entity_type == "edit" ?
                  <div className="row mt-4">
                    <div className="col-6">
                      {!!this.state.entity_id &&
                        <Button type="button" variant="success apply_btn_new" disabled={this.state.loading} onClick={this.deleteEntity}>Delete Entity</Button>
                      }
                    </div>
                    <div className="col-6 text-right">
                      <Button type="button" variant="success apply_btn_new" disabled={this.state.loading} onClick={(e) => this.SaveEntity(1)}>
                        {<>{!!this.state.entity_id ? "Update" : "Save"}</>}
                      </Button>
                    </div>
                  </div>
                  :
                  <div className="row mt-4">
                    <div className="col-12">
                      <div className="reset_apply_btn_new">
                        <Button type="button" variant="outline-success reset_btn_new" disabled={this.state.loading} onClick={this.rejectEntity}>Reject</Button>
                        <Button type="button" variant="success apply_btn_new" disabled={this.state.loading} onClick={(e) => this.SaveEntity(2)}>Accept & Save</Button>
                      </div>
                    </div>
                  </div>
                }
              </React.Fragment>
            :
            MANAGE_ENTITY_PERMISSION && <React.Fragment>
              <div className="row mt-4">
                <div className="col-6">
                  {!!this.state.entity_id &&
                    <Button type="button" variant="outline-success reset_btn_new" disabled={this.state.loading} onClick={this.deleteCAEntity}>Release Entity</Button>
                  }
                  {(!!this.state.entity_id && this.state.entity_type == "invite") &&
                    <Button type="button" variant="outline-success reset_btn_new ml-3" disabled={this.state.loading} onClick={this.resendInvite}>Resend Invite</Button>
                  }
                </div>
                <div className="col-6 text-right">
                  <Button type="button" variant="success apply_btn_new" disabled={this.state.loading} onClick={this.SaveCAEntity}>
                    {this.ACCOUNT_TYPE == 2 && (this.state.entity_type != "edit" || !!!this.state.entity_id) ? "Send Invite" : !!this.state.entity_id ? "Update" : "Save"}
                  </Button>
                </div>
              </div>
            </React.Fragment>
          }
          {/* End Buttons Bar */}


          {/* Code Not Use Start */}
          {/* <div className="row d-none">
            <div className="col-lg-12 col-md-12">
              <div className="right-inner-content-sec-box">
                <div className="top-head clearfix toggle-sec-brd ">
                  <p className="pull-left s-sbold ft-sz-22 blu-color m-0">Email Snapshot Upload</p>
                  <div className="toggle-btn-sec pull-right">
                    <span className="toggle-btn-sec-text s-sbold">No</span>
                    <label className="switch">
                      <input type="checkbox"
                        className={"form-control " + (this.state.errors.show_email_snapshot_config ? " wrong-red " : "")}
                        checked={this.state.show_email_snapshot_config}
                        onChange={this.handleOnChange}
                        disabled={!MANAGE_ENTITY_PERMISSION}
                        autoComplete="off"
                        autoFocus={true}
                        name="show_email_snapshot_config" />
                      <span className="slider round"></span>
                    </label>
                    <span className="toggle-btn-sec-text s-sbold">Yes</span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* End Code Not Use */}

          {/* <button type="button" className={"btn save-btn greenbtn white-color s-bold pull-right mt-1 " + (this.state.loading ? "position-reltv" : "")}
            disabled={this.state.loading} onClick={this.SaveCAEntity}>
            {this.state.loading && ( <span className="spinner-border spinner-border-sm"></span> )}
            <span>{this.ACCOUNT_TYPE == 2 && (this.state.entity_type != "edit" || !!!this.state.entity_id) ? "Send Invite" : !!this.state.entity_id ? "Update" : "Save"}</span>
          </button> */}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const all_value = state.Session || {};
  const module_permissions = all_value.module_permissions || [];
  const account_permissions = all_value.account_permissions || [];
  const entity_list = all_value.entity_list || [];
  const session_values = state.Session || {};
  const entity_config_param = session_values.entity_config_param;
  //const default_entity_detail = session_values.default_entity_detail
  return {
    module_permissions, account_permissions, entity_list, entity_config_param
  };
}

export default connect(mapStateToProps)(withRouter(CreateEntity));