import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import bank_img from '../../Assets/img/HDFC_Bank_logo.jpg';
import bankic_img from '../../Assets/img/icici_bank.png';
import { FI_CREATE_CODE, FI_UPLOAD_ACCOUNT_STATEMENT_CODE, FI_VIEW_ACCOUNT_STATEMENT_CODE } from '../../Helpers/Constants';
import { displayDateFormate } from '../../Helpers/DateFormat';
import BankIcon from '../../Views/IcoonNew/BankIcon';
import CardIcon from '../../Views/IcoonNew/CardIcon';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import BankLogo from '../../Views/FinancialInstitutes/BankLogos';

class ActiveCardOrBankView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    goToDetails = (id) => {
        if (this.props.features_permissions_list.includes(FI_UPLOAD_ACCOUNT_STATEMENT_CODE) ||
            this.props.features_permissions_list.includes(FI_VIEW_ACCOUNT_STATEMENT_CODE)) {
            this.props.history.push('/financial-institutes/view/' + id)
        }
    }

    render() {

        let institute = this.props.financial_institute

        console.log("institute --- ",institute)
        let cssStyle = ""
        if (institute.is_active === 0)
            cssStyle = " gray-bank-card"
        if (institute.bank_code === null)
            cssStyle = " not-support-bank"

        return (
            <div className="col-xl-3 col-lg-4 col-md-4 ">
                <div className="bank-card-listing">

                    <div className="bank-name-h edit_bank_icon">
                        {(institute.bank_code === null ||  (this.props.bank_modal == 1 && institute.is_credit_card == 1)) ? "" :
                            (this.props.features_permissions_list.includes(FI_UPLOAD_ACCOUNT_STATEMENT_CODE) ||
                                this.props.features_permissions_list.includes(FI_VIEW_ACCOUNT_STATEMENT_CODE) ||
                                this.props.features_permissions_list.includes(FI_CREATE_CODE) ?
                                <>
                                    <OverlayTrigger overlay={<Tooltip>{institute.is_credit_card == 0 ?
                                        "Edit Bank" : "Edit Credit Card"}</Tooltip>}>
                                        <span className="icon-edit cicon editbc_bankin" onClick={() => this.props.editInstitute(institute)}></span>
                                    </OverlayTrigger>
                                </> : null)
                        }
                    </div>

                    <div className={"bank-card clickbc_bankin" + cssStyle} onClick={() => this.goToDetails((institute.id || institute.financial_institute_id))}>
                        <div className={institute.is_credit_card == 0 ? " bank-card-img bank-img-new-bg " : " bank-card-img card-img-new-bg "}>
                            <h3 className="bank-name-h">
                                <span className="d-flex align-items-center w-75">
                                    {BankLogo(institute.bank_code) != null && <img width="40" height="auto" className="img-fluid mr-2" src={BankLogo(institute.bank_code)} />}
                                    <OverlayTrigger overlay={<Tooltip >{institute.bank_name}</Tooltip>}>
                                        <span className='ellips-auto'>{institute.bank_name}</span>
                                    </OverlayTrigger>
                                </span>

                                {/* <span className="ca">
                                {(institute.bank_code === null) ? "" :
                                    (this.props.features_permissions_list.includes(FI_UPLOAD_ACCOUNT_STATEMENT_CODE) ||
                                        this.props.features_permissions_list.includes(FI_VIEW_ACCOUNT_STATEMENT_CODE) ||
                                        this.props.features_permissions_list.includes(FI_CREATE_CODE) ?
                                        <>
                                            <OverlayTrigger overlay={<Tooltip>{institute.is_credit_card == 0 ?
                                                "Edit Bank" : "Edit Credit Card"}</Tooltip>}>
                                                <span className="icon-edit cicon editbc_bankin" onClick={() => this.props.editInstitute(institute)}></span>
                                            </OverlayTrigger>
                                        </> : null)
                                }
                            </span> */}
                            </h3>

                            <lable class="bcard-lable">{institute.is_credit_card == 0 ? "Account Name" : "Name on Card"}</lable>
                            <p class="bcard-p">{institute.account_name || "NA"}<span className=''>{(this.props.bank_modal && institute.is_credit_card == 0) ? institute.bank_account_type == 1 ? ` (Saving)` : institute.bank_account_type == 2 ? ` (Current)` : institute.bank_account_type == 3 ? ` (Any Other)` : '' : ''}</span></p>

                            <lable class="bcard-lable">{institute.is_credit_card == 0 ? "A/C No." : null}</lable>
                            {institute.is_credit_card == 0 ? <p className="bcard-p">{institute.account_number}</p> : null}

                            <lable class="bcard-lable">{institute.is_credit_card == 0 ? "" : "Last 4 Digit"}</lable>
                            {institute.is_credit_card == 0 ? <></> : <p className="bcard-p" onClick={() => this.props.editInstitute(institute)}>{institute.account_number}</p>}

                            <lable class="bcard-lable">Last Transaction Date</lable>
                            <p class="bcard-p">{displayDateFormate(institute.last_updated_at) || "NA"}       
                            {
                              institute.gap_count != 0 && institute.gap_count ? <span className="red-text"> [{institute.gap_count} Gap{institute.gap_count>1?'s':''}]</span>:null
                            }
                             </p>
                        </div>

                        <div className="card_bottom_bar">
                            {(institute.bank_code === null) ?
                                <p class="">Not Supported Currently</p>
                                :
                                (this.props.features_permissions_list.includes(FI_UPLOAD_ACCOUNT_STATEMENT_CODE) ||
                                    this.props.features_permissions_list.includes(FI_VIEW_ACCOUNT_STATEMENT_CODE) ?
                                    "" : null)
                            }
                            {(institute.is_active === 0) ?
                                <p class=" acinbg">Inactive</p>
                                :
                                (this.props.features_permissions_list.includes(FI_UPLOAD_ACCOUNT_STATEMENT_CODE) ||
                                    this.props.features_permissions_list.includes(FI_VIEW_ACCOUNT_STATEMENT_CODE) ?
                                    "" : null)
                            }
                            {(institute.is_active === 1 && institute.bank_code !== null) ?
                                <p class=" actbg">Active</p>
                                :
                                (this.props.features_permissions_list.includes(FI_UPLOAD_ACCOUNT_STATEMENT_CODE) ||
                                    this.props.features_permissions_list.includes(FI_VIEW_ACCOUNT_STATEMENT_CODE) ?
                                    "" : null)
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const all_value = state.Session || {};
    const features_permissions_list = all_value.features_permissions_list || [];
    const bank_modal = all_value.entity_config_param.is_buktec_model || 0
    return { features_permissions_list,bank_modal }
}

export default connect(mapStateToProps)(withRouter(ActiveCardOrBankView));