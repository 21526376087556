import sbiImg from '../../Assets/img/bank_logo/Bank Logos/Public Sector Banks in India/SBI-Bank.webp'
import bobImg from '../../Assets/img/bank_logo/Bank Logos/Public Sector Banks in India/BOB-Bank.webp'
import boiImg from '../../Assets/img/bank_logo/Bank Logos/Public Sector Banks in India/BOI-Bank.webp'
import bomImg from '../../Assets/img/bank_logo/Bank Logos/Public Sector Banks in India/BOM-Bank.webp'
import canaraImg from '../../Assets/img/bank_logo/Bank Logos/Public Sector Banks in India/Canara-Bank.webp'
import centImg from '../../Assets/img/bank_logo/Bank Logos/Public Sector Banks in India/Cent-Bank.webp'
import indianImg from '../../Assets/img/bank_logo/Bank Logos/Public Sector Banks in India/Indian-Bank.webp'
import iobImg from '../../Assets/img/bank_logo/Bank Logos/Public Sector Banks in India/IOB-Bank.webp'
import pnbImg from '../../Assets/img/bank_logo/Bank Logos/Public Sector Banks in India/PNB-Bank.webp'
import punjabSindImg from '../../Assets/img/bank_logo/Bank Logos/Public Sector Banks in India/Punjab-Sind-Bank.webp'
import ubiImg from '../../Assets/img/bank_logo/Bank Logos/Public Sector Banks in India/UBI-Bank.webp'
import ucoImg from '../../Assets/img/bank_logo/Bank Logos/Public Sector Banks in India/UCO-Bank.webp'

import citiImg from '../../Assets/img/bank_logo/Bank Logos/Foreign Banks/Citi-Bank.webp'
import adcbImg from '../../Assets/img/bank_logo/Bank Logos/Foreign Banks/ADCB-Bank.webp'
import americaImg from '../../Assets/img/bank_logo/Bank Logos/Foreign Banks/America-Bank.webp'
import barclaysImg from '../../Assets/img/bank_logo/Bank Logos/Foreign Banks/Barclays-Bank.webp'
import bnpParibasImg from '../../Assets/img/bank_logo/Bank Logos/Foreign Banks/BNP-Paribas-Bank.webp'
import creditSuisseAGImg from '../../Assets/img/bank_logo/Bank Logos/Foreign Banks/Credit Suisse A.G.webp'
import dbsImg from '../../Assets/img/bank_logo/Bank Logos/Foreign Banks/DBS-Bank.webp'
import deutscheImg from '../../Assets/img/bank_logo/Bank Logos/Foreign Banks/Deutsche-Bank.webp'
import hsbcImg from '../../Assets/img/bank_logo/Bank Logos/Foreign Banks/HSBC-Bank.webp'
import standardImg from '../../Assets/img/bank_logo/Bank Logos/Foreign Banks/Standard-Chartered-Bank.webp'

import axisImg from '../../Assets/img/bank_logo/Bank Logos/Private Sector Banks in India/Axis-Bank.webp'
import bandhanImg from '../../Assets/img/bank_logo/Bank Logos/Private Sector Banks in India/Bandhan-Bank.webp'
import bandhanImg2 from '../../Assets/img/bank_logo/Bank Logos/Private Sector Banks in India/Bandhan-Bank2.webp'
import iciciImg from '../../Assets/img/bank_logo/Bank Logos/Private Sector Banks in India/ICICI-Bank.webp'
import cityUnionImg from '../../Assets/img/bank_logo/Bank Logos/Private Sector Banks in India/City-Union-Bank.webp'
import hdfcImg from '../../Assets/img/bank_logo/Bank Logos/Private Sector Banks in India/HDFC Bank.webp'
import csbImg from '../../Assets/img/bank_logo/Bank Logos/Private Sector Banks in India/CSB-Bank.webp'
import dcbImg from '../../Assets/img/bank_logo/Bank Logos/Private Sector Banks in India/DCB-Bank.webp'
import dhanlaxmiImg from '../../Assets/img/bank_logo/Bank Logos/Private Sector Banks in India/Dhanlaxmi-Bank.webp'
import fedaralImg from '../../Assets/img/bank_logo/Bank Logos/Private Sector Banks in India/Federal-Bank.webp'
import idbiImg from '../../Assets/img/bank_logo/Bank Logos/Private Sector Banks in India/IDBI-Bank.webp'
import idfcFirstImg from '../../Assets/img/bank_logo/Bank Logos/Private Sector Banks in India/IDFC-FIRST-Bank.webp'
import indusindImg from '../../Assets/img/bank_logo/Bank Logos/Private Sector Banks in India/IndusInd-Bank.webp'
import jkImg from '../../Assets/img/bank_logo/Bank Logos/Private Sector Banks in India/JK-Bank.webp'
import karnatakaImg from '../../Assets/img/bank_logo/Bank Logos/Private Sector Banks in India/Karnataka-Bank.webp'
import kotakMahindraImg from '../../Assets/img/bank_logo/Bank Logos/Private Sector Banks in India/Kotak-Mahindra-Bank.webp'
import kvbImg from '../../Assets/img/bank_logo/Bank Logos/Private Sector Banks in India/KVB-Bank.webp'
import laksmiVilasImg from '../../Assets/img/bank_logo/Bank Logos/Private Sector Banks in India/Lakshmi-Vilas-Bank.webp'
import nationalImg from '../../Assets/img/bank_logo/Bank Logos/Private Sector Banks in India/National-Bank.webp'
import rblImg from '../../Assets/img/bank_logo/Bank Logos/Private Sector Banks in India/RBL-Bank.webp'
import southIndianImg from '../../Assets/img/bank_logo/Bank Logos/Private Sector Banks in India/South-Indian-Bank.webp'
import tmbImg from '../../Assets/img/bank_logo/Bank Logos/Private Sector Banks in India/TMB-MBank.webp'
import yesImg from '../../Assets/img/bank_logo/Bank Logos/Private Sector Banks in India/YES-Bank.webp'

export default function BankLogo(bankCode) {

    switch(bankCode){

        //public banks
        case 'SBI': return sbiImg
        
        case 'BANK_OF_BARODA': return bobImg

        case 'BANK_OF_INDIA': return boiImg

        case 'BANK_OF_MAHARASHTRA': return bomImg

        case 'CANARA': return canaraImg

        case 'CENTRAL_BANK': return centImg

        case 'INDIAN_BANK': return indianImg

        case 'INDIAN_OVERSEAS': return iobImg

        case 'PNB': return pnbImg

        case 'PUNJAB_SIND': return punjabSindImg

        case 'UNION_BANK': return ubiImg

        case 'UCO': return ucoImg

        // private banks
        case 'AXIS': return axisImg

        case 'BANDHAN': return bandhanImg

        case 'CITY_UNION': return cityUnionImg

        case 'CATHOLIC_SYRIAN': return csbImg

        case 'DCB': return dcbImg

        case 'DHANLAXMI': return dhanlaxmiImg

        case 'FEDERAL': return fedaralImg

        case 'HDFC': return hdfcImg

        case 'ICICI': return iciciImg  

        case 'IDBI': return null

        case 'IDFC': return null

        case 'INDUSIND': return indusindImg

        case 'JAMMU_KASHMIR': return jkImg

        case 'KARNATAKA': return karnatakaImg

        case 'KOTAK': return kotakMahindraImg

        case 'KARUR_VYASA': return kvbImg

        case 'LAXMI_VILAS': return null

        case 'NATIONAL': return null

        case 'RBL': return rblImg

        case 'SOUTH_INDIAN': return southIndianImg

        case 'TMB': return null

        case 'YES': return yesImg

        // foreign banks
        case 'ADCB': return null

        case 'BANK_OF_AMERICA': return americaImg

        case 'BARCLAYS': return null

        case 'BNP': return null

        case 'CITI': return citiImg

        case 'CREDIT_SUISSE': return null

        case 'DBS': return null

        case 'DEUTSCHE_BANK': return deutscheImg

        case 'HSBC': return null

        case 'STANDARD_CHARTERED': return standardImg

        //Buktec supported 

        case 'State Bank of India': return sbiImg
        
        case 'Bank Of Baroda': return bobImg

        case 'Bank Of India': return boiImg

        case 'Bank Of Maharashtra': return bomImg

        case 'Canara Ban': return canaraImg

        case 'Central Bank of India': return centImg

        case 'Indian Bank': return indianImg

        case 'Indian Overseas Bank': return iobImg

        case 'Punjab National Bank': return pnbImg

        case 'PUNJAB_SIND': return punjabSindImg

        case 'Union Bank Of India': return ubiImg

        case 'UCO Bank': return ucoImg

        // private banks
        case 'Axis Bank': return axisImg

        case 'Bandhan Bank': return bandhanImg

        case 'City Union Bank': return cityUnionImg

        case 'CATHOLIC_SYRIAN': return csbImg

        case 'DCB Bank': return dcbImg

        case 'DHANLAXMI': return dhanlaxmiImg

        case 'Federal Bank': return fedaralImg

        case 'HDFC Bank': return hdfcImg

        case 'ICICI Bank': return iciciImg  

        case 'IDBI Bank': return null

        case 'IDFC Bank': return null

        case 'Indusind Bank': return indusindImg

        case 'Jammu and Kashmir Bank': return jkImg

        case 'Karnataka Bank': return karnatakaImg

        case 'Kotak Mahindra Bank': return kotakMahindraImg

        case 'KARUR_VYASA': return kvbImg

        case 'LAXMI_VILAS': return null

        case 'NATIONAL': return null

        case 'RBL Bank Limited': return rblImg

        case 'South Indian Bank': return southIndianImg

        case 'TMB': return null

        case 'Yes Bank': return yesImg

        // foreign banks
        case 'ADCB': return null

        case 'BANK_OF_AMERICA': return americaImg

        case 'BARCLAYS': return null

        case 'BNP': return null

        case 'Citi Bank': return citiImg

        case 'CREDIT_SUISSE': return null

        case 'DBS Bank Ltd': return null

        case 'Deutsche Bank': return deutscheImg

        case 'HSBC Bank': return null

        case 'Standard Chartered Bank': return standardImg

        default: return null;
    }
}