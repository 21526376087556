function validateAddNewBank(data, FIList,bank_modal = 0) {
    console.log(data)
    let errors = {};
    let IFSCregEx = /^[A-Z]{4}0[A-Z0-9]{6}$/;

    if (bank_modal == 0 && data.yodlee_supported_bank_id == "") {
        errors.bank_id = 'Bank Name is required';
    }
    if(bank_modal == 1 && data.buktec_supported_bank_id == ""){
        errors.bank_id = 'Bank Name is required';
    }
    if (!data.account_name.trim()) {
        errors.account_name = 'Account Name is required';
    }

    if(data.debit_card_no){
        errors.debit_card_no = 'Please, confirm debit card no.';
    }
 
    if ( bank_modal == 0 && !data.ifsc_code && data.yodlee_supported_bank_id == null) {
        errors.ifsc_code = 'IFSC Code is required';
    }
    if ( bank_modal == 1 && !data.ifsc_code && data.buktec_supported_bank_id == null) {
        errors.ifsc_code = 'IFSC Code is required';
    }

    if (bank_modal == 0 && !data.bank_name && data.yodlee_supported_bank_id == null) {
        errors.bank_name = 'Other Bank Name is required';
    }
    if (bank_modal == 1 && !data.bank_name && data.buktec_supported_bank_id == null) {
        errors.bank_name = 'Other Bank Name is required';
    }
    if (data.ifsc_code && IFSCregEx.test(data.ifsc_code) === false) {
        errors.ifsc_code = 'Please provide valid IFSC Code';
    }

    if (!data.account_number) {
        errors.account_number = 'Account number is required';
    }

    if (bank_modal && !data.bank_account_type) {
        errors.bank_type_option = 'Bank Account Type is required';
    }
    
    //change account number from number only to alphanumeric
    //let regEx = /^\d{9,18}$/;
    let regEx = /^[a-z0-9]+$/;

    if (data.account_number && regEx.test(data.account_number) === false) {
        errors.account_number = 'Please provide valid Account number';
    }
    if (data.account_number && regEx.test(data.account_number) === true) {
        let is_unique = FIList.some(institute => institute.account_number == data.account_number)
        if (is_unique)
            errors.account_number = 'Account number already exits.';
    }

    return errors;
}


export {
    validateAddNewBank
}