import { SET_FINANCIAL_INSTITUTE_REQUEST_ID, SET_FI_FINANCIAL_YEAR_NAME, SET_FI_FILTER_DATE_RANGE, SET_BANKING_TRANSACTION_LIST } from "./Types";

export const setFIRequestId = (id) => ({
    type: SET_FINANCIAL_INSTITUTE_REQUEST_ID,
    payload: id,
  });

export const setFinancialYearName = (financial_year_name) => ({
  type: SET_FI_FINANCIAL_YEAR_NAME,
  payload: financial_year_name,
});

export const setFilterDateRange = (start_date, end_date) => ({
  type: SET_FI_FILTER_DATE_RANGE,
  payload: {
    start_date: start_date,
    end_date: end_date
  },
});
 
export const setBankingTransactionList = (bulk_transaction_list) => ({
  type: SET_BANKING_TRANSACTION_LIST,
  payload: bulk_transaction_list,
});