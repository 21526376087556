import React from 'react'
import { Multiselect } from 'multiselect-react-dropdown'
import DatePicker from 'react-datepicker';
import { Button, Modal, Table } from 'react-bootstrap';
import { changeDateFormate, DatePickerFormate, displayDateFormate } from '../../Helpers/DateFormat';
import { validateCreateTask } from './Validation';
import { Link, withRouter } from 'react-router-dom';
import TaskService from '../../Services/TaskManager/TaskManagerService'
import { toast } from 'react-toastify';
import DeletePopUp from '../../Components/BoilerPlate/DeletePopUp';
import _ from "lodash";
import ChangeLog from '../../Components/BoilerPlate/ChangeLog';
import { ArrowUpDownIcon, LinkedStatus, NotReadyStatus, ProcessedStatus, ReadyStatus, SyncedStatus } from '../IcoonNew';
import StorageData from '../../Helpers/StorageData';
import { connect } from 'react-redux';
import { setTaskTempData } from '../../Redux/Actions/TaskManager';
import SessionManagement from '../../Utils/SessionManagement';
import RouteLeavingGuard from '../../Utils/RouteLeavingGuard';
import { setAppilcationLoder } from '../../Redux/Actions/App';
import { createLog, formatLogData, sendLog } from '../../Helpers/CreateLogs';
import MasterService from '../../Services/MasterService';
import HelpModal from '../../Components/BoilerPlate/HelpModal';
import NotProcessedStatus from '../IcoonNew/NotProcessedStatus';
import CommentsSection from '../Snapshot/CommentsSection';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { setSnapshotCardFilter, setSnapshotFilterData, setPageNo } from '../../Redux/Actions/Snapshot';

class CreateTaskForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			show_help: false,
			loading: false,
			deleteLoading: false,
			// show_save_changes: false,
			show_delete: false,
			show_logs: false,
			is_dirty: false,

			errors: {},
			assign_to_list: [],
			task_id: props.match.params.task_id ? props.match.params.task_id : 0,
			logs: [],

			due_date: '',
			task: '',
			assigned_by: '',
			assigned_to: '',
			priority: 2,
			date: '',
			// task_number: 'New',
			status: 1,

			ref_benificiary_name: "",
			ref_date: "",
			ref_mod_id: null,
			ref_module: "",
			ref_status: null,
			ref_total: null,
			ref_type: null,

			old_data: {},
			logs_list: {},
			save_and_redirect: false,
			next_route: false,
			closed: false,
			statusOption: [
				{ name: 'Mark as done', value: 2 },
				{ name: 'Mark as open', value: 1 }
			],
			communication_bar_tasks_dirty: false,
			communication_bar_notes_dirty: false,
			communication_bar_msgs_dirty: false,
			communication_bar_private_msgs_dirty: false
		}
		this.onCancelGetDataFromStore = this.onCancelGetDataFromStore.bind(this)
		console.log([3, 9, 11, 14, 17, 23], this.props.features_permissions_list)
		this.VIEW_ONLY = _.isEqual([3, 9, 11, 14, 17, 23], this.props.features_permissions_list)
	}

	onCancelGetDataFromStore() {
		if (StorageData.getTaskManagerId()) {
			console.log(" got into it ")
			this.setState({ task_id: StorageData.getTaskManagerId() }, () => this.getDataFromStore())
			// this.getDataFromStore()
		}
	}

	componentDidMount() {
		this.getInitialData()
		if (StorageData.getTaskManagerId()) {
			let id = StorageData.getTaskManagerId()
			console.log("-----block executed-----" + id)
			this.setState({ task_id: id }, () => this.getDataFromDB())
		}

		if (this.state.task_id) {
			console.log("-----not gone-------")
			this.getTaskDetails()
		}


		// this.getChangeLogs()
	}

	getChangeLogs = () => {
		TaskService.getChangeLog().then(data => {
			if (data.responce_code == 200)
				this.setState({ logs: data.result.list })
		})
	}

	emptyStateValue = () => {
		this.setState({
			errors: {},
			task_id: 0,
			due_date: '',
			task: '',
			assigned_to: "",
			priority: 2,
			status: 1,
			is_dirty: false
		});
	}

	componentWillReceiveProps(nextProps) {
		if ((nextProps.match.params.task_id !== this.props.match.params.task_id)) {
			this.getInitialData()
			if (nextProps.match.params.task_id) {
				this.setState({
					task_id: nextProps.match.params.task_id,
					errors: {},
				}, () => this.getTaskDetails())
			} else {
				this.emptyStateValue()
			}
		}
	}

	// setTempDataInStore = () => {
	// 	if(this.state.ref_mod_id){
	// 		let tempData = {
	// 			task: this.state.task,
	// 			assigned_by: this.state.assigned_by,
	// 			assigned_to: this.state.assigned_to,
	// 			date: this.state.date,
	// 			due_date: new Date(this.state.due_date),
	// 			priority: this.state.priority,
	// 			// task_number: this.state.task_number,
	// 			status: this.state.status,

	// 			ref_benificiary_name: this.state.ref_benificiary_name,
	// 			ref_date: this.state.ref_date,
	// 			ref_mod_id: this.state.ref_mod_id,
	// 			ref_module: this.state.ref_module,
	// 			ref_status: this.state.ref_status,
	// 			ref_total: this.state.ref_total,
	// 			ref_type: this.state.ref_type,
	// 		}
	// 		this.props.dispatch(setTaskTempData(tempData))
	// 	}
	// }

	getDataFromDB = () => {
		TaskService.viewTask(this.state.task_id).then(data => {
			if (data.response_code == 200) {
				this.props.updateTaskDetail(data.result)
				this.setState({
					task: data.result.task,
					assigned_by: data.result.assigned_by_name,
					assigned_to: data.result.assigned_to,
					date: data.result.date,
					due_date: new Date(data.result.due_date),
					priority: data.result.priority,
					// task_number: data.result.task_number,
					status: data.result.status,

					ref_benificiary_name: data.result.ref_benificiary_name,
					ref_date: data.result.ref_date,
					ref_mod_id: data.result.ref_mod_id,
					ref_module: data.result.ref_module,
					ref_status: data.result.ref_status,
					ref_total: data.result.ref_total,
					ref_type: data.result.ref_type,

				}, () => {
					// this.setTempDataInStore()
					this.props.dispatch(setAppilcationLoder(false));

					// store old data for log
					const oldData = { ...this.state.old_data };
					oldData.task = data.result.task;
					oldData.assigned_to = data.result.assigned_to;
					oldData.priority = data.result.priority;
					oldData.due_date = (data.result.due_date);
					oldData.status = data.result.status;
					oldData.id = this.state.task_id;
					this.setState({ old_data: oldData })
					// store old data for log
				})
			} else if (data.response_code == 404) {
				this.props.dispatch(setAppilcationLoder(false));
				//toast.error(data.message)

				this.setState({ is_dirty: false }, () => {
					//this.props.history.push('/snapshot/evidence')

					this.props.history.push({
						pathname: `/task-manager`,
						state: {
							showalert: true,
						}
					})
				})
			} else {
				this.props.dispatch(setAppilcationLoder(false));
				toast.error(data.message);
				this.setState({ task_id: 0 })
			}

		})

	}

	getDataFromStore = () => {
		let data = this.props.task_temp_data
		console.log(data)
		this.setState({
			task: data.task,
			assigned_by: data.assigned_by_name,
			assigned_to: data.assigned_to,
			//date: new Date(data.date),
			date: data.date,
			due_date: new Date(data.due_date),
			priority: data.priority,
			// task_number: data.task_number,
			status: data.status,

			ref_benificiary_name: data.ref_benificiary_name,
			ref_date: data.ref_date,
			ref_mod_id: data.ref_mod_id,
			ref_module: data.ref_module,
			ref_status: data.ref_status,
			ref_total: data.ref_total,
			ref_type: data.ref_type,
		}, () => {
			this.props.dispatch(setAppilcationLoder(false));
		})

		StorageData.deleteTaskManagerId()
	}

	getTaskDetails = () => {
		this.props.dispatch(setAppilcationLoder(true));
		if (StorageData.getTaskManagerId()) {
			console.log("----storage id----")
			this.getDataFromStore()
		} else {
			console.log("-----state id------")
			this.getDataFromDB()
		}
	}

	getInitialData = () => {
		TaskService.getRequiredDataForTask().then(data => {
			if (data.response_code == 200) {
				this.setState({
					assigned_by: data.result.assigned_by,
					assign_to_list: _.orderBy(data.result.assigned_to, ['name'], ['asc']),
					date: data.result.date
				})
			} else {
				toast.error(data.message);
			}
		})
	}

	handleStatusChange = (selectedList, selectedItem) => {

		console.log(selectedItem.value)
		this.setState({ status: selectedItem.value })
		if (this.state.is_dirty === false)
			this.setState({ is_dirty: true })
	}

	handleChange = (e) => {
		const { value, name } = e.target;
		this.setState({ [name]: value });
		if (this.state.is_dirty === false)
			this.setState({ is_dirty: true })
	}

	setDate = (date, key) => {
		this.setState({ [key]: date })
		if (this.state.is_dirty === false)
			this.setState({ is_dirty: true })
	}

	saveTask = (e) => {
		//e.preventDefault();
		this.setState({
			loading: true
		});
		//console.log("state--------"+this.state.due_date)
		let postdata = {
			"task": this.state.task,
			"assigned_to": this.state.assigned_to,
			"priority": this.state.priority,
			"due_date": changeDateFormate(this.state.due_date),
		}
		//console.log("postData------"+postdata.due_date)
		let errors = validateCreateTask(postdata);
		if (this.state.task_id) {
			postdata.status = parseInt(this.state.status)
			postdata.id = this.state.task_id
		}
		if (Object.keys(errors).length === 0) {
			if (this.state.task_id) {
				TaskService.updateTask(postdata).then(
					(data) => {
						this.setState({
							loading: false, is_dirty: false
						});
						if (data.response_code == 200) {
							toast.success(data.message);

							// send logs code
							let createdLog = createLog.map(this.state.old_data, postdata)
							formatLogData(createdLog, ["id"], "")
							sendLog({ module_type: 6, module_id: this.state.task_id })

							// store old data for log
							let oldData = { ...this.state.old_data };
							oldData = postdata;
							this.setState({ old_data: oldData })
							// store old data for log
							// send logs code

							let task = {
								assigned_by: this.state.assigned_by,
								assigned_to: this.state.assign_to_list.find(x => x.id == this.state.assigned_to).name,
								date: displayDateFormate(this.state.date),
								due_date: displayDateFormate(this.state.due_date),
								id: parseInt(this.state.task_id),
								is_star: 0,
								priority: this.state.priority,
								status: parseInt(this.state.status),
								task: this.state.task
							}
							this.props.updateTaskDetail(task)
							this.props.newTask(task, 'update');

							if (this.state.save_and_redirect) {
								if (this.state.communication_bar_msgs_dirty) {
									this.setState({ communication_bar_msgs_dirty: false, communication_bar_private_msgs_dirty: false, communication_bar_notes_dirty: false, communication_bar_tasks_dirty: false })
									this.comment_section.saveData('msgs', this.state.next_route);
								} else if (this.state.communication_bar_private_msgs_dirty) {
									this.setState({ communication_bar_msgs_dirty: false, communication_bar_private_msgs_dirty: false, communication_bar_notes_dirty: false, communication_bar_tasks_dirty: false })
									this.comment_section.saveData('private_msgs', this.state.next_route);
								} else if (this.state.communication_bar_notes_dirty) {
									this.setState({ communication_bar_msgs_dirty: false, communication_bar_private_msgs_dirty: false, communication_bar_notes_dirty: false, communication_bar_tasks_dirty: false })
									this.comment_section.saveData('notes', this.state.next_route);
								} else {
									this.setState({ communication_bar_msgs_dirty: false, communication_bar_private_msgs_dirty: false, communication_bar_notes_dirty: false, communication_bar_tasks_dirty: false })
									this.props.history.push(this.state.next_route)
								}
							}

						} else {
							toast.error(data.message);
							this.setState({
								errors: data.reason
							});
						}
					});
			} else {
				TaskService.createTask(postdata).then(
					(data) => {
						this.setState({
							loading: false, is_dirty: false
						});
						if (data.response_code == 200) {
							this.props.history.push('/task-manager/create/' + data.result.id)
							this.setState({
								task_id: data.result.id,
								// task_number: data.result.task_no
							})
							toast.success(data.message);

							// send log code
							sendLog({ module_type: 6, module_id: data.result.id, activity: ['Created New Task'] })
							// send log code

							let task = {
								assigned_by: this.state.assigned_by,
								assigned_to: this.state.assign_to_list.find(x => x.id == this.state.assigned_to).name,
								date: displayDateFormate(this.state.date),
								due_date: displayDateFormate(this.state.due_date),
								id: data.result.id,
								is_star: 0,
								priority: this.state.priority,
								status: this.state.status,
								task: this.state.task
							}
							this.props.newTask(task, 'save');
							let errors = {
								task: '',
								assigned_to: '',
								due_date: ''
							}
							this.setState({
								errors: errors
							});

							if (this.state.save_and_redirect) {
								if (this.state.communication_bar_tasks_dirty) {
									this.comment_section.saveData('tasks', this.state.next_route);
								} else if (this.state.communication_bar_msgs_dirty) {
									this.comment_section.saveData('msgs', this.state.next_route);
								} else if (this.state.communication_bar_private_msgs_dirty) {
									this.comment_section.saveData('private_msgs', this.state.next_route);
								} else if (this.state.communication_bar_notes_dirty) {
									this.comment_section.saveData('notes', this.state.next_route);
								} else {
									this.props.history.push(this.state.next_route)
								}
							}

						} else {
							toast.error(data.message);
							this.setState({
								errors: data.reason,
								save_and_redirect: false,
								next_route: false
							});
						}
					});
			}
		} else {
			this.setState({
				loading: false,
				errors: errors,
				save_and_redirect: false,
				next_route: false
			});
		}
	}

	saveDatatoStore = () => {
		let task_data = {
			task: this.state.task,
			assigned_by: this.state.assigned_by,
			assigned_to: this.state.assigned_to,
			date: this.state.date,
			due_date: this.state.due_date,
			priority: this.state.priority,
			// task_number: this.state.task_number,
			status: this.state.status,

			ref_benificiary_name: this.state.ref_benificiary_name,
			ref_date: this.state.ref_date,
			ref_mod_id: this.state.ref_mod_id,
			ref_module: this.state.ref_module,
			ref_status: this.state.ref_status,
			ref_total: this.state.ref_total,
			ref_type: this.state.ref_type,
		}
		this.props.dispatch(setTaskTempData(task_data))
	}

	viewReference = () => {
		this.saveDatatoStore()
		StorageData.setTaskManagerId(this.state.task_id)
		let module_name = ''
		// this.setState({ task_id: 0})
		StorageData.setDefaultFinacialYear(5);
		this.props.dispatch(setSnapshotFilterData({ filter_by: 2 }));
		this.props.dispatch(setPageNo(1));
		this.props.dispatch(setSnapshotCardFilter(0));

		let filter_data = {
			"type": null,
			"snapshot_number": JSON.stringify(this.state.ref_mod_id),
			"supplier_name": null,
			"from_amount": null,
			"to_amount": null,
			"payment_status": null,
			"created_by": null,
			"is_star": null,
			"voucher_status": null,
			"tags": "",
			"category": null,
			"payment_method": null,
			"source": null,
			"attachment_status": null,
			"gst_status": null,
			"messages": null,
			"tds_status": null,
			"is_entity_mismatch": null
		}

		this.props.dispatch(setSnapshotFilterData(filter_data));




		switch (this.state.ref_module) {
			case 'Evidence':
				module_name = '/snapshot/evidence/create/'
				break;
			case 'Cheque':
				module_name = '/snapshot/evidence/'
				break;
			case 'Snapshot':
				module_name = '/snapshot/evidence/create/'
				break;
			case 'Money Link':
				module_name = '/money-link/'
				break;
			default: module_name = ''
				break;
		}
		toast.info('Press back button to go back to task manager')
		this.props.history.push(`${module_name}${this.state.ref_mod_id}`)
	}

	getStatus = () => {
		switch (this.state.ref_status) {
			case 1:
				return <NotReadyStatus />
			case 2:
				return <ReadyStatus />
			case 3:
				return <LinkedStatus />
			case 4:
				return <ProcessedStatus />
			case 5:
				return <SyncedStatus />
			case 6:
				return <NotProcessedStatus />
			default:
				return "--"
		}
	}

	setPriority = () => {
		this.state.priority === 1 ? this.setState({ priority: 2 }) : this.setState({ priority: 1 })
		if (this.state.is_dirty === false)
			this.setState({ is_dirty: true })
	}

	toggleDeletePopUp = () => {
		this.setState({ show_delete: !this.state.show_delete })
	}

	deleteTask = () => {
		this.setState({ deleteLoading: true })
		TaskService.deleteTask(this.state.task_id).then((data) => {
			if (data.response_code == 200) {
				this.setState({ deleteLoading: false })
				toast.success(data.message);
				this.props.newTask(this.state.task_id, 'delete');
				this.toggleDeletePopUp()
				this.emptyStateValue()
				this.props.history.push('/task-manager/create')
			} else {
				this.toggleDeletePopUp()
				toast.error(data.message);
				if (data.reason) {
					this.setState({
						errors: data.reason, deleteLoading: false
					});
				} else {
					this.setState({
						errors: 'Task cannot be deleted. Try again', deleteLoading: false
					});
				}
			}
		})
	}

	getLogs = () => {
		this.props.dispatch(setAppilcationLoder(true));
		let detail = {}
		if (this.state.ref_mod_id) {

			if (this.state.ref_module == 'Snapshot') {
				detail = { module: 1, submodule: 1, mod_id: this.state.ref_mod_id, submod_id: this.state.task_id }
			} else {
				detail = { module: 4, submodule: 1, mod_id: this.state.ref_mod_id, submod_id: this.state.task_id }
			}

		} else {
			detail = { mod_id: this.state.task_id, module: 6 }
		}

		MasterService.getLogs(detail).then((data) => {
			this.props.dispatch(setAppilcationLoder(false));
			if (data.response_code === 200) {
				this.setState({ logs_list: data.result.change_logs })
			} else {
				toast.error(data.message)
			}
			this.setState({ show_log: true })
		})
	}

	toggleChangeLog = () => {
		this.setState({ show_log: !this.state.show_log })
	}

	toggleHelp = () => {
		this.setState({ show_help: !this.state.show_help })
	}

	saveBeforeLeave = (nextRoute) => {
		this.setState({ save_and_redirect: true, next_route: nextRoute, errors: { task: '' } }, function () {
			if (this.state.is_dirty) {
				this.setState({ is_dirty: false })
				this.saveTask()
			} else if (this.state.communication_bar_tasks_dirty) {
				this.setState({ communication_bar_tasks_dirty: false })
				this.comment_section.saveData('tasks', nextRoute);
			} else if (this.state.communication_bar_notes_dirty) {
				this.setState({ communication_bar_notes_dirty: false })
				this.comment_section.saveData('notes', nextRoute);
			} else if (this.state.communication_bar_msgs_dirty) {
				this.setState({ communication_bar_msgs_dirty: false })
				this.comment_section.saveData('msgs', nextRoute);
			} else if (this.state.communication_bar_private_msgs_dirty) {
				this.setState({ communication_bar_private_msgs_dirty: false })
				this.comment_section.saveData('private_msgs', nextRoute);
			}
		});
	}

	closeAction = () => {
		this.setState({ closed: false })
	}

	update_communication_bar_tasks_dirty = (status) => {
		this.setState({ communication_bar_tasks_dirty: status })
	}

	update_communication_bar_notes_dirty = (status) => {
		this.setState({ communication_bar_notes_dirty: status })
	}

	update_communication_bar_msgs_dirty = (status) => {
		this.setState({ communication_bar_msgs_dirty: status })
	}

	update_communication_bar_private_msgs_dirty = (status) => {
		this.setState({ communication_bar_private_msgs_dirty: status })
	}

	render() {
		let is_dirty = false
		if (this.state.is_dirty) {
			is_dirty = true
		} else if (this.state.is_dirty && (this.state.communication_bar_notes_dirty || this.state.communication_bar_msgs_dirty || this.state.communication_bar_private_msgs_dirty)) {
			is_dirty = true
		} else if (!this.state.is_dirty && (this.state.communication_bar_notes_dirty || this.state.communication_bar_msgs_dirty || this.state.communication_bar_private_msgs_dirty)) {
			is_dirty = true
		} else {
			is_dirty = false
		}

		console.log(":::::::::ref status", this.state.ref_status)
		console.log(":::::::::ref type", this.state.ref_type)

		return (
			// {/*<div className="col-lg-9 col-md-9 col-sm-9 main-content-sec">*/} 
			<div className="col-xl-9 col-lg-8 col-md-8 col-sm-7 main-content-sec-new">
				<ChangeLog
					show_log={this.state.show_log}
					toggleChangeLog={this.toggleChangeLog}
					logs={this.state.logs_list}
				/>
				<HelpModal
					show_help={this.state.show_help}
					toggleHelp={this.toggleHelp}
					logs={this.state.logs_list}
				/>
				<RouteLeavingGuard
					onCancelGetDataFromStore={this.onCancelGetDataFromStore}
					when={is_dirty}
					saveChanges={this.saveBeforeLeave}
					closed={this.state.closed}
					closeAction={this.closeAction}
					resetForm={this.emptyStateValue}
				/>
				{/* <SaveChangesPopUp
					showModal={this.state.show_save_changes}
					toggleSavePopUp={this.toggleSavePopUp}
					saveChanges={this.saveChanges} /> */}
				<DeletePopUp
					toggleDeletePopUp={this.toggleDeletePopUp}
					showModal={this.state.show_delete}
					deleteItem={this.deleteTask}
					deleteLoading={this.state.deleteLoading} />


				{/* <span className="help-icon">
					<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Help</Tooltip>}><span className="icon-Information help-icon"></span></OverlayTrigger>
				</span> */}
				{/* <div className="main-content-inner"> */}
				<div className="main-content-inner-new">
					{/* Sub Header Inner */}
					<div className="sub-header-inner-new">
						<div className="row">
							<div className="col-sm-12">
								<div className="sub-header-lr-new">
									<div className="shleft-new">
										<h3 className="heading-shi">Task Manager </h3>
									</div>
									<div className="shright-new">
										<h3 className="heading-shi mb-0">
											<Link to={'/task-manager'} className="">
												<span className="icon-Cross-with-grey-circle "><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
											</Link>
										</h3>
									</div>
								</div>
							</div>
						</div>
						{/* <div className="row">
							<div className="col-md-6 sleft">
								<h3 className="heading-shi">Task Manager</h3>
							</div>
							<div className="col-md-6 sright">
								<h3 className="heading-shi">
									<Link to={'/task-manager'} className="">
										<span className="icon-Cross-with-grey-circle "><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
									</Link>
								</h3>
							</div>
						</div> */}
					</div>
					{/* End Sub Header Inner */}

					<div className="form-content-sec-new">
						<div className="form-sec-inner-new">
							<div className="row">
								<div className="col-sm-12">
									<div className="form-main-title-new">
										<h4 className="form-title-heading-new">
											Task Details <span className="form-date-new">{displayDateFormate(this.state.date)}</span>
										</h4>
										<div className="fth-rights">
											<span className="form-id-new">{this.state.task_id ? "ID #" + this.state.task_id : "NEW"}</span>
											{this.state.task_id ? <span className="icon-log icon-round-info-button form-changelog-new cpointer" onClick={this.getLogs}></span> : null}
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-12">
									<div className="form-group">
										<lable className="lable-input">Task<span className="red-star">*</span></lable>
										<textarea
											className="form-control"
											rows="3"
											disabled={this.VIEW_ONLY}
											value={this.state.task}
											onChange={(e) => {
												this.handleChange(e)
											}} name="task">
										</textarea>
										{this.state.errors.task && <small className="form-error-text">{this.state.errors.task}</small>}
									</div>
								</div>

								{/* <div className="col-sm-4">
									<div className="form-group">
										<lable className="lable-input">Assigned To<span className="red-star">*</span></lable>
										<input type="text" className="form-control" name="task" />
									</div>
								</div> */}
								<div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
									<div className="form-group">
										<lable className="lable-input">Assigned To<span className="red-star">*</span></lable>
										<select className="form-control"
											disabled={this.VIEW_ONLY} name="assigned_to" onChange={(e) => {
												this.handleChange(e)
											}} value={this.state.assigned_to}>
											<option value="" disabled selected>Select User</option>
											{this.state.assign_to_list.map((item, index) => {
												return <option key={index} value={item.id}>{item.name}</option>
											})}
										</select>
										{this.state.errors.assigned_to && <small className="form-error-text">{this.state.errors.assigned_to}</small>}
									</div>
								</div>

								<div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
									<div className="form-group">
										<lable className="lable-input">Assigned By<span className="red-star">*</span></lable>
										<input type="text" className="form-control" name="assigned_by"
											value={this.state.assigned_by} onChange={(e) => {
												this.handleChange(e)
											}} readOnly />
									</div>
								</div>

								<div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
									<div className="form-group">
										<lable className="lable-input">Due Date<span className="red-star">*</span></lable>
										<DatePicker
											dateFormat={DatePickerFormate()}
											onChange={date => this.setDate(date, "due_date")}
											className="form-control datepicker-icon"
											disabled={this.VIEW_ONLY}
											minDate={new Date()}
											selected={this.state.due_date}
											autoComplete="off"
											name="date" />
										{this.state.errors.due_date && <small className="form-error-text">{this.state.errors.due_date}</small>}
									</div>
								</div>
								<div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 d-flex align-items-end">
									{/* <lable className="lable-input d-lg-block d-md-block d-sm-none">&nbsp;</lable> */}
									<div className="form-group">
										{this.state.priority === 1 ?
											<button className="btn s-bold blu-color high-priority active" name="highPriority"
												onClick={() => { this.setPriority() }}>
												<span className='d-flex align-items-center justify-content-center'>
													<span className="icon-exclamation-red-sign white-red-icon icon-h">
														<span className="path1"></span>
														<span className="path2"></span>
														<span className="path3"></span>
														<span className="path4"></span>
													</span>
													<span className="mx-2 high-priority-text">High Priority</span>
												</span>
											</button>
											:
											<button className="btn s-bold blu-color high-priority " name="highPriority"
												onClick={() => { this.setPriority() }}>
												<span className='d-flex align-items-center justify-content-center'>
													<span className="icon-exclamation-red-sign gray-white-icon icon-h">
														<span className="path1"></span>
														<span className="path2"></span>
														<span className="path3"></span>
														<span className="path4"></span>
													</span>
													<span className="mx-2 high-priority-text">High Priority</span>
												</span>
											</button>
										}
									</div>

									{/* {this.state.priority === 1 ?
										<button className="btn s-bold blu-color high-priority active" name="highPriority"
											onClick={() => { this.setPriority() }}>
											<span className='d-flex align-items-center justify-content-center'>
												<span className="icon-exclamation-red-sign white-red-icon icon-h">
													<span className="path1"></span>
													<span className="path2"></span>
													<span className="path3"></span>
													<span className="path4"></span>
												</span>
												<span className="ml-2 high-priority-text">High Priority</span>
											</span>
										</button>
										:
										<button className="btn s-bold blu-color high-priority " name="highPriority"
											onClick={() => { this.setPriority() }}>
											<span className='d-flex align-items-center justify-content-center'>
												<span className="icon-exclamation-red-sign gray-white-icon icon-h">
													<span className="path1"></span>
													<span className="path2"></span>
													<span className="path3"></span>
													<span className="path4"></span>
												</span>
												<span className="ml-2 high-priority-text">High Priority</span>
											</span>
										</button>
									} */}

								</div>
							</div>

							{/* Task Reference */}
							{this.state.task_id ?
								<>
									{/* <div className="row mt-lg-0 mt-sm-3"> */}
									<div className="row mt-2">
										<div className="col-sm-12">
											<div className="form-main-title-new mb-2">
												<h4 className="form-title-heading-new d-flex align-items-baseline justify-content-between w-100">Task Reference {this.state.ref_mod_id === null && <small className="error-mgs ml-auto">No task reference available</small>}</h4>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-sm-12">
											{this.state.ref_mod_id !== null &&
												<Table responsive className="table-reference mb-0">
													<tbody>
														<tr>
															<td>{this.state.ref_module}</td>
															<td>{this.state.ref_date}</td>
															<td>ID #{this.state.ref_mod_id}</td>
															<td>{this.state.ref_benificiary_name}</td>
															<td className='text-right'>₹{this.state.ref_total} <ArrowUpDownIcon expense={this.state.ref_type} /></td>
															<td className="text-center">
																<div className="mt-1">{this.getStatus()}</div>
																{/* <ReadyStatus />
																<NotReadyStatus />
																<ProcessedStatus />
																<LinkedStatus />
																<SyncedStatus />
																<NotProcessedStatus /> */}
															</td>
															<td>
																<button type="button" className="btn view-ref-btn d-flex align-items-center" onClick={() => this.viewReference()}><span className="icon-view-details white-icon mr-1"></span>View</button>
															</td>
														</tr>
													</tbody>
												</Table>
											}
										</div>
									</div></>
								: null}
							{/* End Task Reference */}
						</div> {/* End form-sec-inner */}
					</div> {/* End form-content-sec */}

					{/* Buttons */}
					<div className="row mt-3">
						<div className="col-sm-12">
							<div className="btn-flex">
								{/* <div className="btn-flex-left"> */}
								<div className="mr-lg-auto">
									{(this.state.task_id && (SessionManagement.getUserDetail().full_name === this.state.assigned_by)) ?
										<Button variant="outline-success reset_btn_new" disabled={this.VIEW_ONLY} onClick={() => { this.toggleDeletePopUp() }}>Delete</Button>
										: null
									}
								</div>

								{/* <div className="btn-flex-right"> */}
								<div className="global_new_rn">
									<Multiselect
										// className="btn"
										onRemove={function noRefCheck() { }}
										onSearch={function noRefCheck() { }}
										onSelect={this.handleStatusChange}
										options={this.state.statusOption}
										isDisabled={this.VIEW_ONLY}
										singleSelect
										// disabled={SessionManagement.getUserDetail().full_name === this.state.assigned_by ? false : true}
										placeholder="Select"
										displayValue="name"
										selectedValues={[
											this.state.statusOption.find((x) => x.value == this.state.status)
										]}
									/>
								</div>

								<Button variant="success apply_btn_new"
									// className={"btn save-btn s-bold greenbtn pull-right " + (this.state.loading ? "position-reltv" : "")}
									disabled={this.state.loading || this.VIEW_ONLY}
									onClick={(e) => this.saveTask(e)}>
									{/* {this.state.loading && (
										<span className="spinner-border spinner-border-sm ml-4"></span>
									)} */}
									{this.state.task_id ? 'Update' : 'Save'}</Button>
								{/* </div> */}
							</div>
						</div>
					</div>
					{/* End Buttons */}

					<CommentsSection
						context_no={6}
						context_name={"task_manager"}
						view_only={this.VIEW_ONLY}
						context_id={this.state.task_id}
						hide_task_manager={true}
						update_communication_bar_tasks_dirty={this.update_communication_bar_tasks_dirty}
						communication_bar_tasks_dirty={this.state.communication_bar_tasks_dirty}
						update_communication_bar_notes_dirty={this.update_communication_bar_notes_dirty}
						communication_bar_notes_dirty={this.state.communication_bar_notes_dirty}
						update_communication_bar_msgs_dirty={this.update_communication_bar_msgs_dirty}
						communication_bar_msgs_dirty={this.state.communication_bar_msgs_dirty}
						update_communication_bar_private_msgs_dirty={this.update_communication_bar_private_msgs_dirty}
						communication_bar_private_msgs_dirty={this.state.communication_bar_private_msgs_dirty}
						onRef={ref => (this.comment_section = ref)} />

				</div> {/* End main-content-inner */}
			</div>

		)
	}
}

function mapStateToProps(state) {
	const all_value = state.TaskManager;
	const task_temp_data = all_value.task_temp_data;

	const session_value = state.Session || {};
	const features_permissions_list = session_value.features_permissions_list || [];
	return {
		task_temp_data, features_permissions_list
	};
}

export default connect(mapStateToProps)(withRouter(CreateTaskForm));