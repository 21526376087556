import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';
import MerchantDropdown from "../../Components/Snapshot/MerchantDropdown";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";
import CurrencyInput from "react-currency-input-field";
// import { TextareaAutosize } from "@material-ui/core";
import { TextareaAutosize } from "@mui/material";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { checkValidGSTNo } from "../../Views/Snapshot/Validation";

import Tooltip from "react-bootstrap/Tooltip";
import ProgressBar from "../BoilerPlate/ProgressBar";


const Row = React.memo(({row,rowIndex,handleStarClick,onMerchantChanged,changeAddNew,all_merchant_list,handleChange,onGSTChange,handleNewCategorySubmit,removeCategory,
    categories,handleCategoryChange,checkForReadyState,isDownload,isUploading,handleTypeChange,snapshot_types,handleDateChange,amountChange,calculateTotalGst,calculateTDS,
  tags,handleMultiSelectChange,handleKeyDown,handleChangeAddNew,customStyles,isIgstChange,handleIgnore,handleDisable,handleRestore,amountRoundOff,handleGstOverrideChange}) => {
    return (
<tr key={rowIndex}>
    {/* STAR */}
    <td
      key={rowIndex + 0}
      className={
        row.disabled || isDownload
          ? "ie_tr_disabled"
          : ""
      }
    >
      <div className="text-center">
        <span
          disabled={
            row.disabled || isDownload
          }
          class={`icon-Star star-td ${row.star.toUpperCase() === "YES"
            ? "active"
            : ""
            }`}
          onClick={() =>
            handleStarClick(rowIndex)
          }
        ></span>
      </div>
    </td>
    {/* Party Name */}
    <td
      key={rowIndex + 1}
      className={row.disabled ? "ie_tr_disabled" : ""}
    >
      <>
        <MerchantDropdown
          isExcel={true}
          global_preidction_status={1}
          merchant_name_prediction_status={1}
          isDisabled={
            row.disabled || isDownload
          }
          selected_option={row.merchant_option}
          selected_value={
            row.merchant_option.value || ""
          }
          onMerchantChanged={(
            option,
            isEdit = false
          ) => {
            console.log(
              "new object options--------------------",
              option
            );
            if (option.label == null)
              option.label = "";
            onMerchantChanged(
              option,
              isEdit,
              rowIndex
            );
          }}
          merchant_list={all_merchant_list}
          is_edit={row.is_edit_supplier}
          is_new={row.is_new}
          new_value={row.new_value}
          changeAddNew={(val) => {
            changeAddNew(val,rowIndex);
          }}
          onBlur={() => {
            checkForReadyState(rowIndex);
          }}
          isHiddenOpt={true}
        />

        {row.errors.merchant_name && (
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-disabled">
                {row.errors.merchant_name}
              </Tooltip>
            }
          >
            <span className="ie_error_dot ie_red_dot"></span>
          </OverlayTrigger>
        )}
        {row.ignoreErrors?.ignore_merchant_name && (
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-disabled">
                {
                  row.ignoreErrors
                    .ignore_merchant_name
                }
              </Tooltip>
            }
          >
            <span className="ie_error_dot ie_yellow_dot"></span>
          </OverlayTrigger>
        )}
      </>
      {/* {!this.state.errors.merchant_name && <small className="error_right">{this.state.errors.merchant_name}</small>} */}
    </td>
    {/* GST NO  */}
    <td
      key={rowIndex + 2}
      className={
        row.disabled || isDownload
          ? "ie_tr_disabled"
          : ""
      }
    >
      <>
        <input
          disabled={
            row.disabled || isDownload
          }
          className="ie_form ie_pgst_pan_reg"
          type="text"
          value={row.gst_no}
          name="gst_no"
          onChange={(e) => {
            handleChange(e, rowIndex);
          }}
          onBlur={(e) => {
            checkForReadyState(rowIndex);
            onGSTChange(e, rowIndex);
          }}
        />

        {row.errors.gst_no && (
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-disabled">
                {row.errors.gst_no}
              </Tooltip>
            }
          >
            <span className="ie_error_dot ie_red_dot"></span>
          </OverlayTrigger>
        )}
      </>
    </td>
    {/* pan num */}
    <td
      key={rowIndex + 3}
      className={
        row.disabled || isDownload
          ? "ie_tr_disabled"
          : ""
      }
    >
      <>
        <input
          className="ie_form ie_pgst_pan_reg"
          type="text"
          name="pan_number"
          value={row.pan_number}
          disabled={
            checkValidGSTNo(row.gst_no) ||
            row.disabled ||
            isDownload
          }
          onChange={(e) => {
            handleChange(e, rowIndex);
          }}
          onBlur={(e) => {
            checkForReadyState(rowIndex);
          }}
        />
        {row.errors.pan_number && (
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-disabled">
                {row.errors.pan_number}
              </Tooltip>
            }
          >
            <span className="ie_error_dot ie_red_dot"></span>
          </OverlayTrigger>
        )}
      </>
    </td>
    {/* Category */}
    <td
      key={rowIndex + 4}
      className={
        row.disabled || isDownload
          ? "ie_tr_disabled"
          : ""
      }
    >
      {/* {console.log("is new ctaegory -------", row.isNewCategory, row.category_id)} */}
      {row.isNewCategory ? (
        <div className="add-new-selectie">
          <input
            disabled={
              row.disabled || isDownload
            }
            className="ie_form"
            type="text"
            value={row.newCategoryName}
            name="newCategoryName"
            // onChange={(e) =>
            //   this.setState({
            //     newCategoryName: e.target.value,
            //   })
            // }
            onBlur={(e) => {
              checkForReadyState(rowIndex);
            }}
            onChange={(e) => {
              handleChange(e, rowIndex);
            }}
            onKeyDown={(e) =>
              handleNewCategorySubmit(
                e,
                rowIndex
              )
            }
          />
          <span
            disabled={
              row.disabled || isDownload
            }
            class="icon-Cross-with-grey-circle ie-add-icon"
            onClick={()=> {
                console.log("removed cate",rowIndex)
                removeCategory(rowIndex)
            }
               
            }
          >
            <span class="path1"></span>
            <span class="path2"></span>
            <span class="path3"></span>
          </span>
        </div>
      ) : (
        <>
          <select
            disabled={
              row.disabled || isDownload
            }
            className="ie_form ie_select_box"
            value={row.category_id}
            onChange={(e) =>
              handleCategoryChange(e, rowIndex)
            }
            name="category_id"
          >
            {/* {!row.category_id && ( */}
            <option value="" disabled hidden>
              Select Categories
            </option>
            {/* )} */}
            <option value="-2" className="ie_add_new">
              Add New Category
            </option>
            {categories.map(
              (category, index) => {
                return (
                  <option
                    key={index}
                    value={category.id}
                  >
                    {category.name}
                  </option>
                );
              }
            )}
          </select>
        </>
      )}
      {row.ignoreErrors?.ignore_category_name && (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              {row.ignoreErrors.ignore_category_name}
            </Tooltip>
          }
        >
          <span className="ie_error_dot ie_yellow_dot"></span>
        </OverlayTrigger>
      )}
    </td>
    {/* Type */}
        <td
        key={rowIndex + 5}
        className={row.disabled ? "ie_tr_disabled" : ""}
        >
        <>
            <select
            disabled={
                row.disabled || isDownload
            }
            className="ie_form ie_select_box"
            value={row.type}
            onChange={(e) => {
                handleTypeChange(e, rowIndex);
            }}
            // disabled={this.props.VIEW_ONLY_SNAPSHOT || !editable || this.props.is_reimbursement}
            name="type"
            >
            <option value="">Select Type</option>
            {snapshot_types.map(
                (type, index) => {
                return (
                    <option key={index} value={type.id}>
                    {type.expense} {type.name}
                    </option>
                );
                }
            )}
            </select>
            {/* {this.props.errors.type && <small className="filter-error-msg">{this.props.errors.type}</small>} */}
            {row.errors.type && (
            <OverlayTrigger
                overlay={
                <Tooltip id="tooltip-disabled">
                    {row.errors.type}
                </Tooltip>
                }
            >
                <span className="ie_error_dot ie_red_dot"></span>
            </OverlayTrigger>
            )}
        </>
        </td>
    {/* Date */}
    <td
      key={rowIndex + 6}
      className={row.disabled ? "ie_tr_disabled" : ""}
    >
      <>
        <DatePicker
          disabled={
            row.disabled || isDownload
          }
          className="ie_form ie_date datepicker-icon"
          showIcon
          selected={
            row.date
              ? moment(
                row.date,
                "DD-MM-YYYY"
              ).toDate()
              : null
          }
          onChange={(date) =>
            handleDateChange(date, rowIndex)
          }
          dateFormat="dd-MM-yyyy" // Set your desired date format
        />
        {row.errors.date && (
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-disabled">
                {row.errors.date}
              </Tooltip>
            }
          >
            <span className="ie_error_dot ie_red_dot"></span>
          </OverlayTrigger>
        )}
      </>
    </td>
    {/* Refrence No */}
    <td
      key={rowIndex + 7}
      className={row.disabled ? "ie_tr_disabled" : ""}
    >
      <>
        <input
          disabled={
            row.disabled || isDownload
          }
          type="text"
          className="ie_form ie_pgst_pan_reg"
          // className={"form-control " + (this.state.errors.invoice_number ? " wrong-red " : "") + (this.state.global_preidction_status && !!this.state.snapshot_number_prediction_status ? " input-bg-green " : "")}
          value={row.invoice_number}
          onBlur={(e) => {
            checkForReadyState(rowIndex);
          }}
          onChange={(e) => {
            handleChange(e, rowIndex);
          }}
          // disabled={this.VIEW_ONLY_SNAPSHOT || !editable}
          name="invoice_number"
        />
        {row.errors.invoice_number && (
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-disabled">
                {row.errors.invoice_number}
              </Tooltip>
            }
          >
            <span className="ie_error_dot ie_red_dot"></span>
          </OverlayTrigger>
        )}
      </>
    </td>

    {/* Total Amount */}
    <td
      key={rowIndex + 8}
      className={row.disabled ? "ie_tr_disabled" : ""}
    >
      <CurrencyInput
        disabled={
          row.disabled || isDownload
        }
        type="text"
        className="ie_form ie_amount form-text-right"
        //		className={"form-control form-text-right" + (this.state.errors.total_amount ? " wrong-red " : "") + (this.state.global_preidction_status && !!this.state.total_amount_prediction_status ? " input-bg-green " : "")}
        name="total_amount"
        value={row.total_amount}
        allowNegativeValue={false}
        autoComplete="off"
        onBlur={async(e) => {
          //	this.fetchSMS()
          await amountRoundOff('total_amount',rowIndex)
          calculateTDS(rowIndex);
          checkForReadyState(rowIndex);
          //this.setApprovalAmt(e)
        }}
        //		disabled={this.VIEW_ONLY_SNAPSHOT || !editable}
        decimalsLimit={2}
        prefix="&#8377; "
        placeholder="&#8377; 0"
        onValueChange={(value, name) =>
          amountChange(name, value, rowIndex)
        }
      />
      {row.errors.total_amount && (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              {row.errors.total_amount}
            </Tooltip>
          }
        >
          <span className="ie_error_dot ie_red_dot"></span>
        </OverlayTrigger>
      )}
    </td>
    {/* Tags */}
    <td
      key={rowIndex + 9}
      className={row.disabled ? "ie_tr_disabled" : ""}
    >
      <Select
        isDisabled={
          row.disabled || isDownload
        }
        className="ie_form_multi"
        isMulti
        options={tags}
        value={(row.tags || []).map((tag) =>
          tags.find(
            (option) => option.value === tag
          )
        )}
        onChange={(selectedOptions) =>
          handleMultiSelectChange(
            selectedOptions,
            rowIndex
          )
        }
        onInputChange={(selectedOptions) =>
          handleChangeAddNew(
            selectedOptions,
            rowIndex
          )
        }
        onKeyDown={(e) =>
          handleKeyDown(e, rowIndex)
        }
        styles={customStyles}
      />
      {row.ignoreErrors?.ignore_tags_name && (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              {row.ignoreErrors.ignore_tags_name}
            </Tooltip>
          }
        >
          <span className="ie_error_dot ie_yellow_dot"></span>
        </OverlayTrigger>
      )}
    </td>
    {/* is perqusite*/}
    <td
      key={rowIndex + 10}
      className={row.disabled ? "ie_tr_disabled" : ""}
    >
      <div className="sup-cust-check">
        <input
          disabled={
            row.disabled || isDownload || (row.type == 11 || row.type == 7 || row.type == 10 || row.type == 1 || row.type == 9)
          }
          className="styled-checkbox"
          type="checkbox"
          // disabled={!!!row.expense}
          onChange={(e) => {
            handleChange(e, rowIndex);
          }}
          name="is_perquisite"
          checked={row.is_perquisite} // assuming cell contains the boolean value
        // onChange={(e) =>
        //   this.handlePerquisitCheckboxChange(
        //     e,
        //     rowIndex
        //   )
        // }
        />
      </div>
    </td>
     {/* is perqusite*/}
     {/*Additional Info*/}
     <td
      key={rowIndex + 11}
      className={row.disabled ? "ie_tr_disabled" : ""}
    >
      <TextareaAutosize
        type="text"
        maxLength="1000"
        className="ie_form ietextarea"
        disabled={row.disabled || row.isDownload}
        //	className={"form-control " + (this.state.errors.additional_information ? " wrong-red " : "")}
        value={row.additional_information}
        minRows={1}
        onChange={(e) => {
          handleChange(e, rowIndex);
        }}
        //	disabled={this.VIEW_ONLY_SNAPSHOT || !editable}
        name="additional_information"
      ></TextareaAutosize>
    </td>
     {/*Addditional Info*/}
     {/* is msme  */}
     <td
      key={rowIndex + 12}
      className={row.disabled ? "ie_tr_disabled" : ""}
    >
      <div className="sup-cust-check lable-input justify-content-end">
        <input
          disabled={
            row.disabled || isDownload
          }
          className="styled-checkbox"
          id="styled-checkbox-msme"
          type="checkbox"
          name="is_msme"
          checked={row.is_msme}
          onChange={(e) => {
            handleChange(e, rowIndex);
          }}
        //	disabled={this.VIEW_ONLY_SNAPSHOT || !editable || (!this.state.is_edit_supplier && !!this.state.merchant_option.value)}
        />
      </div>
    </td>
     {/* is msme */}
   
   {/* cgst Amount */}
   <td
      key={rowIndex + 13}
      className={row.disabled ? "ie_tr_disabled" : ""}
    >
      <CurrencyInput
        type="text"
        className="ie_form ie_csi_gst form-text-right"
        name="cgst_amount"
        value={row.cgst_amount}
        allowNegativeValue={false}
        autoComplete="off"
        onBlur={async() => {
          await amountRoundOff('cgst_amount',rowIndex)
          calculateTotalGst(rowIndex);
          checkForReadyState(rowIndex);
        }}
        disabled={
          row.disabled || isDownload
            ? row.disabled
            : row.is_igst
        }
        decimalsLimit={2}
        prefix="&#8377; "
        placeholder="&#8377; 0"
        onValueChange={(value, name) =>
          amountChange(name, value, rowIndex)
        }
      />
      {row.errors.cgst_amount && !row.is_igst && (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              {row.errors.cgst_amount}
            </Tooltip>
          }
        >
          <span className="ie_error_dot ie_red_dot"></span>
        </OverlayTrigger>
      )}
      {row.errors.same_amount && (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              {row.errors.same_amount}
            </Tooltip>
          }
        >
          <span className="ie_error_dot ie_red_dot"></span>
        </OverlayTrigger>
      )}
    </td>
   {/* cgst amount */}
   {/* Sgst Amount */}
   <td
      key={rowIndex + 15}
      className={row.disabled ? "ie_tr_disabled" : ""}
    >
      <CurrencyInput
        type="text"
        className="ie_form ie_csi_gst form-text-right"
        // disabled={(!!this.state.cgst_amount || !!this.state.sgst_amount) ? "disabled" : ""}
        //	className={"form-control form-text-right mt-1 " + (this.state.errors.igst_amount ? " wrong-red " : "") + (this.state.global_preidction_status && !!this.state.igst_amount_prediction_status ? " input-bg-green " : "")}
        value={row.sgst_amount}
        allowNegativeValue={false}
        decimalsLimit={2}
        prefix="&#8377; "
        placeholder="&#8377; 0"
        onValueChange={(value, name) =>
          amountChange(name, value, rowIndex)
        }
        onBlur={async() => {
          await amountRoundOff('sgst_amount',rowIndex)
          calculateTotalGst(rowIndex);
          checkForReadyState(rowIndex);
        }}
        disabled={
          row.disabled || isDownload
            ? row.disabled
            : row.is_igst
        }
        name="sgst_amount"
      />
      {row.errors.sgst_amount && !row.is_igst && (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              {row.errors.sgst_amount}
            </Tooltip>
          }
        >
          <span className="ie_error_dot ie_red_dot"></span>
        </OverlayTrigger>
      )}
      {row.errors.same_amount && (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              {row.errors.same_amount}
            </Tooltip>
          }
        >
          <span className="ie_error_dot ie_red_dot"></span>
        </OverlayTrigger>
      )}
    </td>
   {/* Sgst Amount */}
   {/* Igst */}
   <td
      key={rowIndex + 16}
      className={row.disabled ? "ie_tr_disabled" : ""}
    >
      <div className="d-flex align-items-center">
        <div className="sup-cust-check lable-input mt-0 ml-2">
          <input
            disabled={
              row.disabled || isDownload
            }
            className="styled-checkbox"
            id="styled-checkbox-igst"
            type="checkbox"
            name="is_igst"
            checked={row.is_igst}
            //	disabled={this.VIEW_ONLY_SNAPSHOT || !editable || this.state.disable_igst}
            onChange={(e) => {
             isIgstChange(e,rowIndex)
            }}
          />
        </div>
        <CurrencyInput
          //disabled={}
          className="ie_form ie_csi_gst form-text-right"
          // disabled={!!this.state.igst_amount ? "disabled" : ""}
          //	className={"form-control form-text-right" + (this.state.errors.sgst_amount ? " wrong-red " : "") + (this.state.global_preidction_status && !!this.state.sgst_amount_prediction_status ? " input-bg-green " : "")}
          value={row.igst_amount}
          allowNegativeValue={false}
          decimalsLimit={2}
          prefix="&#8377; "
          placeholder="&#8377; 0"
          onValueChange={(value, name) =>
            amountChange(name, value, rowIndex)
          }
          is_amount={1}
          onBlur={async() => {
             await amountRoundOff('igst_amount',rowIndex)
            calculateTotalGst(rowIndex);
            checkForReadyState(rowIndex);
          }}
          disabled={
            row.disabled || isDownload
              ? true
              : !row.is_igst
          }
          name="igst_amount"
        />
      </div>
      {row.errors.igst_amount && (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              {row.errors.igst_amount}
            </Tooltip>
          }
        >
          <span className="ie_error_dot ie_red_dot"></span>
        </OverlayTrigger>
      )}
      {row.errors.igst_amount && row.is_igst && (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              {row.errors.igst_amount}
            </Tooltip>
          }
        >
          <span className="ie_error_dot ie_red_dot"></span>
        </OverlayTrigger>
      )}
      {row.errors.igst_amount &&
        row.errors.same_amount && (
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-disabled">
                {row.errors.same_amount}
              </Tooltip>
            }
          >
            <span className="ie_error_dot ie_red_dot"></span>
          </OverlayTrigger>
        )}
    </td>
   {/* Igst */}
   {/* gst claim elligible */}
   <td
      key={rowIndex + 17}
      className={row.disabled ? "ie_tr_disabled" : ""}
    >
      <div className="sup-cust-check lable-input">
        <input
          disabled={
            row.disabled || isDownload
          }
          className="styled-checkbox"
          id="styled-checkbox-igst"
          type="checkbox"
          name="is_gst_claim_eligible"
          checked={row.is_gst_claim_eligible}
          //	disabled={row.disable_igst}
          onChange={(e) => {
            handleChange(e, rowIndex);
          }}
        />
      </div>
    </td>
   {/* gst claim elligible */}
   {/* gst override */}
   <td
      key={rowIndex + 18}
      className={row.disabled ? "ie_tr_disabled" : ""}
    >
      <div className="sup-cust-check lable-input">
          <input
            disabled={
                row.disabled || isDownload || row.hide_gst_override
                }
            className="styled-checkbox"
            id="styled-checkbox-igst"
            type="checkbox"
            name="gst_override"
            checked={row.gst_override}
            //	disabled={row.disable_igst}
            onChange={(e) => {
              handleGstOverrideChange(e, rowIndex);
                }}
          />
      </div>
    </td>

   {/* gst override */}
  {/* tds percentage */}
    <td
      key={rowIndex + 19}
      className={row.disabled ? "ie_tr_disabled" : ""}
    >
        <CurrencyInput
          disabled={
            row.disabled || isDownload || (row.type == 9 || row.type == 10 || row.type == 4 || row.type == 5)
            }
          type="text"
          className="ie_form ie_tds form-text-right"
          //	className={"form-control form-text-right" + (this.state.errors.tds_percentage ? " wrong-red " : "") + (this.state.global_preidction_status && !!this.state.tds_percentage_prediction_status ? " input-bg-green " : "")}
          value={row.tds_percentage}
          allowNegativeValue={false}
          decimalsLimit={2}
          onValueChange={(value, name) => {
                        value > 100
                        ? ""
                        : amountChange(
                            name,
                            value,
                            rowIndex
                            );
                            }}
          onBlur={async() => {
              // this.amountRoundOff('tds_percentage',rowIndex)
              await amountRoundOff('tds_percentage',rowIndex)
              calculateTDS(rowIndex);
              checkForReadyState(rowIndex);
              }}
          is_amount={1}
          //	disabled={this.VIEW_ONLY_SNAPSHOT || !editable}
          name="tds_percentage"
        />
                                {/* {this.state.errors.tds_percentage && <small className="error_right">{this.state.errors.tds_percentage}</small>} */}
    </td>

  {/* tds percentage */}
    
    <td>
      {isUploading ? (
        <div className="ie_ProgressBar">
          <ProgressBar
            key={rowIndex + 19}
            bgcolor={"#77d7b1"}
            completed={
           100
            }
          />
        </div>
      ) : 100 > 0 ? (
        row.disabled ? (
          <span className="ie_status_msg">
            {" "}
            Deleted{" "}
          </span>
        ) : (
          <span className="ie_status_msg">
            {" "}
            {row["STATUS"] === "Uploaded" ? (
              <span style={{ color: "green" }}>
                {row["STATUS"]}
              </span>
            ) : (
              <span style={{ color: "red" }}>
                {row["STATUS"]}
              </span>
            )}
          </span>
        )
      ) : row.disabled || isDownload ? (
        <span className="ie_status_msg">
          {" "}
          Deleted{" "}
        </span>
      ) : (
        <span className="ie_status_msg">
          {" "}
          Waiting...{" "}
        </span>
      )}
    </td>
    <td>
      <div className="ie_icon_up_btn">
        {row.ignoreErrors &&
          Object.keys(row.ignoreErrors).length > 0 ? (
          <OverlayTrigger
            overlay={
              <Tooltip
                id={`tooltip-disabled-${rowIndex}`}
              >
                Ignore
              </Tooltip>
            }
          >
            <span
              class="icon-warning ie_icon_btn yellow-icon"
              onClick={() =>
                handleIgnore(rowIndex)
              }
            ></span>
          </OverlayTrigger>
        ) : (
          <span class="icon-warning ie_icon_btn gray-icon cmenu"></span>
        )}
        {row.disabled ? (
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-disabled">
                Restore
              </Tooltip>
            }
          >
            <span
              class="icon-Exports-green undu-icon blue-icon ie_icon_btn"
              onClick={() =>
                handleRestore(rowIndex)
              }
            ></span>
          </OverlayTrigger>
        ) : (
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-disabled">
                Delete
              </Tooltip>
            }
          >
            <span
              class="icon-delete red-icon ie_icon_btn"
              onClick={() =>
                handleDisable(rowIndex)
              }
            ></span>
          </OverlayTrigger>
        )}
      </div>
    </td>
  </tr>
    )
})

class TableComponent extends Component{
    constructor(props) {
        super(props);
        const{excelData}  = this.props
    }

    render(){
        const { excelData,handleStarClick, onMerchantChanged,changeAddNew,checkForReadyState,handleChange,onGSTChange,handleNewCategorySubmit,removeCategory,
            categories,handleCategoryChange,isDownload,all_merchant_list,isUploading,handleTypeChange,snapshot_types,handleDateChange,amountChange,calculateTotalGst,
          calculateTDS,tags,handleMultiSelectChange,handleKeyDown,handleChangeAddNew,customStyles,isIgstChange,handleIgnore,handleDisable,handleRestore,amountRoundOff,handleGstOverrideChange} = this.props;
        return(
            <Table className="  ">
            <thead>
                  <tr>
                    {excelData.length > 0 && (
                      <>
                        <th key={0} className="text-center">
                          Star
                        </th>
                        <th key={1}>Party Name</th>
                        <th key={2}>Party GST</th>
                        <th key={3}>Party PAN</th>
                        <th key={4}>Category</th>
                        <th key={5}>Type</th>
                        <th key={6} className="ie_date">
                          Date
                        </th>
                        <th key={7}>Reference No</th>
                        <th key={8} className="ie_amount">
                          Total Amount
                        </th>
                        <th key={9}>Tags</th>
                        <th key={10}>Perquisite</th>
                        <th key={11}>Additional Info</th>
                        <th key={12}>MSME</th>
                        <th key={13} className="ie_csi_gst">
                          CGST
                        </th>
                        <th key={14} className="ie_csi_gst">
                          SGST
                        </th>
                        <th key={15} className="ie_csi_gst">
                          IGST
                        </th>
                        <th key={16}>GST Eligible</th>
                        {/* <th key={17} className="ie_tds"> */}
                        <th key={17}>GST Override</th>
                        <th key={18} className="ie_tds">
                          TDS %
                        </th>
                        <th key={19}>Status</th>
                        <th className="ie_action">Action</th>
                      </>
                    )}
                  </tr>
            </thead>
            <tbody>
                {console.log("printing",excelData)}
              {
                excelData.length > 0 &&
                excelData.map((row,rowIndex)=> (
                    
                  <Row
                    key={rowIndex}
                    row={row}
                    rowIndex={rowIndex}
                    isDownload = {isDownload}
                    isUploading = {isUploading}
                    handleStarClick = {handleStarClick}
                    onMerchantChanged = {onMerchantChanged}
                    checkForReadyState = {checkForReadyState}
                    all_merchant_list = {all_merchant_list}
                    handleChange = {handleChange}
                    onGSTChange = {onGSTChange}
                    amountChange = {amountChange}
                    calculateTotalGst = {calculateTotalGst}
                    changeAddNew = {changeAddNew}
                    handleNewCategorySubmit= {handleNewCategorySubmit}
                    removeCategory = {removeCategory}
                    handleCategoryChange = {handleCategoryChange}
                    categories = {categories}
                    handleTypeChange = {handleTypeChange}
                    snapshot_types= {snapshot_types}
                    handleDateChange = {handleDateChange}
                    calculateTDS = {calculateTDS}
                    tags = {tags}
                    handleKeyDown = {handleKeyDown}
                    handleMultiSelectChange = {handleMultiSelectChange}
                    handleChangeAddNew = {handleChangeAddNew}
                    customStyles = {customStyles}
                    isIgstChange = {isIgstChange}
                    handleIgnore = {handleIgnore}
                    handleDisable = {handleDisable}
                    handleRestore = {handleRestore}
                    amountRoundOff = {amountRoundOff}
                    handleGstOverrideChange = {handleGstOverrideChange}

                  />
                ))
              }
            </tbody>
</Table>
        )
    }
}

function mapStateToProps(state) {
    const session_values = state.Session || {};
    const entity_gst_no = session_values.entity_config_param.gst_no || null;
    const amount_round_off = session_values.entity_config_param.amount_round_off;
    const features_permissions_list =
      session_values.features_permissions_list || [];
    const accounting_mode =
      session_values.entity_config_param.accounting_mode || 1;
    return {
      entity_gst_no,
      features_permissions_list,
      accounting_mode,
      amount_round_off
    };
  }


export default connect(mapStateToProps)(withRouter(TableComponent));