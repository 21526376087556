import React from 'react';
import { Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { displayDateFormate } from '../../Helpers/DateFormat';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import SortingIcon from '../IcoonNew/SortingIcon';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { showAmount } from '../../Helpers/Currency';
import ReactDOM from 'react-dom';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Steps } from "intro.js-react";

//import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';


import hdfc_bnk_fevi from '../../Assets/img/hdfc-fevicon.png';
import { toast } from 'react-toastify';
import FinancialInstituteService from '../../Services/FinancialInstitutes/FinancialInstituteService';
import Modal from 'react-bootstrap/Modal';
import AccountDetailsHeader from './AccountDetailsHeader';
import AccountStatementList from './AccountStatementsList';
import { setFIRequestId } from '../../Redux/Actions/FinancialInstitute';
import { setAppilcationLoder } from '../../Redux/Actions/App';
import { FI_UPLOAD_ACCOUNT_STATEMENT_CODE, FI_VIEW_ACCOUNT_STATEMENT_CODE } from '../../Helpers/Constants';
import BreadCrum from '../../Components/BoilerPlate/BreadCrum';
import AccountStatementTransactions from './AccountStatementTransactions';
import FinancialYear from "../../Components/BoilerPlate/FinancialYear";
import { confirm as oldconfirm } from '../../Components/BoilerPlate/Confirm';
import { Button, Dropdown } from 'react-bootstrap';
import BulkRouteLeavingGuard from '../../Utils/BulkRouteLeavingGuard';
import { setBankingTransactionList } from "../../Redux/Actions/FinancialInstitute";
import { confirm } from "react-confirm-box";

// Intro js user guid
import { render } from "react-dom";

const option = {
    render: (message, onConfirm, onCancel) => {
        return (
            <>
                {/* <h1> Replace with {message} </h1>
		        <button onClick={onConfirm}> Yes </button> */}
                <Modal
                    show={true}
                    backdrop="static"
                    keyboard={false}
                    centered
                    // className="save-model-sec er-pop"
                    className="popup-model error-popup"
                >
                    <Modal.Body className="clearfix ">
                        <p className="pmsg-blue-center">
                            Alert
                        </p>
                        <p className="pmsg-text pmsg-gray-center">
                            {message}
                        </p>

                        <div className="center_apply_btn_new">
                            <Button onClick={onCancel} variant="outline-success reset_btn_new">No</Button>
                            <Button onClick={onConfirm} variant="success apply_btn_new">Yes</Button>
                        </div>
                    </Modal.Body>

                    {/* <Modal.Footer className='bottom-save-btn'>
                        <Button onClick={onCancel} variant="" className={"btn model-save-btn save-btn graybtn alert"}>No</Button>
                        <Button onClick={onConfirm} variant="" className="btn save-btn greenbtn model-save-btn">Yes</Button>
                    </Modal.Footer> */}
                </Modal>
            </>
        );
    }
};

const td_style = { whiteSpace: 'normal', wordWrap: 'break-all' };  // added this line from AccountStatementsLists.jsx

class BulkAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
            closed: false,   
            is_dirty : false,
            show_select_all : false,
            selected_items: [],
              total_count: '',
              total_pages: '',
              sort_by: 3,
              sort_order: 2,
              fi_id: this.props.match.params.financial_institute_id,
            stepsEnabled: false,
            initialStep: 0,
            steps: [
            {
                element: ".dropdown_bankin",
                intro: "You can select the time period to view transactions only for that.",
                position: 'right',
                hideNext: true
            },
            {
                element: ".widgets_bankin",
                intro: "This provided you with your bank details and balances."
            },
            {
                element: ".net_bankin",
                intro: "You can directly fetch your bank statements by clicking here and giving the required credentials.",
                position: 'left'
            },
            {
                element: ".uploadpdf_bankin",
                intro: "You also have an option of uploading the bank provided PDF bank statement here. We can read that too ! Dont worry if you have uploaded the same statement twice. We ignore the duplicates.",
                position: 'left'
            },
            {
                element: ".table_bankin",
                intro: "This table gives you all the transactions. In case you have missed to upload transactions for any interim period, you can see a red line between two transactions.",
                // position: 'bottom'
            }
            ],
    };

  }
  
resetSelection = () => {
        this.setState({ selected_items: [], show_select_all: false })
        this.props.dispatch(setBankingTransactionList([]));
    }
  saveBeforeLeave = (nextRoute) => {
    this.setState({ save_and_redirect: true, next_route: nextRoute }, function () {
    });
}
closeAction = () => {
    this.setState({ closed: false })
}
    componentDidMount = () =>{
        // this.props.onRef(this);
        this.props.getFinancialInstituteDetails(this.props.match.params.financial_institute_id);
        // this.props.getAccountStatementTransactions(this.props.match.params.financial_institute_id, 1)
        this.props.getAccountStatementMount(this.props.match.params.financial_institute_id, 1)
        this.setInitialRowsValue();
    }

  componentDidUpdate(prevProps) {	
    if (prevProps.page_no != this.props.page_no) {
        this.setInitialRowsValue();
    }
}
    componentWillUnmount(){
        this.props.dispatch(setBankingTransactionList([]))
    }
    setInitialRowsValue = () => {
    
        //set preselected rows
        let selected_items = this.props.bulk_transaction_list.map(trans => trans.id);
        this.setState({ selected_items })

    }

    checkBulkFlag = async()=>{
      if (this.props.bulk_transaction_list.length) {
    
        //const result = await confirm("This action will clear the selection. Do you want to continue?", options);
        if (await confirm("This action will clear the selection. Do you want to continue?", option
        )) {
            //resetSelection
            this.resetSelection();
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
    
    }


    onRowSelect = (rows, isSelected) => {
        console.log("selected ", rows , isSelected)
        let selected_items = this.state.selected_items;
        let bulk_transaction_list = this.props.bulk_transaction_list;
        const selectedDate = rows.date; 
        const sameDateTransactions = this.props.transaction_list.filter((item) => item.date == selectedDate);
            console.log("selected same date",sameDateTransactions)

        if (isSelected) {
          // selected_items.push(rows.id);
          // bulk_transaction_list.push(rows);
          sameDateTransactions.forEach((transaction) => {
            if (!selected_items.includes(transaction.id)) {
                selected_items.push(transaction.id);
                bulk_transaction_list.push(transaction);
            }
        });

        } else {
            // selected_items = selected_items.filter(item => item != rows.id);
            // bulk_transaction_list = bulk_transaction_list.filter(item => item.id != rows.id);
            selected_items = selected_items.filter(
              (itemId) =>
                  !sameDateTransactions.some((transaction) => transaction.id === itemId)
          );
  
          bulk_transaction_list = bulk_transaction_list.filter(
              (item) => item.date !== selectedDate
          );

        }

        this.props.dispatch(setBankingTransactionList(bulk_transaction_list));
        this.setState({
            selected_items: selected_items,is_dirty:true
        });

        return selected_items;
    }

    onRowSelectAll = (is_selected, rows) => {
        if (is_selected) {
            let bulk_transaction_list = [...rows, ..._.cloneDeep(this.props.bulk_transaction_list)];
            // let selected_items = bulk_transaction_list.map(data => data.id);
            let selected_items =  rows.map((data)=> data.id)
            this.setState({ selected_items: [...this.state.selected_items, ...selected_items], show_select_all: true,is_dirty:true });
            this.props.dispatch(setBankingTransactionList(bulk_transaction_list));
        } else {
            let selected_items = this.props.transaction_list.map(data => data.id);
            let bulk_transaction_list = this.props.bulk_transaction_list.filter(transaction => { return !selected_items.includes(transaction.id); });
            let items = this.state.selected_items.filter(id => { return !selected_items.includes(id); });
            this.setState({ selected_items: items });
            this.props.dispatch(setBankingTransactionList(bulk_transaction_list));

        }
    }
    resetTransactions = async() =>{
        if (await oldconfirm({
              confirmation:  ` Do you wish to delete selected transactions? All transactions for selected dates will be deleted.`
            })) {
                this.props.dispatch(setAppilcationLoder(true));
                const uniqueDates = new Set();
                    const transactionsWithUniqueDates = [];

                    this.props.bulk_transaction_list.forEach(tx => {
                        if (!uniqueDates.has(tx.date)) {
                            uniqueDates.add(tx.date);
                            // transactionsWithUniqueDates.push({ date: tx.date });
                            transactionsWithUniqueDates.push( tx.date);
                        }
                    });
                    console.log("unique dates", uniqueDates,transactionsWithUniqueDates)
                    let payload_data = {
                      "dates" : transactionsWithUniqueDates,
                      "id": this.props.match.params.financial_institute_id
                    }
                    console.log(payload_data)
                  FinancialInstituteService.deleteTransaction(payload_data).then(async(data)=>{
                          this.props.dispatch(setAppilcationLoder(false));
                          if(data.response_code == 200){
                            const total_Count = this.props.bulk_transaction_list.length;
                            const deleted_Count =  data.result.deleted_transactions //data.result.deleted_Count;
                            const not_deleted_Count = (this.props.bulk_transaction_list.length - deleted_Count)
                            // if(deleted_Count > 0){
                            //       await oldconfirm({
                            //         confirmation:  `Successfully deleted ${deleted_Count} Transactions`,
                            //         options: { type: "alert" }
                            //     })
                            //  }

                            if(deleted_Count > 0){
                              await oldconfirm({
                                        confirmation:  `Deleted ${deleted_Count} transactions. Some transactions may not have been deleted as they have accounting data linked.`,
                                        options: { type: "alert" }
                                    })
                            }

                             if(deleted_Count == 0){
                                  await oldconfirm({
                                    confirmation:  `Could not delete transactions. The transactions could not be deleted as they have accounting data linked.`,
                                    options: { type: "alert" }
                                })
                            }
                
                            // toast.success(`Succssfully deleted ${deleted_Count}/${total_Count} snapshots`);
                            // if(not_deleted_Count > 0){
                            //   await oldconfirm({
                            //     confirmation: `${not_deleted_Count} record(s) are having data associated with them so they were not deleted`,
                            //     options: { type: "alert" }
                            // })
                            // }
                           // sendLog({module_type: 1, module_id: resp_data.result.id, activity: ['Snapshot Deleted using bulk action']})
                            this.setState({selected_items: []});
                            this.props.dispatch(setBankingTransactionList([]));
                            this.props.refreshTransactionsList(this.props.match.params.financial_institute_id)
                
                
                          }else{
                            this.setState({selected_items: []});
                            this.props.dispatch(setBankingTransactionList([]));
                            toast.error(data.message)
                          }
                        })
                
        }else {
              this.setState({selected_items : []})
              this.props.dispatch(setBankingTransactionList([]))
              return true;
            }
    }
  getStatus = () => {
    if (this.props.financial_institute_info.response_status == undefined) {
      return ""
    } else if (this.props.financial_institute_info.response_status == 1) {
      return "SUCCESS"
    } else if (this.props.financial_institute_info.response_status == 0) {
      return "PENDING"
    } else {
      return "ERROR"
    }
  }
   // Intro js user guid   
   toggleSteps = () => {
    this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }));
  };
  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };
  // End Intro js user guid

  onSortClick = (sort_order, sort_by) => {
    this.props.onSortClick(sort_order, sort_by)
  }

  onSortChange(sortName, sortOrder) {
    this.props.onSortClick(sortName, sortOrder)
  }

  getCaret = (direction) => {
    console.log(direction)
    switch (direction) {
      case 'asc':
        return (<span className="icon-right-arrow-1 sort-topbottom-icon"></span>);
      case 'desc':
        return (<span className="icon-right-arrow sort-topbottom-icon"></span>);
      default:
        return (<span className="icon-Group-4449 sort-both-icon"></span>);
    }
  }

  revertSortFunc(a, b, order) {   // order is desc or asc
    if (order === 'desc') {
      return a.amount - b.amount;
    } else {
      return b.amount - a.amount;
    }
  }

  changeCredit(cell, row) {
    if (row.type == 1) {
      return cell
    }
    else {
      return "-"
    }
  }

  changeDebit(cell, row) {
    if (row.type == 1) {
      return "-"
    }
    else {
      return cell
    }
  }

  trStyleChange(row, sort_order) {
    if (!!row.is_missing_data == 1) {
      let line_position = sort_order == 1 ? "-8px" : "8px";
      return {
        //backgroundColor: '#fbceb1',
        //borderWidth:"1px", borderColor:"red", borderStyle:'solid'
        //boxShadow: "0 7px 5px -6px red"
        //boxShadow: "1px 3px 1px #9E9E9E"
        boxShadow: "0 " + line_position + " 4px -5px red"
      }
    }
    else {
      return null
    }
  }

  handlePageChange = (page_no) => {
    this.props.handlePageChange(page_no)
  }

  renderShowsTotal(start, to, total) {
    return (
      <p className="main-pagi-show">
        <span className="pagi-show">{start}</span> <span style={{ padding: 1 }}>to</span> <span className="pagi-show">{to}</span> <span style={{ padding: 1 }}>of</span> <span className="pagi-show">{total}</span>
      </p>
    );
  }

  dataFormatDebitAmount = (cell, row) => {
    if (!!row.is_missing_data == 1) {
      if (row.type == 2) {
        return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="" style={{ color: 'red' }}>{"There are some missing entries " + (this.props.sort_order == 1 ? "before" : "after") + " this transaction."}</Tooltip>}><div><span title={showAmount(row.amount)}>{showAmount(row.amount)}</span></div></OverlayTrigger>
      } else {
        return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{"There are some missing entries " + (this.props.sort_order == 1 ? "before" : "after") + " this transaction."}</Tooltip>}><div><span title={showAmount(row.amount)}>-</span></div></OverlayTrigger>
      }
    } else {
      if (row.type == 2) {
        return <span>{showAmount(row.amount)}</span>
      } else {
        return '-'
      }
    }
  }

  dataFormatCreditAmount = (cell, row) => {
    if (!!row.is_missing_data == 1) {
      if (row.type == 1) {
        return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{"There are some missing entries " + (this.props.sort_order == 1 ? "before" : "after") + " this transaction."}</Tooltip>}><div><span title={showAmount(row.amount)}>{showAmount(row.amount)}</span></div></OverlayTrigger>
      } else {
        return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{"There are some missing entries " + (this.props.sort_order == 1 ? "before" : "after") + " this transaction."}</Tooltip>}><div><span title={showAmount(row.amount)}>-</span></div></OverlayTrigger>
      }
    } else {
      if (row.type == 1) {
        return <span>{showAmount(row.amount)}</span>
      } else {
        return '-'
      }
    }
  }

  dataFormatDate = (cell, row) => {
    if (!!row.is_missing_data == 1) {
      return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{"There are some missing entries " + (this.props.sort_order == 1 ? "before" : "after") + " this transaction."}</Tooltip>}><div><span title={displayDateFormate(row.date)}>{displayDateFormate(row.date)}</span></div></OverlayTrigger>
    } else {
      return displayDateFormate(row.date)
    }
  }

  dataFormatNarration = (cell, row) => {
    if (row.is_missing_data == 1) {
      return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className=""><>{"There are some missing entries " + (this.props.sort_order == 1 ? "before" : "after") + " this transaction."}</><br /><>{row.narration}</></Tooltip>}><div className="ellips-auto">{row.narration}</div></OverlayTrigger>
    } else {
      return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{row.narration}</Tooltip>}>
        <div className="ellips-auto">{row.narration}</div>
      </OverlayTrigger>
    }
  }

  dataFormatBalance = (cell, row) => {
    if (!!row.is_missing_data == 1) {
      return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{"There are some missing entries " + (this.props.sort_order == 1 ? "before" : "after") + " this transaction."}</Tooltip>}><div><span title={row.balance_after_transaction}>{row.balance_after_transaction}</span></div></OverlayTrigger>
    } else {
      return <span>{showAmount(row.balance_after_transaction)}</span> ? <span>{showAmount(row.balance_after_transaction)}</span> : "-"
      // return row.balance_after_transaction?row.balance_after_transaction: "-"
    }
  }

	customMultiSelect(props) {
		const { type, checked, disabled, onChange, rowIndex } = props;
		/*
		* If rowIndex is 'Header', means this rendering is for header selection column.
		*/
		if (rowIndex === 'Header') {
			return (
				<div className='checkbox-personalized'>
					<Checkbox {...props} />
					<label htmlFor={'checkbox' + rowIndex}>
						<div className='check'></div>
					</label>
				</div>);
		} else {
			return (
				<div className='checkbox-personalized'>
					<input
						type={type}
						name={'checkbox' + rowIndex}
						id={'checkbox' + rowIndex}
						checked={checked}
						disabled={disabled}
						onChange={e => onChange(e, rowIndex)}
						ref={input => {
							if (input) {
								input.indeterminate = props.indeterminate;
							}
						}} />
					<label htmlFor={'checkbox' + rowIndex}>
						<div className={'check ' + (disabled ? ' disable-bg ' : '')}></div>
					</label>
				</div>);
		}
	}

  render() {
    let transaction_list = this.props.transaction_list || []
    let is_dirty = this.state.is_dirty
		if(this.state.selected_items.length == 0){
		  is_dirty = false
		}else{
		  is_dirty = true
		}
    const selectRowProp = {
        // columnWidth: '100px',
        columnWidth: '50px',
        onSelect: this.onRowSelect,
        onSelectAll: this.onRowSelectAll,
        mode: 'checkbox',
        selected: this.state.selected_items,
        clickToSelect: true,
        // onSelect: this.handleRowSelect
        customComponent: this.customMultiSelect
    };
    const options = {
      onPageChange: this.props.handlePageChange,
      page: this.props.page_no,
      sizePerPage: this.props.per_page_items,
      pageStartIndex: 1,
      paginationSize: 5,
      prePage: 'Prev',
      nextPage: 'Next',
      firstPage: 'First',
      lastPage: 'Last',
      hideSizePerPage: true,
      //paginationShowsTotal: this.renderShowsTotal,
      //   onRowMouseOver: (row) => {
      //     if(!!row.is_missing_data == 1){
      //       return	<OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">aaa</Tooltip>}>
      // 		<span>aa</span>
      // </OverlayTrigger>
      //     }
      //     else{
      //       return	<OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">aaa</Tooltip>}>
      // 		<span>aa</span>
      // </OverlayTrigger>
      //     }
      //   }
    };
    const {
        stepsEnabled,
        steps,
        initialStep,
        hintsEnabled,
        hints
      } = this.state;
      // End Intro js user guid 
      //console.log(this.props)
  
    return (
        <main className="content-wrapper-new">
        <div className="container-fluid container-padding-new">
          {/* Intro js user guid */}
          <Steps
            enabled={stepsEnabled}
            steps={steps}
            initialStep={initialStep}
            onExit={this.onExit}
          />

          {/* End Intro js user guid */}
                <BulkRouteLeavingGuard
                    when={is_dirty}
                    saveChanges={this.saveBeforeLeave}
                    closed={this.state.closed}
                    closeAction={this.closeAction}
                />
          {/* Breadcrumb */}
          <div className="row">
            <div className="col-12">
              <div className="main-sub-header-new">
                <div className="breadcrumb-header-new">
                  <div className="row">
                    <div className="col-12">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item-new"><Link to={'/home'}>Home</Link></li>
                        <li className="breadcrumb-item-new"><Link to='/financial-institutes'>Banking</Link></li>
                        <li className="breadcrumb-item-new">Account Statements</li>
                      </ol>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <h2 className="breadcrumb-title-new">
                        Account Statements
                        {/* <span className="help-icon" > */}
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Help</Tooltip>}><span className="icon-Information help-icon" onClick={this.toggleSteps}></span></OverlayTrigger>
                        {/* </span> */}
                      </h2>
                    </div>
                    <div className="col-9 d-flex align-items-center justify-content-end gap-10">
                    {(this.props.bank_modal && this.props.card_details.is_credit_card == 1) ? <span className='error_right mb-2'>Currently not supported credit cards.</span> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Breadcrumb */}

          {/* Filter */}
          <div className="row mainFilter-new sec-mb">
            <div className="col-12 d-flex flex-wrap align-items-center gap-10">
              <div className="top-new-filter">
                <FinancialYear
                  financialYearChanged={this.props.financialYearChanged}
                  refreshItem={this.props.refreshTransactionsList}
                  dateTypeChanged = {this.props.dateTypeChanged}
                  date_type = {this.props.date_type}
                  checkBulkFlag = {this.checkBulkFlag}
                />
              </div>
            { this.props.financial_institute_info.gap_count != 0 && this.props.financial_institute_info.gap_count ? 
             <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled"><span className="gap-count-date">&nbsp;{this.props.financial_institute_info.gap_date}</span></Tooltip>}>
                <div className="gap-count-note">
                  <span className="gap-count-badge">{this.props.financial_institute_info.gap_count}</span>
                  <span className="gap-count-text">Gap{this.props.financial_institute_info.gap_count>1?'s':''} found in the statement</span>
                </div>
              </OverlayTrigger>:null}

            </div>
          </div>
          {/* End Filter */}

          {/* Account Details Header */}
          <AccountDetailsHeader
            bank_modal = {this.props.bank_modal}
            ledgerNameList={this.props.ledgerNameList}
            fi_details={this.props.fi_details}
            card_details={this.props.card_details}
            show_edit={true}
            updateList={this.props.updateList}
            bank_name_list={this.props.bank_name_list}
            FIList={this.props.FIList}
            getFinancialInstituteDetails={this.props.getFinancialInstituteDetails}
            financial_institute_info={this.props.financial_institute_info}
            default_entity_detail={this.props.default_entity_detail}
            checkBulkFlag = {this.checkBulkFlag}
          />
          {/* End Account Details Header */}

          {/* Buttons Bar */}
          <div className="row sec-mb">
            {/* Note */}
            <div className="col-xl-7 col-lg-7 col-12 d-flex align-items-center pr-0">
              <p className="size-xvi s-sbold blue-text mb-lg-0">
                {this.props.features_permissions_list.includes(FI_UPLOAD_ACCOUNT_STATEMENT_CODE) &&
                  <>
                    {this.props.fi_details.is_credit_card != undefined && this.props.fi_details.is_credit_card != 0 ? <span>Note: Kindly ensure you have uploaded all credit card statements.<br></br></span> : ""}

                    {this.props.financial_institute_info.response_status != undefined && "Last Status: " + this.getStatus() + " - " + (this.props.financial_institute_info.response_status == 1 ? "Bank statement updated successfully" : this.props.financial_institute_info.errors) + " " + "(" + this.props.financial_institute_info.updated_at + ")"}
                  </>
                }
              </p>
            </div>
            {/* End Note */}

            {/* Buttons */}
            <div className="col-xl-5 col-lg-5 col-12">
              <div className="reset_apply_btn_new">

             
               

                <Dropdown className='action-new-menu'>
                  <Dropdown.Toggle variant="" id="dropdown-basic" className='success add-new-btn action-btn-height'>
                    <span class="icon-menu-lines white-icon"></span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                  <button onClick={async() => {
                    if (await this.checkBulkFlag()) {
                        return true;
                    }
                    this.props.handleTabChange(1, this.props.match.params.financial_institute_id)} }>
                      <span class="action-new-icon-sec">
                        <span class="icon-invoice1 action-new-icon"><span class="path1"></span><span class="path2"></span></span>
                      </span>
                      <span> Bank Statement</span>
                    </button>
                    <hr />
                    <button onClick={async() => {
                        if (await this.checkBulkFlag()) {
                            return true;
                        }
                        this.props.handleTabChange(2, this.props.match.params.financial_institute_id)}} >
                      <span class="action-new-icon-sec">
                        <span class="icon-delete action-new-icon"><span class="path1"></span><span class="path2"></span></span>
                      </span>
                      <span> Bulk Action</span>
                    </button>
                    <hr />
                    <button onClick={async() => {
                        if (await this.checkBulkFlag()) {
                            return true;
                        }
                        this.props.getAccountStatementTransactions(this.state.fi_id, this.props.page_no, 1)}} >
                      <span class="action-new-icon-sec">
                        <span class="icon-Exports action-new-icon"><span class="path1"></span><span class="path2"></span></span>
                      </span>
                      <span>Export List</span>
                    </button>
                    <hr />
                    {!this.props.hideRefreshItem &&
                      <button onClick={async() => {
                        if (await this.checkBulkFlag()) {
                            return true;
                        }
                        this.props.refreshTransactionsList()}}>
                        <span class="action-new-icon-sec"><span class="icon-refresh action-new-icon"></span></span>
                        <span>Refresh</span>
                      </button>
                    }
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {/* End Buttons */}
          </div>
          {/* Buttons Bar */}

          {/* Bank Popup */}
         
          {/* End Bank Popup */}

          {/* Table List */}
          <div className="row main-table-listing sec-mb">
            <div className="col-12">
              <div className="table-sec pointer-none table_bankin">
                {this.props.transaction_list.length === 0 ?
                  <h2 className="no-item-list">No items to display</h2>
                  :  <BootstrapTable ref='table' data={transaction_list}
                  keyField='id'
                  options={options}
                  fetchInfo={{ dataTotalSize: this.props.total_count }}
                  remote
                  // hover
                  pagination={true}
                  // className="btable"
                  trStyle={(row) => this.trStyleChange(row, this.props.sort_order)}
                  //style={{}}
                  selectRow={selectRowProp}
                  
                >
                  <TableHeaderColumn width="115px" tdStyle={td_style} headerAlign='center' dataAlign='center' dataFormat={(cell, row) => this.dataFormatDate(cell, row)}>
                    <SortingIcon onSortClick={this.onSortClick} field_name="Date" field_number="1" sort_by={this.props.sort_by} sort_order={this.props.sort_order} />
                  </TableHeaderColumn>
                  <TableHeaderColumn thStyle={{ ...td_style, width: "32%" }} tdStyle={{ ...td_style, width: "32%", overflow: "hidden" }} headerAlign='left' dataAlign='left' dataFormat={(cell, row) => this.dataFormatNarration(cell, row)}>
                    <SortingIcon onSortClick={this.onSortClick} field_name="Narration" field_number="5" sort_by={this.props.sort_by} sort_order={this.props.sort_order} />
                  </TableHeaderColumn>
                  {/* <TableHeaderColumn tdStyle={td_style} headerAlign='left' dataAlign='left' dataField='remark'>
                      <SortingIcon onSortClick={this.onSortClick} field_name="Remark" field_number="3" sort_by={this.props.sort_by} sort_order={this.props.sort_order} />     
                  </TableHeaderColumn> */}
                  <TableHeaderColumn tdStyle={td_style} headerAlign='right' dataAlign='right' dataFormat={(cell, row) => this.dataFormatDebitAmount(cell, row)}>
                    <SortingIcon onSortClick={this.onSortClick} field_name="Debit" field_number="3" sort_by={this.props.sort_by} sort_order={this.props.sort_order} />
                  </TableHeaderColumn>
                  <TableHeaderColumn tdStyle={td_style} headerAlign='right' dataAlign='right' dataFormat={(cell, row) => this.dataFormatCreditAmount(cell, row)}>
                    <SortingIcon onSortClick={this.onSortClick} field_name="Credit" field_number="2" sort_by={this.props.sort_by} sort_order={this.props.sort_order} />
                  </TableHeaderColumn>
                  <TableHeaderColumn tdStyle={td_style} headerAlign='right' dataAlign='right' dataFormat={(cell, row) => this.dataFormatBalance(cell, row)} >
                    <SortingIcon onSortClick={this.onSortClick} field_name="Closing Bal." field_number="4" sort_by={this.props.sort_by} sort_order={this.props.sort_order} />
                  </TableHeaderColumn>
                </BootstrapTable>
                }
              </div>
            </div>
          </div>
          {/* End Table List */}
                {/* Listing Bottom Fixed Bar */}
                    {this.props.transaction_list.length != 0 &&
                        <div className="fix-button-bar-bottom">
                            {this.props.bulk_transaction_list.length != 0 &&
                                <button type="button"
                                    onClick={async () => { if (await oldconfirm({ confirmation: "This action will clear the selection. Do you want to continue?" })) { this.resetSelection() } }}
                                    className={"transpnt-btn " + (this.props.bulk_transaction_list.length == 0 ? " disabled" : "")}
                                >Reset all</button>
                            }
                            {/* {(this.state.show_select_all && this.props.total_count > 25)&&
                                <button type="button" className={"transpnt-btn "} onClick={this.props.selectAllTransactions}>Select across all pages</button>
                            } */}

                            <p className="fbb-p">Selected: {this.props.bulk_transaction_list.length + "/" + this.props.total_count}</p>
                            <div className="bottom-save-btn">
                                {!!this.props.bulk_transaction_list.length ?
                                <button type="button" class="btn btn-success apply_btn_new " onClick={this.resetTransactions}>Delete Transactions</button>
                                    
                                    :
                                    <button type="button" class="btn btn-success apply_btn_new disabled" disabled>Delete Transactions</button>
                                }
                            </div>
                        </div>
                    }
                   
        </div>
      </main >
     
    )
  }
}
function mapStateToProps(state) {
  console.log('state',state)
    const yodleedata = state.yodleedata || {};
    const all_value = state.Session || {};
    const all_value2 = state.FinancialInstitute || {};
    console.log(state.FinancialInstitute)
    const features_permissions_list = all_value.features_permissions_list || [];
    const entity_name = all_value.default_entity_detail.name || {};
    const bank_modal = all_value.entity_config_param.is_buktec_model || 0;
    const filter_start_date_range = all_value2.filter_start_date || "";
  const filter_end_date_range = all_value2.filter_end_date || "";
  const bulk_transaction_list =all_value2.bulk_transaction_list || [];
    return { yodleedata, features_permissions_list, entity_name ,bank_modal,filter_start_date_range,filter_end_date_range,bulk_transaction_list};
}
class Checkbox extends React.Component {
    componentDidMount() { this.update(this.props.checked); }
    componentWillReceiveProps(props) { this.update(props.checked); }
    update(checked) {
        ReactDOM.findDOMNode(this).indeterminate = checked === 'indeterminate';
    }
    render() {
        return (
            <input className='react-bs-select-all'
                type='checkbox'
                name={'checkbox' + this.props.rowIndex}
                id={'checkbox' + this.props.rowIndex}
                checked={this.props.checked}
                onChange={this.props.onChange} />
        );
    }
}

export default connect(mapStateToProps)(withRouter(BulkAction));
