import React, { Component } from 'react';
import { Modal, Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import ReactSelect from "react-select";
import  TextareaAutosize  from "@mui/material/TextareaAutosize";
import { DatePickerFormate } from "../../Helpers/DateFormat";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import CurrencyInput from "react-currency-input-field";
import { decodeHtml } from '../../Helpers/HelperFunction';
import Creatable from "react-select/creatable";
import { changeDateTimeFormat } from '../../Helpers/DateFormat';
import { confirm } from "../../Components/BoilerPlate/Confirm";
import { convertFloatNumberToCurrency } from "../../Helpers/Currency";

class ImportInventoryPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type : this.props.inventory_data.type,
      errors:this.props.inventory_data.inventory_errors,
      line_items_list:this.props.line_items_list,
      item_ledger_list : this.props.item_ledger_list,
      ledger_errors:this.props.inventory_data.ledger_errors,
      line_item_errors: this.props.inventory_data.line_item_errors,
      excelData: [],
      isDownload: false,
      isReady: false,
      is_dirty: true,
      show_edit_popup: false,
      ledger_options:[],
      remove_line_items:[],
        line_item_total: this.props.inventory_data?.inventory_details?.line_item_total,
        line_item_gst_total: this.props.inventory_data?.inventory_details?.line_item_gst_total,
        line_item_discount_total: this.props.inventory_data?.inventory_details?.line_item_discount_total,
        // inventory_voucher_status: this.props.inventory_data?.inventory_details?.inventory_voucher_status,
        inventory_voucher_status : false,
        is_common_item_ledger : false,
        // is_common_item_ledger : this.props.inventory_data.inventory_details.is_common_item_ledger == "" || (this.props.inventory_data.inventory_details.is_common_item_ledger != 0 &&
        //   this.props.inventory_data.inventory_details.is_common_item_ledger != 1) ? this.props.is_common_item_ledger : this.props.inventory_data.inventory_details.is_common_item_ledger,
          accounting_narration:
          this.props.inventory_data.inventory_details.accounting_narration === "" ||
            !this.props.inventory_data.inventory_details.accounting_narration
            ? this.props.getNarration(this.props.inventory_data)
            : this.props.inventory_data.inventory_details.accounting_narration,
        sync_error: this.props.inventory_data.inventory_details.sync_error,
        sync_status: this.props.inventory_data.inventory_details.sync_status || 0,
        sync_datetime:
        this.props.inventory_data.inventory_details.sync_datetime || changeDateTimeFormat(new Date()),
        voucher_creation_date: this.props.inventory_data.inventory_details.voucher_creation_date
        ? new Date(this.props.inventory_data.inventory_details.voucher_creation_date)
          : new Date(this.props.convertToFullDate(this.props.inventory_data.date)),
          voucher_total_amount: this.props.inventory_data.inventory_details.voucher_total_amount,
          voucher_type: this.props.inventory_data.inventory_details.voucher_type,
        line_items:
          this.props.inventory_data?.inventory_details?.line_items.length == 0 ? 
              [
                {
                  // "line_item_id": "",
                  // "line_item_name": "",
                  // "hsn_sac": "",
                  // "rate": "",
                  // "per": "",
                  // "quantity": "",
                  // "gst_rate": "",
                  // "discount_rate": "",
                  // "amount": "",
                  // "item_ledger_guid": "",
                  // "item_ledger_id": null,
                  // "item_ledger_name": "",
                  // "common_item_ledger_guid": "",
                  // "common_item_ledger_id": "",
                  // "common_item_ledger_name": "",
                  // "line_item_update": 0
                  line_item_id: "",
                  line_item_id_prediction_status: 0,
                  line_item_name: "",
                  line_item_name_prediction_status: 0,
                  hsn_sac: "",
                  hsn_sac_prediction_status: 0,
                  rate: "",
                  rate_prediction_status: 0,
                  per: "",
                  per_prediction_status: 0,
                  quantity: "",
                  quantity_prediction_status: 0,
                  gst_rate: "",
                  gst_rate_prediction_status: 0,
                  discount_rate: "",
                  discount_rate_prediction_status: 0,
                  amount: "",
                  amount_prediction_status: 0,
                  item_ledger_guid: "",
                  item_ledger_guid_prediction_status: 0,
                  item_ledger_id: null,
                  item_ledger_id_prediction_status: 0,
                  item_ledger_name: "",
                  item_ledger_name_prediction_status: 0,
                  common_item_ledger_guid: "",
                  common_item_ledger_guid_prediction_status: 0,
                  common_item_ledger_id: "",
                  common_item_ledger_id_prediction_status: 0,
                  common_item_ledger_name: "",
                  common_item_ledger_name_prediction_status: 0,
                  line_item_update: 0,
                },
              ]
              : this.props.inventory_data?.inventory_details?.line_items,
        ledgers: this.props.inventory_data.inventory_details.ledgers.length == 0
                ? [
                  {
                    amount: "",
                    rate: "",
                    guid: "",
                    ledger_id: "",
                    name: "",
                  },
                ]
                : this.props.inventory_data.inventory_details.ledgers,
        party_ledger_guid: this.props.inventory_data.inventory_details.party_ledger_guid,
        party_ledger_id: this.props.inventory_data.inventory_details.party_ledger_id,
        party_ledger_name : this.props.inventory_data.inventory_details.party_ledger_name,
        common_item_ledger_guid:this.props.inventory_data.inventory_details.common_item_ledger_guid,
        common_item_ledger_id:this.props.inventory_data.inventory_details.common_item_ledger_id,
        common_item_ledger_name:this.props.inventory_data.inventory_details.common_item_ledger_name,
        invoice_number : this.props.inventory_data.invoice_number,
        check_sales_purchase:this.props.inventory_data.type == 1 ? 5 : 6,
        voucher_type_guid:this.props.inventory_data.inventory_details.voucher_type_guid ,
        parent_voucher_type_id:this.props.inventory_data.inventory_details.parent_voucher_type_id ? this.props.inventory_data.inventory_details.parent_voucher_type_id : this.props.inventory_data.type == 2 ? 5 : 6,
        voucher_type_name: this.props.inventory_data.inventory_details.voucher_type_name,
        voucher_type_id: this.props.inventory_data.inventory_details.voucher_type_id,
        inventory_voucher_id : this.props.inventory_data.inventory_details.inventory_voucher_id,


       
            
      show_summary_section: false,
      gst_summary: this.props.inventory_data?.gst_summary || [],
      hsn_sac_summary : this.props.inventory_data?.hsn_sac_summary || []
    };

  }
  
  toggleImportInventoryPopup = async() => {
     if (this.state.is_dirty) {
              if (
                  await confirm({
                            confirmation:
                                    "Changes are not saved. Do you still want to leave?",
                      })
                    ) {
                          // this.props.emptyPredictionLineItems();
                         this.props.toggleImportInventoryPopup();
                      }
                                  
   
    this.setState({ excelData: [], isDownload: false, isReady: false });
   
  };
}

  componentDidMount() {
     
  
      if (this.props.ledger_data.hasOwnProperty("ledger_list")) {
        let options = [];
        this.props.ledger_data.ledger_list.map((ledger, index) => {
          options.push({ label: decodeHtml(ledger.name), value: ledger.guid });
        });
        this.setState({ ledger_options: options });
      }


      console.log("mount",this.state.common_item_ledger_guid , this.state.party_ledger_guid)
      if( Object.keys(this.props.inventory_data.merchant_option).length != 0){
        let ledger = this.props.ledger_data.ledger_list.filter((ledger) => ledger.guid == (this.props.inventory_data.type == 1
          ? this.props.inventory_data.merchant_option.sales_ledger_guid
          : this.props.inventory_data.merchant_option.purchase_ledger_guid))
          if (
            ledger.length > 0 && (this.props.inventory_data.inventory_details.is_common_item_ledger == "" || (this.props.inventory_data.inventory_details.is_common_item_ledger != 0 &&
              this.props.inventory_data.inventory_details.is_common_item_ledger != 1) ? this.props.is_common_item_ledger : this.props.inventory_data.inventory_details.is_common_item_ledge)
              && !this.state.common_item_ledger_guid
          ) {
            this.setState({
              common_item_ledger_guid: ledger[0].guid,
              common_item_ledger_id: ledger[0].id,
              common_item_ledger_name: ledger[0].name,
            });
          }
      }

      let party_ledger = this.props.ledger_data.ledger_list.filter(
        (ledger) =>
          ledger.guid ==
          (this.props.inventory_data.merchant_option.party_ledger_guid)
      );
      //console.log(party_ledger);
      if (party_ledger.length > 0 && !this.state.party_ledger_guid) {
        this.setState({
          party_ledger_guid: party_ledger[0].guid,
          party_ledger_id: party_ledger[0].id,
          party_ledger_name: party_ledger[0].name,
        });
      }


      
        let voucher_details = this.props.voucherTypeList.filter(voucher => voucher.guid == (this.props.inventory_data.type == 1 ? this.props.inventory_data.merchant_option.sales_voucher_type_guid :  this.props.inventory_data.merchant_option.purchase_voucher_type_guid));
        console.log("inside voucher ",voucher_details.length)
        if(voucher_details.length > 0 && !this.state.voucher_type_guid){
          console.log("here",voucher_details)
          
            this.setState({
              voucher_type_guid:voucher_details[0].guid,
              voucher_type_id:voucher_details[0].id,
              voucher_type_name:voucher_details[0].name,
              parent_voucher_type_id:voucher_details[0].parent_voucher_type_id
            })
        }else{
          let voucher = this.props.voucherTypeList.filter(voucher => voucher.name == (this.props.inventory_data.type == 1 ? 'Sales' : 'Purchase'));
          if(voucher.length > 0 && !this.state.voucher_type_guid){
            this.setState({
              voucher_type_guid:voucher[0].guid,
              voucher_type_id:voucher[0].id,
              voucher_type_name:voucher[0].name,
              parent_voucher_type_id:voucher[0].parent_voucher_type_id
            })
          }
          
        }
  
     
    }

    componentWillReceiveProps(nextProps) {
       
    
        if (!_.isEqual(nextProps.ledger_data, this.props.ledger_data)) {
          if (nextProps.ledger_data.hasOwnProperty("ledger_list")) {
            let options = [];
            nextProps.ledger_data.ledger_list.map((ledger, index) => {
              options.push({ label: decodeHtml(ledger.name), value: ledger.guid });
            });
            this.setState({ ledger_options: options });
          }
        }
    
       
      }

  handleVoucherChange = (e) =>{
    if(e.target.name == 'voucher_type'){
      if(e.target.value !== ""){
        let voucher_details = this.props.voucherTypeList.filter(voucher => voucher.guid == e.target.value)
        console.log("voucher ",voucher_details)
        this.setState({voucher_type_guid:voucher_details[0].guid,parent_voucher_type_id:voucher_details[0].parent_voucher_type_id,
              voucher_type_id:voucher_details[0].id,voucher_type_name:voucher_details[0].name
        })
    }}
  }

  addNewLineItem = () => {
    let line_items = [...this.state.line_items];

    line_items.push({
      // "id": 0,
      // "line_item_id": "",
      // "line_item_name": "",
      // "hsn_sac": "",
      // "rate": "",
      // "per": "",
      // "quantity": "",
      // "gst_rate": "",
      // "discount_rate": "",
      // "amount": "",
      // "item_ledger_guid": "",
      // "item_ledger_id": "",
      // "item_ledger_name": "",
      // "common_item_ledger_guid": "",
      // "common_item_ledger_id": "",
      // "common_item_ledger_name": ""
      id: 0,
      line_item_id: "",
      line_item_id_prediction_status: 0,
      line_item_name: "",
      line_item_name_prediction_status: 0,
      hsn_sac: "",
      hsn_sac_prediction_status: 0,
      rate: "",
      rate_prediction_status: 0,
      per: "",
      per_prediction_status: 0,
      quantity: "",
      quantity_prediction_status: 0,
      gst_rate: "",
      gst_rate_prediction_status: 0,
      discount_rate: "",
      discount_rate_prediction_status: 0,
      amount: "",
      amount_prediction_status: 0,
      item_ledger_guid: "",
      item_ledger_guid_prediction_status: 0,
      item_ledger_id: "",
      item_ledger_id_prediction_status: 0,
      item_ledger_name: "",
      item_ledger_name_prediction_status: 0,
      common_item_ledger_guid: "",
      common_item_ledger_guid_prediction_status: 0,
      common_item_ledger_id: "",
      common_item_ledger_id_prediction_status: 0,
      common_item_ledger_name: "",
      common_item_ledger_name_prediction_status: 0,
    });
    //console.log(line_items)
    this.setState({ line_items });
  };

  removeLineItem = (index, id) => {
    let remove_line_items = this.state.remove_line_items;
    if (id) {
      remove_line_items.push(id);
    }
    //console.log(remove_line_items)
    if (index == 0 && Object.keys(this.state.line_items).length == 1) {
      this.setState({
        line_items: [
          {
            // "id": 0,
            // "line_item_id": "",
            // "line_item_name": "",
            // "hsn_sac": "",
            // "rate": "",
            // "per": "",
            // "quantity": "",
            // "gst_rate": "",
            // "discount_rate": "",
            // "amount": "",
            // "item_ledger_guid": "",
            // "item_ledger_id": "",
            // "item_ledger_name": "",
            // "common_item_ledger_guid": "",
            // "common_item_ledger_id": "",
            // "common_item_ledger_name": ""
            id: 0,
            line_item_id: "",
            line_item_id_prediction_status: 0,
            line_item_name: "",
            line_item_name_prediction_status: 0,
            hsn_sac: "",
            hsn_sac_prediction_status: 0,
            rate: "",
            rate_prediction_status: 0,
            per: "",
            per_prediction_status: 0,
            quantity: "",
            quantity_prediction_status: 0,
            gst_rate: "",
            gst_rate_prediction_status: 0,
            discount_rate: "",
            discount_rate_prediction_status: 0,
            amount: "",
            amount_prediction_status: 0,
            item_ledger_guid: "",
            item_ledger_guid_prediction_status: 0,
            item_ledger_id: "",
            item_ledger_id_prediction_status: 0,
            item_ledger_name: "",
            item_ledger_name_prediction_status: 0,
            common_item_ledger_guid: "",
            common_item_ledger_guid_prediction_status: 0,
            common_item_ledger_id: "",
            common_item_ledger_id_prediction_status: 0,
            common_item_ledger_name: "",
            common_item_ledger_name_prediction_status: 0,
          },
        ],
        remove_line_items,
        is_dirty: true,
      });
    } else {
      let line_items = this.state.line_items;
      line_items.splice(index, 1);
      this.setState({ line_items, remove_line_items, is_dirty: true });
    }
  };


  addNewAdditionalLedger = () => {
    let ledgers = [...this.state.ledgers];
    ledgers.push({
      id: 0,
      amount: "",
      guid: "",
      ledger_id: "",
      name: "",
      rate: "",
    });
    this.setState({ ledgers });
  };

  removeAdditionalLedger = (index, id) => {
    let remove_ledgers = this.state.remove_ledgers;
    if (id) {
      remove_ledgers.push(id);
    }
    if (index == 0 && Object.keys(this.state.ledgers).length == 1) {
      this.setState({
        ledgers: [
          {
            id: 0,
            amount: "",
            guid: "",
            ledger_id: "",
            name: "",
            rate: "",
          },
        ],
        remove_ledgers,
        is_dirty: true,
      });
    } else {
      let ledgers = this.state.ledgers;
      ledgers.splice(index, 1);
      //console.log(ledgers)
      this.setState({ ledgers, remove_ledgers, is_dirty: true });
    }
  };

  calculateLineItemAmount = (index) => {
    let amt =
      parseFloat(
        (this.state.line_items[index].quantity || 0) *
        (this.state.line_items[index].rate || 0)
      ) *
      (1 - (this.state.line_items[index].discount_rate || 0) / 100);

    let line_items = [...this.state.line_items];
    line_items[index].amount = parseFloat(amt).toFixed(2);

    this.setState({ line_items }, () => {
      this.calculateAllLedgerAmount();
    });
  };

  calculateTotalLineItemAmt = () => {
    let line_items = [...this.state.line_items];
    let amt = 0;

    line_items.forEach((item, index) => {
      if (item.amount) amt += parseFloat(item.amount);
    });

    //this.setState({line_item_total: amt.toFixed(2)})
    return amt.toFixed(2);
  };

  calculateLedgerAmount = (index) => {
    ///if(!!this.state.ledgers[index]){
    let total_of_line_items = this.calculateTotalLineItemAmt() || 0;

    let rate = this.state.ledgers[index].rate || 0;

    let amt = parseFloat(total_of_line_items * (rate / 100));

    let ledgers = [...this.state.ledgers];
    ledgers[index].amount = parseFloat(amt.toFixed(2));

    this.setState({ ledgers });
    //}
  };
  calculateAllLedgerAmount = () => {
    let total_of_line_items = this.calculateTotalLineItemAmt() || 0;
    let ledgers = [...this.state.ledgers];

    ledgers.forEach((led, i) => {
      let rate = led.rate || 0;
      let amt = parseFloat(total_of_line_items * (rate / 100));
      ledgers[i].amount = amt.toFixed(2);
    });

    this.setState({ ledgers });
  };

   //calculate total line items combined gst amt
   calculateTotalLineItemGST = () => {
    let line_items = [...this.state.line_items];
    let total_gst_amt = 0;

    line_items.forEach((item, index) => {
      if (item.gst_rate && item.amount)
        total_gst_amt +=
          (parseFloat(item.gst_rate) / 100) * parseFloat(item.amount);
    });
    //this.setState({line_item_gst_total: total_gst_amt.toFixed(2)})
    return total_gst_amt.toFixed(2);
  };

  //calculate total line items combined discounted amt
  calculateTotalLineItemDisc = () => {
    let line_items = [...this.state.line_items];
    let total_disc_amt = 0;

    line_items.forEach((item, index) => {
      if (item.rate && item.discount_rate && item.quantity)
        total_disc_amt +=
          item.quantity *
          parseFloat(item.rate) *
          (parseFloat(item.discount_rate) / 100);
    });
    //this.setState({line_item_discount_total: total_disc_amt.toFixed(2)})
    return total_disc_amt.toFixed(2);
  };
  calculateTotalLedgersAmt = () => {
    let total_line_item_amt = parseFloat(this.calculateTotalLineItemAmt());

    let ledgers = [...this.state.ledgers];
    let amt = total_line_item_amt || 0;

    ledgers.forEach((ledger, index) => {
      if (ledger.amount) amt += parseFloat(ledger.amount);
    });

    return amt.toFixed(2);
  };

  updateLineItemData = (e, name, index) => {
    let line_items = [...this.state.line_items];

    if (name == "item_ledger_guid") {
      let item_ledger = this.state.item_ledger_list.filter(
        (item_ledger) => item_ledger.guid == e.value
      );

      if (item_ledger.length > 0) {
        line_items[index][name] = e.value;
        //prediction status
        line_items[index].item_ledger_guid_prediction_status = 0;

        //set other data for api
        line_items[index].item_ledger_name = item_ledger[0].name;
        line_items[index].item_ledger_id = item_ledger[0].id;
        line_items[index].item_ledger_guid = item_ledger[0].guid;
        //line_items[index].line_item_guid = item_ledger[0].guid;

        // prediction status

        line_items[index].item_ledger_name_prediction_status = 0;
        line_items[index].item_ledger_id_prediction_status = 0;
        line_items[index].item_ledger_guid_prediction_status = 0;

        //prefill line items list according to selected and if one option is filter out then preselect it in item dropdown
        let item = this.state.line_items_list.filter(
          (item) => item.item_ledger_guid == item_ledger[0].guid
        );

        //console.log(item)
        if (item.length == 1 && !line_items[index].line_item_name) {
          //set other data for api
          line_items[index].line_item_name = item[0].name;
          line_items[index].line_item_id = item[0].id;

          //prediction status
          line_items[index].line_item_name_prediction_status = 0;
          line_items[index].line_item_id_prediction_status = 0;

          //prefill data
          line_items[index].item_data = item[0];
          line_items[index].rate = item[0].rate;

          //prediction status
          line_items[index].rate_prediction_status = 0;

          if (item[0].hsn_sac && !line_items[index].hsn_sac) {
            line_items[index].hsn_sac = item[0].hsn_sac;
            //prediction status
            line_items[index].hsn_sac_prediction_status = 0;
          }

          if (item[0].gst_rate && !line_items[index].gst_rate) {
            line_items[index].gst_rate = item[0].gst_rate;
            //prediction status
            line_items[index].gst_rate_prediction_status = 0;
          }

          if (item[0].per && !line_items[index].per) {
            line_items[index].per = item[0].per;
            //prediction status
            line_items[index].per_prediction_status = 0;
          }

          if (
            this.state.is_common_item_ledger == 0 &&
            this.props.inventory_data.type == 1 &&
            item[0].sales_ledger_guid
          ) {
            let ledger = this.props.ledger_data.ledger_list.filter(
              (item_ledger) => item_ledger.guid == item[0].sales_ledger_guid
            );

            if (
              ledger.length > 0 &&
              !line_items[index].common_item_ledger_guid
            ) {
              line_items[index].common_item_ledger_guid = ledger[0].guid;
              line_items[index].common_item_ledger_id = ledger[0].id;
              line_items[index].common_item_ledger_name = ledger[0].name;
              //prediction status
              line_items[index].common_item_ledger_guid_prediction_status = 0;
              line_items[index].common_item_ledger_id_prediction_status = 0;
              line_items[index].common_item_ledger_name_prediction_status = 0;
            }
          } else if (
            this.state.is_common_item_ledger == 0 &&
            this.props.inventory_data.type == 2 &&
            item[0].purchase_ledger_guid
          ) {
            let ledger = this.props.ledger_data.ledger_list.filter(
              (item_ledger) => item_ledger.guid == item[0].purchase_ledger_guid
            );

            if (
              ledger.length > 0 &&
              !line_items[index].common_item_ledger_guid
            ) {
              line_items[index].common_item_ledger_guid = ledger[0].guid;
              line_items[index].common_item_ledger_id = ledger[0].id;
              line_items[index].common_item_ledger_name = ledger[0].name;
              //prediction status
              line_items[index].common_item_ledger_guid_prediction_status = 0;
              line_items[index].common_item_ledger_id_prediction_status = 0;
              line_items[index].common_item_ledger_name_prediction_status = 0;
            }
          }
        } else {
          // if (!line_items[index].line_item_name) {
          // 	line_items[index].line_item_name = "";
          // 	line_items[index].line_item_id = "";
          // }
        }

        //prefill data only if values are not filled
        if (item_ledger[0].hsn_sac && !line_items[index].hsn_sac) {
          line_items[index].hsn_sac = item_ledger[0].hsn_sac;
          //prediction status
          line_items[index].hsn_sac_prediction_status = 0;
        }

        if (item_ledger[0].gst_rate && !line_items[index].gst_rate) {
          line_items[index].gst_rate = item_ledger[0].gst_rate;
          //prediction status
          line_items[index].gst_rate_prediction_status = 0;
        }

        if (item_ledger[0].per && !line_items[index].per) {
          line_items[index].per = item_ledger[0].per;
          //prediction status
          line_items[index].per_prediction_status = 0;
        }
      }
    } else if (name == "line_item_id") {
      line_items[index][name] = e.value;
      //prediction status
      line_items[index].line_item_id_prediction_status = 0;

      let item = this.state.line_items_list.filter(
        (item) => item.id == e.value
      );
      //console.log(item, this.state.line_items_list,)
      if (item.length > 0) {
        //set other data for api
        line_items[index].line_item_name = item[0].name;
        line_items[index].item_data = item[0];
        //prediction status
        line_items[index].line_item_name_prediction_status = 0;

        //prefill data
        if (item[0].hsn_sac && !line_items[index].hsn_sac) {
          line_items[index].hsn_sac = item[0].hsn_sac;
          //prediction status
          line_items[index].hsn_sac_prediction_status = 0;
        }
        if (item[0].gst_rate && !line_items[index].gst_rate) {
          line_items[index].gst_rate = item[0].gst_rate;
          //prediction status
          line_items[index].gst_rate_prediction_status = 0;
        }
        if (item[0].per && !line_items[index].per) {
          line_items[index].per = item[0].per;
          //prediction status
          line_items[index].per_prediction_status = 0;
        }
        if (item[0].rate && !line_items[index].rate) {
          line_items[index].rate = item[0].rate;
          //prediction status
          line_items[index].rate_prediction_status = 0;
        }
        if (
          this.state.is_common_item_ledger == 0 &&
          this.props.inventory_data.type == 1 &&
          item[0].sales_ledger_guid
        ) {
          let ledger = this.props.ledger_data.ledger_list.filter(
            (item_ledger) => item_ledger.guid == item[0].sales_ledger_guid
          );

          if (ledger.length > 0 && !line_items[index].common_item_ledger_guid) {
            line_items[index].common_item_ledger_guid = ledger[0].guid;
            line_items[index].common_item_ledger_id = ledger[0].id;
            line_items[index].common_item_ledger_name = ledger[0].name;
            //prediction status
            line_items[index].common_item_ledger_guid_prediction_status = 0;
            line_items[index].common_item_ledger_id_prediction_status = 0;
            line_items[index].common_item_ledger_name_prediction_status = 0;
          }
        } else if (
          this.state.is_common_item_ledger == 0 &&
          this.props.inventory_data.type == 2 &&
          item[0].purchase_ledger_guid
        ) {
          let ledger = this.props.ledger_data.ledger_list.filter(
            (item_ledger) => item_ledger.guid == item[0].purchase_ledger_guid
          );

          if (ledger.length > 0 && !line_items[index].common_item_ledger_guid) {
            line_items[index].common_item_ledger_guid = ledger[0].guid;
            line_items[index].common_item_ledger_id = ledger[0].id;
            line_items[index].common_item_ledger_name = ledger[0].name;
            //prediction status
            line_items[index].common_item_ledger_guid_prediction_status = 0;
            line_items[index].common_item_ledger_id_prediction_status = 0;
            line_items[index].common_item_ledger_name_prediction_status = 0;
          }
        }

        let item_ledger = this.state.item_ledger_list.filter(
          (item_ledger) => item_ledger.guid == item[0].item_ledger_guid
        );
        //console.log(item_ledger)
        if (item_ledger.length == 1 && !line_items[index].item_ledger_guid) {
          let line_items = [...this.state.line_items];
          line_items[index].item_ledger_name = item_ledger[0].name;
          line_items[index].item_ledger_id = item_ledger[0].id;
          line_items[index].item_ledger_guid = item_ledger[0].guid;
          // prediction status

          line_items[index].item_ledger_name_prediction_status = 0;
          line_items[index].item_ledger_id_prediction_status = 0;
          line_items[index].item_ledger_guid_prediction_status = 0;

          //prefill data
          if (item_ledger[0].hsn_sac && !line_items[index].hsn_sac) {
            line_items[index].hsn_sac = item_ledger[0].hsn_sac;
            //prediction status
            line_items[index].hsn_sac_prediction_status = 0;
          }
          if (item_ledger[0].gst_rate && !line_items[index].gst_rate) {
            line_items[index].gst_rate = item_ledger[0].gst_rate;
            //prediction status
            line_items[index].gst_rate_prediction_status = 0;
          }
          if (item_ledger[0].per && !line_items[index].per) {
            line_items[index].per = item_ledger[0].per;
            //prediction status
            line_items[index].per_prediction_status = 0;
          }
        }

        //console.log(line_items)
      }
    } else if (name == "common_item_ledger_guid") {
      let item_ledger = this.props.ledger_data.ledger_list.filter(
        (item_ledger) => item_ledger.guid == e.value
      );

      if (item_ledger.length > 0) {
        line_items[index].common_item_ledger_guid = item_ledger[0].guid;
        line_items[index].common_item_ledger_id = item_ledger[0].id;
        line_items[index].common_item_ledger_name = item_ledger[0].name;
        //prediction status
        line_items[index].common_item_ledger_guid_prediction_status = 0;
        line_items[index].common_item_ledger_id_prediction_status = 0;
        line_items[index].common_item_ledger_name_prediction_status = 0;
      }
    } else {
      line_items[index][name] = e.target.value;
      if (name == "hsn_sac") {
        //prediction status
        line_items[index].hsn_sac_prediction_status = 0;
      }
      if (name == "quantity") {
        //prediction status
        line_items[index].quantity_prediction_status = 0;
        line_items[index].amount_prediction_status = 0;
      }
      if (name == "per") {
        //prediction status
        line_items[index].per_prediction_status = 0;
      }
    }
    this.setState({ line_items, is_dirty: true }, () => {
      this.calculateLineItemAmount(index);
    });
  };
  checkForErrors = () => {
    this.setState({ errors: {}, line_item_errors: [], ledger_errors: [] });
    let errors = {};
    let line_item_errors = [];
    let ledger_errors = [];
//SOC::mandatory fields for accounting status
    if (
      this.props.accounting_status != 3 &&
      this.state.inventory_voucher_status &&
      this.props.SNAPSHOT_LEDGER_ENTRIES_CODE
    ) {
      if (!this.state.voucher_creation_date) {
        errors.voucher_creation_date = "Please select Voucher Date";
      }
      if (!this.state.party_ledger_guid) {
        errors.party_ledger_guid = "Please select Party Ledger";
      }
      if (
        !this.state.common_item_ledger_guid &&
        this.state.is_common_item_ledger == 1
      ) {
        if (this.inventory_data.type == 1) {
          errors.common_item_ledger_guid = "Please select Sales Ledger";
        } else {
          errors.common_item_ledger_guid = "Please select Purchase Ledger";
        }
      }
      let line_item_err = 0;
      this.state.line_items.forEach((item, index) => {
        if (
          !item.line_item_name &&
          !item.hsn_sac &&
          !item.rate &&
          !item.per &&
          !item.quantity &&
          !item.gst_rate &&
          !item.discount_rate &&
          !item.item_ledger_guid &&
          !item.common_item_ledger_guid
        ) {
          line_item_err += 1;
        }
      });

      if (this.state.line_items.length == line_item_err) {
        errors.line_item_err = true;
      }
    }
     //EOC::mandatory fields for accounting status

    //SOC::mandatory fields for entry status
    if (this.props.inventory_data.entry_status != 1 || this.props.accounting_status != 3) {
      this.state.line_items.forEach((item, index) => {
        if (
          item.line_item_name ||
          item.hsn_sac ||
          item.rate ||
          item.per ||
          item.quantity ||
          item.gst_rate ||
          item.discount_rate ||
          item.item_ledger_guid ||
          item.common_item_ledger_guid
        ) {
          let line_item_name_error,
            quantity_error,
            rate_error,
            amount_error,
            item_ledger_guid_error,
            common_item_ledger_guid_error,
            per_error = false;

          if (!item.line_item_name) line_item_name_error = true;

          if (!item.quantity || parseFloat(item.quantity) <= 0)
            quantity_error = true;

          if (!item.rate) rate_error = true;

          if (!item.amount) amount_error = true;

          if (
            this.state.inventory_voucher_status &&
            this.props.SNAPSHOT_LEDGER_ENTRIES_CODE &&
            this.props.accounting_status != 3
          ) {
            if (!item.item_ledger_guid) {
              item_ledger_guid_error = true;
            }
            if (
              !item.common_item_ledger_guid &&
              !this.state.is_common_item_ledger
            ) {
              common_item_ledger_guid_error = true;
            }
            if (!item.per) per_error = true;
          }

          if (
            line_item_name_error ||
            quantity_error ||
            rate_error ||
            amount_error ||
            item_ledger_guid_error ||
            common_item_ledger_guid_error ||
            per_error
          ) {
            line_item_errors[index] = {};

            if (line_item_name_error)
              line_item_errors[index].line_item_name = true;

            if (quantity_error) line_item_errors[index].quantity = true;

            if (rate_error) line_item_errors[index].rate = true;

            if (amount_error) line_item_errors[index].amount = true;

            if (item_ledger_guid_error)
              line_item_errors[index].item_ledger_guid = true;

            if (common_item_ledger_guid_error)
              line_item_errors[index].common_item_ledger_guid = true;

            if (per_error) line_item_errors[index].per = true;
          }
        }
      });

      this.state.ledgers.forEach((led, index) => {
        console.log(led.amount, led.rate, led.guid);
        if (parseFloat(led.amount) > 0 || led.rate || led.guid) {
          let rate_error,
            amount_error,
            guid_error = false;

          if (
            this.state.inventory_voucher_status &&
            this.props.SNAPSHOT_LEDGER_ENTRIES_CODE &&
            this.props.accounting_status != 3
          ) {
            if (!led.amount) {
              amount_error = true;
            }
            if (!led.guid) {
              guid_error = true;
            }

            if (amount_error || guid_error) {
              ledger_errors[index] = {};

              if (amount_error) ledger_errors[index].amount = true;

              if (guid_error) ledger_errors[index].guid = true;
            }
          }
        }
      });

      // if (this.state.is_igst) {
      //   if (Number.isNaN(this.state.igst_amount)) {
      //     errors.igst_amount = "Please fill IGST";
      //   }
      // } else {
      //   if (
      //     this.state.cgst_amount &&
      //     !this.state.sgst_amount &&
      //     this.state.entry_status != 1
      //   ) {
      //     errors.sgst_amount = "Please fill SGST";
      //   }

      //   if (
      //     !this.state.cgst_amount &&
      //     this.state.sgst_amount &&
      //     this.state.entry_status != 1
      //   ) {
      //     errors.cgst_amount = "Please fill CGST";
      //   }
      // }

      // if (
      //   (this.state.cgst_amount || this.state.sgst_amount) &&
      //   this.state.igst_amount
      // ) {
      //   errors.igst_amount = "Please fill either IGST or CGST & SGST";
      // }
      //console.log(this.state.tds_status, this.state.tds_percentage, this.state.tds_amount)
      //this.state.tds_status && 
    
    }

    let final_errors = {};

    final_errors.errors = errors;
    final_errors.line_item_errors = line_item_errors;
    final_errors.ledger_errors = ledger_errors;

    return final_errors;
  }
  saveLineItemData = async () => {
      let line_items = [...this.state.line_items];
      let hsn_sac_codes = [];
      let show_hsn_sac_warning = false;
  
      console.log("line items", line_items);
      line_items.forEach((item, index) => {
        if (item.line_item_id) {
          let item_data = this.state.line_items_list.filter(
            (i) => i.id == item.line_item_id
          );
          line_items[index].line_item_update = 0;
          if (item_data.length > 0) {
            if (
              this.state.inventory_voucher_status &&
              (item_data[0].item_ledger_guid != item.item_ledger_guid ||
                (!this.state.is_common_item_ledger &&
                  this.state.type == 1 &&
                  item_data[0].sales_ledger_guid !=
                  item.common_item_ledger_guid) ||
                (!this.state.is_common_item_ledger &&
                  this.state.type == 2 &&
                  item_data[0].purchase_ledger_guid !=
                  item.common_item_ledger_guid))
            ) {
              line_items[index].line_item_update = 1;
            }
            if (
              item_data[0].gst_rate != item.gst_rate ||
              item_data[0].per != item.per ||
              item_data[0].hsn_sac != item.hsn_sac ||
              item_data[0].rate != item.rate ||
              item.name != item_data[0].name
            ) {
              line_items[index].line_item_update = 1;
            }
            //if()
          }
        }
  
        if (item.gst_rate && item.hsn_sac) {
          if (!hsn_sac_codes[item.hsn_sac]) {
            hsn_sac_codes[item.hsn_sac] = [];
          }
          hsn_sac_codes[item.hsn_sac].push(item.gst_rate);
        }
      });
      //console.log(hsn_sac_codes)
  
      let numeric_hsn_array = new Array();
      for (var items in hsn_sac_codes) {
        numeric_hsn_array.push(hsn_sac_codes[items]);
      }
      //console.log(numeric_hsn_array)
      numeric_hsn_array.forEach((hsn_sac_code, numeric_hsn_array) => {
        console.log(hsn_sac_code);
        let all_equal = hsn_sac_code.every(
          (val) => parseFloat(val) == parseFloat(hsn_sac_code[0])
        );
        console.log(hsn_sac_code, all_equal);
        if (!all_equal) {
          show_hsn_sac_warning = true;
        }
      });
  
      if (show_hsn_sac_warning)
        toast.warning("Same HSN/SAC codes have different GST%");
      //return;
  
      let merchant_update = 0;
      if (
        this.state.inventory_voucher_status &&
        (this.props.inventory_data.merchant_option.party_ledger_guid !=
          this.state.party_ledger_guid ||
          (this.state.is_common_item_ledger &&
            this.state.type == 1 &&
            this.props.inventory_data.merchant_option.sales_ledger_guid !=
            this.state.common_item_ledger_guid) ||
          (this.state.is_common_item_ledger &&
            this.state.type == 2 &&
            this.props.inventory_data.merchant_option.sales_ledger_guid !=
            this.state.common_item_ledger_guid))
      ) {
        merchant_update = 1;
      }
  
      if((this.state.voucher_type_guid != (this.props.inventory_data.merchant_option.sales_voucher_type_guid || "") && this.props.inventory_data.type == 1)){
        merchant_update = 1
      }
      if((this.state.voucher_type_guid != (this.props.inventory_data.merchant_option.purchase_voucher_type_guid || "") && this.props.inventory_data.type == 2)){
        merchant_update = 1
      }
  
      console.log("save line item data ----------", this.state.is_base_amt_changed);
      this.setState(
        {
          line_items,
          merchant_update,
         
          // gst_summary: gst_total > 0 ? {
          // 	"cgst_total": this.state.is_igst ? null : total_cgst_amount,
          // 	"sgst_total": this.state.is_igst ? null : total_sgst_amount,
          // 	"igst_total": this.state.is_igst ? total_igst_amount : null,
          // 	"gst_total": gst_total,
          // 	"gst_details": gst_details
          // } : null,
          // hsn_sac_summary: hsn_sac_gst_total > 0 ? {
          // 	"basic_total": hsn_sac_basic_total,
          // 	"cgst_total": this.state.is_igst ? null : hsn_sac_cgst_total,
          // 	"sgst_total": this.state.is_igst ? null : hsn_sac_sgst_total,
          // 	"igst_total": this.state.is_igst ? hsn_sac_igst_total : null,
          // 	"gst_total": hsn_sac_gst_total,
          // 	"hsn_sac_details": hsn_details
          // }: null,
        },
        () => {
          console.log(this.state);
          this.props.saveLineItemData(this.state,this.props.inventory_index);
        }
      );
    };

     getGSTSummaryTotals = (gst_details, amount) => {
        let total = 0;
    
        if (amount == "total") {
          let total_cgst_amount = 0,
            total_sgst_amount = 0,
            total_igst_amount = 0;
          gst_details.map((data, index) => {
            total_cgst_amount += parseFloat(data.cgst_amount) || 0;
            total_sgst_amount += parseFloat(data.sgst_amount) || 0;
            total_igst_amount += parseFloat(data.igst_amount) || 0;
          });
    
          total = total_cgst_amount + total_sgst_amount + total_igst_amount;
        } else {
          gst_details.map((data, index) => {
            total += parseFloat(data[amount]) || 0;
          });
        }
    
        return (
          <td className="td-width-100 text-right">
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">
                  ₹ {convertFloatNumberToCurrency(parseFloat(total).toFixed(2))}
                </Tooltip>
              }
            >
              <div className="d-inline-flex td-width-100">
                <span className="ellipsisc ellipsisc-line-1 w-100">
                  ₹ {convertFloatNumberToCurrency(parseFloat(total).toFixed(2))}
                </span>
              </div>
            </OverlayTrigger>
          </td>
        );
      };
  getHSNSACSummaryTotals = (hsn_details, amount) => {
    let total = 0;

    hsn_details.map((data, index) => {
      total += parseFloat(data[amount]) || 0;
    });

    if (total) {
      return (
        <td className="td-width-100 text-right">
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-disabled">
                ₹ {convertFloatNumberToCurrency(parseFloat(total).toFixed(2))}
              </Tooltip>
            }
          >
            <div className="d-inline-flex td-width-100">
              <span className="ellipsisc ellipsisc-line-1 w-100">
                ₹ {convertFloatNumberToCurrency(parseFloat(total).toFixed(2))}
              </span>
            </div>
          </OverlayTrigger>
        </td>
      );
    } else {
      return <td className="td-width-100 text-right"></td>;
    }
  };

  renderGSTSummary = () => {
    let gst_summary = [];
    let hsn_summary = [];
    let gst_details = [];
    let hsn_details = [];

    this.state.line_items.forEach((item, index) => {
      console.log(item.gst_rate);
      if (item.gst_rate) {
        if (!gst_summary[item.gst_rate]) {
          gst_summary[item.gst_rate] = 0;
        }
        gst_summary[item.gst_rate] += parseFloat(item.amount) || 0;
      }

      if (item.hsn_sac) {
        let hsn_sac = item.hsn_sac;
        if (!hsn_summary[item.hsn_sac]) {
          hsn_summary[hsn_sac] = {};
          hsn_summary[hsn_sac].amt = 0;
          // hsn_summary[hsn_sac].cgst_rate = 0;
          // hsn_summary[hsn_sac].sgst_rate = 0;
          // hsn_summary[hsn_sac].igst_rate = 0;
        }
        console.log(item.gst_rate);
        hsn_summary[hsn_sac].amt += parseFloat(item.amount) || 0;

        // hsn_summary[hsn_sac].cgst_rate += this.state.is_igst ? 0 : item.gst_rate*0.5;
        // hsn_summary[hsn_sac].sgst_rate += this.state.is_igst ? 0 : item.gst_rate*0.5;
        // hsn_summary[hsn_sac].igst_rate += this.state.is_igst ? item.gst_rate*1 : 0;
        if (!hsn_summary[hsn_sac].cgst_rate)
          hsn_summary[hsn_sac].cgst_rate = this.state.is_igst
            ? 0
            : item.gst_rate * 0.5;

        if (!hsn_summary[hsn_sac].sgst_rate)
          hsn_summary[hsn_sac].sgst_rate = this.state.is_igst
            ? 0
            : item.gst_rate * 0.5;

        if (!hsn_summary[hsn_sac].igst_rate)
          hsn_summary[hsn_sac].igst_rate = this.state.is_igst
            ? item.gst_rate * 1
            : 0;

        hsn_summary[hsn_sac].hsn_sac = item.hsn_sac;

        console.log(hsn_summary);
      }
    });

    let numeric_hsn_array = new Array();
    for (var items in hsn_summary) {
      numeric_hsn_array.push(hsn_summary[items]);
    }
    //console.log(gst_summary, hsn_summary, numeric_hsn_array)

    gst_summary.forEach((amt, index) => {
      let cgst_amount = !this.state.is_igst ? amt * 0.5 : null;
      let sgst_amount = !this.state.is_igst ? amt * 0.5 : null;
      let igst_amount = this.state.is_igst ? amt : null;

      gst_details.push({
        gst_rate: index,
        gst_amount: amt,
        cgst_amount: cgst_amount || null,
        sgst_amount: sgst_amount || null,
        igst_amount: igst_amount || null,
      });
    });
    //console.log(numeric_hsn_array)
    numeric_hsn_array.forEach((data, index) => {
      let gst_amount = 0;
      let cgst_amount = !this.state.is_igst
        ? (data.amt * data.cgst_rate) / 100
        : null;
      let sgst_amount = !this.state.is_igst
        ? (data.amt * data.sgst_rate) / 100
        : null;
      let igst_amount = this.state.is_igst
        ? (data.amt * data.igst_rate) / 100
        : null;

      (gst_amount += this.state.is_igst
        ? igst_amount || 0
        : (cgst_amount || 0) + (sgst_amount || 0)),
        hsn_details.push({
          cgst_rate: data.cgst_rate || null,
          sgst_rate: data.sgst_rate || null,
          igst_rate: data.igst_rate || null,
          gst_amount: parseFloat(gst_amount).toFixed(2),
          cgst_amount: cgst_amount ? parseFloat(cgst_amount).toFixed(2) : null,
          sgst_amount: sgst_amount ? parseFloat(sgst_amount).toFixed(2) : null,
          igst_amount: igst_amount ? parseFloat(igst_amount).toFixed(2) : null,
          hsn_sac: data.hsn_sac,
          basic_amount: parseFloat(data.amt).toFixed(2),
        });
    });

    console.log(gst_details, hsn_details)

    return (
      <>
        <div className="col-xl-5">
          <table className="table borderTable">
            <thead>
              <tr>
                <th className="td-width-50 text-center">Type</th>
                {gst_details.map((data, index) => {
                  return (
                    <th className="td-width-100 text-right">
                      {this.props.inventory_data.is_igst
                        ? data.gst_rate + "%"
                        : data.gst_rate * 0.5 + "% (" + data.gst_rate + "%)"}
                    </th>
                   );
                })}
                <th className="td-width-100 text-right">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-width-50 text-center">CGST</td>
                {this.props.inventory_data.is_igst
                  ? gst_details.map((data, index) => {
                    return (
                    <td></td>
                   );
                  }) 
                   : gst_details.map((data, index) => {
                    return ( 
                      <td className="td-width-100 text-right">
                        {data.cgst_amount ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                ₹{" "}
                                {convertFloatNumberToCurrency(
                                  data.cgst_amount.toFixed(2)
                                )}
                              </Tooltip>
                            }
                          >
                            <div className="d-inline-flex td-width-100">
                              <span className="ellipsisc ellipsisc-line-1 w-100">
                                ₹{" "}
                                {convertFloatNumberToCurrency(
                                  data.cgst_amount.toFixed(2)
                                )}
                              </span>
                            </div>
                          </OverlayTrigger>
                         ) : null} 
                      </td>
                     );
                  })} 
                {this.props.inventory_data.is_igst ? (
                   <td className="td-width-100 text-right"></td> 
                 ) : (
                  this.getGSTSummaryTotals(gst_details, "cgst_amount")
                )} 
              </tr>
              <tr>
                <td className="td-width-50 text-center">SGST</td>
                {this.props.inventory_data.is_igst
                  ? gst_details.map((data, index) => {
                    return (
                    <td></td>
                   );
                  })
                  : gst_details.map((data, index) => {
                    return ( 
                      <td className="td-width-100 text-right">
                        {data.sgst_amount ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                ₹{" "}
                                {convertFloatNumberToCurrency(
                                  data.sgst_amount.toFixed(2)
                                )}
                              </Tooltip>
                            }
                          >
                            <div className="d-inline-flex td-width-100">
                              <span className="ellipsisc ellipsisc-line-1 w-100">
                                ₹{" "}
                                {convertFloatNumberToCurrency(
                                  data.sgst_amount.toFixed(2)
                                )}
                              </span>
                            </div>
                          </OverlayTrigger>
                         ) : null} 
                      </td>
                     );
                  })} 
                {this.state.is_igst ? (
                  <td className="td-width-100 text-right"></td> 
                 ) : (
                  this.getGSTSummaryTotals(gst_details, "sgst_amount")
                )} 
              </tr>
              <tr>
                <td className="td-width-50 text-center">IGST</td>

                {this.props.inventory_data.is_igst
                  ? gst_details.map((data, index) => {
                    return (
                      <td className="td-width-100 text-right">
                        {data.igst_amount ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                ₹{" "}
                                {convertFloatNumberToCurrency(
                                  data.igst_amount.toFixed(2)
                                )}
                              </Tooltip>
                            }
                          >
                            <div className="d-inline-flex td-width-100">
                              <span className="ellipsisc ellipsisc-line-1 w-100">
                                ₹{" "}
                                {convertFloatNumberToCurrency(
                                  data.igst_amount.toFixed(2)
                                )}
                              </span>
                            </div>
                          </OverlayTrigger>
                         ) : null}
                      </td>
                    );
                 })
                 : gst_details.map((data, index) => { 
                   return ( 
                    <td className="td-width-100 text-right"></td>
                   );
                  })} 
                 {this.state.is_igst ? (
                  this.getGSTSummaryTotals(gst_details, "igst_amount")
                ) : ( 
                   <td className="td-width-100 text-right"></td> 
                 )} 
              </tr>
              <tr className="gray-dark-bg">
                <td className="td-width-50 text-center blue-text">Total (₹)</td>
                {gst_details.map((data, index) => {
                  return (
                    <td className="td-width-100 text-right">
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            ₹ {convertFloatNumberToCurrency(data.gst_amount)}
                          </Tooltip>
                        }
                      >
                        <div className="d-inline-flex td-width-100">
                          <span className="ellipsisc ellipsisc-line-1 w-100">
                            ₹ {convertFloatNumberToCurrency(data.gst_amount)}
                          </span>
                        </div>
                      </OverlayTrigger>
                    </td>
                   );
                })}
                {this.getGSTSummaryTotals(gst_details, "total")} 
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-xl-7" style={{height: '220px', overflowY: 'auto', marginBottom: '10px'}}>
          <table className="table borderTable">
            <thead>
              <tr>
                <th width="150px" className="text-center" rowSpan={2}>
                  HSN/SAC
                </th>
                <th width="130px" className="text-right" rowSpan={2}>
                  Taxable Value
                </th>
                <th className="text-center" colSpan={2}>
                  CGST
                </th>
                <th className="text-center" colSpan={2}>
                  SGST
                </th>
                <th className="text-center" colSpan={2}>
                  IGST
                </th>
                <th className="text-right">GST Total</th>
              </tr>
              <tr>
                <th width="80px" className="text-right">
                  Rate
                </th>
                <th className="text-right">Amount</th>
                <th width="80px" className="text-right">
                  Rate
                </th>
                <th className="text-right">Amount</th>
                <th width="80px" className="text-right">
                  Rate
                </th>
                <th className="text-right">Amount</th>
                <th className=""></th>
              </tr>
            </thead>
            <tbody>
              {hsn_details.map((data, index) => {
                return (
                  <tr>
                    <td className="text-center">
                      {data.hsn_sac}
                      </td>
                    <td className="text-right">
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            ₹ {convertFloatNumberToCurrency(data.basic_amount)}
                          </Tooltip>
                        }
                      >
                        <div className="d-inline-flex">
                          <span className="ellipsisc ellipsisc-line-1 w-100">
                            ₹ {convertFloatNumberToCurrency(data.basic_amount)}
                          </span>
                        </div>
                      </OverlayTrigger>
                    </td>
                    <td className="text-right">
                      {data.cgst_rate}
                      {data.cgst_rate ? "%" : null}
                    </td>
                    <td className="text-right">
                      {data.cgst_amount && (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              ₹ {convertFloatNumberToCurrency(data.cgst_amount)}
                            </Tooltip>
                          }
                        >
                          <div className="d-inline-flex">
                            <span className="ellipsisc ellipsisc-line-1 w-100">
                              ₹ {convertFloatNumberToCurrency(data.cgst_amount)}
                            </span>
                          </div>
                        </OverlayTrigger>
                       )} 
                    </td>
                    <td className="text-right">
                       {data.sgst_rate}
                      {data.sgst_rate ? "%" : null} 
                    </td>
                    <td className="text-right">
                       {data.sgst_amount && ( 
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              ₹ {convertFloatNumberToCurrency(data.sgst_amount)}
                            </Tooltip>
                          }
                        >
                          <div className="d-inline-flex">
                            <span className="ellipsisc ellipsisc-line-1 w-100">
                              ₹ {convertFloatNumberToCurrency(data.sgst_amount)}
                            </span>
                          </div>
                        </OverlayTrigger>
                       )} 
                    </td>
                    <td className="text-right">
                      {data.igst_rate}
                      {data.igst_rate ? "%" : null}
                    </td>
                    <td className="text-right">
                      {data.igst_amount && (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              ₹ {convertFloatNumberToCurrency(data.igst_amount)}
                            </Tooltip>
                          }
                        >
                          <div className="d-inline-flex">
                            <span className="ellipsisc ellipsisc-line-1 w-100">
                              ₹ {convertFloatNumberToCurrency(data.igst_amount)}
                            </span>
                          </div>
                        </OverlayTrigger>
                       )} 
                    </td>
                    <td className="text-right">
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            ₹ {convertFloatNumberToCurrency(data.gst_amount)}
                          </Tooltip>
                        }
                      >
                        <div className="d-inline-flex">
                          <span className="ellipsisc ellipsisc-line-1 w-100">
                            ₹ {convertFloatNumberToCurrency(data.gst_amount)}
                          </span>
                        </div>
                      </OverlayTrigger>
                    </td>
                  </tr>
                 );
              })} 

              <tr className="gray-dark-bg">
                <td className="text-center blue-text">Total (₹)</td>
                {this.getHSNSACSummaryTotals(hsn_details, "basic_amount")}
                <td className="text-right"></td>
                {this.getHSNSACSummaryTotals(hsn_details, "cgst_amount")}
                <td className="text-right"></td>
                {this.getHSNSACSummaryTotals(hsn_details, "sgst_amount")}
                <td className="text-right"></td>
                {this.getHSNSACSummaryTotals(hsn_details, "igst_amount")}
                {this.getHSNSACSummaryTotals(hsn_details, "gst_amount")}
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  };

  showWarningStyle = (index, name) => {
    if (
      this.props.SNAPSHOT_LEDGER_ENTRIES_CODE &&
      this.state.inventory_voucher_status == true
    ) {
      //console.log(name, this.state.line_items[index].item_ledger_guid)
      let warning = false;

      this.state.line_items[index][name + "_warning"] = false;
      this.state.line_items[index][name + "_warning_mismatch"] = false;
      if (this.state.line_items[index].item_ledger_guid) {
        let item = this.state.item_ledger_list.filter(
          (item_ledger) =>
            item_ledger.guid == this.state.line_items[index].item_ledger_guid
        );
        //console.log(item)
        if (item.length > 0) {
          //console.log(item[0][name], this.state.line_items[index][name])
          if (!item[0][name]) {
            let warning_field = name + "_warning";
            warning = true;
            this.state.line_items[index][warning_field] = true;
          }
          if (
            item[0][name] &&
            this.state.line_items[index][name] != item[0][name]
          ) {
            let warning_field = name + "_warning_mismatch";
            warning = true;
            this.state.line_items[index][warning_field] = true;
          }
        }
      }

      if (warning) {
        return " item-warning-field";
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  render() {
    console.log("Errors -------------" , this.state.errors);
    console.log("inventory popup",this.props.inventory_data,this.state.ledgers,this.props.VIEW_ONLY_SNAPSHOT)
    let item_ledger_list = this.state.item_ledger_list.map((item) => {
      return { value: item.guid, label: item.name };
    });

    let line_items_list = this.state.line_items_list.map((item) => {
      return { value: item.id, label: item.name };
    });

    return (
      <Modal
        show={this.props.show_import_excel_popup}
        onHide={this.toggleImportInventoryPopup}
        backdrop="static"
        keyboard={false}
        centered
        className="popup-model import-excel-popup inventory_popup"
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center gap-10 w-100">Item Details
          {(!!Object.keys(this.state.errors).length ||
                  this.state.line_item_errors.length != 0 ||
                  this.state.ledger_errors.length != 0) && (
                    <span className="red-text error_right mb-0">
                      {this.state.errors.inventory_error_status}
                    </span>
                  )}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {(this.props.SNAPSHOT_LEDGER_ENTRIES_CODE ||
            this.props.VIEW_ONLY_SNAPSHOT) &&
            this.state.inventory_voucher_status == true && (
          <div className="row">
            <div className="col-xl-2 col-lg-3">
              <div className="form-group">
                <label className="lable-input">
                  Invoice#
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="invoice_id"
                  value={this.state.invoice_number}
                  disabled
                />
                {/* <small className="form-error-text">error</small> */}
              </div>
            </div>
            <div className="col-xl-2 col-lg-3">
              <div className="form-group">
                <label className="lable-input">
                  Voucher Type
                </label>
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1" className="mb-0">
                    <Form.Control as="select" 
                    // disabled={(this.props.ledger_data.ledger_list !== undefined && this.props.ledger_data.ledger_list.length === 0) ? true : false} 
                    name="voucher_type" 
                    value={this.state.voucher_type_guid}
                      onChange={this.handleVoucherChange} 
                      >
                      <option selected value="">Voucher Type</option>
                      {
                        this.props.voucherTypeList.filter((value) => value.parent_voucher_type_id == this.state.check_sales_purchase
                        ).map((value, index) => {
                          return (
                            <option key={index} value={value.guid}>
                              {value.name}
                            </option>
                          );
                        })
                      }
                    </Form.Control>
                    {this.props.voucherTypeList.length == 0 && ( 
                      <small className="error_right">
                        Please sync with tally to get Voucher Types
                      </small>
                     )}
                  </Form.Group>
                </Form>

              </div>
            </div>
            <div className="col-xl-2 col-lg-3">
              <div className="form-group">
                <label className="lable-input">
                  Voucher Date
                  <span className="red-star">*</span>
                </label>
                <DatePicker
                  className={
                    "form-control datepicker-icon"
                     +
                    (this.state.errors.voucher_creation_date
                      ? " item-error-field"
                      : "")
                  }
                  dateFormat={DatePickerFormate()}
                  onChange={(date) => {
                    this.setState({
                      voucher_creation_date: date,
                      is_dirty: true,
                      voucher_creation_date_prediction_status: 0,
                    });
                  }}
                  disabled={
                    this.props.VIEW_ONLY_SNAPSHOT ||
                    !this.props.editable
                  }
                  selected={this.state.voucher_creation_date}
                  autoComplete="off"
                  name="voucher_creation_date"
                />
                {this.state.errors.voucher_creation_date && (
                <small className="error_right">
                  {this.state.errors.voucher_creation_date}
                </small>
                 )} 
              </div>
            </div>
            <div className="col-xl-3 col-lg-3">
              <div className="form-group">
                <label className="lable-input">
                  Party Ledger
                  <span className="red-star">*</span>
                </label>
                <Form>
                  <ReactSelect
                    className={
                      "Select-Search typeed-dd"
                       +
                      (this.state.errors.party_ledger_guid
                        ? " error-border "
                        : "")
                    }
                    classNamePrefix="Select type-select "
                    name="party_ledger"
                    options={this.state.ledger_options}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={true}
                    value={this.state.ledger_options.filter(
                      (option) =>
                        option.value ==
                        this.state.party_ledger_guid
                    )}
                    onChange={(option) => {
                      let ledger =
                        this.props.ledger_data.ledger_list.filter(
                          (ledger) =>
                            ledger.guid == option.value
                        );

                      this.setState({
                        party_ledger_guid: ledger[0].guid,
                        party_ledger_id: ledger[0].id,
                        party_ledger_name: ledger[0].name,
                        party_ledger_guid_prediction_status: 0,
                        party_ledger_id_prediction_status: 0,
                        party_ledger_name_prediction_status: 0,
                        is_dirty: true,
                      });
                    }}
                    isDisabled={
                      this.props.VIEW_ONLY_SNAPSHOT ||
                      !this.props.editable
                    }
                  />
                  {this.state.errors.party_ledger_guid && (
                    <small className="error_right">
                      {this.state.errors.party_ledger_guid}
                    </small>
                   )}
                </Form>
              </div>
            </div>
             {this.state.is_common_item_ledger == 1 && ( 
            <div className="col-xl-2 col-lg-3">
              <div className="form-group">
                <label className="lable-input">
                  {this.props.inventory_data.type == 1
                    ? "Sales Ledger"
                    : "Purchase Ledger"}{" "}
                  {/* Purchase Ledger */}
                  <span className="red-star">*</span>
                </label>
                <Form>
                  <ReactSelect
                    className={
                      "Select-Search typeed-dd"
                       +
                      (this.state.errors
                        .common_item_ledger_guid
                        ? " error-border "
                        : "")
                    }
                    classNamePrefix="Select type-select "
                    name="purchase_ledger"
                    options={this.state.ledger_options}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={true}
                  value={this.state.ledger_options.filter(
                    (option) =>
                      option.value ==
                      this.state.common_item_ledger_guid
                  )}
                  onChange={(option) => {
                    let ledger =
                      this.props.ledger_data.ledger_list.filter(
                        (ledger) =>
                          ledger.guid == option.value
                      );

                    this.setState({
                      common_item_ledger_guid:
                        ledger[0].guid,
                      common_item_ledger_id: ledger[0].id,
                      common_item_ledger_name:
                        ledger[0].name,
                      common_item_ledger_guid_prediction_status: 0,
                      common_item_ledger_id_prediction_status: 0,
                      common_item_ledger_name_prediction_status: 0,
                      is_dirty: true,
                    });
                  }}
                  isDisabled={
                    this.props.VIEW_ONLY_SNAPSHOT ||
                    !this.props.editable
                  }
                  />
                 {this.state.errors
                    .common_item_ledger_guid && ( 
                  <small className="error_right">
                  {
                          this.state.errors
                            .common_item_ledger_guid
                        } 
                  </small>
                   )} 
                </Form>
              </div>
            </div>
            )} 
          </div>
           )} 

          {/* Ledger Table */}
          <div className="row">
            <div className="col-12">
              <div className="main-invoice-item-new inventory-filter mt-0 p-2">
                <div className="table-responsive overflowx-xl-inherit" style={this.state.line_items.length > 6 ? {overflowY:'scroll', height:'400px'} : {}}>
                  <table className="table ledger-table invent-table mb-0">
                    <thead>
                      <tr>
                        {(this.props
                          .SNAPSHOT_LEDGER_ENTRIES_CODE ||
                          this.props.VIEW_ONLY_SNAPSHOT) &&
                          this.state.inventory_voucher_status ==
                          true && (
                            <th
                              style={{
                                minWidth: "180px",
                                maxWidth: "180px",
                              }}
                            >
                              Item Ledger
                              <span className="red-star">*</span>
                            </th>
                           )} 
                        <th
                          style={{
                            minWidth: "220px",
                            maxWidth: "220px",
                          }}
                        >
                          Item<span className="red-star">*</span>
                        </th>
                        <th
                          style={{
                            minWidth: "100px",
                            width: "100px",
                          }}
                          className="text-left"
                        >
                          HSN/SAC
                        </th>
                        <th
                          style={{
                            minWidth: "80px",
                            width: "80px",
                          }}
                          className="text-center"
                        >
                          Quantity
                          <span className="red-star">*</span>
                        </th>
                        <th
                          style={{
                            minWidth: "80px",
                            width: "80px",
                          }}
                          className="text-right"
                        >
                          Rate<span className="red-star">*</span>
                        </th>
                        <th
                          style={{
                            minWidth: "90px",
                            width: "90px",
                          }}
                          className="text-center"
                        >
                          Per<span className="red-star">*</span>
                        </th>
                        <th
                          style={{
                            minWidth: "90px",
                            width: "90px",
                          }}
                          className="text-right"
                        >
                          GST %
                        </th>
                        <th
                          style={{
                            minWidth: "90px",
                            width: "90px",
                          }}
                          className="text-right"
                        >
                          Disc %
                        </th>
                        <th
                          style={{
                            minWidth: "160px",
                            maxWidth: "160px",
                            width:'160px'
                          }}
                          className=" text-right"
                        >
                          Amount
                          <span className="red-star">*</span>
                        </th>
                        {(this.props
                                        .SNAPSHOT_LEDGER_ENTRIES_CODE ||
                                        this.props.VIEW_ONLY_SNAPSHOT) &&
                                        this.state.is_common_item_ledger == 0 &&
                                        this.state.inventory_voucher_status ==
                                        true && (
                                          <th
                                            style={{
                                              minWidth: "160px",
                                              maxWidth: "160px",
                                            }}
                                          >
                                            {this.props.inventory_data.type == 1
                                              ? "Sales Ledger"
                                              : "Purchase Ledger"}
                                            <span className="red-star">*</span>
                                          </th>
                                        )}
                                      <th
                                        style={{
                                          minWidth: "20px",
                                          maxWidth: "20px",
                                        }}
                                      ></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.line_items.map(
                        (item, index) => {
                          return ( 
                            <tr>
                              {(this.props
                                .SNAPSHOT_LEDGER_ENTRIES_CODE ||
                                this.props.VIEW_ONLY_SNAPSHOT) &&
                                this.state
                                  .inventory_voucher_status ==
                                true && (
                                  <td className="">
                                    <div className="form-group">
                                      <Form>
                                        <ReactSelect
                                          className={
                                            "Select-Search typeed-dd"
                                             +
                                            (this.state
                                              .line_item_errors[
                                              index
                                            ] &&
                                              this.state
                                                .line_item_errors[
                                                index
                                              ].item_ledger_guid
                                              ? " error-border "
                                              : "")
                                          }
                                          classNamePrefix="Select type-select "
                                          name="item_ledger_guid"
                                          options={
                                            item_ledger_list
                                          }
                                          onChange={(e) => {
                                            this.updateLineItemData(
                                              e,
                                              "item_ledger_guid",
                                              index
                                            );
                                          }}
                                          value={item_ledger_list.filter(
                                            (option) =>
                                              option.value ==
                                              this.state
                                                .line_items[index]
                                                .item_ledger_guid
                                          )}
                                          // isDisabled={
                                          //   this.props
                                          //     .VIEW_ONLY_SNAPSHOT ||
                                          //   !this.props.editable
                                          // }
                                        />
                                      </Form>
                                    </div>
                                  </td>
                                 )} 

                              <td className="">
                                <div className="form-group">
                                  <Form>
                                    {this.state.line_items[index]
                                      .is_edit ? (
                                      <div className="form-group fieldp-icon">
                                        <input
                                          type="text"
                                          className={
                                            "form-control w-100 mr-auto "
                                             +
                                            (this.state
                                              .line_item_errors[
                                              index
                                            ] &&
                                              this.state
                                                .line_item_errors[
                                                index
                                              ].line_item_name
                                              ? " item-error-field "
                                              : "")
                                          }
                                          name="hsn_sac"
                                          value={
                                            this.state.line_items[
                                              index
                                            ].line_item_name
                                          }
                                          onChange={(e) => {
                                            let line_items = [
                                              ...this.state
                                                .line_items,
                                            ];
                                            line_items[
                                              index
                                            ].line_item_name =
                                              e.target.value;
                                            line_items[
                                              index
                                            ].line_item_update = 1;
                                            line_items[
                                              index
                                            ].line_item_name_prediction_status = 0;
                                            this.setState({
                                              line_items,
                                              is_dirty: true,
                                            });
                                          }}
                                          disabled={
                                            this.props
                                              .VIEW_ONLY_SNAPSHOT ||
                                            !this.props.editable
                                          }
                                        />
                                        <span
                                          className="dbox-tble-icon fieldp-icon-bg"
                                          onClick={() => {
                                            let line_items =
                                              this.state
                                                .line_items;
                                            line_items[
                                              index
                                            ].is_edit = false;

                                            line_items.forEach(
                                              (item, i) => {
                                                if (
                                                  this.state
                                                    .line_items[
                                                    index
                                                  ]
                                                    .line_item_id ==
                                                  item.line_item_id
                                                ) {
                                                  line_items[
                                                    i
                                                  ].line_item_name =
                                                    this.state.line_items[
                                                      index
                                                    ].line_item_name;
                                                  line_items[
                                                    i
                                                  ].line_item_update = 1;
                                                }
                                              }
                                            );

                                            const newItems =
                                              this.state.line_items_list.map(
                                                (item, i) => {
                                                  if (
                                                    item.id ==
                                                    this.state
                                                      .line_items[
                                                      index
                                                    ].line_item_id
                                                  ) {
                                                    return {
                                                      ...item,
                                                      name: this
                                                        .state
                                                        .line_items[
                                                        index
                                                      ]
                                                        .line_item_name,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              );

                                            this.setState({
                                              line_items,
                                              line_items_list:
                                                newItems,
                                            });
                                          }}
                                        >
                                          <OverlayTrigger
                                            overlay={
                                              <Tooltip id="tooltip-disabled">
                                                Done
                                              </Tooltip>
                                            }
                                          >
                                            <span className="icon-check white-icon"></span>
                                          </OverlayTrigger>
                                        </span>
                                      </div>
                                     ) : (
                                      <div className="fieldp-icon">
                                        <Creatable
                                          className={
                                            "Select-Search typeed-dd w-100 mr-auto " +
                                            (this.state
                                              .line_item_errors[
                                              index
                                            ] &&
                                              this.state
                                                .line_item_errors[
                                                index
                                              ].line_item_name
                                              ? " error-border"
                                              : "")
                                            
                                          }
                                          classNamePrefix="Select type-select "
                                          name="line_item_id"
                                          options={
                                            this.state.line_items[
                                              index
                                            ].item_ledger_guid
                                              ? this.state.line_items_list
                                                .filter(
                                                  (item) =>
                                                    item.item_ledger_guid ==
                                                    this.state
                                                      .line_items[
                                                      index
                                                    ]
                                                      .item_ledger_guid
                                                )
                                                .map((item) => {
                                                  return {
                                                    value:
                                                      item.id,
                                                    label:
                                                      item.name,
                                                  };
                                                })
                                              : line_items_list
                                          }
                                          onChange={(e) => {
                                            this.updateLineItemData(
                                              e,
                                              "line_item_id",
                                              index
                                            );
                                          }}
                                          value={{
                                            value:
                                              this.state
                                                .line_items[index]
                                                .line_item_id,
                                            label:
                                              this.state
                                                .line_items[index]
                                                .line_item_name,
                                          }}
                                          isDisabled={
                                            this.props
                                              .VIEW_ONLY_SNAPSHOT ||
                                            !this.props.editable
                                          }
                                          onCreateOption={(
                                            val
                                          ) => {
                                            let line_items = [
                                              ...this.state
                                                .line_items,
                                            ];
                                            line_items[
                                              index
                                            ].line_item_name = val;
                                            line_items[
                                              index
                                            ].line_item_id = 0;
                                            line_items[
                                              index
                                            ].line_item_update = 1;

                                            let line_items_list =
                                              [
                                                ...this.state
                                                  .line_items_list,
                                              ];
                                            line_items_list.push({
                                              name: val,
                                              id: 0,
                                            });

                                            this.setState({
                                              line_items,
                                              line_items_list,
                                            });
                                            line_items[
                                              index
                                            ].line_item_name_prediction_status = 0;

                                            this.setState({
                                              line_items,
                                              is_dirty: true,
                                            });
                                          }}
                                        />
                                        {this.state.line_items[
                                          index
                                        ].line_item_id &&
                                          this.props.editable ==
                                          true ? (
                                          <span
                                            className={
                                              "dbox-tble-icon fieldp-icon-bg" +
                                              (this.props
                                                .VIEW_ONLY_SNAPSHOT ||
                                                !this.props.editable
                                                ? "disable-opacity"
                                                : "")
                                            }
                                            onClick={() => {
                                              let line_items = [
                                                ...this.state
                                                  .line_items,
                                              ];
                                              line_items[
                                                index
                                              ].is_edit = true;
                                              this.setState({
                                                line_items,
                                              });
                                            }}
                                          >
                                            <OverlayTrigger
                                              overlay={
                                                <Tooltip id="tooltip-disabled">
                                                  Edit
                                                </Tooltip>
                                              }
                                            >
                                              <span className="icon-Edit white-icon"></span>
                                            </OverlayTrigger>
                                          </span>
                                        ) : null}
                                      </div>
                                    )} 
                                  </Form>
                                </div>
                              </td>
                              <td className=" text-center">
                                <div
                                  className={
                                    "form-group"
                                      +
                                     this.showWarningStyle(
                                       index,
                                       "hsn_sac"
                                     )
                                  }
                                >
                                  <div class="a-tooltip">
                                    <input
                                      type="text"
                                      className={
                                        "form-control text-left"
                                         +
                                        this.showWarningStyle(
                                          index,
                                          "hsn_sac"
                                        )
                                      }
                                      name="hsn_sac"
                                      value={
                                        this.state.line_items[
                                          index
                                        ].hsn_sac
                                      }
                                      onChange={(e) =>
                                        this.updateLineItemData(
                                          e,
                                          "hsn_sac",
                                          index
                                        )
                                      }
                                      id={"lineitem" + index}
                                      disabled={
                                        this.props
                                          .VIEW_ONLY_SNAPSHOT ||
                                        !this.props.editable
                                      }
                                    />
                                    {(this.state.line_items[index]
                                      .hsn_sac_warning == true ||
                                      this.state.line_items[index]
                                        .hsn_sac_warning_mismatch ==
                                      true) && (
                                        <span class="a-tooltiptext">
                                          {this.state.line_items[
                                            index
                                          ].hsn_sac_warning
                                            ? "HSN data not available in Item Ledger"
                                            : "HSN data mismatched with Item Ledger data"}
                                        </span>
                                      )} 
                                  </div>
                                </div>
                              </td>
                              <td className=" text-center">
                                <div className="form-group">
                                  <input
                                    type="input"
                                    className={
                                      "form-control form-control text-center "
                                       +
                                      (this.state
                                        .line_item_errors[
                                        index
                                      ] &&
                                        this.state.line_item_errors[
                                          index
                                        ].quantity
                                        ? " item-error-field "
                                        : "")
                                    }
                                    name="quantity"
                                    value={
                                      this.state.line_items[index]
                                        .quantity
                                    }
                                    onChange={(e) => {
                                      if (
                                        e.target.value < 0 ||
                                        isNaN(e.target.value)
                                      ) {
                                        return;
                                      } else {
                                        this.updateLineItemData(
                                          e,
                                          "quantity",
                                          index
                                        );
                                      }
                                    }}
                                    disabled={
                                      this.props
                                        .VIEW_ONLY_SNAPSHOT ||
                                      !this.props.editable
                                    }
                                    // //min="0"
                                    onBlur={() =>
                                      this.calculateLineItemAmount(
                                        index
                                      )
                                    }
                                  />
                                </div>
                              </td>
                              <td className=" text-right">
                                <div className="form-group">
                                  <CurrencyInput
                                    type="text"
                                    className={
                                      "form-control text-right"
                                       +
                                      (this.state
                                        .line_item_errors[
                                        index
                                      ] &&
                                        this.state.line_item_errors[
                                          index
                                        ].rate
                                        ? " item-error-field "
                                        : "") +
                                      (this.props
                                        .line_items_prediction_status &&
                                        this.state.line_items[index]
                                          .rate_prediction_status
                                        ? " input-bg-green "
                                        : "")
                                    }
                                    name="rate"
                                    value={
                                      this.state.line_items[index]
                                        .rate
                                    }
                                    allowNegativeValue={false}
                                    autoComplete="off"
                                    disabled={
                                      this.props
                                        .VIEW_ONLY_SNAPSHOT ||
                                      !this.props.editable
                                    }
                                    decimalsLimit={2}
                                    prefix="&#8377; "
                                    placeholder="&#8377; 0"
                                    onValueChange={(
                                      value,
                                      name
                                    ) => {
                                      let line_items = [
                                        ...this.state.line_items,
                                      ];
                                      line_items[index][name] =
                                        value;
                                      line_items[
                                        index
                                      ].rate_prediction_status = 0;
                                      line_items[
                                        index
                                      ].amount_prediction_status = 0;
                                      this.setState({
                                        line_items,
                                        is_dirty: true,
                                      });
                                    }}
                                    onBlur={() =>
                                      this.calculateLineItemAmount(
                                        index
                                      )
                                    }
                                  />
                                </div>
                              </td>
                              <td className=" text-center">
                                <div
                                  className={
                                    "form-group"
                                      +
                                     this.showWarningStyle(
                                       index,
                                       "per"
                                     )
                                  }
                                >
                                  <div class="a-tooltip">
                                    <input
                                      type="text"
                                      className={
                                        "form-control text-center"
                                         +
                                        (this.state
                                          .line_item_errors[
                                          index
                                        ] &&
                                          this.state
                                            .line_item_errors[index]
                                            .per
                                          ? " item-error-field "
                                          : "") +
                                        this.showWarningStyle(
                                          index,
                                          "per"
                                        )
                                      }
                                      name={`per${index}`}
                                      key={`per${index}`}
                                      value={
                                        this.state.line_items[
                                          index
                                        ].per
                                      }
                                      onChange={(e) =>
                                        this.updateLineItemData(
                                          e,
                                          "per",
                                          index
                                        )
                                      }
                                      disabled={
                                        this.props
                                          .VIEW_ONLY_SNAPSHOT ||
                                        !this.props.editable
                                      }
                                    />
                                    {(this.state.line_items[index]
                                      .per_warning == true ||
                                      this.state.line_items[index]
                                        .per_warning_mismatch ==
                                      true) && (
                                        <span class="a-tooltiptext">
                                          {this.state.line_items[
                                            index
                                          ].per_warning
                                            ? "Per data not available in Item Ledger"
                                            : "Per data mismatched with Item Ledger data"}
                                            
                                        </span>
                                       )} 
                                  </div>
                                </div>
                              </td>
                              <td className=" text-right">
                                <div
                                  className={
                                    "form-group"
                                     +
                                    this.showWarningStyle(
                                      index,
                                      "gst_rate"
                                    )
                                  }
                                >
                                  <div class="a-tooltip">
                                    <CurrencyInput
                                      type="text"
                                      className={
                                        "form-control text-right"
                                         +
                                        this.showWarningStyle(
                                          index,
                                          "gst_rate"
                                        )
                                      }
                                      name="gst_rate"
                                      value={
                                        this.state.line_items[
                                          index
                                        ].gst_rate
                                      }
                                      allowNegativeValue={false}
                                      decimalsLimit={2}
                                      onValueChange={(
                                        value,
                                        name
                                      ) => {
                                        if (value > 100) {
                                          return;
                                        } else {
                                          let line_items = [
                                            ...this.state
                                              .line_items,
                                          ];
                                          line_items[index][
                                            name
                                          ] = value;
                                          line_items[
                                            index
                                          ].gst_rate_prediction_status = 0;
                                          this.setState({
                                            line_items,
                                            is_dirty: true,
                                          });
                                        }
                                      }}
                                      is_amount={1}
                                      disabled={
                                        this.props
                                          .VIEW_ONLY_SNAPSHOT ||
                                        !this.props.editable
                                      }
                                    />
                                    {(this.state.line_items[index]
                                      .gst_rate_warning == true ||
                                      this.state.line_items[index]
                                        .gst_rate_warning_mismatch ==
                                      true) && (
                                        <span class="a-tooltiptext">
                                          {this.state.line_items[
                                            index
                                          ].gst_rate_warning
                                            ? "GST% data not available in Item Ledger"
                                            : "GST% data mismatched with Item Ledger data"}
                                            GST% data not available in Item Ledger
                                        </span>
                                       )}
                                  </div>
                                </div>
                              </td>
                              <td className=" text-right">
                                <div className="form-group">
                                  <CurrencyInput
                                    type="text"
                                    className={
                                      "form-control text-right"
                                    }
                                    name="discount_rate"
                                    value={
                                      this.state.line_items[index]
                                        .discount_rate
                                    }
                                    allowNegativeValue={false}
                                    decimalsLimit={2}
                                    onValueChange={(
                                      value,
                                      name
                                    ) => {
                                      if (value > 100) {
                                        return;
                                      } else {
                                        let line_items = [
                                          ...this.state
                                            .line_items,
                                        ];
                                        line_items[index][name] =
                                          value;
                                        line_items[
                                          index
                                        ].discount_rate_prediction_status = 0;
                                        line_items[
                                          index
                                        ].amount_prediction_status = 0;
                                        this.setState({
                                          line_items,
                                          is_dirty: true,
                                        });
                                      }
                                    }}
                                    is_amount={1}
                                    disabled={
                                      this.props
                                        .VIEW_ONLY_SNAPSHOT ||
                                      !this.props.editable
                                    }
                                    onBlur={() =>
                                      this.calculateLineItemAmount(
                                        index
                                      )
                                    }
                                  />
                                </div>
                              </td>

                              <td className=" text-right">
                                <div className="form-group">
                                  <CurrencyInput
                                    type="text"
                                    className={
                                      "form-control text-right"
                                       +
                                      (this.state
                                        .line_item_errors[
                                        index
                                      ] &&
                                        this.state.line_item_errors[
                                          index
                                        ].amount
                                        ? " item-error-field "
                                        : "")
                                    }
                                    name="amount"
                                    value={
                                      this.state.line_items[index]
                                        .amount
                                    }
                                    allowNegativeValue={false}
                                    autoComplete="off"
                                    disabled={
                                      this.props
                                        .VIEW_ONLY_SNAPSHOT ||
                                      !this.props.editable
                                    }
                                    decimalsLimit={2}
                                    prefix="&#8377; "
                                    placeholder="&#8377; 0"
                                  />
                                </div>
                              </td>
                              {(this.props
                                              .SNAPSHOT_LEDGER_ENTRIES_CODE ||
                                              this.props.VIEW_ONLY_SNAPSHOT) &&
                                              this.state
                                                .is_common_item_ledger == 0 &&
                                              this.state
                                                .inventory_voucher_status ==
                                              true && (
                                             
                                                <td className="">
                                                  <div className="form-group">
                                                    <Form>
                                                      <ReactSelect
                                                        className={
                                                          "Select-Search typeed-dd" +
                                                          (this.state
                                                            .line_item_errors[
                                                            index
                                                          ] &&
                                                            this.state
                                                              .line_item_errors[
                                                              index
                                                            ]
                                                              .common_item_ledger_guid
                                                            ? " error-border "
                                                            : "") +
                                                          (this.props
                                                            .line_items_prediction_status &&
                                                            this.state.line_items[
                                                              index
                                                            ]
                                                              .common_item_ledger_guid_prediction_status
                                                            ? " input-bg-green "
                                                            : "")
                                                        }
                                                        classNamePrefix="Select type-select "
                                                        name="common_item_ledger_guid"
                                                        options={
                                                          this.state
                                                            .ledger_options
                                                        }
                                                        onChange={(e) => {
                                                          this.updateLineItemData(
                                                            e,
                                                            "common_item_ledger_guid",
                                                            index
                                                          );
                                                        }}
                                                        value={this.state.ledger_options.filter(
                                                          (option) =>
                                                            option.value ==
                                                            this.state
                                                              .line_items[index]
                                                              .common_item_ledger_guid
                                                        )}
                                                        isDisabled={
                                                          this.props
                                                            .VIEW_ONLY_SNAPSHOT ||
                                                          !this.props.editable
                                                        }
                                                      />
                                                    </Form>
                                                  </div>
                                                </td>
                                              )}
                              {this.props.VIEW_ONLY_SNAPSHOT ||
                                              !this.props.editable ? null : (
                                              <td className="text-center croslt ">
                                                <span
                                                  onClick={() =>
                                                    this.removeLineItem(
                                                      index,
                                                      this.state.line_items[
                                                        index
                                                      ].id || null
                                                    )
                                                  }
                                                  className="icon-Cross-with-grey-circle size-xx cpointer"
                                                >
                                                  <span className="path1"></span>
                                                  <span className="path2 cpointer"></span>
                                                  <span className="path3"></span>
                                                </span>
                                              </td>
                                             )}
                            </tr>
                          );
                        }
                      )}

                      <tr>
                        <td className=" va-middle d-flex align-items-center gap-10">
                          {this.props.VIEW_ONLY_SNAPSHOT ||
                            !this.props.editable ? null : (
                            <button
                              onClick={() =>
                                this.addNewLineItem()
                              }
                              class="ledger-add-row"
                            >
                              <span class="icon-plus-circled"></span>
                            </button>
                           )} 
                          {this.state.errors.line_item_err && (
                            <span class="red-text error_right mb-0">
                              Please fill atleast one Line Item
                            </span>
                           )} 
                        </td>

                        {(this.props
                          .SNAPSHOT_LEDGER_ENTRIES_CODE ||
                          this.props.VIEW_ONLY_SNAPSHOT) &&
                          this.state.inventory_voucher_status ==
                          true && 
                          <td></td>
                           } 

                        <td></td>
                        <td></td>
                        <td></td>

                        <td className="ledger-total text-center">
                          Total (₹)
                        </td>
                        <td className="text-right">
                          <CurrencyInput
                            type="text"
                            className="form-control text-right green-white-bgt"
                            value={this.calculateTotalLineItemGST()}
                            allowNegativeValue={false}
                            autoComplete="off"
                            disabled={true}
                            decimalsLimit={2}
                            prefix="&#8377; "
                            placeholder="&#8377; 0"
                          />
                        </td>
                        <td className="text-right">
                          <CurrencyInput
                            type="text"
                            className="form-control text-right green-white-bgt"
                            value={this.calculateTotalLineItemDisc()}
                            allowNegativeValue={false}
                            autoComplete="off"
                            disabled={true}
                            decimalsLimit={2}
                            prefix="&#8377; "
                            placeholder="&#8377; 0"
                          />
                        </td>
                        <td className="text-right">
                          <CurrencyInput
                            type="text"
                            className="form-control text-right green-white-bgt"
                            value={this.calculateTotalLineItemAmt()}
                            allowNegativeValue={false}
                            autoComplete="off"
                            disabled={true}
                            decimalsLimit={2}
                            prefix="&#8377; "
                            placeholder="&#8377; 0"
                          />
                        </td>
                        {(this.props
                          .SNAPSHOT_LEDGER_ENTRIES_CODE ||
                          this.props.VIEW_ONLY_SNAPSHOT) &&
                          this.state.is_common_item_ledger == 0 &&
                          this.state.inventory_voucher_status ==
                          true && 
                          <td></td>
                           } 
                        <td></td>
                      </tr>

                      <tr className="separator-border">
                        {(this.props
                          .SNAPSHOT_LEDGER_ENTRIES_CODE ||
                          this.props.VIEW_ONLY_SNAPSHOT) &&
                          this.state.inventory_voucher_status ==
                          true && 
                          <td></td>
                           } 
                        <td colSpan={9}></td>
                        {(this.props
                          .SNAPSHOT_LEDGER_ENTRIES_CODE ||
                          this.props.VIEW_ONLY_SNAPSHOT) &&
                          this.state.is_common_item_ledger == 0 &&
                          this.state.inventory_voucher_status ==
                          true && 
                          <td></td>
                           } 
                      </tr>

                     {(this.props.SNAPSHOT_LEDGER_ENTRIES_CODE ||
                        this.props.VIEW_ONLY_SNAPSHOT) &&
                        this.state.inventory_voucher_status ==
                        true &&  this.state.ledgers.map(
                                        (ledger, index) => {
                                          return (
                                            <tr className="ledger-second-table">
                                              <td className="">
                                                <Form className="form-group">
                                                  <ReactSelect
                                                    //className="Select-Search typeed-dd"
                                                    className={
                                                      "Select-Search typeed-dd" +
                                                      (this.state.ledger_errors[
                                                        index
                                                      ] &&
                                                        this.state.ledger_errors[
                                                          index
                                                        ].guid
                                                        ? " error-border "
                                                        : "")
                                                    }
                                                    classNamePrefix="Select type-select "
                                                    name="guid"
                                                    options={
                                                      this.state.ledger_options
                                                    }
                                                    //onBlur={() => this.calculateLedgerAmount(index)}
                                                    onChange={(e) => {
                                                      let ledger_data =
                                                        this.props.ledger_data.ledger_list.filter(
                                                          (ledger) =>
                                                            ledger.guid ==
                                                            e.value
                                                        );

                                                      let ledgers = [
                                                        ...this.state.ledgers,
                                                      ];
                                                      ledgers[index].guid =
                                                        ledger_data[0].guid;
                                                      ledgers[index].name =
                                                        ledger_data[0].name;
                                                      ledgers[index].ledger_id =
                                                        ledger_data[0].id;

                                                      if (!ledgers[index].rate)
                                                        ledgers[index].rate =
                                                          ledger_data[0].rate_of_calc;

                                                      this.setState(
                                                        {
                                                          ledgers,
                                                          is_dirty: true,
                                                        },
                                                        () => {
                                                          this.calculateLedgerAmount(
                                                            index
                                                          );
                                                        }
                                                      );
                                                    }}
                                                    value={this.state.ledger_options.filter(
                                                      (option) =>
                                                        option.value ==
                                                        this.state.ledgers[
                                                          index
                                                        ].guid
                                                    )}
                                                    isDisabled={
                                                      this.props
                                                        .VIEW_ONLY_SNAPSHOT ||
                                                      !this.props.editable
                                                    }
                                                  />
                                                </Form>
                                              </td>
                                              <td className=""></td>
                                              <td className=""></td>
                                              <td className=""></td>
                                              <td className="">
                                                <CurrencyInput
                                                  type="text"
                                                  className="form-control text-right"
                                                  name="rate"
                                                  value={
                                                    this.state.ledgers[index]
                                                      .rate
                                                  }
                                                  allowNegativeValue={false}
                                                  autoComplete="off"
                                                  disabled={
                                                    this.props
                                                      .VIEW_ONLY_SNAPSHOT ||
                                                    !this.props.editable
                                                  }
                                                  decimalsLimit={2}
                                                  //prefix="&#8377; "
                                                  //placeholder='&#8377; 0'
                                                  onBlur={() =>
                                                    this.calculateLedgerAmount(
                                                      index
                                                    )
                                                  }
                                                  onValueChange={(
                                                    value,
                                                    name
                                                  ) => {
                                                    if (value > 100) {
                                                      return;
                                                    } else {
                                                      let ledgers = [
                                                        ...this.state.ledgers,
                                                      ];
                                                      ledgers[index][name] =
                                                        value;
                                                      this.setState({
                                                        ledgers,
                                                        is_dirty: true,
                                                      });
                                                    }
                                                  }}
                                                />
                                              </td>
                                              <td className=" text-center">
                                                <input
                                                  type="text"
                                                  className={
                                                    "form-control text-center"
                                                  }
                                                  name="per"
                                                  value="%"
                                                />
                                              </td>
                                              <td className=""></td>
                                              <td className=""></td>
                                              <td className=" text-right">
                                                <CurrencyInput
                                                  type="text"
                                                  //className="form-control text-right"
                                                  className={
                                                    "form-control text-right " +
                                                    (this.state.ledger_errors[
                                                      index
                                                    ] &&
                                                      this.state.ledger_errors[
                                                        index
                                                      ].amount
                                                      ? " item-error-field "
                                                      : "")
                                                  }
                                                  name="amount"
                                                  value={
                                                    this.state.ledgers[index]
                                                      .amount
                                                  }
                                                  allowNegativeValue={true}
                                                  autoComplete="off"
                                                  disabled={
                                                    this.props
                                                      .VIEW_ONLY_SNAPSHOT ||
                                                    !this.props.editable
                                                  }
                                                  decimalsLimit={2}
                                                  prefix="&#8377; "
                                                  placeholder="&#8377; 0"
                                                  onValueChange={(
                                                    value,
                                                    name
                                                  ) => {
                                                    let ledgers = [
                                                      ...this.state.ledgers,
                                                    ];
                                                    ledgers[index][name] =
                                                      value;
                                                    this.setState({
                                                      ledgers,
                                                      is_dirty: true,
                                                    });
                                                  }}
                                                />
                                              </td>
                                              {(this.props
                                                .SNAPSHOT_LEDGER_ENTRIES_CODE ||
                                                this.props
                                                  .VIEW_ONLY_SNAPSHOT) &&
                                                this.state
                                                  .is_common_item_ledger ==
                                                0 && <td className=""></td>}
                                              <td className="text-center croslt">
                                                {this.props
                                                  .VIEW_ONLY_SNAPSHOT ||
                                                  !this.props.editable ? null : (
                                                  <span
                                                    onClick={() =>
                                                      this.removeAdditionalLedger(
                                                        index,
                                                        this.state.ledgers[
                                                          index
                                                        ].id
                                                      )
                                                    }
                                                    className="icon-Cross-with-grey-circle size-xx cpointer"
                                                  >
                                                    <span className="path1"></span>
                                                    <span className="path2 cpointer"></span>
                                                    <span className="path3"></span>
                                                  </span>
                                                )}
                                              </td>

                                           
                                            </tr>
                                          );
                                        }
                                      )
                                    }
                      {(this.props.SNAPSHOT_LEDGER_ENTRIES_CODE ||
                        this.props.VIEW_ONLY_SNAPSHOT) &&
                        this.state.inventory_voucher_status ==
                        true && (
                          <tr>
                            <td className=" va-middle">
                              {this.props.VIEW_ONLY_SNAPSHOT ||
                                !this.props.editable ? null : (
                                <button
                                  onClick={() =>
                                    this.addNewAdditionalLedger()
                                  }
                                  class="ledger-add-row"
                                >
                                  <span class="icon-plus-circled"></span>
                                </button>
                               )} 
                            </td>
                            {(this.props
                              .SNAPSHOT_LEDGER_ENTRIES_CODE ||
                              this.props.VIEW_ONLY_SNAPSHOT) &&
                              this.state
                                .inventory_voucher_status ==
                              true && 
                              <td></td>
                               } 
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="ledger-total text-center">
                              Total (₹)
                            </td>
                            <td className=" text-right">
                              {/* <input type="text" class="form-control text-right green-white-bgt" name="zzz" value="₹ 5,36,000" /> */}
                              <CurrencyInput
                                type="text"
                                className="form-control text-right green-white-bgt"
                                value={this.calculateTotalLedgersAmt()}
                                allowNegativeValue={false}
                                autoComplete="off"
                                disabled={true}
                                decimalsLimit={2}
                                prefix="&#8377; "
                                placeholder="&#8377; 0"
                              />
                            </td>

                            {(this.props
                              .SNAPSHOT_LEDGER_ENTRIES_CODE ||
                              this.props.VIEW_ONLY_SNAPSHOT) &&
                              this.state.is_common_item_ledger ==
                              0 &&
                              this.state
                                .inventory_voucher_status ==
                              true && 
                              <td></td>
                               } 

                            <td></td>
                          </tr>
                         )} 
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* End Ledger Table */}

          {(this.props.SNAPSHOT_LEDGER_ENTRIES_CODE ||
            this.props.VIEW_ONLY_SNAPSHOT) &&
            this.state.inventory_voucher_status == true && (
          <div className="row">
            <div className="col-sm-12">
            <div className="inventory-filter d-flex align-items-center w-100">
              <div className="form-group w-100">
                <label className="lable-input">
                  Accounting Narration
                </label>
                <TextareaAutosize
                  type="text"
                  maxLength="1000"
                  className="form-control"
                  name="accounting-narration"
                  minRows={1}
                  value={this.state.accounting_narration}
                  onChange={(e) =>
                    this.setState({
                      accounting_narration: e.target.value,
                      is_dirty: true,
                    })
                  }
                  disabled={
                    this.props.VIEW_ONLY_SNAPSHOT ||
                    !this.props.editable
                  }
                ></TextareaAutosize>
              </div>
            </div>
            </div>
          </div>
           )} 

          <div className="row">
            <div className="col-sm-12">
              <div className="form-main-title-new pb-2">
                <h4 className="form-title-heading-new">
                  GST Summary
                </h4>
                <div>
                  {this.state.show_summary_section ? (
                    <span
                      class="icon-Cross-with-grey-circle size-xxvii cpointer"
                      onClick={() =>
                        this.setState({
                          show_summary_section: false,
                        })
                      }
                    >
                      <span class="path1"></span>
                      <span class="path2 cpointer"></span>
                      <span class="path3"></span>
                    </span>
                  ) : (
                    <span
                      class="icon-view-details size-xxv blue-icon cpointer"
                      onClick={() =>
                        this.setState({
                          show_summary_section: true,
                        })
                      }
                    ></span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-2">
          {this.state.show_summary_section
              ? this.renderGSTSummary()
              : null}
          </div>

          {this.props.VIEW_ONLY_SNAPSHOT ||
          !this.props.editable ? null : (
          <div className="row pb-2">
            <div className="col-12">
              <div className="reset_apply_btn_new">
                <Button
                  variant="outline-success reset_btn_new"
                   onClick={async () => {
                                                      if (this.state.is_dirty) {
                                                        if (
                                                          await confirm({
                                                            confirmation:
                                                              "Changes are not saved. Do you still want to leave?",
                                                          })
                                                        ) {
                                                          // this.props.emptyPredictionLineItems();
                                                         this.props.toggleImportInventoryPopup()
                                                        }
                                                      } else {
                                                        this.props.toggleImportInventoryPopup()
                                                      }
                                                    }}
                >
                  Cancel
                </Button>
                <Button
                  variant="success apply_btn_new"
                  onClick={() => {
                    this.setState(
                      {
                        line_item_discount_total:
                          this.calculateTotalLineItemDisc(),
                        line_item_gst_total:
                          this.calculateTotalLineItemGST(),
                        line_item_total:
                          this.calculateTotalLineItemAmt(),
                        voucher_total_amount:
                          this.calculateTotalLedgersAmt(),
                      },
                      async() => {
                        let errors = this.checkForErrors();
                        console.log('errors -----------',errors);

                        if (
                          Object.keys(errors.errors).length ==
                          0 &&
                          errors.line_item_errors.length == 0 &&
                          errors.ledger_errors.length == 0
                        ) {
                          // alert('done')
                          // return;

                          await this.saveLineItemData();
                          this.props.toggleImportInventoryPopup()
                          // this.props.emptyPredictionLineItems();
                          // this.setState({
                          //   show_edit_popup: false,
                          // });
                        } else {
                          console.log("errors eroors")
                          this.setState({
                            errors: {
                              ...errors.errors,
                              inventory_error_status:
                                "Please fill required mandatory fields.",
                            },
                            line_item_errors:
                              errors.line_item_errors,
                            ledger_errors: errors.ledger_errors,
                          });
                        }
                      }
                    );
                  }}
                >
                  Done
                </Button>
              </div>
            </div>
          </div>
           )} 
        </Modal.Body>
      </Modal>
    );
  }
}

export default ImportInventoryPopup;
